import {
  FETCH_AB_CUSTOM_BUILD_LIST,
  FETCH_AB_CUSTOM_BUILD_LIST_SUCCESS,
  FETCH_AB_CUSTOM_BUILD_LIST_ERROR,
  SET_PAGE_AB_CUSTOM_BUILD_LIST,
  AB_DUPLICATE,
  AB_DUPLICATE_SUCCESS,
  AB_DUPLICATE_ERROR,
  AB_SHARE_LINK,
  AB_SHARE_LINK_SUCCESS,
  AB_SHARE_LINK_ERROR,
  AB_DELETE,
  AB_DELETE_SUCCESS,
  AB_DELETE_ERROR,
  AB_CREATE_VOLUME,
  AB_CREATE_VOLUME_SUCCESS,
  AB_CREATE_VOLUME_ERROR,
  SET_AB_VOLUME,
  GET_LIBRARY_BUILD,
  GET_LIBRARY_BUILD_SUCCESS,
  GET_LIBRARY_BUILD_ERROR,
  SET_AB_LIBRARY,
  RESET_AB_CUSTOM_BUILD_LIST
} from "./actionTypes";

const ABInitialState = {
  ABCustomBuildList: [],
  LibraryBuildListing: [],
  shareMBLink: "",
  shareABLink: "",
  loading: false,
  search: "",
  sizePerPageAB: 10,
  pageNoAB: 1,
  sortField: "volume_id",
  sortOrder: "DESC",
  totalSizeAB: 0,
}

const types = {
  1: "kids",
  2: "students",
  3: "groups",

}

const ABReducer = (state = ABInitialState, action) => {
  const { type, payload } = action
  switch (type) {
    /*Reset ABCustom Build list */
    case RESET_AB_CUSTOM_BUILD_LIST:
      return {
        ...state,
        ABCustomBuildList: [],
      }
    /** AB list */
    case FETCH_AB_CUSTOM_BUILD_LIST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_AB_CUSTOM_BUILD_LIST_SUCCESS:
      return {
        ...state,
        ABCustomBuildList: [...state.ABCustomBuildList, ...payload.data],
        loading: false,
        totalSizeAB: payload.count,
      }
    case FETCH_AB_CUSTOM_BUILD_LIST_ERROR:
      return {
        ...state,
        loading: false,
      }

    /** AB CREATE VOLUME */
    case AB_CREATE_VOLUME:
      return {
        ...state,
        loading: true,
      };
    case AB_CREATE_VOLUME_SUCCESS:
      return {
        ...state,
        // ABCustomBuildList: action.payload,
        loading: false,
        totalSizeAB: payload.count,
      };
    case AB_CREATE_VOLUME_ERROR:
      return {
        ...state,
        loading: false,
      };

    //AB delete
    case AB_DELETE:
      return {
        ...state,
        loading: true,
      }
    case AB_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case AB_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      }
    //AB DUPLICATE
    case AB_DUPLICATE:
      return {
        ...state,
        loading: false,
      }
    case AB_DUPLICATE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case AB_DUPLICATE_ERROR:
      return {
        ...state,
        loading: false,
      }
    /** Get library build */
    case GET_LIBRARY_BUILD:
      return {
        ...state,
        loading: true
      }
    case GET_LIBRARY_BUILD_SUCCESS:
      return {
        ...state,
        loading: false,
        LibraryBuildListing: action.payload,
      }
    case GET_LIBRARY_BUILD_ERROR:
      return {
        ...state,
        loading: false
      }
    // Set PageNo Of AB Custom Build List
    case SET_PAGE_AB_CUSTOM_BUILD_LIST:
      return {
        ...state,
        pageNoAB: payload,
      }

    //AB share link
    case AB_SHARE_LINK:
      return {
        ...state,
        loading: true,
      }
    case AB_SHARE_LINK_SUCCESS:
      return {
        ...state,
        shareABLink: action.payload.shareable_link,
        loading: false,
      }
    case AB_SHARE_LINK_ERROR:
      return {
        ...state,
        loading: true,
      }

    //deleting VOLUME from redux
    case SET_AB_VOLUME:
      return {
        ...state,
        //deleting VOLUME from redux
        ABCustomBuildList: state.ABCustomBuildList.filter((item) => item.volume_id != payload.elementId.volume_id),
      }
    //deleting VOLUME from redux
    case SET_AB_LIBRARY:
      return {
        ...state,
        LibraryBuildListing: {
          ...state.LibraryBuildListing,
          [types[payload.elementMinistry]]: state.LibraryBuildListing[types[payload.elementMinistry]].filter(i => i.volume_id !== payload.elementId.volume_id)
        }
      }
    default:
      return state
  }
}
export default ABReducer
