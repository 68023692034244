import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { get, set } from "services/cookies"; //set
import { setChatBoxVisibility } from "store/actions";
import moment from "moment";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useHistory } from "react-router-dom";

const ReminderPopupModel = ({
  handleSecurityClick,
  handleSecuritySetup,
  currDate,
}) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [isOpen, setIsOpen] = useState(true);

  const onCloseReminder = user_subscription_id => {
    let pData = get("securityPaymentCloseArr") || {};
    pData[user_subscription_id] = (pData[user_subscription_id] || 0) + 1;
    set("securityPaymentCloseArr", pData);
    handleSecurityClick(user_subscription_id);
    setIsOpen(false);
  };

  // const openChatmsg = () => {
  //   dispatch(setChatBoxVisibility("maximized"));
  // };

  const setupNow = () => {
    window.location.replace(process.env.REACT_APP_REDIRECT_URL+'?security_question=true');
    handleSecuritySetup(1);
    setIsOpen(false);
  };

  return (
    <>
      <Modal isOpen={isOpen} className="modal-dialog-centered reminderPopup">
        <ModalHeader toggle={""}>Reminder Alert</ModalHeader>
        <ModalBody>
        You can now set up your security question and answer to help you in case you forget your password.
          {/* {" "}
          <span className="btn-link cursor-pointer" onClick={openChatmsg}>
            HERE
          </span>{" "}
          ! */}
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary btn-edit-profile btn-edit-profile-3"
            onClick={e => setupNow()}
          >
            SETUP NOW
          </button>
          <button
            className="btn btn-primary btn-edit-profile btn-edit-profile-3"
            onClick={e => onCloseReminder(1 + currDate)}
          >
           MAYBE NEXT TIME
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ReminderPopupModel;
