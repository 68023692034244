const AppLibraryLoader = () => {
    return (
             <div className="col-lg-4 col-md-6">
                 <div className="valume-5-cards box--1">
                    <div className="ph-col-12">
                        <div className="ph-row content-loading">
                            <div className="ph-col-10 big empty"></div>
                            <div className="ph-col-10 big"></div>
                            <div className="ph-col-10 big"></div>
                            <div className="ph-col-10 big"></div>
                            <div className="ph-col-10 big"></div>
                            <div className="ph-col-10 big"></div>
                            <div className="ph-col-10 big empty"></div>
                        </div>
                    </div>
                 </div>
             </div>
    )
}

export default AppLibraryLoader