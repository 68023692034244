export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SET_ERROR = "SET_ERROR";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SET_AUTH_DATA = "SET_AUTH_DATA";
export const LOGOUT = "LOGOUT";
export const SET_LOADING = "SET_LOADING";
export const SET_TOKEN = "SET_TOKEN";

export const SET_TOKEN_LOADING = "SET_TOKEN_LOADING";
export const SET_TOKEN_ERROR = "SET_TOKEN_ERROR";
export const SET_PROFILE = "SET_PROFILE";

export const SET_IS_AUTH = "SET_IS_AUTH";

export const SET_MENU = "SET_MENU";

export const GET_TUTORIAL_DATA = "GET_TUTORIAL_DATA";
export const GET_TUTORIAL_DATA_SUCCESS = "GET_TUTORIAL_DATA_SUCCESS";
export const GET_TUTORIAL_DATA_ERROR = "GET_TUTORIAL_DATA_ERROR";

export const GET_TUTORIAL_DATA_BY_ID = "GET_TUTORIAL_DATA_BY_ID";
export const GET_TUTORIAL_DATA_BY_ID_SUCCESS = "GET_TUTORIAL_DATA_BY_ID_SUCCESS";
export const GET_TUTORIAL_DATA_BY_ID_ERROR = "GGET_TUTORIAL_DATA_BY_ID_ERROR";

export const GET_FAQ_DATA = "GET_FAQ_DATA";
export const GET_FAQ_DATA_SUCCESS = "GET_FAQ_DATA_SUCCESS";
export const GET_FAQ_DATA_ERROR = "GET_FAQ_DATA_ERROR";

// Subscription
export const GET_SUBSCRIPTION_URL = "/listProduct"
export const GET_ACTIVE_SUBSCRIPTION_URL = "/getActiveSubscriptionsBySite"

export const GET_PROFILE_SIDEBAR_MENU = "GET_PROFILE_SIDEBAR_MENU";
export const SET_PROFILE_SIDEBAR_LOADING = "SET_PROFILE_SIDEBAR_LOADING";
