/** Message Builder list */
export const FETCH_AppBuilderDetail_LISTING = "FETCH_AppBuilderDetail_LISTING"
export const FETCH_AppBuilderDetail_LISTING_SUCCESS = "FETCH_AppBuilderDetail_LISTING_SUCCESS"
export const FETCH_AppBuilderDetail_LISTING_ERROR = "FETCH_AppBuilderDetail_LISTING_ERROR"

/** APP BUILDER ADD SECTION */
export const AB_DETAIL_ADD_SECTION = "AB_DETAIL_ADD_SECTION"
export const AB_DETAIL_ADD_SECTION_SUCCESS = "AAB_DETAIL_ADD_SUCCESS"
export const AB_DETAIL_ADD_SECTION_ERROR = "AB_DETAIL_ADD_SECTION_ERROR"

/** Restore Point List  */
export const FETCH_RESTORE_POINT_LISTING = "FETCH_RESTORE_POINT_LISTING";
export const FETCH_RESTORE_POINT_LISTING_SUCCESS = "FETCH_RESTORE_POINT_LISTING_SUCCESS";
export const FETCH_RESTORE_POINT_LISTING_ERROR = "FETCH_RESTORE_POINT_LISTING_ERROR";

/** Sections DUPLICATE  */
export const DUPLICATE_SECTIONS = "DUPLICATE_SECTIONS";
export const DUPLICATE_SECTIONS_SUCCESS = "DUPLICATE_SECTIONS_SUCCESS";
export const DUPLICATE_SECTIONS_ERROR = "DUPLICATE_SECTIONS_ERROR";

/** Sections DELETE  */
export const DELETE_SECTIONS = "DELETE_SECTIONS";
export const DELETE_SECTIONS_SUCCESS = "DELETE_SECTIONS_SUCCESS";
export const DELETE_SECTIONS_ERROR = "DELETE_SECTIONS_ERROR";

/** Drag drop section  */
export const SECTION_DRAG_DROP = "SECTION_DRAG_DROP"
export const SECTION_DRAG_DROP_SUCCESS = "SECTION_DRAG_DROP_SUCCESS"
export const SECTION_DRAG_DROP_ERROR = "SECTION_DRAG_DROP_ERROR"

/** Sections Image DropZone Element  */
export const IMAGE_SECTIONS = "IMAGE_SECTIONS";
export const IMAGE_SECTIONS_SUCCESS = "IMAGE_SECTIONS_SUCCESS";
export const IMAGE_SECTIONS_ERROR = "IMAGE_SECTIONS_ERROR";

/** App Builder save Custom Restore on change */
export const AB_SAVE_CUSTOM_RESTORE = "AB_SAVE_CUSTOM_RESTORE"
export const AB_SAVE_CUSTOM_RESTORE_SUCCESS = "AB_SAVE_CUSTOM_RESTORE_SUCCESS"
export const AB_SAVE_CUSTOM_RESTORE_ERROR = "AB_SAVE_CUSTOM_RESTORE_ERROR"

/** App Builder RESTOREList */
export const FETCH_AB_RESTORE_LIST = "FETCH_AB_RESTORE_LIST"
export const FETCH_AB_RESTORE_LIST_SUCCESS = "FETCH_AB_RESTORE_LIST_SUCCESS"
export const FETCH_AB_RESTORE_LIST_ERROR = "FETCH_AB_RESTORE_LIST_ERROR"

/**App Builder Details Import Categories Volume*/
export const FETCH_AB_CATEGORIES_VOLUME_LIST = "FETCH_AB_CATEGORIES_VOLUME_LIST"
export const FETCH_AB_CATEGORIES_VOLUME_LIST_SUCCESS = "FETCH_AB_CATEGORIES_VOLUME_LIST_SUCCESS"
export const FETCH_AB_CATEGORIES_VOLUME_LIST_ERROR = "FETCH_AB_CATEGORIES_VOLUME_LIST_ERROR"

/**App Builder Details Import Series By Categories Volume*/
export const FETCH_AB_SERIES_BY_CATEGORIES_VOLUME = "FETCH_AB_SERIES_BY_CATEGORIES_VOLUME"
export const FETCH_AB_SERIES_BY_CATEGORIES_VOLUME_SUCCESS = "FETCH_AB_SERIES_BY_CATEGORIES_VOLUME_SUCCESS"
export const FETCH_AB_SERIES_BY_CATEGORIES_VOLUME_ERROR = "FETCH_AB_SERIES_BY_CATEGORIES_VOLUME_ERROR"
