import produce from "immer";
import {
  SET_ERROR,
  SET_PROFILE,
  LOGOUT,
  SET_LOADING,
  SET_TOKEN,
  SET_IS_AUTH,
  SET_MENU,
  GET_TUTORIAL_DATA_SUCCESS,
  GET_TUTORIAL_DATA_BY_ID_SUCCESS,
  GET_FAQ_DATA_SUCCESS,
  SET_PROFILE_SIDEBAR_LOADING,
  GET_PROFILE_SIDEBAR_MENU,
} from "./actionTypes";

const initialState = {
  user: null,
  token: "",
  tutorialData:[],
  tutorialDetails:[],
  faqData:[],
  profileLoading: true,  
  profileMenuList: [],
  error: "",
  loading: false,
  isAuth: false,
  toggleMenu: true,
};

const authReducer = produce((state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ERROR:
      state.error = payload;
      break;
    case SET_PROFILE:
      state.user = payload;
      state.isAuth = true;
      break;
    case SET_TOKEN:
      state.token = payload;
      break;
    case LOGOUT:
      state.user = null;
      state.token = "";
      state.isAuth = false;
      break;
    case SET_LOADING:
      state.loading = payload;
      break;
    case SET_IS_AUTH:
      state.isAuth = payload;
      break;
    case SET_MENU:
      state.toggleMenu = !payload;
      break;
    case GET_TUTORIAL_DATA_SUCCESS:
      state.tutorialData = payload;
      break;
    case GET_TUTORIAL_DATA_BY_ID_SUCCESS:
      state.tutorialDetails = payload;
      break;
    case GET_FAQ_DATA_SUCCESS:
        state.faqData = payload;
        break;
    case GET_PROFILE_SIDEBAR_MENU:
      state.profileMenuList = payload;
      break;
    case SET_PROFILE_SIDEBAR_LOADING:
      state.profileLoading = payload;
      break;
    default:
      return state;
  }
});

export default authReducer;
