import React from 'react';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { Droppable } from "react-beautiful-dnd";
import { Link, useLocation } from "react-router-dom";
import CustomTable from 'components/common/CustomTable/CustomTable';
import Header from "components/common/Header/Header";
import Footer from "components/common/Footer/Footer";

export default function AppBuilder({ appFolderId, appFolderTitle, appFolderTitleName, appFolderState, isAppSearchValue, isAfterOnDragAppTitle }) {
  const { ABCustomBuildList } = useSelector(state => state.AppBuilderListing);
  const { ABFolderBuildList } = useSelector(state => state.AppBuilderFolderListing);

  // For Listing ABData
  let ListingDataAB = ''
  if (appFolderId !== 0) {
    if (!!ABFolderBuildList && ABFolderBuildList.length) {
      ListingDataAB = ABFolderBuildList != '' ? ABFolderBuildList : []
    }
  } else {
    if (appFolderState) {
      if (appFolderId === 0) {
        ListingDataAB = ABCustomBuildList
      } else {
        if (!!ABFolderBuildList && ABFolderBuildList.length) {
          ListingDataAB = ABFolderBuildList != '' ? ABFolderBuildList : []
        }
      }
    } else {
      ListingDataAB = ABCustomBuildList
    }
  }

  const Location = useLocation();
  const isValid = useMemo(
    () => Location.pathname.startsWith("/app-builder"),
    [Location]
  );

  return (
    <div className="main_content">
      <Header />
      <div className="main_card main_card_body">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/builder"> <span>Home</span></Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {isValid ? "App Builder" : "Lesson Builder"}
            </li>
          </ol>
        </nav>
        <div className="main_card_wrapper">
          <Droppable index="rowList" id="rowList" droppableId="rowsList">
            {
              (provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <CustomTable data={ListingDataAB} appFolderTitle={appFolderTitle} appFolderId={appFolderId} appFolderTitleName={appFolderTitleName} appFolderState={appFolderState} isAppSearchValue={isAppSearchValue} isAfterOnDragAppTitle={isAfterOnDragAppTitle} />
                </div>
              )
            }
          </Droppable>
        </div>
      </div>
      <Footer />
    </div>
  )
}
