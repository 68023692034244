import { React, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Droppable } from "react-beautiful-dnd";
import CustomTable from "components/common/CustomTable/CustomTable";
import Header from "components/common/Header/Header";
import Footer from "components/common/Footer/Footer";
import NoticeBanner from "./NoticeBanner";

const MessageBuilder = ({
  folderId,
  folderTitle,
  messageFolderName,
  folderTitleState,
  isSearchValue,
  isAfterOnDragTitle,
}) => {
  const { MBCustomBuildList } = useSelector(
    (state) => state.MessageBuilderList
  );
  const { MBFolderBuildList } = useSelector(
    (state) => state.MessageBuilderFolderList
  );
  const Location = useLocation();
  const isValid = useMemo(
    () => Location.pathname.startsWith("/app-builder"),
    [Location]
  );

  /** For Listing MBData **/
  let ListingData = "";
  if (folderId !== 0) {
    ListingData = MBFolderBuildList != "" ? MBFolderBuildList : [];
  } else {
    ListingData = MBCustomBuildList;
  }

  return (
    <div className="main_content">
      <Header />
      <div className="main_card main_card_body home-page">
        {/* <NoticeBanner /> */}
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/builder">
                {" "}
                <span>Home</span>
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {isValid ? "App Builder" : "Lesson Builder"}
            </li>
          </ol>
        </nav>
        <div className="main_card_wrapper">
          <Droppable index="rowList" id="rowList" droppableId="rowsList">
            {(provided, snapshot) => (
              <span ref={provided.innerRef} {...provided.droppableProps}>
                <CustomTable
                  data={ListingData}
                  folderTitle={folderTitle}
                  folderId={folderId}
                  messageFolderName={messageFolderName}
                  folderTitleState={folderTitleState}
                  isSearchValue={isSearchValue}
                  isAfterOnDragTitle={isAfterOnDragTitle}
                />
              </span>
            )}
          </Droppable>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MessageBuilder;
