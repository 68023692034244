import {
  FETCH_AB_TEACHING_ELEMENTS,
  FETCH_AB_TEACHING_ELEMENTS_SUCCESS,
  FETCH_AB_TEACHING_ELEMENTS_ERROR,
} from "./actionTypes";
import { axiosBuilder } from "services/api";
import { getABTeachingElements, getMBTeachingUsingCodeElements, } from "constants/urls";
import { toast } from "react-toastify";

//App Builder Teaching Elements
export const handleFetchABElements = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch(fetchABElements(true));
      const res = await axiosBuilder.post(getABTeachingElements, payload);
      if (res.data.data) {
        dispatch(fetchABElementsSuccess(res.data.data));
        cb(res.data.data)
        toast.success(res.data?.message);
      }
    } catch (err) {
      dispatch(
        fetchABElementsError(err.response?.data?.message || err.message)
      );
    }
  };
};

//App Builder Teaching Elements using code
export const handleFetchABElementsUsingCode = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch(fetchABElements(true));
      const res = await axiosBuilder.post(
        getMBTeachingUsingCodeElements,
        payload
      );
      if (res.data.data) {
        dispatch(fetchABElementsSuccess(res.data.data));
        cb(res.data.data)
        toast.success(res.data?.message);
      }
    } catch (err) {
      dispatch(
        fetchABElementsError(err.response?.data?.message || err.message)
      );
    }
  };
};

//APP Builder Teaching Elements Listing
export const fetchABElements = (data) => {
  return {
    type: FETCH_AB_TEACHING_ELEMENTS,
    payload: data,
  };
};
export const fetchABElementsSuccess = (data) => {
  return {
    type: FETCH_AB_TEACHING_ELEMENTS_SUCCESS,
    payload: data,
  };
};
export const fetchABElementsError = (data) => {
  return {
    type: FETCH_AB_TEACHING_ELEMENTS_ERROR,
    payload: data,
  };
};
