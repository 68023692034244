import { React, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { imageUrl } from "services/aws";
import TutorialLoader from "../../common/Loader/MBLoading";
import Header from "components/common/Header/Header";
import Footer from "components/common/Footer/Footer";
import { handleGetAllTutorial } from "store/actions";
import backImage from "assets/images/icon/back.png";
import growNoData from "assets/images/no-data.png";
import noThumbnail from "assets/images/noThumbnail.png";

const Tutorial = () => {
  const { tutorialData, isAuth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [active, setActive] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [search, setSearch] = useState("");

  let arr = []
  const data = tutorialData.map((item, index) => {
    return (
      (item.is_active === 0 ? arr.push(+ 1) : null)
    )
  })

  useEffect(() => {
    if (isAuth) {
      setIsLoader(false)
      dispatch(handleGetAllTutorial(3, (status) => {
        if (status) {
          setIsLoader(true)
        }
      }));
    }
  }, [dispatch]);

  const tags = useMemo(() => {
    if (!!tutorialData && !!tutorialData?.length) {
      const data = []
      tutorialData.forEach(i => { data.push(...i.tags.map(tag => tag.toLowerCase().trim())) })
      return [...new Set(data)]
    } else return []
  }, [tutorialData])


  const handleFilter = (value) => {
    if (!active.includes(value)) {
      setActive(old => [...old, value]);
    } else {
      setActive(active.filter(x => x !== value))
    }
  };

  const filteredTutorials = useMemo(
    () =>
    tutorialData.filter(
    x =>
    !!x.is_active &&
    (!active.length
    ? true && RegExp(`${search}`, "gi").test(x.title)
    : x.tags.some(i => active.includes(i)) &&
    RegExp(`${search}`, "gi").test(x.title))
    ) || [],
    [tutorialData, active, search]
  );

  const getPreviewImage = url => {
    const data = url.split(".");
    data.pop();
    let returnUrl = `${data
      .join(".")
      .replace(".s3.us-east", "-thumbnails.s3.us-east")}.jpg`;
    return returnUrl;
  };

  return (
    <div className="no-sidebar tutorials">
      <Header />
      <div className="main_card main_card_body pb-0">
        <nav aria-label="breadcrumb">
        </nav>
        <div className="main_card_wrapper tabbing-card main_card_wrapper_1">
          <div className="wrapper-heading-area filter-area">
            <div className="title">
              <Link className="" to="/builder">
                {" "}
                <img src={backImage} className="mr-2" />
                <h1 className="wrapper-heading fw-600 mb0">
                  {" "}
                  Back To Your Message
                </h1>
              </Link>
            </div>
            <div className="page-filter">
	      <div>
	        <div className="search-box">
	          <input
	            type="text"
	            className="filter-form-control"
	            placeholder="Search"
	            value={search}
	            onChange={e => setSearch(e.target.value)}
	          />
	          <i className="fa fa-search" aria-hidden="true"></i>
	        </div>
              </div>
            </div>
          </div>

          {tags && !!tags.length && (
            <div className="p10 m10 main-filter flex-wrap">
              {tags?.map((tag) => (
                <div key={tag} className={`filter-box mt-2 ${active.includes(tag) ? "activeTab" : ""}`} onClick={() => handleFilter(tag)}>
                  <span >{tag}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        {arr.length !== tutorialData.length ? <div className="main_card_wrapper tabbing-card">
          <div className="card">
            <div className="card-body">
              <div className="row">
		              {!!filteredTutorials.length ? (
                    filteredTutorials.map((build, index) => (
                       <div className="col-lg-3" key={index}>
                          <div>
                            <div className="card-img">
                              {build.featured_image_url ? <img src={build.featured_image_url.replace(imageUrl.S3ACCOUNTS_URL, imageUrl.ACCOUNT_DISPLAY_URL)} className="card-img" /> : (build.video_url ? <img src={getPreviewImage(build.video_url.replace(imageUrl.S3BUILDER_URL, imageUrl.BUILDER_DISPLAY_URL))} className="card-img" /> : <img src={noThumbnail} className="card-img" />)}
                            </div>
                            <div className="card-desc">
                              <h6>{build.title}</h6>
                              <p dangerouslySetInnerHTML={{ __html: build.content }}></p>
                            </div>
                            {
                              build.button_link ? <Link to={{ pathname: `${build.button_link}` }} target="_blank"><span className="read-more">Read More &gt;</span></Link> : <Link to={`/tutorialDetail/${build.tutorial_id}`}><span className="read-more">Read More &gt;</span></Link>
                            }
                          </div>
                        </div>
                      ))
                      ) :
                      (
                        <>
                          <div className="col-lg-12">
                            <img
                              src={growNoData}
                              className="no-data-found d-flex"
                            ></img>
                          </div>
                        </>
                      )}
              </div>
            </div>
          </div>
        </div> : (tutorialData && isLoader && arr.length === tutorialData.length ? <div className="row">
          <div className="col-lg-12">
            <img
              src={growNoData}
              className="no-data-found d-flex"
            ></img>
          </div>
        </div> : <TutorialLoader />)}
      </div>
      <Footer />
    </div>
  )
};

export default Tutorial;
