import { combineReducers } from "redux"
import authReducer from "./Auth/reducer"
import MessageBuilderBuildingBlocks from "./MessageBuilderBuildingBlocks/reducer"
import MessageBuilderDetail from "./messageBuildeElements/reducer"
import MessageBuilderExport from './messageBuilderExport/reducer'
import AppBuilderDetail from "./appSection/reducer"
import SeriesDetail from "./appSeries/reducer"
import RestorePoint from "./appSection/reducer"

//App
import AppBuilderListing from '../modules/App/AppBuilder/store/reducer'
import AppBuilderTeaching from '../modules/App/Teaching/store/reducer'
import AppBuilderExport from '../modules/App/AppBuilderDetails/store/reducer'
import AppBuilderFolderListing from '../modules/App/AppFolders/store/reducer'

//Message
import MessageBuilderList from '../modules/Message/Builder/store/reducer'
import MessageBuilderTeaching from '../modules/Message/Teaching/store/reducer'
import MessageBuilderFolderList from '../modules/Message/Folders/store/reducer'

//Site
import sitesReducer from "./Sites/reducer"

//Footer
import footerReducer from "./Footer/reducer"

//Subscription
import subscription from "./Subscription/reducer";

const rootReducer = combineReducers({
  auth:authReducer,
  MessageBuilderList,
  MessageBuilderFolderList,
  MessageBuilderBuildingBlocks,
  MessageBuilderDetail,
  AppBuilderListing,
  AppBuilderFolderListing,
  AppBuilderTeaching,
  MessageBuilderTeaching,
  MessageBuilderExport,
  AppBuilderDetail,
  SeriesDetail,
  RestorePoint,
  subscription,
  AppBuilderExport,
  footerReducer,
  site: sitesReducer,
})
export default rootReducer
