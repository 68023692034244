import {
  FETCH_AB_EXPORT_ELEMENTS,
  FETCH_AB_EXPORT_ELEMENTS_SUCCESS,
  FETCH_AB_EXPORT_ELEMENTS_ERROR
} from "./actionTypes";
import axios from "axios";
import { axiosBuilder } from "services/api";
import { getABExportElements } from "constants/urls";
import fileDownload from 'js-file-download'

//Message Builder Export Elements
export const handleFetchABExportElements = (
  payload,
) => {
  return async (dispatch) => {
    try {
      dispatch(fetchABElements(true));
      const res = await axiosBuilder.post(getABExportElements, payload);
      if (res.data.data) {
        const response = await axios({
          url: res.data.data.url,
          method: "GET",
          responseType: "blob",
        });
        fileDownload(response.data, "App_Builder_" + (new Date() / 1000) + ".pdf")
        dispatch(fetchABElementsSuccess(res.data.data.data))
      }
    } catch (err) {
      dispatch(fetchABElementsError(err.response?.data?.message || err.message))
    }
  };
};


//Message Builder Export Elements Listing
export const fetchABElements = (data) => {
  return {
    type: FETCH_AB_EXPORT_ELEMENTS,
    payload: data
  }
}
export const fetchABElementsSuccess = (data) => {
  return {
    type: FETCH_AB_EXPORT_ELEMENTS_SUCCESS,
    payload: data
  }
}
export const fetchABElementsError = (data) => {
  return {
    type: FETCH_AB_EXPORT_ELEMENTS_ERROR,
    payload: data
  }
}



