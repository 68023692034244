import {
  FETCH_MB_CUSTOM_BUILD_LIST,
  FETCH_MB_CUSTOM_BUILD_LIST_SUCCESS,
  FETCH_MB_CUSTOM_BUILD_LIST_ERROR,
  SET_PAGE_MB_CUSTOM_BUILD_LIST,
  MB_DUPLICATE,
  MB_DUPLICATE_SUCCESS,
  MB_DUPLICATE_ERROR,
  MB_SHARE_LINK,
  MB_SHARE_LINK_SUCCESS,
  MB_SHARE_LINK_ERROR,
  MB_DELETE,
  MB_DELETE_SUCCESS,
  MB_DELETE_ERROR,
  MB_UPLOAD_BUILD_IMAGE,
  MB_UPLOAD_BUILD_IMAGE_SUCCESS,
  MB_UPLOAD_BUILD_IMAGE_ERROR,
  SET_MB_LISTING,
  RESET_MB_CUSTOM_BUILD_LIST,
  SHARE_DASHBOARD_ACCESS,
} from "./actionTypes";

const MBInitialState = {
  MBCustomBuildList: [],
  MBShareLink: [],
  loading: false,
  search: "",
  sizePerPageMB: 10,
  pageNoMB: 1,
  sortField: "message_build_list_id",
  sortOrder: "DESC",
  totalSizeMB: 0,
  sharedDashboardAccess: false,
};

const MBReducer = (state = MBInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    /*Reset MBCustom Build List */
    case RESET_MB_CUSTOM_BUILD_LIST:
      return {
        ...state,
        MBCustomBuildList: [],
      };
    /** MB list */
    case FETCH_MB_CUSTOM_BUILD_LIST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MB_CUSTOM_BUILD_LIST_SUCCESS:
      return {
        ...state,
        MBCustomBuildList: [...state.MBCustomBuildList, ...payload.data],
        loading: false,
        totalSizeMB: payload.count,
      };
    case FETCH_MB_CUSTOM_BUILD_LIST_ERROR:
      return {
        ...state,
        loading: false,
      };

    //Set MbCustom List PageNo
    case SET_PAGE_MB_CUSTOM_BUILD_LIST:
      return {
        ...state,
        pageNoMB: payload,
      };

    //MB DUPLICATE
    case MB_DUPLICATE:
      return {
        ...state,
        loading: true,
      };
    case MB_DUPLICATE_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case MB_DUPLICATE_ERROR:
      return {
        ...state,
        loading: true,
      };
    //MB share link
    case MB_SHARE_LINK:
      return {
        ...state,
        loading: true,
      };
    case MB_SHARE_LINK_SUCCESS:
      return {
        ...state,
        MBShareLink: action.payload.shareable_link,
        loading: true,
      };
    case MB_SHARE_LINK_ERROR:
      return {
        ...state,
        loading: true,
      };

    //MB Upload Build Media
    case MB_UPLOAD_BUILD_IMAGE:
      return {
        ...state,
        loading: true,
      };
    case MB_UPLOAD_BUILD_IMAGE_SUCCESS:
      return {
        ...state,
        MBShareLink: action.payload,
        loading: true,
      };
    case MB_UPLOAD_BUILD_IMAGE_ERROR:
      return {
        ...state,
        loading: true,
      };

    //MB delete
    case MB_DELETE:
      return {
        ...state,
        loading: true,
      };
    case MB_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case MB_DELETE_ERROR:
      return {
        ...state,
        loading: false,
      };

    //deleting MB MESSAGE from redux
    case SET_MB_LISTING:
      return {
        ...state,
        MBCustomBuildList: [
          ...state.MBCustomBuildList.filter(
            (item) =>
              item.message_build_list_id !==
              payload.elementId.message_build_list_id
          ),
        ],
      };
    case SHARE_DASHBOARD_ACCESS:
      return {
        ...state,
        sharedDashboardAccess: payload,
      };
    default:
      return state;
  }
};
export default MBReducer;
