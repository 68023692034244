import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import "assets/css/style.css";
import "assets/css/responsive.css";
import { get } from "services/cookies";

ReactDOM.render(
  <div className={`conntent_wrapper admin_side ${!!get("mainToken") && window.location === window.parent.location ? "admin-menu" : ""} `} id="sidemenu">
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </div>,
  document.getElementById("root")
);
