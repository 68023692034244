import { ReactComponent as StarIcon } from "assets/images/new-banner-star-icon.svg";
import { ReactComponent as CloseBannerIcon } from "assets/images/close-icon.svg";
import { useState } from "react";

const NoticeBanner = () => {
  const [banner, setBanner] = useState(true);

  return (
    <>
      {banner && (
        <div className="notice-banner">
          <div className="text-section">
            <div className="text-one">
              <StarIcon style={{ marginRight: "6px" }} />
              <span>A new Grow Lesson Builder is coming!</span>
            </div>
            <div className="text-two">
              In June, we will introduce a new Lesson Builder experience for all
              users. Don’t worry, you’ll still be able to access this current
              version until August 1, 2024.
            </div>
          </div>
          <div className="close-section">
            <button
              className="banner-close-btn"
              onClick={() => setBanner(false)}
            >
              <CloseBannerIcon />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default NoticeBanner;
