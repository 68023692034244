
/** SeriesDetail list */
export const FETCH_APP_SERIES_LISTING = "FETCH_APP_SERIES_LISTING"
export const FETCH_APP_SERIES_LISTING_SUCCESS = "FETCH_APP_SERIES_LISTING_SUCCESS"
export const FETCH_APP_SERIES_LISTING_ERROR = "FETCH_APP_SERIES_LISTING_ERROR"

/** SeriesDetail DUPLICATE */
export const SERIES_DETAIL_DUPLICATE = "SeriesDetail_DUPLICATE"
export const SERIES_DETAIL_DUPLICATE_SUCCESS = "SeriesDetail_DUPLICATE_SUCCESS"
export const SERIES_DETAIL_DUPLICATE_ERROR = "SeriesDetail_DUPLICATE_ERROR"

/** SeriesDetailListing DELETE */
export const SERIES_DETAIL_DELETE = "SeriesDetail_DELETE"
export const SERIES_DETAIL_DELETE_SUCCESS = "SeriesDetail_DELETE_SUCCESS"
export const SERIES_DETAIL_DELETE_ERROR = "SeriesDetail_DELETE_ERROR"

/** SeriesDetail SHARE LINK */
export const SERIES_DETAIL_SHARE_LINK = "SeriesDetail_SHARE_LINK"
export const SERIES_DETAIL_SHARE_LINK_SUCCESS = "SeriesDetail_SHARE_LINK_SUCCESS"
export const SERIES_DETAIL_SHARE_LINK_ERROR = "SeriesDetail_SHARE_LINK_ERROR"

/** SeriesElement List  */
export const FETCH_SERIES_ELEMENT_LISTING = "FETCH_SERIES_ELEMENT_LISTING"
export const FETCH_SERIES_ELEMENT_LISTING_SUCCESS = "FETCH_SERIES_ELEMENT_LISTING_SUCCESS"
export const FETCH_SERIES_ELEMENT_LISTING_ERROR = "FETCH_SERIES_ELEMENT_LISTING_ERROR"

/** Series Add  */
export const ADD_SERIES = "ADD_SERIES"
export const ADD_SERIES_SUCCESS = "ADD_SERIES_SUCCESS"
export const ADD_SERIES_ERROR = "ADD_SERIES_ERROR"

/** Series SAVE  */
export const SAVE_APP_VOLUME_DATA = " SAVE_APP_VOLUME_DATA"
export const SAVE_APP_VOLUME_DATA_SUCCESS = " SAVE_APP_VOLUME_DATA_SUCCESS"
export const SAVE_APP_VOLUME_DATA_ERROR = " SAVE_APP_VOLUME_DATA_ERROR"


/** Drag drop series  */
export const SERIES_DRAG_DROP = "SERIES_DRAG_DROP"
export const SERIES_DRAG_DROP_SUCCESS = "SERIES_DRAG_DROP_SUCCESS"
export const SERIES_DRAG_DROP_ERROR = "SERIES_DRAG_DROP_ERROR"


/** Series Title SAVE  */
export const SAVE_APP_SERIES_TITLE = "SAVE_APP_SERIES_TITLE"
export const SAVE_APP_SERIES_TITLE_SUCCESS = "SAVE_APP_SERIES_TITLE_SUCCESS"
export const SAVE_APP_SERIES_TITLE_ERROR = "SAVE_APP_SERIES_TITLE_ERROR"

/** To update redux when element is deleted */
export const SET_AB_SECTION = "SET_AB_SECTION"

/** To update redux when SERIES is deleted */
export const SET_AB_SERIES = "SET_AB_SERIES"

//On/Off Slider App Elements
export const SET_VISIBLE_APP_UPDATE = "SET_VISIBLE_APP_UPDATE"

/**App Builder Series Import*/
export const FETCH_AB_SERIES_IMPORT = "FETCH_AB_SERIES_IMPORT"
export const FETCH_AB_SERIES_IMPORT_SUCCESS = "FETCH_AB_SERIES_IMPORT_SUCCESS"
export const FETCH_AB_SERIES_IMPORT_ERROR = "FETCH_AB_SERIES_IMPORT_ERROR"

export const AB_VOLUME_LENGTH = "AB_VOLUME_LENGTH";

export const AB_RESET_SERIES_DATA = "AB_RESET_SERIES_DATA";
