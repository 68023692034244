import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getActiveSubscriptionsBySite, handleABLibraryDuplicate } from "store/actions";
import { handleFetchABListing, handleGetAppLibraryBuild } from "../store/action";
import Header from "components/common/Header/Header";
import Footer from "components/common/Footer/Footer";
import AppLibraryLoader from "components/common/Loader/AppLibraryLoader";
import { imageUrl } from "services/aws";
import rightArrow from 'assets/images/eye.png';
import growNoData from "assets/images/no-data.png";

const AppBuilderLibraryCard = ({ user, data, DefaultImage, loading, isDuplicate }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [duplicateLoading, setDuplicateLoading] = useState(false);
  const [isAppBuildId, setIsAppBuildId] = useState(false);
  const { totalSizeAB } = useSelector((state) => state.AppBuilderListing);
  const [buildCount, setBuildCount] = useState(0)

  const handleListDuplicate = (data) => {
    setIsAppBuildId(data.volume_id)
    setDuplicateLoading(true);
    dispatch(
      handleABLibraryDuplicate({ volume_id: data.volume_id },
        (id) => {
          if (id) {
            history.push("/app-builder/edit/" + id);
            dispatch(handleFetchABListing({
              page_no: 1,
              page_record: 10,
              sortField: "created_datetime",
              sortOrder: "DESC",
            }));
          }
          setDuplicateLoading(false);
        }
      ), [dispatch])
  };

  useEffect(() => {
    dispatch(getActiveSubscriptionsBySite({ site_id: 3 }, (status) => {
        setBuildCount(status.is_system_volume_count);
    }))
  },[dispatch])

  return (
    <div className="valume-5-cards box">
      <div className="card-header kids-heading-text">
        {data.volume_title ? data.volume_title : "NEW MESSAGE1"}
      </div>

      <ul className="list-group list-group-flush lists-of-content">
        {data.gb_series_buildlists.map((buildList, index) => {
          return (
            <li className="list-group-item img-with-text" key={index}>
              <div className="imageWithText">
                {
                  buildList.media_url === "" ? <img src={DefaultImage} alt="blast off" /> :
                    <img
                      src={buildList.media_url === "" ? <div>Load</div> : buildList.media_url.replace(imageUrl.S3BUILDER_URL, imageUrl.BUILDER_DISPLAY_URL)}
                      alt="blastoff"
                    />
                }
                <span className="title">
                  {buildList.series_buildlist_title}
                </span>
              </div>
              <div className="view-icon">
                <Link className="inner-tab-box"
                  to={`app-builder/view/${data.volume_id}?code=${buildList.public_code}`}
                  key={index}
                  title="View"
                >
                  <div className="arrow">
                    <img src={rightArrow} alt="right-arrow" />
                  </div>
                </Link>
              </div>
            </li>
          )
        }
        )}
      </ul>

      {duplicateLoading && isAppBuildId === data.volume_id ? (
        <button className={loading ? "d-none" : "btn duplicate-button"}>
          Duplicating...
        </button>
      ) : (!!user && user.user_role === 3 && !isDuplicate && buildCount >= 12 ? "" :
        <button
          className={loading ? "d-none" : "btn duplicate-button"}
          onClick={() => handleListDuplicate(data)}
        >
          Duplicate
        </button>
      )}
    </div>
  )
}

const AppBuilderLibrary = () => {
  const { StoreAppLibraryFolderTitle, StoreAppLibraryFolderId } = useSelector((state) => state.AppBuilderFolderListing);
  const { LibraryBuildListing, loading } = useSelector((state) => state.AppBuilderListing);
  const { user } = useSelector((state) => state.auth);

  const [isDuplicate, setIsDuplicate] = useState(false);

  const dispatch = useDispatch();

  let DefaultImage = "https://wp.builder.stuffyoucanuse.dev/wp-content/themes/the-grow-builder/assets/images/placeholder_msg.png";

  useEffect(() => {
    dispatch(handleGetAppLibraryBuild({
      volume_id: StoreAppLibraryFolderId,
    }));
  }, [dispatch, StoreAppLibraryFolderId])

  useEffect(() => {
    dispatch(getActiveSubscriptionsBySite({ site_id: 3 }, (status) => {
      if (status.count > 0) {
        setIsDuplicate(true)
      }
    }))
  }, [dispatch])

  // For Library Loading
  const loadplacehonder = [1, 2, 3];

  return (
    <div className="main_content">
      <Header />
      <div className="main_card main_card_body">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/builder"> <span>Home</span></Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              APP BUILDER LIBRARY
            </li>
          </ol>
        </nav>
        <div className="main_card_wrapper tabbing-card">
          <div className="wrapper-heading-area filter-area">
            <div className="title">
              <h1 className="wrapper-heading fw-600">
                {StoreAppLibraryFolderTitle}
              </h1>
            </div>
          </div>
        </div>
        <div className="tabbing-area no-background">
          <div className="library-details">
            <div className="valume-5-1">
              {LibraryBuildListing.kids === "" && LibraryBuildListing.students === "" && LibraryBuildListing.groups === "" ? (
                <div className="row">
                  <div className="col-lg-12">
                    <img
                      src={growNoData}
                      className="no-data-found d-flex"
                    ></img>
                  </div>
                </div>
              ) : (
                <div className="row">
                  {loading ? (loadplacehonder.map((i) => <AppLibraryLoader key={i} />)) : (
                    <>
                      {StoreAppLibraryFolderTitle === "3 Month Pack" || StoreAppLibraryFolderTitle === "Free Trial" ? (
                        <>
                          {
                            /***** Kids Blocks *******/
                            (LibraryBuildListing.kids && LibraryBuildListing.kids.length > 0 && LibraryBuildListing.kids[0].gb_series_buildlists.length > 0) && (
                              <div className=" col-lg-4 col-md-6 col-sm-12">
                                {LibraryBuildListing.kids.map((data, ind) => {
                                  return (
                                    <AppBuilderLibraryCard key={ind} user={user} data={data} DefaultImage={DefaultImage} isDuplicate={isDuplicate} loading={loading} />
                                  );
                                })}
                              </div>
                            )
                          }
                          {
                            /***** Students Blocks *******/
                            (LibraryBuildListing.students && LibraryBuildListing.students.length > 0 && LibraryBuildListing.students[0].gb_series_buildlists.length > 0) && (
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                {LibraryBuildListing.students.map((data, ind) => {
                                  return (
                                    <AppBuilderLibraryCard key={ind} user={user} data={data} DefaultImage={DefaultImage} isDuplicate={isDuplicate} loading={loading} />
                                  );
                                })}
                              </div>
                            )
                          }
                        </>
                      ) : (
                        <>
                          {
                            /***** Kids Blocks *******/
                            (LibraryBuildListing.kids && LibraryBuildListing.kids.length > 0 && LibraryBuildListing.kids[0].gb_series_buildlists.length > 0) && (
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                {LibraryBuildListing.kids.map((data, ind) => {
                                  return (
                                    <AppBuilderLibraryCard key={ind} user={user} data={data} DefaultImage={DefaultImage} isDuplicate={isDuplicate} loading={loading} />
                                  );
                                })}
                              </div>
                            )
                          }
                          {
                            /***** Students Blocks *******/
                            (LibraryBuildListing.students && LibraryBuildListing.students.length > 0 && LibraryBuildListing.students[0].gb_series_buildlists.length > 0) && (
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                {LibraryBuildListing.students.map((data, ind) => {
                                  return (
                                    <AppBuilderLibraryCard key={ind} user={user} data={data} DefaultImage={DefaultImage} isDuplicate={isDuplicate} loading={loading} />
                                  );
                                })}
                              </div>
                            )
                          }
                          {
                            /***** Groups Blocks *******/
                            (LibraryBuildListing.groups && LibraryBuildListing.groups.length > 0 && LibraryBuildListing.groups[0].gb_series_buildlists.length > 0) && (
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                {LibraryBuildListing.groups.map((data, ind) => {
                                  return (
                                    <AppBuilderLibraryCard key={ind} user={user} data={data} DefaultImage={DefaultImage} isDuplicate={isDuplicate} loading={loading} />
                                  );
                                })}
                              </div>
                            )}
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AppBuilderLibrary;
