import {
  FETCH_MB_EXPORT_ELEMENTS,
  FETCH_MB_EXPORT_ELEMENTS_SUCCESS,
  FETCH_MB_EXPORT_ELEMENTS_ERROR,
  SET_MB_EXPORT_LOADING,
} from "./actionTypes";

const MBElementsExportInitialState = {
  MBExportElements: [],
  loading:false,
  mbExpportLoading: false,
}


const MBExportElementReducer = (state = MBElementsExportInitialState, action) => {
  const { type, payload } = action
  switch (type) {
    /** App Builder Export list */
    case FETCH_MB_EXPORT_ELEMENTS:
      return {
        ...state,
        mbExpportLoading: true,
      }
    case FETCH_MB_EXPORT_ELEMENTS_SUCCESS:
      return {
        ...state,
        mbExpportLoading: false,
        MBExportElements: action.payload,
        totalSize: payload.length,
      }
    case FETCH_MB_EXPORT_ELEMENTS_ERROR:
      return {
        ...state,
        mbExpportLoading: true,
      }
    case SET_MB_EXPORT_LOADING:
      return {
        ...state,
        mbExpportLoading: payload,
      }
    default:
      return state
  }
}
export default MBExportElementReducer
