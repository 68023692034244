import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { imageUrl } from "services/aws";
import { handleGetTutorialById } from "store/actions";
import Header from "components/common/Header/Header";
import Footer from "components/common/Footer/Footer";
import backImage from "assets/images/icon/back.png";
import noThumbnail from "assets/images/noThumbnail.png";

const TutorialDetail = () => {
  const { tutorialDetails } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(handleGetTutorialById(params.id));
  }, [dispatch]);

  return (
    <div className="no-sidebar tutorialDetails">
      <Header />
      <div className="main_card main_card_body pb-0">
        <div className="main_card_wrapper tabbing-card main_card_wrapper_1">
          <div className="wrapper-heading-area filter-area">
            <div className="title">
              <Link className="" to="/tutorial">
                {" "}
                <img src={backImage.replace(imageUrl.S3ACCOUNTS_URL, imageUrl.ACCOUNT_DISPLAY_URL)} className="mr-2" alt="back" />
                <h1 className="wrapper-heading fw-600 mb0">
                  {" "}
                  {tutorialDetails.title}
                </h1>
              </Link>
            </div>
            <div className="page-filter">
            </div>
          </div>
        </div>
        <div className="main_card_wrapper tabbing-card">
          <div className="card">
            <div className="card-body">
              <div className="tutorial-media-file">
                {tutorialDetails.video_url ? <video src={tutorialDetails.video_url.replace(imageUrl.S3ACCOUNTS_URL, imageUrl.ACCOUNT_DISPLAY_URL)} controls className="video"></video> : (tutorialDetails.featured_image_url ? <img src={tutorialDetails.featured_image_url} className="image"  alt="details"/> : <img src={noThumbnail} className="image" alt="details"/>)}
              </div>
              <div className="tutorial-title">
                <h5>{tutorialDetails.title}</h5>
              </div>
              <div className="tutorial-description">
                <p dangerouslySetInnerHTML={{ __html: tutorialDetails.content }}></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
};

export default TutorialDetail;
