import {
  FETCH_AppBuilderDetail_LISTING,
  FETCH_AppBuilderDetail_LISTING_SUCCESS,
  FETCH_AppBuilderDetail_LISTING_ERROR,
  AB_DETAIL_ADD_SECTION,
  AB_DETAIL_ADD_SECTION_SUCCESS,
  AB_DETAIL_ADD_SECTION_ERROR,
  FETCH_RESTORE_POINT_LISTING,
  FETCH_RESTORE_POINT_LISTING_SUCCESS,
  FETCH_RESTORE_POINT_LISTING_ERROR,
  DUPLICATE_SECTIONS,
  DUPLICATE_SECTIONS_SUCCESS,
  DUPLICATE_SECTIONS_ERROR,
  DELETE_SECTIONS,
  DELETE_SECTIONS_SUCCESS,
  DELETE_SECTIONS_ERROR,
  SECTION_DRAG_DROP,
  SECTION_DRAG_DROP_SUCCESS,
  SECTION_DRAG_DROP_ERROR,
  IMAGE_SECTIONS,
  IMAGE_SECTIONS_SUCCESS,
  IMAGE_SECTIONS_ERROR,
  AB_SAVE_CUSTOM_RESTORE,
  AB_SAVE_CUSTOM_RESTORE_SUCCESS,
  AB_SAVE_CUSTOM_RESTORE_ERROR,
  FETCH_AB_RESTORE_LIST,
  FETCH_AB_RESTORE_LIST_SUCCESS,
  FETCH_AB_RESTORE_LIST_ERROR,
  FETCH_AB_CATEGORIES_VOLUME_LIST,
  FETCH_AB_CATEGORIES_VOLUME_LIST_SUCCESS,
  FETCH_AB_CATEGORIES_VOLUME_LIST_ERROR,
  FETCH_AB_SERIES_BY_CATEGORIES_VOLUME,
  FETCH_AB_SERIES_BY_CATEGORIES_VOLUME_SUCCESS,
  FETCH_AB_SERIES_BY_CATEGORIES_VOLUME_ERROR,
} from "./actionTypes";

import { axiosBuilder } from "services/api";
import {
  getABTeachingElements,
  AppBuilderDetailAddSection,
  RestorePoint,
  DuplicateSections,
  DeleteSections,
  buildElementDrag,
  imageDropZone,
  createCustomABRestorePoint,
  getABRestoreList,
  ImportCategoriesVolume,
  ImportSeries,
} from "constants/urls";
import {
  handleFetchSeriesDetail,
  handleFetchSeriesElement,
} from "../appSeries/actions";

//AppBuilderDetail Listing
export const handleFetchAppBuilderDetailListing = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(fetchAppBuilderDetailListing(true));
      const res = await axiosBuilder.post(getABTeachingElements, payload);
      if (res.data.data.data) {
        dispatch(fetchAppBuilderDetailListingSuccess(res.data.data.data));
      }
    } catch (err) {
      dispatch(
        fetchAppBuilderDetailListingError(
          err.response?.data?.message || err.message
        )
      );
    }
  };
};

//AB DETAILS ADD SECTION
export const handleAppBuilderDetailAddSection = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch(ABDetailAddSection(true));
      const res = await axiosBuilder.post(AppBuilderDetailAddSection, payload);

      if (res.data) {
        dispatch(ABDetailAddSectionSuccess(res.data.data));
        dispatch(
          handleFetchSeriesElement({
            series_build_list_id: res.data.data.build_list_id,
          })
        );
        cb(res.data.data);
        //toast.success(res.data?.message);
      }
    } catch (err) {
      dispatch(
        ABDetailAddSectionError(err.response?.data?.message || err.message)
      );
    }
  };
};

//Section Duplicate
export const handleDuplicateSections = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(duplicateSections(true));
      const res = await axiosBuilder.post(DuplicateSections, payload);

      if (res.data.data.data) {
        dispatch(duplicateSectionsSuccess(res.data.data.data));
        dispatch(handleFetchAppBuilderDetailListing());
      }
    } catch (err) {
      dispatch(
        duplicateSectionsError(err.response?.data?.message || err.message)
      );
    }
  };
};

//Section Delete
export const handleDeleteSections = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(deleteSections(true));
      const res = await axiosBuilder.post(DeleteSections, payload);

      if (res.data.data.data) {
        dispatch(deleteSectionsSuccess(res.data.data.data));
      }
    } catch (err) {
      dispatch(deleteSectionsError(err.response?.data?.message || err.message));
    }
  };
};

//Create restore point header listing
export const handleFetchRestorePoint = (payload, cb = () => { }) => {
  return async (dispatch) => {
    try {
      dispatch(fetchRestorePoint(true));
      const res = await axiosBuilder.post(RestorePoint, payload);
      if (res.data.data) {
        dispatch(fetchRestorePointSuccess(res.data.data));
        cb(res.data.data);
      }
    } catch (err) {
      dispatch(
        fetchRestorePointError(err.response?.data?.message || err.message)
      );
    }
  };
};

//drag drop section
export const handleSectionDragDrop = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(sectionDragDrop(true));
      const res = await axiosBuilder.post(buildElementDrag, payload);
      if (res.data.data.data) {
        dispatch(sectionDragDropSuccess(res.data.data.data));
      }
    } catch (err) {
      dispatch(
        sectionDragDropError(err.response?.data?.message || err.message)
      );
    }
  };
};
/** Sections Image DropZone Element  */
export const handleImageSectionDropZone = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch(imageSections(true));
      const res = await axiosBuilder.post(imageDropZone, payload);
      if (res.data) {
        dispatch(imageSectionsSuccess(res.data.data.data));
        if (payload.volume_id) {
          dispatch(
            handleFetchSeriesDetail({
              volume_id: payload.volume_id,
            })
          );
        }
        dispatch(
          handleFetchSeriesElement({
            series_build_list_id: payload.series_build_list_id,
          })
        );
        cb(payload.media_url);
      }
    } catch (err) {
      dispatch(imageSectionsError(err.response?.data?.message || err.message));
    }
  };
};


/** Save Heading and ABElement Title On Foucs Out **/
export const handleSaveHeadingTitle = (payload, cb = () => { }) => {
  return async (dispatch) => {
    try {
      dispatch(imageSections(true));
      const res = await axiosBuilder.post(imageDropZone, payload);
      if (res.data) {
        dispatch(imageSectionsSuccess(res.data.data.data));
        cb(res.data.data)
      }
    } catch (err) {
      dispatch(imageSectionsError(err.response?.data?.message || err.message));
    }
  };
};

/** Sections ABElement Description On Foucs Out  */
export const handleDescSectionDropZone = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch(imageSections(true));
      const res = await axiosBuilder.post(imageDropZone, payload);
      if (res.data) {
        dispatch(imageSectionsSuccess(res.data.data.data));
        cb(res.data.data)
      }
    } catch (err) {
      dispatch(imageSectionsError(err.response?.data?.message || err.message));
    }
  };
};



//AB save custom restore
export const handleSaveABCustomRestore = (payload, cb) => {
  return async (dispatch) => {
    try {
      const res = await axiosBuilder.post(createCustomABRestorePoint, payload);
      if (res.data.data) {
        dispatch(saveABCustomRestoreSuccess(res.data.data));
        cb(res.data.data)
      }
    } catch (err) {
      dispatch(
        saveABCustomRestoreError(err.response?.data?.message || err.message)
      );
    }
  };
};

//AB restore listing
export const handleABRestoreList = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch(fetchABRestoreList(true));
      const res = await axiosBuilder.post(getABRestoreList, payload);
      if (res.data.data) {
        dispatch(fetchABRestoreListSuccess(res.data.data));
        dispatch(
          handleFetchSeriesElement({
            series_build_list_id: payload.series_build_list_id,
          })
        );
        cb(res.data.data);
        //toast.success(res.data?.message);
      }
    } catch (err) {
      dispatch(
        fetchABRestoreListError(err.response?.data?.message || err.message)
      );
    }
  };
};

/**App Builder Details Import Categories Volume*/
export const handleABCategoriesVolumeList = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch(fetchABCategoriesVolumeList(true));
      const res = await axiosBuilder.post(ImportCategoriesVolume, payload);
      if (res.data.data.rows) {
        dispatch(fetchABCategoriesVolumeListSuccess(res.data.data.rows));
        cb(res.data.data.rows);
      }
    } catch (err) {
      dispatch(
        fetchABCategoriesVolumeListError(
          err.response?.data?.message || err.message
        )
      );
    }
  };
};

/**App Builder Details Import Series By Categories Volume*/
export const handleABSeriesInCategoriesVolume = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(fetchABSeriesByCategoriesVolume(true));
      const res = await axiosBuilder.post(ImportSeries, payload);
      if (res.data.data) {
        dispatch(fetchABSeriesByCategoriesVolumeSuccess(res.data.data));
      }
    } catch (err) {
      dispatch(
        fetchABSeriesByCategoriesVolumeError(
          err.response?.data?.message || err.message
        )
      );
    }
  };
};

export const handleElementTitleChange = (payload, cb) => {
  return async (dispatch) => {
    try {
      //dispatch(ABDetailAddSection(true));
      const res = await axiosBuilder.post(AppBuilderDetailAddSection, payload);
      if (res.data) {
        dispatch(ABDetailAddSectionSuccess(res.data.data));
        cb(res.data.data);
      }
    } catch (err) {
      dispatch(
        ABDetailAddSectionError(err.response?.data?.message || err.message)
      );
    }
  };
};

//AB Elements save custom restore point
export const saveABCustomRestore = (data) => {
  return {
    type: AB_SAVE_CUSTOM_RESTORE,
    payload: data,
  };
};
export const saveABCustomRestoreSuccess = (data) => {
  return {
    type: AB_SAVE_CUSTOM_RESTORE_SUCCESS,
    payload: data,
  };
};
export const saveABCustomRestoreError = (data) => {
  return {
    type: AB_SAVE_CUSTOM_RESTORE_ERROR,
    payload: data,
  };
};
//AppBuilderDetail Listing
export const fetchAppBuilderDetailListing = (data) => {
  return {
    type: FETCH_AppBuilderDetail_LISTING,
    payload: data,
  };
};
export const fetchAppBuilderDetailListingSuccess = (data) => {
  return {
    type: FETCH_AppBuilderDetail_LISTING_SUCCESS,
    payload: data,
  };
};
export const fetchAppBuilderDetailListingError = (data) => {
  return {
    type: FETCH_AppBuilderDetail_LISTING_ERROR,
    payload: data,
  };
};

/** Sections Image DropZone Element  */
export const imageSections = (data) => {
  return {
    type: IMAGE_SECTIONS,
    payload: data,
  };
};
export const imageSectionsSuccess = (data) => {
  return {
    type: IMAGE_SECTIONS_SUCCESS,
    payload: data,
  };
};
export const imageSectionsError = (data) => {
  return {
    type: IMAGE_SECTIONS_ERROR,
    payload: data,
  };
};

//AB DETAILS ADD SECTION
export const ABDetailAddSection = (data) => {
  return {
    type: AB_DETAIL_ADD_SECTION,
    payload: data,
  };
};
export const ABDetailAddSectionSuccess = (data) => {
  return {
    type: AB_DETAIL_ADD_SECTION_SUCCESS,
    payload: data,
  };
};
export const ABDetailAddSectionError = (data) => {
  return {
    type: AB_DETAIL_ADD_SECTION_ERROR,
    payload: data,
  };
};

//Sections Duplicate
export const duplicateSections = (data) => {
  return {
    type: DUPLICATE_SECTIONS,
    payload: data,
  };
};
export const duplicateSectionsSuccess = (data) => {
  return {
    type: DUPLICATE_SECTIONS_SUCCESS,
    payload: data,
  };
};
export const duplicateSectionsError = (data) => {
  return {
    type: DUPLICATE_SECTIONS_ERROR,
    payload: data,
  };
};

//Section Delete
export const deleteSections = (data) => {
  return {
    type: DELETE_SECTIONS,
    payload: data,
  };
};
export const deleteSectionsSuccess = (data) => {
  return {
    type: DELETE_SECTIONS_SUCCESS,
    payload: data,
  };
};
export const deleteSectionsError = (data) => {
  return {
    type: DELETE_SECTIONS_ERROR,
    payload: data,
  };
};

//Create restore point header listing
export const fetchRestorePoint = (data) => {
  return {
    type: FETCH_RESTORE_POINT_LISTING,
    payload: data,
  };
};
export const fetchRestorePointSuccess = (data) => {
  return {
    type: FETCH_RESTORE_POINT_LISTING_SUCCESS,
    payload: data,
  };
};
export const fetchRestorePointError = (data) => {
  return {
    type: FETCH_RESTORE_POINT_LISTING_ERROR,
    payload: data,
  };
};

//Section Drag drop
export const sectionDragDrop = (data) => {
  return {
    type: SECTION_DRAG_DROP,
    payload: data,
  };
};
export const sectionDragDropSuccess = (data) => {
  return {
    type: SECTION_DRAG_DROP_SUCCESS,
    payload: data,
  };
};
export const sectionDragDropError = (data) => {
  return {
    type: SECTION_DRAG_DROP_ERROR,
    payload: data,
  };
};

//AB Elements restore Listing
export const fetchABRestoreList = (data) => {
  return {
    type: FETCH_AB_RESTORE_LIST,
    payload: data,
  };
};
export const fetchABRestoreListSuccess = (data) => {
  return {
    type: FETCH_AB_RESTORE_LIST_SUCCESS,
    payload: data,
  };
};
export const fetchABRestoreListError = (data) => {
  return {
    type: FETCH_AB_RESTORE_LIST_ERROR,
    payload: data,
  };
};

/**App Builder Details Import Categories Volume*/
export const fetchABCategoriesVolumeList = (data) => {
  return {
    type: FETCH_AB_CATEGORIES_VOLUME_LIST,
    payload: data,
  };
};
export const fetchABCategoriesVolumeListSuccess = (data) => {
  return {
    type: FETCH_AB_CATEGORIES_VOLUME_LIST_SUCCESS,
    payload: data,
  };
};
export const fetchABCategoriesVolumeListError = (data) => {
  return {
    type: FETCH_AB_CATEGORIES_VOLUME_LIST_ERROR,
    payload: data,
  };
};

/**App Builder Details Import Series By Categories Volume*/
export const fetchABSeriesByCategoriesVolume = (data) => {
  return {
    type: FETCH_AB_SERIES_BY_CATEGORIES_VOLUME,
    payload: data,
  };
};
export const fetchABSeriesByCategoriesVolumeSuccess = (data) => {
  return {
    type: FETCH_AB_SERIES_BY_CATEGORIES_VOLUME_SUCCESS,
    payload: data,
  };
};
export const fetchABSeriesByCategoriesVolumeError = (data) => {
  return {
    type: FETCH_AB_SERIES_BY_CATEGORIES_VOLUME_ERROR,
    payload: data,
  };
};
