import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const Location = useLocation();
  const isValid = useMemo(
    () => Location.pathname.startsWith("/app-builder"),
    [Location]
  )

  if (isValid === true) {
    return (
      <>
        {children}
      </>
    );
  }
  else {
    return (
      <>
        {children}
      </>
    );
  }
};

export default Layout;
