import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "reactstrap";
import { DragDropContext } from "react-beautiful-dnd";
import { handleMBRestoreList, /* handleFetchMBExportElements, */ handleFetchMBElements, handleBuildingBlockDrag, handleMessageElementDragDrop, handleSaveMBCustomRestore, handleSaveOnChangeMB, handleMBDuplicate, handleDeleteMBCustomRestore, handleMBIndexSuccess, /* handleSaveOnElementChangeMB, */ getActiveSubscriptionsBySite, fetchMBElements } from "store/actions";
import { handleFetchMBListing } from "../Builder/store/actions";
import BuildingBlocks from "modules/Message/Details/components/MessageBuildingBlocks";
import MBElementsData from "modules/Message/Details/components/MessageElements";
import Header from "components/common/Header/Header";
import Footer from "components/common/Footer/Footer";
import moment from "moment";
import queryString from "query-string";
import modalClose from "assets/images/icon/modal-close.svg";
import deleteAlertIcon from "assets/images/icon/red-info.svg";
import backImage from "assets/images/icon/back.png";
import dropdownImg from "assets/images/icon/dropimg.svg";
import unauthorizedUserImage from "assets/images/unauthorizedUserImage.svg"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ExportBuilder from "./components/ExportBuilder";
import html2pdf from "html2pdf.js";
import { setMBExportLoading } from "store/messageBuilderExport/action";

const MessageBuilderDetail = () => {
  const { MBRestoreList } = useSelector((state) => state.MessageBuilderDetail);
  const { mbExpportLoading } = useSelector((state) => state.MessageBuilderExport);
  const { MBElements, saveLoading, duplicateLoading} = useSelector((state) => state.MessageBuilderDetail);
  const { totalSizeMB } = useSelector((state) => state.MessageBuilderList)
  const { user, isAuth } = useSelector((state) => state.auth);

  const [savePaper, setSavePaper] = useState("yes");
  const [saveBuildName, setSaveBuildName] = useState("");
  const [alert, setAlert] = useState(false);
  const [deleteRestore, setDeleteRestore] = useState(false);
  const [restore, setRestore] = useState(false);
  const [bulidLimit, setBuildLimit] = useState(false);
  const [buildRestore, setBuildRestore] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [mbBuildId, setMBBuildId] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [elements, setElements] = useState([]);
  const [elementLoader, setElementLoader] = useState(false);
  const [buttonEnable, setButtonEnable] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isDelete, setIsDelete] = useState(false)
  const [isMessageList, setIsMessageList] = useState(1)
  const [isOnSave, setIsOnSave] = useState(false)
  const [MBElementLoading, setMBElementLoading] = useState(true);
  const [scrollToTop, setScrollToTop] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false);
  const [exportLoader,setExportLoader] = useState(false);

  const dispatch = useDispatch();
  const params = useParams();
  const messageBuilderId = params.id;
  const location = useLocation();
  const history = useHistory();
  const componentRef = useRef();

  //For MB Details Return Url
  const parsed = queryString.parse(location.search);
  let decoded;
  if (parsed.media_url) {
    decoded = window.atob(parsed.returnUrl);
  }

  useEffect(() => {
    if (isAuth) {
      dispatch(getActiveSubscriptionsBySite({ site_id: 3 }, (status) => {
        if (status.count > 0) {
          setIsSubscription(true)
        }
      }))
    }
  }, [dispatch, isAuth])

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
        setScrollToTop(true);
      } else if (scrolled <= 300) {
        setScrollToTop(false);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // Set MBElements
  useEffect(() => {
    setElements(MBElements);
    setIsMessageList(MBElements.count);
    if (!!MBElements.sycu_build_elements_details && MBElements.sycu_build_elements_details) {
      if (MBElements.sycu_build_elements_details.length >= 0) {
        setMBElementLoading(false)
      }
    }
  }, [MBElements, elements]);

  // Open Restore Point DropDown
  const handleSaveList = () => {
    setShowDropdown(!showDropdown);
    if (!showDropdown) {
      dispatch(
        handleMBRestoreList({
          message_build_list_id: messageBuilderId,
        })
      );
    }
  };
  /* Close Restore Point Modal*/
  const closeRestorePointDiv = () => {
    setShowDropdown(false);
  };

  /* Every 10 min restors point create */
  const MINUTE_MS = 600000;
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(
        handleSaveMBCustomRestore({
          message_build_list_id: parseInt(params.id),
          restore_point_title: "Restore",
          is_auto_saved: 1,
        })
      );
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [dispatch, params.id]);

  //On building Block Drag
  const onDragEnd = (onDragEnd) => {
    if (onDragEnd.destination) {
      let buildingBlockId = onDragEnd.draggableId;
      let destination = onDragEnd.destination.index;
      let source = onDragEnd.source.index;

      const element = {
        build_elements_details_id: true,
        build_elements_id: true,
        build_elements_name: "Placeholder",
        build_list_id: "",
        build_type: 1,
        content: "",
        element_category: 1,
        element_type: 1,
        extra_content: "",
        extra_title: "",
        hex_color: "000000",
        is_collapsed: 0,
        is_delete: 0,
        is_series: 0,
        is_visible: 1,
        media_url: "",
        sortable_order: onDragEnd.destination.index,
        title: "placeholder",
      };

      if (
        onDragEnd.source.droppableId === onDragEnd.destination.droppableId &&
        onDragEnd.source.droppableId !== "rowsList"
      ) {
        const reorder = (list, x, y) => {
          const result = list;
          const [removed] = result.splice(x, 1);
          result.splice(y, 0, removed);
          return result;
        };

        const items = reorder(
          elements.sycu_build_elements_details,
          onDragEnd.source.index,
          onDragEnd.destination.index
        );
        setElements(items);
        dispatch(
          handleMessageElementDragDrop({
            id: messageBuilderId,
            build_elements_details_id: buildingBlockId,
            destination: destination,
            source: source,
            type: 0,
            sort_order_type: "single",
          })
        );
      } else {
        if (
          onDragEnd.source.droppableId !== onDragEnd.destination.droppableId
        ) {
          dispatch(
            handleMBIndexSuccess(
              elements.sycu_build_elements_details.splice(
                onDragEnd.destination.index,
                0,
                element
              )
            )
          );
          dispatch(
            handleBuildingBlockDrag({
              message_build_list_id: parseInt(messageBuilderId),
              build_elements_id: parseInt(buildingBlockId),
              destination: destination,
            })
          );
        }

        if (
          onDragEnd.source.droppableId == onDragEnd.destination.droppableId &&
          onDragEnd.source.droppableId !== "rowsList"
        ) {
          dispatch(
            handleMessageElementDragDrop({
              id: messageBuilderId,
              build_elements_details_id: buildingBlockId,
              destination: destination,
              source: source,
              type: 0,
              sort_order_type: "single",
            })
          );
        }
      }
    }
  };

  /** Open Print Modal **/
  const handlePrintClick = () => {
    setAlert(true);
  };

  /** Open Restore Point Modal **/
  const handleRestoreClick = () => {
    dispatch(
      handleMBRestoreList(
        {
          message_build_list_id: messageBuilderId,
        },
        (data) => {
          if (data.flag == false) {
            setBuildLimit(true);
          } else {
            setRestore(true);
          }
        }
      )
    );
    setShowDropdown(false);
  };

  // console.log("Global Loader", exportLoader);

  /** Open Update And Make Copy Build  **/
  const handleBuildRestoreClick = (message_build_list_id) => {
    setMBBuildId(message_build_list_id);
    setBuildRestore(true);
  };

  /** Export MBElemets **/
  const handleExportClick = async () => {
    // ******** Old Export through API ********
    // dispatch(
    //   handleFetchABExportElements({
    //     id: parseInt(seriesId),
    //     type: 0,
    //   })
    // );
    // ******** Old Export through API ********
    

    // ******** New Export ********
    setExportLoader(true);
    const element = componentRef?.current;
    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5],
      filename: `Message_Builder_${new Date() / 1000}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        scale: 4, // Increase scale to improve quality
        useCORS: true,
        logging: true, // Enable logging for debugging
        dpi: 300, // Set DPI to improve quality
        letterRendering: true, // Better text rendering
      },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };

    try {
      await html2pdf().from(element).set(opt).save();
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setExportLoader(false);
      dispatch(setMBExportLoading(false));
    }
    // ******** New Export ********
  };

  /** Close Print Modal **/
  const handleCloseAlert = () => {
    setAlert(false);
  };

  /** Close Create Restore Point Modal  **/
  const handleCloseRestore = () => {
    setRestore(false);
    setSaveBuildName("")
    setButtonEnable(false)
  };

  /** Close Update And Copy Modal **/
  const handleBuildCloseRestore = () => {
    setBuildRestore(false);
  };

  /** Open Restore Point Build Limit Modal **/
  const handleBuildLimit = () => {
    setBuildLimit(false);
  };

  /** For Select Save Paper Option **/
  const handleChange = (event) => {
    setSavePaper(event.target.value);
  };

  /** For Change Value Of Create Restore Point **/
  const handleValueChange = (event) => {
    const checkSpace = event.target.value.indexOf(" ");

    if (checkSpace === 0) {
      return false
    }

    if (event.target.value === "" || checkSpace === 0) {
      setButtonEnable(false)
    } else {
      setButtonEnable(true)
    }
    setSaveBuildName(event.target.value);
  };

  /** For Submit Print Optiion **/
  const handleSubmitPrint = (e) => {
    e.preventDefault();
    history.push(
      `/builder/print/${params.id}?print=yes&savepaper=${savePaper}`
    );
  };

  /** For Save Restore Point **/
  const handleSubmitRestore = (e) => {
    setIsSubmit(true)
    e.preventDefault();
    dispatch(
      handleSaveMBCustomRestore({
        message_build_list_id: parseInt(params.id),
        restore_point_title: saveBuildName,
        is_auto_saved: 0,
      }, (status) => {
        if (status) {
          setIsSubmit(false)
          setRestore(false);
          setSaveBuildName("");
        }
      })
    );
    setButtonEnable(false);
  };

  /** For Make Copy Of MBElements **/
  const handleMakeCopy = (e) => {
    if (!!user && user.user_role == 3) {
      if (isSubscription) {
        dispatch(
          handleMBDuplicate(
            {
              message_build_list_id: e.mbBuildId,
            },
            (id) => {
              if (id) {
                setElementLoader(true);
                history.replace("/builder/edit/" + id.message_build_list_id);
                dispatch(
                  handleFetchMBElements(
                    { id: id.message_build_list_id, is_series: 0 },
                    (status) => {
                      if (status) {
                        setElementLoader(false);
                        dispatch(handleFetchMBListing({
                          page_no: 1,
                          page_record: 10,
                          sortField: "created_datetime",
                          sortOrder:  "DESC",
                        }))
                      }
                    }
                  )
                );
              }
            }
          )
        );
        setBuildRestore(false);
      } else if (totalSizeMB >= 12) {
        history.push("/subscription")
      } else {
        dispatch(
          handleMBDuplicate(
            {
              message_build_list_id: e.mbBuildId,
            },
            (id) => {
              if (id) {
                setElementLoader(true);
                history.replace("/builder/edit/" + id.message_build_list_id);
                dispatch(
                  handleFetchMBElements(
                    { id: id.message_build_list_id, is_series: 0 },
                    (status) => {
                      if (status) {
                        setElementLoader(false);
                        dispatch(handleFetchMBListing({
                          page_no: 1,
                          page_record: 10,
                          sortField: "created_datetime",
                          sortOrder:  "DESC",
                        }))
                      }
                    }
                  )
                );
              }
            }
          )
        );
        setBuildRestore(false);
      }
    } else {
      dispatch(
        handleMBDuplicate(
          {
            message_build_list_id: e.mbBuildId,
          },
          (id) => {
            if (id) {
              setElementLoader(true);
              history.replace("/builder/edit/" + id.message_build_list_id);
              dispatch(
                handleFetchMBElements(
                  { id: id.message_build_list_id, is_series: 0 },
                  (status) => {
                    if (status) {
                      setElementLoader(false);
                      dispatch(handleFetchMBListing({
                        page_no: 1,
                        page_record: 10,
                        sortField: "created_datetime",
                        sortOrder:  "DESC",
                      }))
                    }
                  }
                )
              );
            }
          }
        )
      );
      setBuildRestore(false);
    }

  };

  /** For Update Current MBElements **/
  const handleUpdateCurrent = (e) => {
    setIsUpdate(true)
    dispatch(
      handleSaveOnChangeMB({
        message_build_list_id: e.mbBuildId,
        type: 2,
      }, (status) => {
        if (status) {
          setIsUpdate(false)
          setBuildRestore(false);
          setIsMessageList(1)
        }
      })
    );
  };

  //Manual Restore Point Delete
  const ManualRestorePointDelete = (message_build_list_id) => {
    setSelectedRows([message_build_list_id]);
    setDeleteRestore(true);
  };

  let temp = [];
  let temp2 = [];

  //Message Builder Detail Redirect To Return URL(Admin)
  const handleReturnUrl = () => {
    window.location.replace(decoded + "?tab=3");
  };

  /** Close DropDown onClick anyWhere onScreen start **/
  const closeDropDown = useCallback((e) => {
    let click = e.target.closest("#navbarDropdown");
    if (!click) {
      setShowDropdown(false);
    }
  },[]);

  useEffect(() => {
    window.addEventListener("click", closeDropDown);
    return () => window.removeEventListener("click", closeDropDown);
  }, [closeDropDown]);

  /** Close DropDown onClick anyWhere onScreen End **/

  /**Open Delete Modal **/
  const handleDeleteConfirm = () => {
    setIsDelete(true)
    dispatch(
      handleDeleteMBCustomRestore({
        message_build_list_id: [...selectedRows][0],
      }, (status) => {
        if (status) {
          setIsDelete(false)
          setDeleteRestore(false);
        }
      })
    );
    setSelectedRows([]);
  };

  /** Close Delete Modal **/
  const handleDeleteClose = () => {
    setDeleteRestore(false);
    setSelectedRows([]);
  };

  const goBack = () => {
    window.location.replace(`${parsed.redirect_url}${location?.hash}`)
  }


  //On Click Save Elements Data
  const handleSaveMessageElements = () => {
    setIsOnSave(true)
    setInterval(() => {
      setIsOnSave(false);
    }, 2000);
  }

  return (
    <div className="no-sidebar MBDetails">
      <Header />
      <div className="main_card pb-0">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/builder">
                {" "}
                <span>Home</span>
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Lesson Builder
            </li>
          </ol>
        </nav>
        <div className="main_card_wrapper tabbing-card">
          <div className="wrapper-heading-area filter-area">
            <div className="title">
              {parsed.returnUrl ? (
                <Link className=""
                  onClick={handleReturnUrl}
                >
                  <img src={backImage} className="mr-2" alt="" />
                  <h1 className="wrapper-heading fw-600 mb0"> Back To Admin</h1>
                </Link>
              ) : (
                parsed.redirect_url ?
                  <Link className="" onClick={goBack} to="#">
                    {" "}
                    <img src={backImage} className="mr-2" alt="" />
                    <h1 className="wrapper-heading fw-600 mb0">
                      {" "}
                      Go Back
                    </h1>
                  </Link>
                  :
                  <Link className="" to="/builder">
                    {" "}
                    <img src={backImage} className="mr-2" alt="" />
                    <h1 className="wrapper-heading fw-600 mb0">
                      {" "}
                      Back To Your Library
                    </h1>
                  </Link>
              )}
            </div>
            {isMessageList !== 0 ?
              <div className={!isMessageList ? "d-none" : "page-filter"}>
                <span className="savingLoader">
                  {saveLoading || isOnSave === true ? "Saving..." : ""}
                </span>
                <span className="savingLoader">
                  {duplicateLoading ? "Duplicating..." : ""}
                </span>
                <Link
                  className={mbExpportLoading ? "btn primary-border-btn disabled" : "btn primary-border-btn"}
                  to={"/builder/view/" + params.id}
                >
                  Teach
                </Link>
                {mbExpportLoading ?
                  <button
                    disabled
                    type="submit"
                    className="btn primary-border-btn"
                  >
                    Print
                  </button> :
                  <button
                    onClick={() => handlePrintClick()}
                    type="submit"
                    className="btn primary-border-btn"
                  >
                    Print
                  </button>
                }
                {/* {mbExpportLoading ? ( */}
              
                  <button
                  key={exportLoader}
                    onClick={handleExportClick}
                    type="submit"
                    className="btn primary-border-btn"
                    disabled={exportLoader}
                  >
                    {exportLoader ? "Exporting..." : "Export"}

                  </button>


                {mbExpportLoading ?
                  <button
                    type="submit"
                    className="btn primary-border-btn"
                  >
                    Save
                  </button> : <button
                    onClick={() => handleSaveMessageElements()}
                    type="submit"
                    className="btn primary-border-btn"
                    disabled={isOnSave === true}
                  >
                    Save
                  </button>}
                {mbExpportLoading ?
                  <button
                    disabled
                    type="submit"
                    className="btn primary-border-btn"
                    id="restore-point"
                  >
                    Create Restore Point
                  </button> :
                  <button
                    onClick={() => handleRestoreClick()}
                    type="submit"
                    className="btn primary-border-btn"
                    id="restore-point"
                  >
                    Create Restore Point
                  </button>
                }

                <Link
                  className={mbExpportLoading ? "dropdown restore-dropdown disabled" : "dropdown restore-dropdown"}
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => handleSaveList()}
                >
                  <img
                    src={dropdownImg}
                    id="dropdownMenuTable"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    alt=""
                  />
                  <div
                    className={`dropdown-menu dropdown_collpase dropdown-table dropdown-menu-right ${showDropdown ? "show" : ""
                      }`}
                    aria-labelledby="navbarDropdown"
                  >
                    <div className="list-area">
                      <h3 className="strip">Automatic Restore Points</h3>
                      {!!MBRestoreList.rows &&
                        MBRestoreList.rows.map((saveList, i) => {
                          if (saveList.is_auto_saved === 1 || saveList.is_auto_saved === null) {
                            temp.push(saveList);
                          } else {
                            temp2.push(saveList);
                          }
                        })}
                      {temp.length > 0 ? (
                        temp &&
                        temp.map((saveList, i) => {
                          if (saveList.is_auto_saved === 1 || saveList.is_auto_saved === null) {
                            return (
                              <div
                                className="dropdown-item"
                                key={i}
                                onClick={() =>
                                  handleBuildRestoreClick(
                                    saveList.message_build_list_id
                                  )
                                }
                              >
                                <span>Restore</span>
                                <span className="ml-3">
                                  {moment(saveList.updated_datetime).format(
                                    "MM/DD/YYYY h:mm:ss A"
                                  )}
                                  { }
                                </span>
                              </div>
                            );
                          }
                        })
                      ) : (
                        <h3>No data found</h3>
                      )}
                      <h3 className="strip">Manual Restore Points</h3>
                      {temp2.length > 0 ? (
                        temp2 &&
                        temp2.map((saveList, i) => {
                          if (saveList.is_auto_saved === 0) {
                            return (
                              <div className="d-flex align-items-center dropdown-item-content" key={i}>
                                <div
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleBuildRestoreClick(
                                      saveList.message_build_list_id
                                    )
                                  }
                                >
                                  <span>{saveList.restore_point_title}</span>
                                  <span className="ml-3">
                                    {moment(saveList.updated_datetime).format(
                                      "MM/DD/YYYY h:mm:ss A"
                                    )}
                                    { }
                                  </span>
                                </div>
                                <div className="deleteButton mr-3">
                                  <i
                                    onClick={() =>
                                      ManualRestorePointDelete(
                                        saveList.message_build_list_id
                                      )
                                    }
                                    className="fa fa-trash delete_restore_point"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                            );
                          }
                        })
                      ) : (
                        <h3>No data found</h3>
                      )}
                    </div>
                  </div>
                </Link>
              </div> : null}
          </div>
        </div>
      </div>
      {isMessageList === 0 ?
        <div className="main_card_wrapper unauthorize-build-data">
          <div className="unauthorized_user_image col-lg-12">
            <div className="main_card_wrapper tabbing-card">
              <img src={unauthorizedUserImage} alt="unauthorizedUserImage" className="unauthorizedUserImage" />
            </div>
          </div>
        </div> :
        <div className="main_card pt-0 main_card_body">
          <div className="row" onClick={() => closeRestorePointDiv()}>
            <DragDropContext onDragEnd={onDragEnd}>
              <MBElementsData
                MBElements={elements}
                mbBuildId={mbBuildId}
                elementLoader={elementLoader}
                isMessageList={isMessageList}
                MBElementLoading={MBElementLoading}
              />

              <BuildingBlocks elementLoader={elementLoader} MBElementLoading={MBElementLoading} />
            </DragDropContext>
          </div>
        </div>}
      <Footer />
      {/* Print Modal */}
      <Modal isOpen={alert} className="fileUpload sharableLink" centered>
        <div className="modal-body modal_popup">
          <button
            type="button"
            className="close"
            onClick={() => handleCloseAlert()}
          >
            <img src={modalClose} alt="modal-close" />
          </button>
          <div className="modal_title">
            <h3>Print Option</h3>
          </div>
          <div className="modal_data">
            <form onSubmit={handleSubmitPrint}>
              <div className="row">
                <div className="col-md-12">
                  <div className="print-box mb-3">
                    <div className="heading">
                      <h4 className="dark-text">Spaced Out</h4>
                      <h4 className="gray-text">Don’t cut off sections</h4>
                    </div>
                    <label className="radio-style">
                      <input
                        type="radio"
                        checked={savePaper === "no"}
                        name="swal2-radio"
                        value="no"
                        onChange={handleChange}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="print-box">
                    <div className="heading">
                      <h4 className="dark-text">Tight formatting</h4>
                      <h4 className="gray-text">Save paper</h4>
                    </div>
                    <label className="radio-style">
                      <input
                        type="radio"
                        checked={savePaper === "yes"}
                        name="swal2-radio"
                        value="yes"
                        onChange={handleChange}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-12 modal-btn">
                  <button className="btn primary-btn w-100" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      {/* Delete Modal */}
      <Modal
        isOpen={deleteRestore}
        className="modal-dialog modal-dialog-centered"
        role="document"
        centered
      >
        <div className="modal-content">
          <div className="modal-body modal_popup">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => handleDeleteClose()}
            >
              <img src={modalClose} alt="modal-close" />
            </button>
            <div className="modal_data editImageModalData">
              <div className="row">
                <div className="col-md-12 text-center mt-5">
                  <img src={deleteAlertIcon} alt="red-info" />
                </div>
                <div className="col-md-12 text-center mb20 mt15">
                  <h1 className="fw-600 dark-text ">
                    Are you sure you want to delete?
                  </h1>
                </div>
                <div className="col-md-12 modal-btn">
                  {
                    isDelete ?
                      <button
                        className="btn secondary-btn mr-2"
                        data-dismiss="modal"
                        disabled
                      >
                        {" "}
                        cancel
                      </button> :
                      <button
                        className="btn secondary-btn mr-2"
                        data-dismiss="modal"
                        onClick={() => handleDeleteClose()}
                      >
                        {" "}
                        cancel
                      </button>
                  }
                  {
                    isDelete ?
                      <button
                        className="btn delete-border-btn ml-3"
                        disabled
                      >
                        Deletting...
                      </button> :
                      <button
                        className="btn delete-border-btn ml-3"
                        onClick={() => handleDeleteConfirm()}
                      >
                        Delete
                      </button>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* ScrollToTop Button */}
      {scrollToTop && <div onClick={scrollTop} className="scrollToTop"><i className="fa fa-arrow-up"></i></div>}

      {/* Create Restore Point Modal */}
      <Modal isOpen={restore} className="fileUpload sharableLink" centered>
        <div className="modal-body modal_popup">
          <button
            type="button"
            className="close"
            onClick={() => handleCloseRestore()}
          >
            <img src={modalClose} alt="modal-close" />
          </button>
          <div className="modal_title">
            <h3>Name Current Version</h3>
          </div>
          <div className="modal_data">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <input
                    type="text"
                    name="build-name"
                    className="form-control"
                    value={saveBuildName}
                    onChange={handleValueChange}
                  />
                </div>
                <div className="col-md-12 modal-btn">
                  {
                    isSubmit ?
                      <button
                        className="btn primary-btn"
                        type="submit"
                        disabled
                      >
                        Submiting...
                      </button> :
                      <button
                        className="btn primary-btn"
                        type="submit"
                        disabled={!buttonEnable}
                        onClick={(e) => handleSubmitRestore(e)}
                      >
                        Submit
                      </button>
                  }

                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      {/* Update And Copy Modal */}
      <Modal isOpen={buildRestore} className="fileUpload sharableLink restore-modal" centered>
        <div className="modal-body modal_popup">
          <button
            type="button"
            className="close"
            onClick={() => handleBuildCloseRestore()}
          >
            <img src={modalClose} alt="modal-close" />
          </button>
          <div className="modal_title">
            <h5>How do you want to restore this version?</h5>
          </div>
          <div className="modal_data">
            <div className="row mt-4">
              <div className="col-md-6">
                {
                  isUpdate ?
                    <button
                      className="btn primary-btn"
                      type="submit"
                      disabled
                    >
                      Updating Current...
                    </button> :
                    <button
                      className="btn primary-btn"
                      type="submit"
                      onClick={() => handleUpdateCurrent({ mbBuildId })}
                    >
                      Update Current
                    </button>
                }
              </div>
              <div className="col-md-6">
                {
                  isUpdate ?
                    <button
                      className="btn primary-btn"
                      type="submit"
                      disabled
                    >
                      Make a copy
                    </button> :
                    <button
                      className="btn primary-btn"
                      type="submit"
                      onClick={() => handleMakeCopy({ mbBuildId })}
                    >
                      Make a copy
                    </button>
                }
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* BuildLimit Modal */}
      <Modal isOpen={bulidLimit} className="fileUpload restoreLimit" centered>
        <div className="modal-content">
          <div className="modal-body modal_popup">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => handleBuildLimit()}
            >
              <img src={modalClose} alt="modal-close" />
            </button>
            <div className="modal_data editImageModalData">
              <div className="row">
                <div className="col-md-12 text-center mt-5">
                  <img src={deleteAlertIcon} alt="red-info" />
                </div>
                <div className="col-md-12 text-center mb20 mt15">
                  <span className="fw-600 dark-text ">
                    You can only save 5 restore points. Please delete any if you
                    want to create a new one.
                  </span>
                </div>
                <div className="col-md-12 modal-btn">
                  <button
                    className="btn primary-btn mr-2"
                    onClick={() => handleBuildLimit()}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* Export Builder Component */}
      <ExportBuilder isHidden={true} ref={componentRef} />
    </div>
  );
};

export default MessageBuilderDetail;
