import { 
  FETCH_AB_EXPORT_ELEMENTS,
  FETCH_AB_EXPORT_ELEMENTS_SUCCESS,
  FETCH_AB_EXPORT_ELEMENTS_ERROR
} from "./actionTypes";

const ABElementsInitialState = {
  ABExportElements: [],
  abExpportLoading: false,
}

const ABExportElementReducer = (state = ABElementsInitialState, action) => {
  const { type, payload } = action
  switch (type) {
    /** App Builder Export list */
    case FETCH_AB_EXPORT_ELEMENTS:
      return {
        ...state,
        abExpportLoading: true,
      }
    case FETCH_AB_EXPORT_ELEMENTS_SUCCESS:
   
      return {
        ...state,
        abExpportLoading: false,
        ABExportElements: action.payload,
        totalSize: payload.length,
      }
    case FETCH_AB_EXPORT_ELEMENTS_ERROR:
      return {
        ...state,
        abExpportLoading: false,
      }
    default:
      return state
  }
}
export default ABExportElementReducer
