import { React, useState, useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import AppSidebar from "modules/App/AppFolders";
import AppBuilder from "modules/App/AppBuilder/components/AppBuilder";
import AppBuilderLibrary from "./AppBuilderLibrary/index";
import {
  handleDragDropOfBuild,
  handleGetABFolderBuildById,
} from "store/actions";
import { setPageABFolderBuildList } from "../AppFolders/store/action";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AppBuilderListing = () => {
  const [folderId, setFolderId] = useState(0);
  const [appFolderId, setAppFolderId] = useState(0);
  const [libraryFolderId, setLibraryFolderId] = useState(true);
  const [isFolder, setIsFolder] = useState(true);
  const [isLibrary, setIsLibrary] = useState(false);
  const [appFolderTitle, setAppFolderTitle] = useState("User Builds");
  const [appFolderTitleName, setAppFolderTitleName] = useState("");
  const [appFolderState, setAppFolderState] = useState(false);
  const [isAppSearchValue, setIsAppSearchValue] = useState(false);
  const [isFolderScroll, setIsFolderScroll] = useState(1);
  const [isAfterOnDragAppTitle, setIsAfterOnDragAppTitle] =
    useState("User Builds");
  const [isOnDrag, setIsOnDrag] = useState(false);
  const [isDuplicateBuild, setIsDuplicateBuild] = useState(false);

  const { toggleMenu, isAuth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // For element up & down Drag
  const onDragEnd = (dragDropDetails) => {
    if (dragDropDetails.destination !== null) {
      let appId = dragDropDetails.draggableId;
      let folderId = dragDropDetails.destination.droppableId;
      if (folderId != "rowsList") {
        setIsOnDrag(true);
        dispatch(
          handleDragDropOfBuild(
            {
              folder_id: folderId,
              id: appId,
              type: 2,
            },
            (data) => {
              setFolderId(parseInt(folderId));
              dispatch(setPageABFolderBuildList(isFolderScroll));
              setAppFolderState(true);
              setIsAfterOnDragAppTitle(data.build_folder_title);
              setIsOnDrag(false);
            }
          )
        );
      }
    }
  };

  //Select App Folder ID
  const onSelectAppFolder = (onSelectAppFolder) => {
    setFolderId(onSelectAppFolder);
    setAppFolderTitle(onSelectAppFolderTitle);
    setAppFolderId(onSelectAppFolder);
    setIsFolder(true);
    setIsLibrary(false);
  };
  //Select App Folder Title
  const onSelectAppFolderTitle = (onSelectAppFolderTitle) => {
    setAppFolderTitle(onSelectAppFolderTitle);
  };

  useEffect(() => {
    if (isAuth) {
      dispatch(handleGetABFolderBuildById({ build_folder_id: folderId }));
    }
    setAppFolderState(false);
  }, []);

  const onSelectAppLibraryFolder = (onSelectAppLibraryFolder) => {
    setLibraryFolderId(onSelectAppLibraryFolder);
    setIsLibrary(true);
    setIsFolder(false);
  };
  //Select App Search Value by Folder
  const onSelectFolderSearch = (onSelectFolderSearch) => {
    setIsAppSearchValue(onSelectFolderSearch);
  };

  const afterOnDragAppTitle = (afterOnDragAppTitle) => {
    setIsAfterOnDragAppTitle(afterOnDragAppTitle);
  };

  const handleDuplicateBuild = (handleDuplicateBuild) => {
    setIsDuplicateBuild(handleDuplicateBuild);
  };

  return (
    <div className={!toggleMenu ? "mini-menu" : ""}>
      <DragDropContext onDragEnd={onDragEnd}>
        <AppSidebar
          onSelectAppFolder={onSelectAppFolder}
          _isActive={folderId}
          isOnDrag={isOnDrag}
          onSelectAppFolderTitle={onSelectAppFolderTitle}
          onSelectAppLibraryFolder={onSelectAppLibraryFolder}
          onSelectFolderSearch={onSelectFolderSearch}
          afterOnDragAppTitle={afterOnDragAppTitle}
          handleDuplicateBuild={handleDuplicateBuild}
        />
        {isFolder ? (
          <AppBuilder
            appFolderId={appFolderId}
            appFolderTitle={appFolderTitle}
            appFolderTitleName={appFolderTitleName}
            appFolderState={appFolderState}
            isAppSearchValue={isAppSearchValue}
            isAfterOnDragAppTitle={isAfterOnDragAppTitle}
          />
        ) : null}
        {isLibrary ? (
          <AppBuilderLibrary
            libraryFolderId={libraryFolderId}
            isDuplicateBuild={isDuplicateBuild}
          />
        ) : null}
      </DragDropContext>
    </div>
  );
};

export default AppBuilderListing;
