import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import Font from "@ckeditor/ckeditor5-font/src/font";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Link from "@ckeditor/ckeditor5-link/src/link";
import ListStyle from "@ckeditor/ckeditor5-list/src/liststyle";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageBlock from "@ckeditor/ckeditor5-image/src/imageblock";
import { s3 } from "services/aws";

class CustomAdapter {
  constructor(loader) {
    this.loader = loader;
  }
  upload() {
    return this.loader.file.then(
      file =>
        new Promise((res, rej) => {
          const fileName = file.name
            .split(".")
            .slice(0, -1)
            .join(".")
            .replace(" ", "_");
          const fileExtension = file.name.split(".")[1];
          const currentTime = file.lastModified;
          const fileFullName =
            fileName + "_" + currentTime + "." + fileExtension;
          const params = {
            ACL: "public-read",
            Key: fileFullName,
            ContentType: file.type,
            Body: file,
          };
          s3(process.env.REACT_APP_AWS_FOLDER_NAME)
            .upload(params)
            .send(function (err, data) {
              if (err) {
                rej(`Couldn't upload file: ${file.name}.`);
              }
              res({ default: data.Location });
            });
        })
    );
  }
}
function UploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = data => {
    return new CustomAdapter(data);
  };
}

export const config = (isDisabled = false) => ({
  plugins: [
    Essentials,
    Bold,
    Italic,
    Paragraph,
    Underline,
    Font,
    Alignment,
    Heading,
    Link,
    ListStyle,
  ],


  fontSize: {
    options: [
      '8px',
      "10px",
      "12px",
      "14px",
      "16px",
      "18px",
      "20px",
      "22px",
      "24px",
      "26px",
      "28px",
      '30px',
      "32px",
      "34px",
      "36px",
      "38px",
      "40px",
      "42px",
      "44px",
    ]
  },
  toolbar: {
    items:
      isDisabled
        ? []
        :
        [
          "bold",
          "italic",
          "underline",
          "link",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "alignment",
          "undo",
          "redo",
        ],
    shouldNotGroupWhenFull: true,
  },

});

export const appConfig = (isDisabled = false) => ({
  plugins: [
    Essentials,
    Bold,
    Italic,
    Paragraph,
    Underline,
    Font,
    Alignment,
    Heading,
    Link,
    ListStyle,
    Image,
    ImageBlock,
    ImageStyle,
    ImageCaption,
    ImageToolbar,
    ImageResize,
    ImageUpload,
    UploadAdapterPlugin,
  ],

  fontSize: {
    options: [
      '8px',
      "10px",
      "12px",
      "14px",
      "16px",
      "18px",
      "20px",
      "22px",
      "24px",
      "26px",
      "28px",
      '30px',
      "32px",
      "34px",
      "36px",
      "38px",
      "40px",
      "42px",
      "44px",
    ]
  },
  toolbar: {
    items:
      isDisabled
        ? []
        :
        [
          "bold",
          "italic",
          "underline",
          "link",
          "|",
          "bulletedList",
          "numberedList",
          "|",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "alignment",
          "undo",
          "redo",
          "|",
          "uploadImage",
        ],
    shouldNotGroupWhenFull: true,
  },
  image: {
    toolbar: [
      "imageStyle:alignLeft",
      "imageStyle:alignRight",
      "|",
      "imageStyle:alignBlockLeft",
      "imageStyle:alignCenter",
      "imageStyle:alignBlockRight",
      "|",
      "toggleImageCaption",
      "imageTextAlternative",
      "|",
      "linkImage",
    ],
  },
});
