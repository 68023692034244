import React from "react";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRouteMiddleware = ({ component: Component, ...rest }) => {
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      exact
      render={(props) => {
        return true ? (
          <Component {...props} />
        ) : (
          null
        );
      }}
    />
  );
};

export default PrivateRouteMiddleware;
