import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { handleFetchMBListing, handleMBDuplicate } from '../store/actions';
import queryString from 'query-string'
import loaderImage from "../../../../assets/images/loaderImage.gif"
const LibraryDuplicate = () => {
  const { isAuth } = useSelector((state) => state.auth)
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search)
  const [isDuplicate, setIsDuplicate] = useState(false);
  // for Duplicate MBLibrary
  useEffect(() => {
    if (isAuth) {
      setIsDuplicate(true)
      dispatch(
        handleMBDuplicate({
          message_build_list_id: parseInt(params.id),
          category_id: parseInt(parsed.parent_category_id),
          ministry_type: parseInt(parsed.ministry_type)
        }, (status) => {
          if (parsed.redirect_url) {
            if (status.user_subscription === true) {
              history.replace(`/builder/edit/${status.message_build_list_id}?redirect_url=${parsed.redirect_url}${location?.hash}`)
            } else {
              history.replace(`/subscription?redirect_url=${parsed.redirect_url}${location?.hash}`)
            }
          } else {
            history.replace(`/builder/edit/${status.message_build_list_id}`)
            setIsDuplicate(false)
          }
          dispatch(handleFetchMBListing({
            page_no: 1,
            page_record: 10,
            sortField: "created_datetime",
            sortOrder:  "DESC",
          }))
        })
      )
    }
  }, [params.id, parsed.parent_category_id, parsed.ministry_type])
  return (
    (isDuplicate === true &&
      <span className='loaderImage'>
        <img src={loaderImage} />
      </span>)
  )
}
export default LibraryDuplicate