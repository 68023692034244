import { React, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { handleFetchMBElements, handleMBElementDelete, setMBElements, setPageMBFolderBuildList, setPageMBCustomBuildList } from "store/actions";
import { MessageElements } from "../../../../components/MessageElements/MessageElements";
import { handleSaveOnElementChangeMB } from "../../../../store/messageBuildeElements/actions";
import WarningAlert from "components/warningAlert";
import { useDebounce } from "use-debounce/lib";
import MBLoading from "components/common/Loader/MBLoading";

const MessageBuilderElements = ({ elementLoader, MBElementLoading }) => {
  const { MBElements } = useSelector(state => state.MessageBuilderDetail);
  const { StoreFolderId } = useSelector((state) => state.MessageBuilderFolderList);
  const { isAuth } = useSelector((state) => state.auth);
  // const [MBElementLoading, setMBElementLoading] = useState(true);
  const [searchMB, setSearch] = useState("");
  const [alert, setAlert] = useState(false);
  const [MBTitle, setOnchangeMBTitle] = useState("");
  const [buildTitle, setBuildTitle] = useState(false);
  const [subTitle, setSubTitle] = useState(false);
  // For Sub Title
  const [MBsubtitle, setOnchangeMBSubTitle] = useState("");
  // For content
  const [content, setContent] = useState("");
  // For Extra Content
  const [extraContent, setExtraContent] = useState("");
  const debounceSearch = useDebounce(searchMB);
  // for open alert when delete
  const [selectedRows, setSelectedRows] = useState([]);

  const dispatch = useDispatch();
  const Location = useLocation();
  const params = useParams();
  const messageId = params.id;

  //Builder Details and Teaching Title
  const isValid = useMemo(
    () => Location.pathname.startsWith("/app-builder/edit"),
    [Location]
  );
  let isDeleteSeries;
  if (isValid == true) {
    isDeleteSeries = 1;
  } else {
    isDeleteSeries = 0;
  }

  useEffect(() => {
    if (isAuth) {
      dispatch(
        handleFetchMBElements({
          id: params.id,
          is_series: 0,
        })
      );
    }
  }, [dispatch]);

  const handleChange = (id, key, value) => {
    dispatch(setMBElements({ id, key, value, isArray: true }));
  };

  // For Title
  useEffect(() => {
    if (!!MBElements.build_title) {
      setOnchangeMBTitle(MBElements.build_title);
      setBuildTitle(false);
      setSubTitle(false);
    }
  }, [MBElements]);
  // For subtitle
  useEffect(() => {
    if (!!MBElements.build_sub_title) {
      setOnchangeMBSubTitle(MBElements.build_sub_title);
      setBuildTitle(false);
      setSubTitle(false);
    }
  }, [MBElements]);

  // For Content and Extra content
  useEffect(() => {
    if (!!MBElements.content) {
      setContent(MBElements.content);
      setBuildTitle(false);
      setSubTitle(false);
    }
    if (!!MBElements.extra_content) {
      setExtraContent(MBElements.extra_content);
      setBuildTitle(false);
      setSubTitle(false);
    }
  }, [MBElements]);

  //MB title/ subtitle save
  const handleMBTitleSave = () => {
    if (StoreFolderId !== 0) {
      dispatch(setPageMBFolderBuildList(1))
    } else {
      dispatch(setPageMBCustomBuildList(1))
    }
    if (MBTitle != "" && MBsubtitle != "") {
      setBuildTitle(false);
      setSubTitle(false);
      dispatch(
        handleSaveOnElementChangeMB({
          type: 1,
          message_build_list_id: messageId,
          build_title: MBTitle,
          build_sub_title: MBsubtitle,
          element_details: [{}],
        },
        (status) => {
          if (status) {
            dispatch(
              handleFetchMBElements({ id: messageId, is_series: 0 })
            );
          }
        }
      )
      );
    } else {
      if (MBTitle == "") {
        setBuildTitle(true);
      }
      if (MBsubtitle == "") {
        setSubTitle(true);
      }
    }
  };

  const handleTitleChange = (e) => {
    let checkSpace = e.target.value.indexOf(" ")
    if (checkSpace === 0) {
      return false
    }
    setOnchangeMBTitle(e.target.value)
  }

  const handleSubTitleChange = (e) => {
    let checkSpace = e.target.value.indexOf(" ")
    if (checkSpace === 0) {
      return false
    }
    setOnchangeMBSubTitle(e.target.value)
  }

  return (
    <div className="col-lg-9">
      <div className="main_card_wrapper tabbing-card MBCardBody">
        <form action="#" method="post">
          {MBElementLoading || elementLoader == true ? <MBLoading /> :
            !MBElementLoading && (
              <>
                <input
                  type="text"
                  value={MBTitle}
                  className="box-border"
                  onBlur={() => handleMBTitleSave()}
                  onChange={(e) => handleTitleChange(e)}
                />
                <div className={buildTitle ? "build-title-error alert alert-danger" : "d-none build-title-error alert alert-danger"}>Please Enter Build Title</div>
                <input
                  className="box-border-subtitle"
                  type="text"
                  value={MBsubtitle}
                  onBlur={() => handleMBTitleSave()}
                  onChange={(e) => handleSubTitleChange(e)}
                />
                <div className={subTitle ? "sub-title-error alert alert-danger" : "d-none sub-title-error alert alert-danger"}>Please Enter Build Sub-title</div>
              </>
            )}
          {MBElementLoading || elementLoader == true ? <MBLoading /> :

            !MBElementLoading && (
              <Droppable index="rowList" id="rowList" droppableId="CardList">
                {(provided, snapshot) => (
                  <div
                    className="element-info"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {!!MBElements?.sycu_build_elements_details &&
                      MBElements.sycu_build_elements_details.map(
                        (element, index) => {
                          return (
                            <Draggable
                              key={element.build_elements_details_id}
                              index={index}
                              draggableId={"" + element.build_elements_details_id}
                            >
                              {(provided, snapshot) => (
                                <div
                                  key={index}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {alert && (
                                    <WarningAlert
                                      title="Are you sure you want to delete?"
                                      onConfirm={() => {
                                        dispatch(
                                          handleMBElementDelete({
                                            build_elements_details_id: [
                                              ...selectedRows,
                                            ][0].build_elements_details_id,
                                            is_delete: "1",
                                            is_collapsed: "",
                                            is_series: isDeleteSeries,
                                          })
                                        );
                                        setAlert(false);
                                        setSelectedRows([]);
                                      }}
                                      onCancel={() => {
                                        setAlert(false);
                                        setSelectedRows([]);
                                      }}
                                    ></WarningAlert>
                                  )}

                                  {element.build_elements_details_id === true ? (
                                    <MBLoading />
                                  ) : (
                                    <MessageElements
                                      key={index}
                                      handleChange={handleChange}
                                      element={element}
                                      index={index}
                                    />
                                  )}
                                </div>
                              )}
                            </Draggable>
                          );
                        }
                      )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            )
          }

        </form>
      </div>
    </div>
  );
};

export default MessageBuilderElements;
