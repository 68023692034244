import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleMBElementDelete } from "store/actions";
import { handleSaveOnChangeMB } from "store/actions";
import { useLocation } from "react-router-dom";
import { imageUrl, s3 } from "services/aws";
import { useDropzone } from "react-dropzone";
import CkEditor from "components/common/CustomCkEditor/CkEditor";
import { Collapse } from "reactstrap";
import downArrow from "assets/images/icon/down-arrow.svg";
export const MessageTeachingElement = ({ element, index }) => {
  const { isAuth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const [ImageName, setImageName] = useState("");
  const [progress, setProgress] = useState(0);
  const [onOffVisible, setOnOffVisible] = useState([]);
  const [preview, setPreview] = useState("");
  const [isVideoUrl, setIsVideoUrl] = useState();
  const [isMediaUrl, setIsMediaUrl] = useState();
  const [successData, setSuccessData] = useState(true);
  const [inValidUrl, setInValidUrl] = useState(false);
  const [isOpenElementToggle, setIsOpenElementToggle] = useState(true);
  const params = useParams();
  const messageId = params.id;
  let elementCollapse;
  let MBElementToggle;
  let elementToggle = element.build_elements_details_id;
  let isClass;
  //Builder Details and Teaching Title
  const Location = useLocation();
  let contentData = "";
  const isValid = useMemo(
    () => Location.pathname.startsWith("/app-builder/edit"),
    [Location]
  );
  let elementTitle;
  let buildVisible;
  if (isValid == true) {
    elementTitle = element.element_name;
    elementToggle = element.build_elements_details_id;
    buildVisible = element.is_visible;
  } else {
    elementTitle = element.build_elements_name;
    elementToggle = element.build_elements_details_id;
    buildVisible = element.is_visible;
  }
  if (element.is_collapsed == 0) {
    isClass = "";
    MBElementToggle = "element-off";
  } else {
    isClass = "d-none";
    MBElementToggle = "";
  }
  //Element Listing Collapse
  if (element.is_collapsed == 0) {
    isClass = "";
  } else {
    isClass = "d-none";
  }
  useEffect(() => {
    setIsOpenElementToggle(element.is_collapsed == 0 ? true : false);
  }, []);
  //MB Teaching Elements Toggle
  const handleMessageTeachingAccordion = (e) => {
    setIsOpenElementToggle(isOpenElementToggle == true ? false : true);
    let isCollapsedElement = isOpenElementToggle;
    if(isAuth){
      dispatch(
        handleMBElementDelete({
          build_elements_details_id: elementToggle,
          is_collapsed: parseInt(isCollapsedElement == true ? 1 : 0),
          is_series: 0,
        })
      );
    }
    //MB Details Elements Toggle up down
    var contentElement = document.getElementById(
      "element_1" + e.target.getAttribute("data-build-element-id")
    );
    if (contentElement.classList.contains("element-off")) {
      contentElement.classList.remove("element-off");
    } else {
      contentElement.classList.add("element-off");
    }
  };
  useEffect(() => {
    setOnOffVisible((prev) => [
      ...prev,
      {
        id: elementToggle,
        sts: buildVisible,
      },
    ]);
  }, []);

  const getYoutubeVideoId = url => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };
  
  //handle video element while videoUrl is not embed
  useEffect(() => {
      if (element.media_url) {
        let video_url = element.media_url;
        if (["www.youtube.com", "youtu.be", "youtube.com"].some(item => video_url.includes(item))) {
          const video_main_url = getYoutubeVideoId(video_url);
          video_url = `https://www.youtube.com/embed/${video_main_url}`;
      } else if (video_url.includes("vimeo.com")) {
        const vimeo_url = video_url.split("/");
        if(video_url.includes("?h")){
          video_url = video_url
        }else if(vimeo_url[3] != ""){
          let result = `https://player.vimeo.com/video/${vimeo_url[3]}?h=${vimeo_url[4]}`;
        if (result.includes("?share")) {
          video_url = result.replace("?share=copy", "");
        } else {
          video_url = `https://player.vimeo.com/video/${vimeo_url[3]}?h=${vimeo_url[4]}`;
        }
        } else {
          const code = video_url.split("/").pop();
          video_url = `https://vimeo.com/video/${code}`; 
        }
      } else if (video_url.includes("dailymotion.com")) {
        const dailymotion_url = video_url.split("/")
        if(dailymotion_url[3] != ""){
          video_url = `https://dailymotion.com/${dailymotion_url[3]}/${dailymotion_url[4]}`;
        } else {
          const code = video_url.split("/").pop();
          video_url = `https://dailymotion.com/video/${code}`; 
        }
      } else if (video_url.includes("ted.com")) {
        const ted_url = video_url.split("/")
        if(ted_url[3] != ""){
          video_url = `https://ted.com/${ted_url[3]}/${ted_url[4]}`;
        } else {
          const code = video_url.split("/").pop();
          video_url = `https://ted.com/talks/${code}`; 
        }
      }
      setIsVideoUrl(video_url);
    }
  }, [])
  //MB Details Elements Toggle up down
  // const handleToggle = (e) => {
  //   var contentElement = document.getElementById(
  //     "element_1" + e.target.getAttribute("data-build-element-id")
  //   );
  //   if (contentElement.classList.contains("element-off")) {
  //     contentElement.classList.remove("element-off");
  //   } else {
  //     contentElement.classList.add("element-off");
  //   }
  // };
  //DRAG AND DROP IMAGE
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const params = {
      ACL: "public-read",
      Key: file.name,
      ContentType: file.type,
      Body: file,
    };
    setIsUploading(true);
    setImageName(file.name);
    s3(process.env.REACT_APP_AWS_FOLDER_NAME)
      .upload(params)
      .on("httpUploadProgress", function (evt) {
        const value = Math.round((evt.loaded / evt.total) * 100);
        setProgress(value);
        if (value === 100) {
          setIsUploading(false);
        }
      })
      .send(function (err, data) {
        if (err) {
          return;
        }
        dispatch(
          handleSaveOnChangeMB({
            message_build_list_id: messageId,
            type: 1,
            element_details: [
              {
                build_elements_details_id: element.build_elements_details_id,
                media_url: data.Location,
              },
            ],
          })
        );
        setPreview(data.Location);
      });
  }, []);
  return (
    <>
      {element.is_visible == 1 ? (
        <div className="flipbox MBdetail" key={index}>
          <div className={`flipbox-left ${elementTitle}`}></div>
          {element.build_elements_name != "BIG IDEA / BIBLE" &&
            element.build_elements_name != "Section" &&
            element.build_elements_name != "Header" &&
            element.build_elements_name != "Big Idea (Legacy)" &&
          element.build_elements_name != "ABOUT THIS WEEK" ? (
            <div className={`box-border d-flex align-items-center border-left-bar ${elementTitle}`}>
              <div className="box-inner w-100">
                <div className="flipbox-right">
                  <div className="d-flex align-items-center justify-content-between">
                    <input
                      className="box-border-title"
                      type="text"
                      name=""
                      placeholder="test"
                      defaultValue={element.title}
                      id=""
                      disabled
                    />
                    <div className="righthold">
                      <button
                        type="button"
                        onClick={handleMessageTeachingAccordion}
                        data-target={element.is_collapsed}
                        data-build-element-id={element.build_elements_details_id}
                        className="teachingToogleBtn"
                      >
                        <img
                          src={downArrow}
                          // onClick={handleToggle}
                          id={"element_1" + elementToggle}
                          className={`accordionArrow ` + MBElementToggle}
                          aria-hidden="true"
                          data-build-element-id={
                            element.build_elements_details_id
                          }
                        />
                      </button>
                    </div>
                  </div>
                  <Collapse
                    className={"element_collapse mt15"}
                    isOpen={isOpenElementToggle}
                  >
                    {elementTitle == "Image" ? (
                      <div
                        id="collapseTwo cust-img"
                        className="collapse show element_image"
                        aria-labelledby="headingTwo"
                      >
                        <center className={element.media_url ? "" : "d-none"}>
                          <img src={element.media_url.replace(imageUrl.S3BUILDER_URL, imageUrl.BUILDER_DISPLAY_URL)} className={element.media_url ? "seriesImg mt-3 mb-3" : "seriesImg mt-3"} />
                        </center>
                      </div>
                    ) : null}
                    {elementTitle == "Video" ? (
                      (isVideoUrl === undefined && !isVideoUrl) ||
                        successData == false ? (
                        <div>
                          {successData != true &&
                            inValidUrl == true &&
                            isMediaUrl == undefined && (
                              <div className="viderror">
                                <b>Video link invalid! Please try again.</b>
                              </div>
                            )}
                        </div>
                      ) : successData == true ? (
                        <center>
                          {" "}
                          <iframe
                            src={isMediaUrl ? isMediaUrl : isVideoUrl}
                            frameBorder="0"
                            className="iframe mb-2"
                            allowFullScreen
                          ></iframe>{" "}
                        </center>
                      ) : null
                    ) : null}
                    <CkEditor
                      name={element.element_name}
                      data={element.content}
                      disabled
                    />
                  </Collapse>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};
