import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { handleFetchABElements } from "store/actions";
import queryString from "query-string";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { imageUrl } from "services/aws";

const ExportAppBuilder = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { SeriesElements, SeriesDetail } = useSelector(
        (state) => state.SeriesDetail
      );
    const { isAuth } = useSelector((state) => state.auth);
    const parsed = queryString.parse(location.search);
    // eslint-disable-next-line no-unused-vars
    const [isPrintElement, setIsPrintElement] = useState(false);
    const [seriesTitle, setSeriesTitle] = useState();

    // Set Series title when changed
    useEffect(() => {
        if(SeriesDetail){
            let selectedSeries = SeriesDetail?.gb_series_buildlists?.find((seriesData) => seriesData?.series_build_list_id === SeriesElements?.series_build_list_id);
            setSeriesTitle(selectedSeries?.series_buildlist_title);
        }
    },[SeriesDetail, SeriesElements?.series_build_list_id])

  useEffect(() => {
    if (isAuth) {
      dispatch(
        handleFetchABElements({
          series_build_list_id: parsed.seriesId,
          is_series: 0,
        }, (status) => {
          setIsPrintElement(true)
        })
      );
    }
  }, [dispatch, isAuth, parsed.seriesId]);

  // useEffect(() => {
  //   if (
  //     MBTeachingElements?.sycu_build_elements_details &&
  //     // eslint-disable-next-line eqeqeq
  //     isPrintElement == true
  //   ) {
  //     props?.print();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [MBTeachingElements, isPrintElement]);

  return (
    <html
      style={{ background: "#555" }}
      className={`${props?.isHidden && "component-hidden"}`}
    >
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{seriesTitle}</title>
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap"
          rel="stylesheet"
        />
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js"></script>
        <style>{`
          .up {
            transform: rotate(-180deg);
            -webkit-transform: rotate(-180deg);
          }
          .tempswitch {
            clear: both;
            overflow: hidden;
            padding: 17px 2px !important;
            font-weight: 400;
          }
          .page-break {
            page-break-inside: avoid ! important;
            page-break-before: avoid !important;
            page-break-after: avoid !important;
            position: relative;
          }
          .box a {
            word-break: break-all !important;
          }
          @page {
            margin: 0px 0px 0px 0px !important;
            padding: 0px 0px 0px 0px !important;
          }
        `}</style>
      </head>
      <body
      className="export-builder"
        style={{
          position: "relative",
          width: "100%",
          maxWidth: "900px",
          backgroundColor: "#fff",
          margin: "0 auto",
          lineHeight: "22px",
          fontFamily: "'Poppins', sans-serif",
          border: "none !important",
        }}
        ref={ref}
      >
        <div
          style={{
            // backgroundColor: "transparent",
            borderRadius: "0px",
            padding: "15px",
            border: "0px solid #eeeeee",
            backgroundColor: "#f5f5f8",
          }}
        >
          <div
            id="bd-main-area"
            style={{
              borderCollapse: "collapse",
              position: "relative",
              display: "block",
              
            }}
          >
            <div className="title-area" style={{ paddingBottom: "16px" }}>
              <h2
                className="maintitle"
                style={{
                  fontFamily: 'Roboto, sans-serif',
                  fontSize: "36px",
                  fontStyle: "italic",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: "800",
                  flexWrap: "wrap",
                  lineHeight: "40px",
                  paddingBottom: "10px",
                  display: "flex",
                  background: "#fff",
                  boxSizing: "border-box",
                  borderRadius: "5px",
                  color: "#212529",
                  marginTop: "5px",
                  backgroundColor: "#f5f5f8",
                }}
              >
                {seriesTitle}
              </h2>
            </div>
            <div 
                style={{
                    width: "100%",
                    border: "1px solid",
                    borderTopWidth: "15px",
                    borderTopColor: "#000000",
                    marginBottom: "8px",
                }}
            />
            {/* Series Image */}
            <div 
                className="box-border"
                style={{
                    border: "0px solid #ececec",
                    borderLeftColor: "rgba(176, 206, 71, 1)",
                    padding: "16px 20px",
                    borderLeftWidth: "17px",
                }}
            >
              <div className="flipbox-left image"></div>
              <div className="flipbox-right">
                <div className="flipbox-title">
                  <div className="etitle">
                    <b>Series Image</b>
                  </div>
                </div>
                <div
                  id="collapseTwo cust-img"
                  className="collapse show"
                  aria-labelledby="headingTwo"
                >
                  <center>
                    {SeriesElements.media_url ? (
                      <img
                        className="seriesImg"
                        src={SeriesElements.media_url.replace(imageUrl.S3BUILDER_URL, imageUrl.BUILDER_DISPLAY_URL)}
                        alt="series"
                      />
                    ) : (
                      <div className="nomedia">No Media Found</div>
                    )}
                  </center>
                </div>
              </div>
            </div>
            {/* Series Description */}
            <div className="box-border"
            style={{
                border: "0px solid #ececec",
                borderLeftColor: "#000000",
                padding: "16px 20px",
                borderLeftWidth: "17px",
            }}>
              <div className="flipbox-left talk"></div>
              <div className="flipbox-right">
                <div
                  id="headingOne"
                  className=""
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <div className="flipbox-title"></div>
                </div>
                <div
                  id="collapseOne"
                  className=" collapse show"
                  aria-labelledby="headingOne"
                ></div>

                <div className="etitle hidden_title ">
                  <b>Series Description</b>
                  <div
                    dangerouslySetInnerHTML={{
                        __html: SeriesElements?.series_buildlist_content,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {!!SeriesElements.sycu_build_elements_details &&
            SeriesElements.sycu_build_elements_details
            ?.filter(data => data?.is_visible === 1)
            ?.map((element, index) => (
              <React.Fragment key={index}>
                {/* For BIG IDEA and BIBLE */}
                {element.build_elements_id === 16 ? (
                  <div
                    className="big-idea-wrap"
                    style={{
                      height: "auto",
                      minHeight: "100px",
                      marginTop: "10px",
                      marginBottom: "10px",
                      display: "table",
                      width: "100%",
                    }}
                  >
                    <div
                      className="big-idea"
                      style={{
                        display: "table-cell",
                        width: "50%",
                        padding: "0 10px 0 0",
                        textAlign: "center",
                      }}
                    >
                      <div
                        className="big-idea-inner"
                        id="bigIdea"
                        style={{
                          height: "auto",
                          minHeight: "100px",
                          padding: "25px",
                          backgroundColor: "#e6e6e6",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "28px",
                            textAlign: "center",
                            display: "block",
                            color: "#2c3336",
                            fontWeight: "700",
                            fontStyle: "italic",
                            marginBottom: "30px",
                          }}
                        >
                          {element.title}
                        </div>
                        <p
                          style={{
                            height: "auto",
                            minHeight: "16px",
                            overflow: "visible",
                            margin: "0",
                            color: "#3c4247",
                            lineHeight: "16px",
                            fontSize: "14px",
                            // wordBreak: "break-all",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: element.content,
                          }}
                        />
                      </div>
                    </div>

                    <div
                      className="big-idea"
                      style={{
                        display: "table-cell",
                        width: "50%",
                        padding: "0 0 0 10px",
                        textAlign: "center",
                      }}
                    >
                      <div
                        className="big-idea-inner"
                        id="bible"
                        style={{
                          height: "auto",
                          backgroundColor: "#e6e6e6",
                          minHeight: "100px",
                          padding: "25px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "28px",
                            textAlign: "center",
                            display: "block",
                            color: "#2c3336",
                            fontWeight: "700",
                            fontStyle: "italic",
                            marginBottom: "30px",
                          }}
                        >
                          {element.extra_title}
                        </div>
                        <p
                          style={{
                            height: "auto",
                            minHeight: "16px",
                            overflow: "visible",
                            margin: "0",
                            color: "#3c4247",
                            lineHeight: "16px",
                            fontSize: "14px",
                            // wordBreak: "break-all",
                          }}
                        >
                          {element.extra_content}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : element.build_elements_id === 21 ? (
                  <div
                    className="big-idea-wrap"
                    style={{
                      height: "auto",
                      minHeight: "100px",
                      marginTop: "18px",
                      marginBottom: "10px",
                      display: "table",
                      width: "101.7%",
                    }}
                  >
                    <div
                      className="big-idea"
                      style={{
                        display: "table-cell",
                        width: "50%",
                        padding: "0 10px 0 0",
                        textAlign: "center",
                      }}
                    >
                      <div
                        className="big-idea-inner"
                        id="bigIdea"
                        style={{
                          height: "auto",
                          minHeight: "100px",
                          padding: "25px",
                          backgroundColor: "#e6e6e6",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "28px",
                            textAlign: "center",
                            display: "block",
                            color: "#2c3336",
                            fontWeight: "700",
                            fontStyle: "italic",
                            marginBottom: "30px",
                          }}
                        >
                          {element.title}
                        </div>
                        <p
                          style={{
                            height: "auto",
                            minHeight: "16px",
                            overflow: "visible",
                            margin: "0",
                            color: "#3c4247",
                            lineHeight: "16px",
                            fontSize: "14px",
                            // wordBreak: "break-all",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: element.content,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : // For Scripture
                element.build_elements_id === 2 ? (
                  <div
                    className="box"
                    style={{
                      marginBottom: "5px",
                      marginTop: "23px",
                      border: "0px solid #BBBBBB",
                      borderLeftColor: "rgb(197, 32, 38)",
                      padding: "16px 20px",
                      borderLeftWidth: "17px",
                      lineHeight: 1.5,
                      wordBreak: "normal",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div
                        className="box-title"
                        style={{
                          background: "#ffffff",
                          border: "1px solid #BBBBBB",
                          boxSizing: "border-box",
                          borderRadius: "5px",
                          padding: "8px 14px",
                          width: "100%",
                        }}
                      >
                        {element.title}
                      </div>
                    </div>
                    <div
                      style={{
                        background: "#ffffff",
                        border: "1px solid #BBBBBB",
                        boxSizing: "border-box",
                        borderRadius: "5px",
                        padding: "8px 14px",
                        width: "100%",
                        marginTop: "20px",
                        wordBreak: "normal",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: element.content,
                      }}
                    />
                  </div>
                ) : // For Talk
                element.build_elements_id === 1 ? (
                  <div
                    className="box talk-container"
                    style={{
                      marginBottom: "5px",
                      marginTop: "23px",
                      border: "0px solid #BBBBBB",
                      borderLeftColor: "rgb(70, 67, 186)",
                      padding: "16px 20px",
                      borderLeftWidth: "17px",
                      lineHeight: 1.5,
                      wordBreak: "normal",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div
                        className="box-title"
                        style={{
                          background: "#ffffff",
                          border: "1px solid #BBBBBB",
                          boxSizing: "border-box",
                          borderRadius: "5px",
                          padding: "8px 14px",
                          width: "100%",
                        }}
                      >
                        {element.title}
                      </div>
                    </div>
                    <div
                      style={{
                        background: "#ffffff",
                        border: "1px solid #BBBBBB",
                        boxSizing: "border-box",
                        borderRadius: "5px",
                        padding: "8px 14px",
                        width: "100%",
                        marginTop: "20px",
                        wordBreak: "normal",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: element.content,
                      }}
                    />
                  </div>
                ) : // For Story
                element.build_elements_id === 3 ? (
                  <div
                    className="box"
                    style={{
                      marginBottom: "5px",
                      marginTop: "23px",
                      border: "0px solid #BBBBBB",
                      borderLeftColor: "rgb(50, 189, 212)",
                      padding: "16px 20px",
                      borderLeftWidth: "17px",
                      lineHeight: 1.5,
                      wordBreak: "normal",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div
                        className="box-title"
                        style={{
                          background: "#ffffff",
                          border: "1px solid #BBBBBB",
                          boxSizing: "border-box",
                          borderRadius: "5px",
                          padding: "8px 14px",
                          width: "100%",
                        }}
                      >
                        {element.title}
                      </div>
                    </div>
                    <div
                      style={{
                        background: "#ffffff",
                        border: "1px solid #BBBBBB",
                        boxSizing: "border-box",
                        borderRadius: "5px",
                        padding: "8px 14px",
                        width: "100%",
                        marginTop: "20px",
                        wordBreak: "normal",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: element.content,
                      }}
                    />
                  </div>
                ) : // For Image
                element.build_elements_id === 4 ? (
                  <div
                    className="box"
                    style={{
                      marginBottom: "5px",
                      marginTop: "23px",
                      border: "0px solid #BBBBBB",
                      borderLeftColor: "rgb(161, 203, 58)",
                      padding: "16px 20px",
                      borderLeftWidth: "17px",
                      lineHeight: 1.5,
                      wordBreak: "normal",
                      overflow: "hidden"
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div
                        className="box-title"
                        style={{
                          background: "#ffffff",
                          border: "1px solid #BBBBBB",
                          boxSizing: "border-box",
                          borderRadius: "5px",
                          padding: "8px 14px",
                          width: "100%",
                        }}
                      >
                        {element.title}
                      </div>
                    </div>
                    {element?.media_url && (
                      <>
                        <img
                          src={
                            element?.media_url ||
                            "https://sycu-builder-thumbnails.s3.us-east-2.amazonaws.com/builder-website-images/broken.jpg"
                          }
                          alt="img"
                          style={{
                            margin: "10px 0",
                            padding: "10px",
                            objectFit: "contain",
                            height: "100%",
                            width: "100%",
                          }}
                        />
                      </>
                    )}
                    <div
                      style={{
                        background: "#ffffff",
                        border: "1px solid #BBBBBB",
                        boxSizing: "border-box",
                        borderRadius: "5px",
                        padding: "8px 14px",
                        width: "100%",
                        marginTop: "20px",
                        wordBreak: "normal",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: element.content,
                      }}
                    />
                  </div>
                ) : // For Video
                element.build_elements_id === 5 ? (
                  <div
                    className="box"
                    style={{
                      marginBottom: "5px",
                      marginTop: "23px",
                      border: "0px solid #BBBBBB",
                      borderLeftColor: "rgb(226, 222, 27)",
                      padding: "16px 20px",
                      borderLeftWidth: "17px",
                      lineHeight: 1.5,
                      wordBreak: "normal",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div
                        className="box-title"
                        style={{
                          background: "#ffffff",
                          border: "1px solid #BBBBBB",
                          boxSizing: "border-box",
                          borderRadius: "5px",
                          padding: "8px 14px",
                          width: "100%",
                        }}
                      >
                        {element.title}
                      </div>
                      <div
                        style={{ display: "inline-block", float: "right" }}
                      ></div>
                    </div>
                    {element?.media_url && (
                      <h3
                        style={{
                          margin: "10px auto",
                          display: "table",
                          textAlign: "center",
                        }}
                      >
                        <a
                          href={element?.media_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            display: "block",
                            textDecoration: "none",
                            marginBottom: "10px",
                          }}
                        >
                          Here is the video URL
                        </a>
                      </h3>
                    )}
                    <div
                      style={{
                        background: "#ffffff",
                        border: "1px solid #BBBBBB",
                        boxSizing: "border-box",
                        borderRadius: "5px",
                        padding: "8px 14px",
                        width: "100%",
                        marginTop: "20px",
                        wordBreak: "normal",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: element.content,
                      }}
                    />
                  </div>
                ) : // For Question
                element.build_elements_id === 6 ? (
                  <div
                    className="box"
                    style={{
                      marginBottom: "5px",
                      marginTop: "23px",
                      border: "0px solid #BBBBBB",
                      borderLeftColor: "rgb(50, 144, 206)",
                      padding: "16px 20px",
                      borderLeftWidth: "17px",
                      lineHeight: 1.5,
                      wordBreak: "normal",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div
                        className="box-title"
                        style={{
                          background: "#ffffff",
                          border: "1px solid #BBBBBB",
                          boxSizing: "border-box",
                          borderRadius: "5px",
                          padding: "8px 14px",
                          width: "100%",
                        }}
                      >
                        {element.title}
                      </div>
                    </div>
                    <div
                      style={{
                        background: "#ffffff",
                        border: "1px solid #BBBBBB",
                        boxSizing: "border-box",
                        borderRadius: "5px",
                        padding: "8px 14px",
                        width: "100%",
                        marginTop: "20px",
                        wordBreak: "normal",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: element.content,
                      }}
                    />
                  </div>
                ) : // For Object Lesson
                element.build_elements_id === 7 ? (
                  <div
                    className="box"
                    style={{
                      marginBottom: "5px",
                      marginTop: "23px",
                      border: "0px solid #BBBBBB",
                      borderLeftColor: "rgb(79, 192, 162)",
                      padding: "16px 20px",
                      borderLeftWidth: "17px",
                      lineHeight: 1.5,
                      wordBreak: "normal",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div
                        className="box-title"
                        style={{
                          background: "#ffffff",
                          border: "1px solid #BBBBBB",
                          boxSizing: "border-box",
                          borderRadius: "5px",
                          padding: "8px 14px",
                          width: "100%",
                        }}
                      >
                        {element.title}
                      </div>
                    </div>
                    <div
                      style={{
                        background: "#ffffff",
                        border: "1px solid #BBBBBB",
                        boxSizing: "border-box",
                        borderRadius: "5px",
                        padding: "8px 14px",
                        width: "100%",
                        marginTop: "20px",
                        wordBreak: "normal",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: element.content,
                      }}
                    />
                  </div>
                ) : // For Activity
                element.build_elements_id === 8 ? (
                  <div
                    className="box"
                    style={{
                      marginBottom: "5px",
                      marginTop: "23px",
                      border: "0px solid #BBBBBB",
                      borderLeftColor: "rgb(79, 192, 162)",
                      padding: "16px 20px",
                      borderLeftWidth: "17px",
                      lineHeight: 1.5,
                      wordBreak: "normal",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div
                        className="box-title"
                        style={{
                          background: "#ffffff",
                          border: "1px solid #BBBBBB",
                          boxSizing: "border-box",
                          borderRadius: "5px",
                          padding: "8px 14px",
                          width: "100%",
                        }}
                      >
                        {element.title}
                      </div>
                    </div>
                    <div
                      style={{
                        background: "#ffffff",
                        border: "1px solid #BBBBBB",
                        boxSizing: "border-box",
                        borderRadius: "5px",
                        padding: "8px 14px",
                        width: "100%",
                        marginTop: "20px",
                        wordBreak: "normal",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: element.content,
                      }}
                    />
                  </div>
                ) : // For Discussion
                element.build_elements_id === 9 ? (
                  <div
                    className="box"
                    style={{
                      marginBottom: "5px",
                      marginTop: "23px",
                      border: "0px solid #BBBBBB",
                      borderLeftColor: "rgb(162, 218, 225)",
                      padding: "16px 20px",
                      borderLeftWidth: "17px",
                      lineHeight: 1.5,
                      wordBreak: "normal",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div
                        className="box-title"
                        style={{
                          background: "#ffffff",
                          border: "1px solid #BBBBBB",
                          boxSizing: "border-box",
                          borderRadius: "5px",
                          padding: "8px 14px",
                          width: "100%",
                        }}
                      >
                        {element.title}
                      </div>
                      <div
                        style={{ display: "inline-block", float: "right" }}
                      ></div>
                    </div>
                    <div
                      style={{
                        background: "#ffffff",
                        border: "1px solid #BBBBBB",
                        boxSizing: "border-box",
                        borderRadius: "5px",
                        padding: "8px 14px",
                        width: "100%",
                        marginTop: "20px",
                        wordBreak: "normal",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: element.content,
                      }}
                    />
                  </div>
                ) : element.build_elements_id === 10 || // For Prayer
                  element.build_elements_id === 11 || // For Reflection
                  element.build_elements_id === 12 || // For Response
                  element.build_elements_id === 13 || // For Music
                  element.build_elements_id === 14 ? ( // For Poll
                  <div
                    className="box"
                    style={{
                      marginBottom: "5px",
                      marginTop: "23px",
                      border: "0px solid #BBBBBB",
                      borderLeftColor:
                        element.build_elements_id === 14
                          ? "rgb(216, 72, 38)"
                          : element.build_elements_id === 13
                          ? "rgb(234, 171, 32)"
                          : element.build_elements_id === 12
                          ? "rgb(92, 71, 156)"
                          : element.build_elements_id === 11
                          ? "rgb(173, 78, 157)"
                          : "#d84826" /* 'rgb(236, 73, 127)' */,
                      padding: "16px 20px",
                      borderLeftWidth: "17px",
                      lineHeight: 1.5,
                      wordBreak: "normal",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div
                        className="box-title"
                        style={{
                          background: "#ffffff",
                          border: "1px solid #BBBBBB",
                          boxSizing: "border-box",
                          borderRadius: "5px",
                          padding: "8px 14px",
                          width: "100%",
                        }}
                      >
                        {element.title}
                      </div>
                      <div
                        style={{ display: "inline-block", float: "right" }}
                      ></div>
                    </div>
                    <div
                      style={{
                        background: "#ffffff",
                        border: "1px solid #BBBBBB",
                        boxSizing: "border-box",
                        borderRadius: "5px",
                        padding: "8px 14px",
                        width: "100%",
                        marginTop: "20px",
                        wordBreak: "normal",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: element.content,
                      }}
                    />
                  </div>
                ) : // For Header
                element.build_elements_id === 18 ? (
                  <div
                    className="box-header"
                    style={{
                      marginTop: "23px",
                      padding: "0px",
                      backgroundColor: "#fff",
                      margin: "24px 0 13px",
                    }}
                  >
                    <h3
                      style={{
                        border: "1px solid rgb(187, 187, 187)",
                        color: "rgb(60, 66, 71)",
                        backgroundColor: "rgb(255, 255, 255)",
                        fontWeight: 600,
                        fontSize: "32px",
                        padding: "12px 4px",
                        width: "100%",
                        fontFamily: "inherit",
                      }}
                    >
                      {element.title}
                    </h3>
                  </div>
                ) : // For Section
                element.build_elements_id === 17 ? (
                  <div
                    className="box-header"
                    style={{
                      marginTop: "23px",
                      backgroundColor: "#000000",
                      padding: "8px 0",
                      marginBottom: "5px",
                    }}
                  >
                    <h3
                      style={{
                        margin: 0,
                        fontSize: "24px",
                        fontStyle: "italic",
                        fontWeight: 400,
                        textAlign: "center",
                        color: "#fff",
                      }}
                    >
                      {element.title}
                    </h3>
                  </div>
                ) : // For Big Idea Legacy
                element.build_elements_id === 19 ? (
                  <div
                    className="box-header"
                    style={{
                      margin: "30px auto 15px",
                      textAlign: "center",
                      position: "relative",
                      backgroundColor: "rgb(44, 51, 54)",
                      padding: "20px",
                      marginBottom: "0px",
                    }}
                  >
                    <h3
                      style={{
                        margin: 0,
                        fontSize: "27px",
                        fontWeight: "bold",
                        textAlign: "center",
                        color: "#fff",
                        fontFamily: "inherit",
                      }}
                    >
                      {element.title}
                    </h3>
                    <p
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        color: "#fff",
                        fontFamily: "inherit",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: element.content,
                      }}
                    />
                  </div>
                ) : (
                  // Other elements
                  <div
                    className="box"
                    style={{
                      // marginTop: '23px',
                      padding: "20px",
                      marginTop: "10px",
                      backgroundColor: "#fff",
                      borderLeft: "22px solid",
                      borderLeftWidth: "17px",
                      borderLeftColor: "#e6e6e6",
                      marginBottom: "5px",
                      lineHeight: 1.5,
                      wordBreak: "normal",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div
                        className="box-title"
                        style={{
                          color: "#2c3336",
                          fontSize: "18px",
                          fontWeight: 700,
                          display: "inline-block",
                        }}
                      >
                        {element.title}
                      </div>
                      <div
                        style={{ display: "inline-block", float: "right" }}
                      ></div>
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: element.content,
                      }}
                    />
                  </div>
                )}
              </React.Fragment>
            ))}
        </div>
      </body>
    </html>
  );
});

export default ExportAppBuilder;
