import {
  GET_MB_FOLDER_LIST,
  GET_MB_FOLDER_LIST_SUCCESS,
  GET_MB_FOLDER_LIST_ERROR,
  MB_FOLDER_ADD_EDIT,
  MB_FOLDER_ADD_EDIT_SUCCESS,
  MB_FOLDER_ADD_EDIT_ERROR,
  MB_FOLDER_DELETE,
  MB_FOLDER_DELETE_SUCCESS,
  MB_FOLDER_DELETE_ERROR,
  GET_MB_FOLDER_BUILD_LIST,
  GET_MB_FOLDER_BUILD_LIST_SUCCESS,
  GET_MB_FOLDER_BUILD_LIST_ERROR,
  DRAG_BUILD_TO_FOLDER,
  DRAG_BUILD_TO_FOLDER_SUCCESS,
  DRAG_BUILD_TO_FOLDER_ERROR,
  GET_LIBRARY_FOLDER_BY_TYPE,
  GET_LIBRARY_FOLDER_BY_TYPE_SUCCESS,
  GET_LIBRARY_FOLDER_BY_TYPE_ERROR,
  SET_MB_LISTING_BY_FOLDER_ID,
  GET_LIBRARY_BUILD,
  GET_LIBRARY_BUILD_SUCCESS,
  GET_LIBRARY_BUILD_ERROR,
  SET_MB_LIBRARY,
  SET_MB_FOLDER_ID,
  SET_MB_FOLDER_TITLE,
  SET_MB_LIBRARY_FOLDER_ID,
  SET_MB_LIBRARY_FOLDER_TITLE,
  RESET_MB_FOLDER_BUILD_LIST,
  SET_PAGE_MB_FOLDER_BUILD_LIST
} from "./actionTypes";

const MBInitialState = {
  MBFolderListing: [],
  MBFolderBuildList: [],
  loading: false,
  LibraryListing: [],
  LibraryBuildListing: [],
  StoreFolderId: 0,
  StoreFolderTitle: "User Builds",
  StoreLibraryFolderId: "",
  StoreLibraryFolderTitle: "",
  MBFolderSave: false,
  search: "",
  sizePerPageMBFolder: 10,
  pageNoMBFolder: 1,
  sortField: "message_build_list_id",
  sortOrder: "DESC",
  totalSizeMBFolder: 0,
}

const MBReducer = (state = MBInitialState, action) => {
  const { type, payload } = action
  switch (type) {
    /* Reset MBFolder Build List */
    case RESET_MB_FOLDER_BUILD_LIST:
      return {
        ...state,
        MBFolderBuildList: []
      }

    /* Set pageNo for MbFolder build List */
    case SET_PAGE_MB_FOLDER_BUILD_LIST:
      return {
        ...state,
        pageNoMBFolder: payload
      }

    /**Get MB folder list */
    case GET_MB_FOLDER_LIST:
      return {
        ...state,
        loading: true,
      }
    case GET_MB_FOLDER_LIST_SUCCESS:
      return {
        ...state,
        MBFolderListing: action.payload,
        loading: false,
        totalSizeMBFolder: payload.count,
      }
    case GET_MB_FOLDER_LIST_ERROR:
      return {
        ...state,
        loading: true,
      }
    /** MB folder ADD/EDIT */
    case MB_FOLDER_ADD_EDIT:
      return {
        ...state,
        loading: true,
        MBFolderSave: true,
      }
    case MB_FOLDER_ADD_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        MBFolderSave: false,
      }
    case MB_FOLDER_ADD_EDIT_ERROR:
      return {
        ...state,
        loading: true,
        MBFolderSave: false,
      }
    /** MB folder DELETE */
    case MB_FOLDER_DELETE:
      return {
        ...state,
        loading: true,
      }
    case MB_FOLDER_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case MB_FOLDER_DELETE_ERROR:
      return {
        ...state,
        loading: true,
      }
    /** Get builder by folder id */
    case GET_MB_FOLDER_BUILD_LIST:
      return {
        ...state,
        loading: false,
      }
    case GET_MB_FOLDER_BUILD_LIST_SUCCESS:
      return {
        ...state,
        MBFolderBuildList: [...state.MBFolderBuildList, ...payload.data],
        StoreLibraryFolderId: "",
        loading: false,
        totalSizeMBFolder: payload.count
      }
    case GET_MB_FOLDER_BUILD_LIST_ERROR:
      return {
        ...state,
        loading: true,
      }
    /** Get library folder using type */
    case GET_LIBRARY_FOLDER_BY_TYPE:
      return {
        ...state,
        loading: false,
      }
    case GET_LIBRARY_FOLDER_BY_TYPE_SUCCESS:
      return {
        ...state,
        LibraryListing: action.payload,
        loading: false,
      }
    case GET_LIBRARY_FOLDER_BY_TYPE_ERROR:
      return {
        ...state,
        loading: true,
      }
    /** Drag drop build to folder */
    case DRAG_BUILD_TO_FOLDER:
      return {
        ...state,
        loading: true,
      }
    case DRAG_BUILD_TO_FOLDER_SUCCESS:
      return {
        ...state,
        loading: true,
      }
    case DRAG_BUILD_TO_FOLDER_ERROR:
      return {
        ...state,
        loading: true,
      }

    //deleting MB MESSAGE from redux
    case SET_MB_LISTING_BY_FOLDER_ID:
      return {
        ...state,
        MBFolderBuildList: [
          ...state.MBFolderBuildList.filter(item => item.message_build_list_id !== payload.elementId.message_build_list_id)
        ]
      }
    case GET_LIBRARY_BUILD:
      return {
        ...state,
        loading: true,
      }
    case GET_LIBRARY_BUILD_SUCCESS:
      return {
        ...state,
        LibraryBuildListing: action.payload,
        loading: false,
      }
    case GET_LIBRARY_BUILD_ERROR:
      return {
        ...state,
        loading: false,
      }
    //deleting library from redux
    case SET_MB_LIBRARY:
      return {
        ...state,
        ...state.LibraryBuildListing.map(x => {
          if (x.category_id === payload.category_id) {
            let message_details = x.message_details;
            let week = payload.week_id[0]
            const index = message_details.findIndex(key => Object.keys(key)[0] === week)
            message_details[index][week] = message_details[index][week].filter(id => id.message_build_list_id !== payload.elementId.message_build_list_id)
            x.message_details = message_details;
            return x;
          } else {
            return x;
          }
        })
      }
    /*Store Folder ID */
    case SET_MB_FOLDER_ID:
      return {
        ...state,
        StoreFolderId: action.payload,
        loading: true,
      }
    /*Store Folder TITLE */
    case SET_MB_FOLDER_TITLE:
      return {
        ...state,
        StoreFolderTitle: action.payload,
        loading: true,
      }

    /*Store Library Folder ID */
    case SET_MB_LIBRARY_FOLDER_ID:
      return {
        ...state,
        StoreLibraryFolderId: action.payload,
        StoreFolderId: 0,
        loading: false,
      }

    /*Store Library Folder TITLE */
    case SET_MB_LIBRARY_FOLDER_TITLE:
      return {
        ...state,
        StoreLibraryFolderTitle: action.payload,
        loading: true,
      }
    default:
      return state
  }
}
export default MBReducer
