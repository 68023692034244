import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import MessageBuilderPrintBuilds from "./components/builds";
import Header from "components/common/Header/Header";
import Footer from "components/common/Footer/Footer";
import backImage from "assets/images/icon/back.png";
import unauthorizedUserImage from "assets/images/unauthorizedUserImage.svg";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

export const MessageBuilderPrint = () => {
  const componentRef = useRef();
  const params = useParams();
  const { user } = useSelector((state) => state.auth);
  const { MBTeachingElements } = useSelector(
    (state) => state.MessageBuilderTeaching
  );

  const [isMessageList, setIsMessageList] = useState();

  // Set MBPrintElements
  useEffect(() => {
    setIsMessageList(MBTeachingElements.count);
  }, [MBTeachingElements]);

  const print = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Message_Builder_${new Date() / 1000}.pdf`,
  });

  const printCss = `@media print {
    .print-tools-box.tools-left .bib-left {
      width: 49% !important;
    }
    .print-tools-box.tools-left .bib-right {
      width: 49% !important;
    }
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
    .page-break {
      margin-bottom: 20rem;
      display: block;
      page-break-before: auto;
    }
  }
  @page {
    size: auto;
    margin: 15mm 10mm;
  }
  .MBdetail{ 
    page-break-inside: avoid;
  }
  `;

  // .MBdetail is used for section break if content not fit

  return (
    <>
      <div className="no-sidebar">
        <div className="main_content">
          <Header />
          <div className="main_card main_card_body pb-0">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/builder">
                    {" "}
                    <span>Home</span>
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Lesson Builder
                </li>
              </ol>
            </nav>
            <div className="main_card_wrapper tabbing-card">
              <div className="wrapper-heading-area filter-area">
                <div className="title">
                  <Link to="/">
                    <img src={backImage} alt="" className="mr-2" />
                    <h1 className="wrapper-heading fw-600 mb0">
                      Back To Your Lesson Builder
                    </h1>
                  </Link>
                </div>
                {isMessageList !== 0 ? (
                  <div className="new-builder-action-button">
                    <Link
                      to={"/builder/edit/" + params.id}
                      className={!isMessageList ? "d-none" : "btn primary-btn"}
                    >
                      Edit
                    </Link>
                  </div>
                ) : null}
              </div>
            </div>
            {isMessageList === 0 ? (
              <div className="main_card_wrapper tabbing-card">
                <div className="unauthorized_user_image col-lg-12">
                  <div className="main_card_wrapper tabbing-card">
                    <img
                      src={unauthorizedUserImage}
                      alt="unauthorizedUserImage"
                      className="unauthorizedUserImage"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="main-container">
                <div className="container-fluid custom-cont-padd">
                  <div className="row sub-container-area">
                    <MessageBuilderPrintBuilds
                      ref={componentRef}
                      css={printCss}
                      print={print}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};
