import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import CkEditor from "components/common/CustomCkEditor/CkEditor";
import { AppTeachingElements } from "./AppTeachingElements";
import MBLoading from "components/common/Loader/MBLoading";
import { handleFetchABElements, handleFetchABElementsUsingCode } from "store/actions";
import growNoData from "assets/images/no-data.png";
import queryString from "query-string";
import { imageUrl } from "services/aws";

export const AppBuilderTeachingElements = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [elementLoader, setElementLoader] = useState(false);

  const { ABTeachingElements } = useSelector(
    (state) => state.AppBuilderTeaching
  );
  const { isAuth } = useSelector((state) => state.auth);

  useEffect(() => {
    // Get ABElements By Code
    if (parsed.code) {
      setElementLoader(false)
      dispatch(
        handleFetchABElementsUsingCode({
          code_id: parsed.code,
        }, (status) => {
          if (status) {
            setElementLoader(true)
          }
        })
      );
    } else if (parsed.seriesId) {
      setElementLoader(false)
      if (isAuth) {
        dispatch(
          handleFetchABElements({
            series_build_list_id: parseInt(parsed.seriesId),
          }, (status) => {
            if (status) {
              setElementLoader(true)
            }
          })
        );
      }
    } else {
      setElementLoader(false)
      dispatch(
        handleFetchABElements({
          series_build_list_id: params.id,
        }, (status) => {
          if (status) {
            setElementLoader(true)
          }
        })
      );
    }
    return () => {
      setElementLoader(false)
    }
  }, [dispatch]);
  const [elementChange, setOnchangeElement] = useState(false);

  return (
    elementLoader == false ?
      <div className="col-12">
        <MBLoading />
      </div> : (Object.keys(ABTeachingElements).length !== 0 ?
        <div className="col-12">
          <div className="message-builder-left-side app-builder-teaching">
            <form className="message-form" action="#" method="post">
              {elementLoader == false ? <MBLoading /> : <h2 id="message-heading" className="box-border">
                {ABTeachingElements.series_buildlist_title}
              </h2>}
              <div className="tools">
                <div className="tools-area"></div>
              </div>
              {elementLoader == false ? <MBLoading /> : <>
                <div className="box-border">
                  <div className="flipbox-left image"></div>
                  <div className="flipbox-right">
                    <div className="flipbox-title">
                      <div className="etitle">
                        <b>Series Image</b>
                      </div>
                    </div>
                    <div
                      id="collapseTwo cust-img"
                      className="collapse show"
                      aria-labelledby="headingTwo"
                    >
                      <center>
                        {ABTeachingElements.media_url ? (
                          <img
                            className="seriesImg"
                            src={ABTeachingElements.media_url.replace(imageUrl.S3BUILDER_URL, imageUrl.BUILDER_DISPLAY_URL)}
                          />
                        ) : (
                          <div className="nomedia">No Media Found</div>
                        )}
                      </center>
                    </div>
                  </div>
                </div>
              </>}

              {elementLoader == false ? <MBLoading /> : <>
                <div className="box-border">
                  <div className="flipbox-left talk"></div>
                  <div className="flipbox-right">
                    <div
                      id="headingOne"
                      className=""
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <div className="flipbox-title"></div>
                    </div>
                    <div
                      id="collapseOne"
                      className=" collapse show"
                      aria-labelledby="headingOne"
                    ></div>

                    <div className="etitle hidden_title ">
                      <b>Series Description</b>
                      <CkEditor
                        data={ABTeachingElements.series_buildlist_content}
                        disabled
                        onChange={(data) => {
                          setOnchangeElement({ elementChange: data });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>}

              {elementLoader == false ? <MBLoading /> : (!!ABTeachingElements?.sycu_build_elements_details &&
                ABTeachingElements.sycu_build_elements_details.map(
                  (element, index) => {
                    return (
                      <AppTeachingElements
                        key={index}
                        element={element}
                        index={index}
                      />
                    );
                  }
                ))}
            </form>
          </div>
        </div> : <div className="col-lg-12 no-data-col">
          <img src={growNoData} className="no-data-found"></img>
        </div>)
  );
};
