import { React, useEffect, useState, useMemo, useCallback } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import { Modal, Button, Progress } from "reactstrap";
import moment from "moment";
import { axiosBuilder } from "services/api";
import { s3 } from "services/aws";
import { useDropzone } from "react-dropzone";
import { createABVolume, createMBVolume } from "constants/urls";
import { get } from "services/cookies"; //set
import ReminderPopupModel from "./ReminderPopupModel";
import DefaultImage from "../../../assets/images/GROWLogo.jpg";
import {
  handleGetFolderBuildById,
  handleGetABFolderBuildById,
  setABListingByFolderId,
  setMBListingByFolderId,
  setABVolume,
  setMBListing,
  handleABDuplicate,
  handleABDelete,
  handleFetchABListing,
  handleFetchMBListing,
  handleMBUploadBuildImage,
  handleABShareLink,
  handleMBDelete,
  handleMBDuplicate,
  setPageABCustomBuildList,
  setPageABFolderBuildList,
  setPageMBFolderBuildList,
  setPageMBCustomBuildList,
  setABLibraryFolderId,
  setMBLibraryFolderId,
  getActiveSubscriptionsBySite,
  handleMBBuildLength,
  handleVolumeLength,
  handleMBResetBuildData,
  handleResetVolumeData,
} from "store/actions";
import searchIcon from "assets/images/icon/search-normal.svg";
import buildActionIcon from "assets/images/icon/action-dot.svg";
import deleteAlert from "assets/images/icon/red-info.svg";
import modalClose from "assets/images/icon/modal-close.svg";
import dragFile from "assets/images/icon/folder-2.svg";
import { imageUrl } from "services/aws";
import InfiniteScroll from "react-infinite-scroll-component";
import MBLoading from "../Loader/MBLoading";

const DropZone = ({
  onUploaded,
  accept,
  multiple,
  children,
  handleUploadProgress,
}) => {
  //UseStates
  const [progress, setProgress] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (!isUploading) setProgress(0);
  }, [isUploading]);

  //Upload to AWS S3
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const fileName = file.name
      .split(".")
      .slice(0, -1)
      .join(".")
      .replace(" ", "_");
    const fileExtension = file.name.split(".")[1];
    const currentTime = file.lastModified;
    const fileFullName = fileName + "_" + currentTime + "." + fileExtension;
    const params = {
      ACL: "public-read",
      Key: fileFullName,
      ContentType: file.type,
      Body: file,
    };
    setIsUploading(true);
    s3(process.env.REACT_APP_AWS_FOLDER_NAME)
      .upload(params)
      .on("httpUploadProgress", function (evt) {
        const value = Math.round((evt.loaded / evt.total) * 100);
        setProgress(value);
        handleUploadProgress(value);
        if (value === 100) {
          setIsUploading(false);
        }
      })
      .send(function (err, data) {
        if (err) {
          return;
        }
        onUploaded(data.Location);

        setIsUploading(false);
      });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    accept,
  });
  return (
    <>
      <div className="modal_data editImageModalData">
        <div className="row">
          <div
            className={
              children
                ? "col-md-12 form-group d-flex align-items-center"
                : "col-md-12 form-group"
            }
          >
            <div className="dropzone-area" {...getRootProps()}>
              <div className="align-items-center">
                <input {...getInputProps()} />
                <img src={dragFile} alt="folder" draggable="false" />
                <h3 className="fw-500 dark-text">
                  Drop your files here, or <span>Browse</span>
                </h3>
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
      {isUploading && (
        <div className="mt-1">
          <Progress color="primary" value={progress}>
            {progress}%
          </Progress>
        </div>
      )}
    </>
  );
};
const UploadMedia = ({ row, onClose, isOpen, folderId, appFolderId }) => {
  //FIND THE LOCATION APP OR MESSAGE
  const Location = useLocation();
  const isValid = useMemo(
    () => Location.pathname.startsWith("/app-builder"),
    [Location]
  );
  const dispatch = useDispatch();
  //UseStates
  const [urlValue, setUrlValue] = useState("");
  const [isHandleModal, setIsHandleModal] = useState(false);
  const [isUpload, setIsUpload] = useState(0);

  //Upload Image handle Change
  function handleChange(url) {
    setUrlValue(url);
  }
  //Upload Image
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid == true) {
      if (urlValue === "") {
        onClose();
      } else {
        //MB Build Upload Image
        setIsHandleModal(true);
        dispatch(
          handleMBUploadBuildImage(
            {
              id: row.volume_id,
              media_url: urlValue,
              type: 1,
            },
            (status) => {
              if (status) {
                if (appFolderId !== 0) {
                  setIsHandleModal(false);
                  //Get AB using Folder id
                  dispatch(
                    handleGetABFolderBuildById({
                      build_folder_id: appFolderId,
                      page_no: 1,
                      page_record: 10,
                    })
                  );
                } else {
                  setIsHandleModal(false);
                  //AB Listing
                  dispatch(
                    handleFetchABListing({
                      page_no: 1,
                      page_record: 10,
                    })
                  );
                }
                onClose();
                setUrlValue("");
              }
            }
          )
        );
      }
    } else {
      if (urlValue === "") {
        onClose();
      } else {
        //MB Build Upload Image
        setIsHandleModal(true);
        dispatch(
          handleMBUploadBuildImage(
            {
              id: row.message_build_list_id,
              media_url: urlValue,
              type: 0,
            },
            (status) => {
              if (status) {
                if (folderId !== 0) {
                  //Get build by folder id
                  setIsHandleModal(false);
                  dispatch(
                    handleGetFolderBuildById({
                      build_folder_id: folderId,
                      page_no: 1,
                      page_record: 10,
                    })
                  );
                } else {
                  //MB Listing
                  setIsHandleModal(false);
                  dispatch(
                    handleFetchMBListing({
                      page_no: 1,
                      page_record: 10,
                    })
                  );
                }
                onClose();
                setUrlValue("");
              }
            }
          )
        );
      }
    }
  };

  //Close upload media modal
  const handleClose = () => {
    setUrlValue("");
    onClose();
    setIsHandleModal(false);
  };
  const handleUploadProgress = (handleUploadProgress) => {
    setIsUpload(handleUploadProgress);
  };
  return (
    <Modal
      isOpen={isOpen !== null}
      toggle={isUpload !== 100 && isUpload > 0 ? null : handleClose}
      className="fileUpload"
      centered
    >
      <div className="modal-header">
        <div className="modal_title">
          <h3>
            {isValid === true ? "App Builder Image" : "Lesson Builder Image"}
          </h3>
        </div>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
          disabled={isUpload !== 100 && isUpload > 0}
        >
          <img src={modalClose} alt="modal-close" />
        </button>
      </div>
      <form className="needs-validation px-3" onSubmit={handleSubmit}>
        <input type="hidden" value={row}></input>
        <DropZone
          accept="image/*"
          multiple={false}
          text="image"
          onUploaded={(value) => {
            handleChange(value);
          }}
          handleUploadProgress={handleUploadProgress}
        >
          {!!urlValue && (
            <img className="editImageSize ml-3" src={urlValue} alt="icon" />
          )}
        </DropZone>
        <div className="col-md-12 modal-btn py-4">
          <button
            type="button"
            className="btn secondary-btn mr-2"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
            disabled={isUpload !== 100 && isUpload > 0}
          >
            cancel
          </button>
          {isHandleModal === true ? (
            <button className="btn primary-btn volume-media" color="success">
              Uploading...
            </button>
          ) : (
            <button
              className="btn primary-btn volume-media"
              color="success"
              type="submit"
              disabled={urlValue === ""}
            >
              Upload
            </button>
          )}
        </div>
      </form>
    </Modal>
  );
};

const CustomTable = ({
  data,
  folderId,
  appFolderId,
  appFolderState,
  messageFolderName,
  isAfterOnDragTitle,
  isAfterOnDragAppTitle,
  showAddButton = true,
  addButtonTitle = "Create New Volume",
}) => {
  //UseSelector
  const { sizePerPageAB, pageNoAB, shareABLink, totalSizeAB } = useSelector(
    (state) => state.AppBuilderListing
  );
  const { totalSizeMB, pageNoMB, sizePerPageMB, sharedDashboardAccess } =
    useSelector((state) => state.MessageBuilderList);
  const {
    sizePerPageMBFolder,
    pageNoMBFolder,
    totalSizeMBFolder,
    StoreFolderTitle,
  } = useSelector((state) => state.MessageBuilderFolderList);
  const {
    StoreAppFolderTitle,
    sizePerPageABFolder,
    pageNoABFolder,
    totalSizeABFolder,
  } = useSelector((state) => state.AppBuilderFolderListing);
  const { isAuth, user } = useSelector((state) => state.auth);
  //UseStates
  const [sharable, setSharable] = useState(false);
  const [search, setSearch] = useState("");
  const [copied, setCopied] = useState(null);
  const [nameSort, setNameSort] = useState("DESC");
  const [duplicateLoading, setDuplicateLoading] = useState(false);
  const [buildAction, setBuildAction] = useState(false);
  const [isBuildActionId, setIsBuildActionId] = useState(0);
  const [buildVolumeAction, setBuildVolumeAction] = useState(0);
  const [createdDateSort, SetCreatedDateSort] = useState("DESC");
  const [UpdatedDateSort, SetUpdatedDateSort] = useState("DESC");
  const [selectedRows, setSelectedRows] = useState([]);
  const [alert, setAlert] = useState(false);
  const [isNameSorted, setIsNameSorted] = useState(false);
  const [isCreatedSorted, setIsCreatedSorted] = useState(false);
  const [isModifiedSorted, setIsModifiedSorted] = useState(false);
  const [lastBuildDropDown, setLastBuildDropDown] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [totalRows, setTotalRows] = useState(null);
  const [totalRowsCount, setTotalRowsCount] = useState(null);
  const [totalRowsByFolder, setTotalRowsByFolder] = useState(null);
  const [isFolderScroll, setIsFolderScroll] = useState(1);
  const [isBuildLoader, setIsBuildLoader] = useState(false);
  const [isBuildLength, setIsBuildLength] = useState(false);
  const [isHandleModal, setIsHandleModal] = useState(false);
  const [restoreButton, setRestoreButton] = useState(false);
  const [sortField, setSortField] = useState("created_datetime");

  const dispatch = useDispatch();
  let history = useHistory();
  const loadPlaceHolder = [1, 2];
  //FIND THE LOCATION APP OR MESSAGE
  const Location = useLocation();
  const isValid = useMemo(
    () => Location.pathname.startsWith("/app-builder"),
    [Location]
  );

  useEffect(() => {
    dispatch(
      getActiveSubscriptionsBySite({ site_id: 3 }, (status) => {
        if (isValid === true) {
          if (status.is_user_volume_count > 0 && appFolderId === 0) {
            setTotalRows(status.is_user_volume_count);
          }
        } else {
          if (status.is_user_message_count > 0 && folderId === 0) {
            setTotalRows(status.is_user_message_count);
          }
        }
      })
    );
    dispatch(handleMBResetBuildData());
    dispatch(handleResetVolumeData());
  }, []);

  // For save seleted folderData in state
  useEffect(() => {
    if (isValid === true) {
      if (totalSizeAB > 0 && appFolderId === 0) {
        setTotalRowsCount(totalSizeAB);
        dispatch(handleVolumeLength(totalSizeAB));
        setTotalRowsByFolder(null);
        setHasMore(true);
      } else {
        if (totalSizeABFolder > 0 && appFolderId !== 0) {
          setTotalRowsByFolder(totalSizeABFolder);
          setTotalRows(null);
          setHasMore(true);
        }
      }
    } else {
      if (totalSizeMB > 0 && folderId === 0) {
        dispatch(handleMBBuildLength(totalSizeMB));
        setTotalRowsCount(totalSizeMB);
        setTotalRowsByFolder(null);
        setHasMore(true);
      } else {
        if (totalSizeMBFolder > 0 && folderId !== 0) {
          setTotalRowsByFolder(totalSizeMBFolder);
          setTotalRows(null);
          setHasMore(true);
        }
      }
    }
  }, [
    totalSizeMB,
    totalSizeAB,
    totalSizeMBFolder,
    totalSizeABFolder,
    appFolderId,
    folderId,
  ]);

  useEffect(() => {
    if (isAuth) {
      dispatch(
        getActiveSubscriptionsBySite({ site_id: 3 }, (status) => {
          if (status.count > 0) {
            setRestoreButton(true);
          }
        })
      );
    }
  }, [dispatch]);

  //Condition here
  useEffect(() => {
    //Get Series ID From App Builder Teaching
    let id = localStorage.getItem("seriesId");
    if (id) {
      localStorage.removeItem("seriesId");
    }
    //App builder Data
    if (isValid == true) {
      if (appFolderId !== 0) {
        if (search === "") {
          setIsBuildLoader(false);
          dispatch(
            handleGetABFolderBuildById(
              {
                build_folder_id: appFolderId,
                page_no: pageNoABFolder,
                page_record: sizePerPageABFolder,
                sortField: sortField,
                sortOrder: isNameSorted
                  ? nameSort
                  : isCreatedSorted
                  ? createdDateSort
                  : isModifiedSorted
                  ? UpdatedDateSort
                  : "DESC",
              },
              (status) => {
                if (status) {
                  setIsBuildLoader(true);
                }
              }
            )
          );
        } else {
          if (search !== "") {
            dispatch(
              handleGetABFolderBuildById({
                build_folder_id: appFolderId,
                search: search,
                page_no: pageNoABFolder,
                page_record: sizePerPageABFolder,
                sortField: sortField,
                sortOrder: isNameSorted
                  ? nameSort
                  : isCreatedSorted
                  ? createdDateSort
                  : isModifiedSorted
                  ? UpdatedDateSort
                  : "DESC",
              })
            );
          }
        }
      } else {
        if (search === "" && isAuth) {
          setIsBuildLoader(false);
          dispatch(
            handleFetchABListing(
              {
                page_no: pageNoAB,
                page_record: sizePerPageAB,
                sortField: sortField,
                sortOrder: isNameSorted
                  ? nameSort
                  : isCreatedSorted
                  ? createdDateSort
                  : isModifiedSorted
                  ? UpdatedDateSort
                  : "DESC",
              },
              (status) => {
                if (status) {
                  setIsBuildLoader(true);
                }
              }
            )
          );
        } else {
          if (search !== "") {
            dispatch(
              handleFetchABListing({
                search: search,
                page_no: pageNoAB,
                page_record: sizePerPageAB,
                sortField: sortField,
                sortOrder: isNameSorted
                  ? nameSort
                  : isCreatedSorted
                  ? createdDateSort
                  : isModifiedSorted
                  ? UpdatedDateSort
                  : "DESC",
              })
            );
          }
        }
      }
    } else {
      // Message Builder Data
      if (folderId !== 0) {
        if (search === "") {
          setIsBuildLoader(false);
          dispatch(
            handleGetFolderBuildById(
              {
                build_folder_id: folderId,
                page_no: pageNoMBFolder,
                page_record: sizePerPageMBFolder,
                sortField: sortField,
                sortOrder: isNameSorted
                  ? nameSort
                  : isCreatedSorted
                  ? createdDateSort
                  : isModifiedSorted
                  ? UpdatedDateSort
                  : "DESC",
              },
              (status) => {
                if (status) {
                  setIsBuildLoader(true);
                }
              }
            )
          );
        } else {
          if (search !== "") {
            dispatch(
              handleGetFolderBuildById({
                build_folder_id: folderId,
                search: search,
                page_no: pageNoMBFolder,
                page_record: sizePerPageMBFolder,
                sortField: sortField,
                sortOrder: isNameSorted
                  ? nameSort
                  : isCreatedSorted
                  ? createdDateSort
                  : isModifiedSorted
                  ? UpdatedDateSort
                  : "DESC",
              })
            );
          }
        }
      } else {
        if (search === "" && isAuth) {
          setIsBuildLoader(false);
          dispatch(
            handleFetchMBListing(
              {
                page_no: pageNoMB,
                page_record: sizePerPageMB,
                sortField: sortField,
                sortOrder: isNameSorted
                  ? nameSort
                  : isCreatedSorted
                  ? createdDateSort
                  : isModifiedSorted
                  ? UpdatedDateSort
                  : "DESC",
              },
              (status) => {
                if (status) {
                  setIsBuildLoader(true);
                }
              }
            )
          );
        } else {
          if (search !== "") {
            dispatch(
              handleFetchMBListing({
                search: search,
                page_no: pageNoMB,
                page_record: sizePerPageMB,
                sortField: sortField,
                sortOrder: isNameSorted
                  ? nameSort
                  : isCreatedSorted
                  ? createdDateSort
                  : isModifiedSorted
                  ? UpdatedDateSort
                  : "DESC",
              })
            );
          }
        }
      }
    }
    return () => {
      // setIsNameSorted(false)
    };
  }, [
    dispatch,
    appFolderId,
    folderId,
    pageNoMB,
    pageNoAB,
    pageNoABFolder,
    pageNoMBFolder,
    search,
  ]);

  //ByDefault Set PageNo
  useEffect(() => {
    if (isValid === true) {
      //Store Library Folder ID
      dispatch(setMBLibraryFolderId(""));
      dispatch(setPageABCustomBuildList(isFolderScroll));
    } else {
      //Store Library Folder ID
      dispatch(setABLibraryFolderId(""));
      dispatch(setPageMBCustomBuildList(isFolderScroll));
    }
  }, []);

  // onFolder Change search value Empty
  useEffect(() => {
    setSearch("");
  }, [folderId, appFolderId]);

  //Infinire scroll for App Builder
  const fetchMoreDataAB = () => {
    //Update PageNo for App Builder
    if (isBuildLength === true && totalRowsCount - 1 === data.length) {
      setHasMore(false);
    }
    if (isBuildLength === true && totalRowsByFolder - 1 === data.length) {
      setHasMore(false);
    }
    if (appFolderId !== 0 && totalRowsByFolder > 0) {
      dispatch(setPageABFolderBuildList(pageNoABFolder + 1));
      if (search !== "") {
        setHasMore(false);
      }
    } else {
      dispatch(setPageABCustomBuildList(pageNoAB + 1));
    }
  };

  //Infinire scroll for Message Builder
  const fetchMoreDataMB = () => {
    //Update PageNo for Message Builder
    if (isBuildLength === true && totalRowsCount - 1 === data.length) {
      setHasMore(false);
    }
    if (isBuildLength === true && totalRowsByFolder - 1 === data.length) {
      setHasMore(false);
    }
    if (folderId !== 0 && totalRowsByFolder > 0) {
      dispatch(setPageMBFolderBuildList(pageNoMBFolder + 1));
      if (search !== "") {
        setHasMore(false);
      }
    } else {
      dispatch(setPageMBCustomBuildList(pageNoMB + 1));
    }
  };
  const handlePopupSubmit = (value) => {};

  //Create new volume
  const CreateNewVolume = async () => {
    if (!!user && user.user_role == 3) {
      if (restoreButton || sharedDashboardAccess) {
        if (isValid == true) {
          try {
            const res = await axiosBuilder.post(createABVolume, {
              build_folder_id: 0,
              is_system: 1,
            });
            if (res.data.data) {
              history.push("app-builder/edit/" + res.data.data.volume_id);
              dispatch(
                handleFetchABListing({
                  page_no: isFolderScroll,
                  page_record: sizePerPageAB,
                  sortField: sortField,
                  sortOrder: "DESC",
                })
              );
            }
          } catch (err) {}
        } else {
          try {
            const res = await axiosBuilder.post(createMBVolume, {
              build_folder_id: 0,
              is_default_build: 0,
              is_demo_build: 0,
              media_url: "",
              sortable_order: 0,
              build_type: 0,
              is_deleted: 0,
            });
            if (res.data.data) {
              history.push(
                "builder/edit/" + res.data.data.message_build_list_id
              );
              dispatch(
                handleFetchMBListing({
                  page_no: isFolderScroll,
                  page_record: sizePerPageMB,
                  sortField: sortField,
                  sortOrder: "DESC",
                })
              );
            }
          } catch (err) {}
        }
      }
      // else if (totalRows >= 1) {
      //   console.log("totalRows", totalRows);
      //   history.push("/subscription");
      // }
      else {
        if (isValid == true) {
          try {
            const res = await axiosBuilder.post(createABVolume, {
              build_folder_id: 0,
              is_system: 1,
            });
            if (res.data.data) {
              history.push("app-builder/edit/" + res.data.data.volume_id);
              dispatch(
                handleFetchABListing({
                  page_no: isFolderScroll,
                  page_record: sizePerPageAB,
                  sortField: sortField,
                  sortOrder: "DESC",
                })
              );
            }
          } catch (err) {}
        } else {
          if (totalRows >= 1) {
            history.push("/subscription");
          } else {
            try {
              const res = await axiosBuilder.post(createMBVolume, {
                build_folder_id: 0,
                is_default_build: 0,
                is_demo_build: 0,
                media_url: "",
                sortable_order: 0,
                build_type: 0,
                is_deleted: 0,
              });
              if (res.data.data) {
                history.push(
                  "builder/edit/" + res.data.data.message_build_list_id
                );
                dispatch(
                  handleFetchMBListing({
                    page_no: isFolderScroll,
                    page_record: sizePerPageMB,
                    sortField: sortField,
                    sortOrder: "DESC",
                  })
                );
              }
            } catch (err) {}
          }
        }
      }
    } else {
      if (isValid == true) {
        try {
          const res = await axiosBuilder.post(createABVolume, {
            build_folder_id: 0,
            is_system: 1,
          });
          if (res.data.data) {
            history.push("app-builder/edit/" + res.data.data.volume_id);
            dispatch(
              handleFetchABListing({
                page_no: isFolderScroll,
                page_record: sizePerPageAB,
                sortField: sortField,
                sortOrder: "DESC",
              })
            );
          }
        } catch (err) {}
      } else {
        try {
          const res = await axiosBuilder.post(createMBVolume, {
            build_folder_id: 0,
            is_default_build: 0,
            is_demo_build: 0,
            media_url: "",
            sortable_order: 0,
            build_type: 0,
            is_deleted: 0,
          });
          if (res.data.data) {
            history.push("builder/edit/" + res.data.data.message_build_list_id);
            dispatch(
              handleFetchMBListing({
                page_no: isFolderScroll,
                page_record: sizePerPageMB,
                sortField: sortField,
                sortOrder: "DESC",
              })
            );
          }
        } catch (err) {}
      }
    }
  };

  //Create A Duplicate Build
  const handleListDuplicate = (build) => {
    if (!!user && user.user_role === 3) {
      if (restoreButton || sharedDashboardAccess) {
        setDuplicateLoading(true);
        if (isValid == true) {
          setBuildVolumeAction(!buildVolumeAction);
          //AB Duplicate
          dispatch(
            handleABDuplicate({ volume_id: build.volume_id }, (status) => {
              if (status) {
                if (appFolderId !== 0) {
                  //Get AB using Folder id
                  dispatch(
                    handleGetABFolderBuildById({
                      build_folder_id: appFolderId,
                      page_no: 1,
                      page_record: sizePerPageAB,
                    })
                  );
                  setDuplicateLoading(false);
                } else {
                  //AB Listing
                  dispatch(
                    handleFetchABListing({
                      page_no: 1,
                      page_record: sizePerPageAB,
                      sortOrder: "",
                    })
                  );
                  setDuplicateLoading(false);
                }
              }
            })
          );
        } else {
          setBuildAction(!buildAction);
          setDuplicateLoading(true);
          //MB Duplicate
          dispatch(
            handleMBDuplicate(
              {
                message_build_list_id: build.message_build_list_id,
              },
              (status) => {
                if (status) {
                  if (folderId !== 0) {
                    //Get build by folder id
                    dispatch(
                      handleGetFolderBuildById({
                        build_folder_id: folderId,
                        page_no: 1,
                        page_record: sizePerPageMB,
                      })
                    );
                    setDuplicateLoading(false);
                  } else {
                    //MB Listing
                    dispatch(
                      handleFetchMBListing({
                        page_no: 1,
                        page_record: sizePerPageMB,
                        sortOrder: "",
                      })
                    );
                    setDuplicateLoading(false);
                  }
                }
              }
            ),
            [dispatch]
          );
        }
      } else if (totalRows >= 1) {
        history.push("/subscription");
      } else {
        setDuplicateLoading(true);
        if (isValid == true) {
          setBuildVolumeAction(!buildVolumeAction);
          //AB Duplicate
          dispatch(
            handleABDuplicate({ volume_id: build.volume_id }, (status) => {
              if (status) {
                if (appFolderId !== 0) {
                  //Get AB using Folder id
                  dispatch(
                    handleGetABFolderBuildById({
                      build_folder_id: appFolderId,
                      page_no: 1,
                      page_record: sizePerPageAB,
                    })
                  );
                  setDuplicateLoading(false);
                } else {
                  //AB Listing
                  dispatch(
                    handleFetchABListing({
                      page_no: 1,
                      page_record: sizePerPageAB,
                      sortOrder: "",
                    })
                  );
                  setDuplicateLoading(false);
                }
              }
            })
          );
        } else {
          setBuildAction(!buildAction);
          setDuplicateLoading(true);
          //MB Duplicate
          dispatch(
            handleMBDuplicate(
              {
                message_build_list_id: build.message_build_list_id,
              },
              (status) => {
                if (status) {
                  if (folderId !== 0) {
                    //Get build by folder id
                    dispatch(
                      handleGetFolderBuildById({
                        build_folder_id: folderId,
                        page_no: 1,
                        page_record: sizePerPageMB,
                      })
                    );
                    setDuplicateLoading(false);
                  } else {
                    //MB Listing
                    dispatch(
                      handleFetchMBListing({
                        page_no: 1,
                        page_record: sizePerPageMB,
                        sortOrder: "",
                      })
                    );
                    setDuplicateLoading(false);
                  }
                }
              }
            ),
            [dispatch]
          );
        }
      }
    } else {
      setDuplicateLoading(true);
      if (isValid == true) {
        setBuildVolumeAction(!buildVolumeAction);
        //AB Duplicate
        dispatch(
          handleABDuplicate({ volume_id: build.volume_id }, (status) => {
            if (status) {
              if (appFolderId !== 0) {
                //Get AB using Folder id
                dispatch(
                  handleGetABFolderBuildById({
                    build_folder_id: appFolderId,
                    page_no: 1,
                    page_record: sizePerPageAB,
                  })
                );
                setDuplicateLoading(false);
              } else {
                //AB Listing
                dispatch(
                  handleFetchABListing({
                    page_no: 1,
                    page_record: sizePerPageAB,
                    sortOrder: "",
                  })
                );
                setDuplicateLoading(false);
              }
            }
          })
        );
      } else {
        setBuildAction(!buildAction);
        setDuplicateLoading(true);
        //MB Duplicate
        dispatch(
          handleMBDuplicate(
            {
              message_build_list_id: build.message_build_list_id,
            },
            (status) => {
              if (status) {
                if (folderId !== 0) {
                  //Get build by folder id
                  dispatch(
                    handleGetFolderBuildById({
                      build_folder_id: folderId,
                      page_no: 1,
                      page_record: sizePerPageMB,
                    })
                  );
                  setDuplicateLoading(false);
                } else {
                  //MB Listing
                  dispatch(
                    handleFetchMBListing({
                      page_no: 1,
                      page_record: sizePerPageMB,
                      sortOrder: "",
                    })
                  );
                  setDuplicateLoading(false);
                }
              }
            }
          ),
          [dispatch]
        );
      }
    }
  };

  //handle Delete Modal for Build List
  const handleListRemove = (build) => {
    if (isValid == true) {
      setBuildVolumeAction(!buildVolumeAction);
    } else {
      setBuildAction(!buildAction);
    }
    setAlert(true);
    setSelectedRows([build]);
  };

  //Handle Delete Modal
  const handleBuildDeletePopupClose = () => {
    setAlert(false);
    setSelectedRows([]);
    setIsHandleModal(false);
  };

  //Delete App and Message builder list
  const handleBuildDeleteByBuildId = () => {
    if (isValid == true) {
      let elementId = [...selectedRows][0];
      //App Volume
      if (appFolderId !== 0) {
        /** To update redux when App Volume is deleted */
        dispatch(setABListingByFolderId({ elementId }));
        // setTotalRowsByFolder(totalSizeABFolder - 1)
      } else {
        /** To update redux when App Volume is deleted from User Builds*/
        dispatch(setABVolume({ elementId }));
      }
      //AB Delete
      setIsBuildLength(false);
      setIsHandleModal(true);
      dispatch(
        handleABDelete(
          { volume_id: [...selectedRows][0].volume_id },
          (status) => {
            if (status) {
              setIsHandleModal(false);
              setAlert(false);
              setSelectedRows([]);
              dispatch(
                handleFetchABListing({
                  page_no: isFolderScroll,
                  page_record: sizePerPageAB,
                  sortField: sortField,
                  sortOrder: "DESC",
                })
              );
              if (appFolderId == 0) {
                setIsBuildLength(true);
                setTotalRows(totalRowsCount - 1);
                dispatch(handleVolumeLength(totalRowsCount - 1));
              } else {
                setIsBuildLength(true);
                setTotalRowsByFolder(totalRowsByFolder - 1);
              }
            }
          }
        )
      );
    } else {
      let elementId = [...selectedRows][0];
      if (folderId !== 0) {
        /** To update redux when Message Build is deleted */
        dispatch(setMBListingByFolderId({ elementId }));
      } else {
        /** To update redux when Message Build is deleted from User Builds*/
        dispatch(setMBListing({ elementId }));
      }
      //MB Delete
      setIsBuildLength(false);
      setIsHandleModal(true);
      dispatch(
        handleMBDelete(
          {
            message_build_list_id: [...selectedRows][0].message_build_list_id,
          },
          (status) => {
            if (status) {
              setIsHandleModal(false);
              setAlert(false);
              setSelectedRows([]);
              dispatch(
                handleFetchMBListing({
                  page_no: isFolderScroll,
                  page_record: sizePerPageMB,
                  sortField: sortField,
                  sortOrder: "DESC",
                })
              );
              if (folderId == 0) {
                setIsBuildLength(true);
                setTotalRows(totalRowsCount - 1);
                dispatch(handleMBBuildLength(totalSizeMB - 1));
              } else {
                setIsBuildLength(true);
                setTotalRowsByFolder(totalRowsByFolder - 1);
              }
            }
          }
        )
      );
    }
  };

  //Search By Build Name
  const handleSearch = (e) => {
    if (isValid == true) {
      if (appFolderId != 0) {
        dispatch(setPageABFolderBuildList(1));
        setHasMore(true);
      } else {
        dispatch(setPageABCustomBuildList(1));
        setHasMore(true);
      }
    } else {
      if (folderId != 0) {
        dispatch(setPageMBFolderBuildList(1));
        setHasMore(true);
      } else {
        dispatch(setPageMBCustomBuildList(1));
        setHasMore(true);
      }
    }
    const checkSpace = e.target.value.indexOf(" ");
    if (checkSpace === 0) {
      return false;
    } else {
      setSearch(e.target.value);
    }
  };

  //For Sorting APP BUILDER & MESSAGE BUILDER Listing By Name Sort
  const handleNameSort = (build) => {
    if (isValid == true) {
      if (appFolderId != 0) {
        dispatch(setPageABFolderBuildList(1));
      } else {
        dispatch(setPageABCustomBuildList(1));
      }
    } else {
      if (folderId != 0) {
        dispatch(setPageMBFolderBuildList(1));
      } else {
        dispatch(setPageMBCustomBuildList(1));
      }
    }

    if (isValid == true) {
      //For Sorting APP Listing
      if (appFolderId !== 0) {
        //For Sorting APP Listing By Folder ID
        if (nameSort === "ASC") {
          setIsNameSorted(false);
          //For Sorting APP Listing in Descending Order
          dispatch(
            handleGetABFolderBuildById(
              {
                build_folder_id: appFolderId,
                page_no: pageNoABFolder,
                page_record: sizePerPageABFolder,
                sortField: build,
                sortOrder: "DESC",
              },
              (status) => {
                if (status) {
                  setSortField(build);
                  setIsNameSorted(true);
                }
              }
            )
          );
          setNameSort("DESC");
        }
        if (nameSort === "DESC") {
          setIsNameSorted(false);
          //For Sorting APP Listing in Ascending Order
          dispatch(
            handleGetABFolderBuildById(
              {
                page_no: pageNoABFolder,
                page_record: sizePerPageABFolder,
                build_folder_id: appFolderId,
                sortField: build,
                sortOrder: "ASC",
              },
              (status) => {
                if (status) {
                  setSortField(build);
                  setIsNameSorted(true);
                }
              }
            )
          );
          setNameSort("ASC");
        }
      } else {
        //For Sorting APP Listing For User Builds
        if (nameSort === "ASC") {
          //For Sorting APP Listing in Descending Order
          dispatch(
            handleFetchABListing(
              {
                page_no: pageNoAB,
                page_record: sizePerPageAB,
                sortField: build,
                sortOrder: "DESC",
              },
              (status) => {
                if (status) {
                  setSortField(build);
                  setIsNameSorted(true);
                }
              }
            )
          );
          setNameSort("DESC");
        }
        if (nameSort === "DESC") {
          setIsNameSorted(false);
          //For Sorting APP Listing in Ascending Order
          dispatch(
            handleFetchABListing(
              {
                page_no: pageNoAB,
                page_record: sizePerPageAB,
                sortField: build,
                sortOrder: "ASC",
              },
              (status) => {
                if (status) {
                  setSortField(build);
                  setIsNameSorted(true);
                }
              }
            )
          );
          setNameSort("ASC");
        }
      }
    } else {
      //For Sorting Message Listing
      if (folderId !== 0) {
        //For Sorting Message Listing By Folder ID
        if (nameSort === "ASC") {
          setIsNameSorted(false);
          //For Sorting Message Listing in Descending Order
          dispatch(
            handleGetFolderBuildById(
              {
                build_folder_id: folderId,
                page_no: pageNoMBFolder,
                page_record: sizePerPageMBFolder,
                sortField: "build_title",
                sortOrder: "DESC",
              },
              (status) => {
                if (status) {
                  setSortField("build_title");
                  setIsNameSorted(true);
                }
              }
            )
          );
          setNameSort("DESC");
        }
        if (nameSort === "DESC") {
          setIsNameSorted(false);
          //For Sorting Message Listing in Ascending Order
          dispatch(
            handleGetFolderBuildById(
              {
                build_folder_id: folderId,
                page_no: pageNoMBFolder,
                page_record: sizePerPageMBFolder,
                sortField: "build_title",
                sortOrder: "ASC",
              },
              (status) => {
                if (status) {
                  setSortField("build_title");
                  setIsNameSorted(true);
                }
              }
            )
          );
          setNameSort("ASC");
        }
      } else {
        //For Sorting Message Listing For User Builds
        if (nameSort === "ASC") {
          setIsNameSorted(false);
          //For Sorting Message Listing in Descending Order
          dispatch(
            handleFetchMBListing(
              {
                page_no: pageNoMB,
                page_record: sizePerPageMB,
                sortField: "build_title",
                sortOrder: "DESC",
              },
              (status) => {
                if (status) {
                  setSortField("build_title");
                  setIsNameSorted(true);
                }
              }
            )
          );
          setNameSort("DESC");
        }
        if (nameSort === "DESC") {
          setIsNameSorted(false);
          //For Sorting Message Listing in Ascending Order
          dispatch(
            handleFetchMBListing(
              {
                page_no: pageNoMB,
                page_record: sizePerPageMB,
                sortField: "build_title",
                sortOrder: "ASC",
              },
              (status) => {
                if (status) {
                  setSortField("build_title");
                  setIsNameSorted(true);
                }
              }
            )
          );
          setNameSort("ASC");
        }
      }
    }
  };

  //For Sorting APP BUILDER & MESSAGE BUILDER Listing By Modified_Date
  const handleModifiedDateSort = (build) => {
    if (isValid == true) {
      if (appFolderId != 0) {
        dispatch(setPageABFolderBuildList(1));
      } else {
        dispatch(setPageABCustomBuildList(1));
      }
    } else {
      if (folderId != 0) {
        dispatch(setPageMBFolderBuildList(1));
      } else {
        dispatch(setPageMBCustomBuildList(1));
      }
    }
    if (isValid == true) {
      if (appFolderId !== 0) {
        //For Sorting APP Listing By Folder ID
        if (UpdatedDateSort === "ASC") {
          setIsModifiedSorted(false);
          //For Sorting APP Listing By Modified_Date in Descending Order
          dispatch(
            handleGetABFolderBuildById(
              {
                page_no: pageNoABFolder,
                page_record: sizePerPageABFolder,
                build_folder_id: appFolderId,
                sortField: build,
                sortOrder: "DESC",
              },
              (status) => {
                if (status) {
                  setSortField(build);
                  setIsModifiedSorted(true);
                }
              }
            )
          );
          SetUpdatedDateSort("DESC");
        }
        if (UpdatedDateSort === "DESC") {
          setIsModifiedSorted(false);
          //For Sorting APP Listing By Modified_Date in Ascending Order
          dispatch(
            handleGetABFolderBuildById(
              {
                page_no: pageNoABFolder,
                page_record: sizePerPageABFolder,
                build_folder_id: appFolderId,
                sortField: build,
                sortOrder: "ASC",
              },
              (status) => {
                if (status) {
                  setSortField(build);
                  setIsModifiedSorted(true);
                }
              }
            )
          );
          SetUpdatedDateSort("ASC");
        }
      } else {
        //For Sorting APP Listing For User Builds
        if (UpdatedDateSort === "ASC") {
          setIsModifiedSorted(false);
          //For Sorting APP Listing By Modified_Date in Descending Order
          dispatch(
            handleFetchABListing(
              {
                page_no: pageNoAB,
                page_record: sizePerPageAB,
                sortField: build,
                sortOrder: "DESC",
              },
              (status) => {
                if (status) {
                  setSortField(build);
                  setIsModifiedSorted(true);
                }
              }
            )
          );
          SetUpdatedDateSort("DESC");
        }
        if (UpdatedDateSort === "DESC") {
          setIsModifiedSorted(false);
          //For Sorting APP Listing By Modified_Date in Ascending Order
          dispatch(
            handleFetchABListing(
              {
                page_no: pageNoAB,
                page_record: sizePerPageAB,
                sortField: build,
                sortOrder: "ASC",
              },
              (status) => {
                if (status) {
                  setSortField(build);
                  setIsModifiedSorted(true);
                }
              }
            )
          );
          SetUpdatedDateSort("ASC");
        }
      }
    } else {
      //For Sorting Message Listing
      if (folderId !== 0) {
        //For Sorting Message Listing By Folder ID
        if (UpdatedDateSort === "ASC") {
          setIsModifiedSorted(false);
          //For Sorting Message Listing in Descending Order
          dispatch(
            handleGetFolderBuildById(
              {
                page_no: pageNoMBFolder,
                page_record: sizePerPageMBFolder,
                build_folder_id: folderId,
                sortField: build,
                sortOrder: "DESC",
              },
              (status) => {
                if (status) {
                  setSortField(build);
                  setIsModifiedSorted(true);
                }
              }
            )
          );
          SetUpdatedDateSort("DESC");
        }
        if (UpdatedDateSort === "DESC") {
          setIsModifiedSorted(false);
          //For Sorting Message Listing in Ascending Order
          dispatch(
            handleGetFolderBuildById(
              {
                page_no: pageNoMBFolder,
                page_record: sizePerPageMBFolder,
                build_folder_id: folderId,
                sortField: build,
                sortOrder: "ASC",
              },
              (status) => {
                if (status) {
                  setSortField(build);
                  setIsModifiedSorted(true);
                }
              }
            )
          );
          SetUpdatedDateSort("ASC");
        }
      } else {
        //For Sorting Message Listing For User Builds
        if (UpdatedDateSort === "ASC") {
          setIsModifiedSorted(false);
          //For Sorting Message Listing in Descending Order
          dispatch(
            handleFetchMBListing(
              {
                page_no: pageNoMB,
                page_record: sizePerPageMB,
                sortField: "updated_datetime",
                sortOrder: "DESC",
              },
              (status) => {
                if (status) {
                  setSortField(build);
                  setIsModifiedSorted(true);
                }
              }
            )
          );
          SetUpdatedDateSort("DESC");
        }
        if (UpdatedDateSort === "DESC") {
          setIsModifiedSorted(false);
          //For Sorting Message Listing in Ascending Order
          dispatch(
            handleFetchMBListing(
              {
                page_no: pageNoMB,
                page_record: sizePerPageMB,
                sortField: "updated_datetime",
                sortOrder: "ASC",
              },
              (status) => {
                if (status) {
                  setSortField(build);
                  setIsModifiedSorted(true);
                }
              }
            )
          );
          SetUpdatedDateSort("ASC");
        }
      }
    }
  };

  //APP AND MESSAGE BUILD SHARE LINK COPY TO CLIPBOARD
  const handleSubmitShareLink = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(e.target[0].value);
    setSharable(false);
  };
  //ShareLink App builder list
  const handleShareLinkMB = (shareLinkAB) => {
    if (isValid === true) {
      setBuildVolumeAction(!buildVolumeAction);
    } else {
      setBuildAction(!buildAction);
    }
    setSharable(true);
    //AB Share link
    dispatch(
      handleABShareLink({
        build_share_code_id: shareLinkAB.code_id,
      })
    );
  };

  //Handle Share Link Modal
  const handleCloseShareLinkAB = () => {
    setSharable(false);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: isDragging ? "10px 0px 15px 5px" : "0px 10px",
    background: isDragging ? "rgba(176, 206, 71, .7)" : "",
    color: isDragging ? "#2D3335" : "",
    border: isDragging ? "2px solid #b3b3b3" : "",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
    // lineHeight: "25px",
    ...draggableStyle,
  });

  //for PAgination data
  // let DefaultImage =
  //   "https://wp.builder.stuffyoucanuse.dev/wp-content/themes/the-grow-builder/assets/images/placeholder_msg.png";

  //Open Action By Folder ID
  const handleFolderAction = (buildId, index) => {
    //handle Last Build DropDown
    let allBuildLength = data.length;
    if (
      allBuildLength - 1 == index ||
      allBuildLength - 2 == index ||
      allBuildLength - 3 == index ||
      allBuildLength - 4 == index ||
      allBuildLength - 5 == index
    ) {
      setLastBuildDropDown(true);
    } else {
      setLastBuildDropDown(false);
    }
    if (isValid === true) {
      setIsBuildActionId(buildId.volume_id);
      setBuildVolumeAction(!buildVolumeAction);
    } else {
      setIsBuildActionId(buildId.message_build_list_id);
      setBuildAction(!buildAction);
    }
  };

  const closeDropDown = (e) => {
    let click = e.target.closest("#DropDownBuildClose");
    if (!click) {
      if (isValid === true) {
        setBuildVolumeAction(false);
        setLastBuildDropDown(false);
      } else {
        setBuildAction(false);
        setLastBuildDropDown(false);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("click", closeDropDown);
    return () => window.removeEventListener("click", closeDropDown);
  }, [closeDropDown]);

  const [clicked, setClicked] = useState([]);
  const [securityClicked, setSecurityClicked] = useState([]);
  const [openSecurity, setOpenSecurity] = useState(false);
  const currDate = moment().format("MM/DD/YYYY");

  const handleClick = (user_subscription_id) => {
    setClicked((prev) => [...prev, user_subscription_id]);
  };

  const data2 = get("securityPaymentCloseArr") || {};
  const handleSecurityClick = (user_subscription_id) => {
    setSecurityClicked((prev) => [...prev, user_subscription_id]);
  };

  const handleSecuritySetup = () => {
    setOpenSecurity(true);
  };

  return (
    <div className="container-area">
      {!!user &&
        user?.sycu_user_security_question_answers?.length === 0 &&
        isValid != true && (
          <>
            {!securityClicked.includes(1 + currDate) &&
              !data2[1 + currDate] > 0 && (
                // <ReminderPopupModel
                //   handleSecurityClick={handleSecurityClick}
                //   handleSecuritySetup={handleSecuritySetup}
                //   currDate={currDate}
                // />
                <></>
              )}
          </>
        )}
      <Modal
        isOpen={sharable}
        className="fileUpload sharableLink tableListModal"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Public Link</h5>
          <button
            type="button"
            className="close"
            onClick={() => handleCloseShareLinkAB()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form className="needs-validation p-4" onSubmit={handleSubmitShareLink}>
          <div className="row d-flex justify-content-start clipboard-row">
            <div className="col-11">
              <input
                className="inputBox form-control"
                type="text"
                name="shareLinkUrl"
                value={shareABLink.replace(".dev", ".org")}
                disabled
              ></input>
            </div>
            <div className="col-1">
              <div className="d-flex justify-content-start shareCopy">
                <Button
                  color="primary"
                  type="submit"
                  className="shareCopyButton"
                >
                  <i
                    title="Copied"
                    className="message-builder-table-action-icon fa fa-clone mr0"
                    aria-hidden="true"
                  ></i>
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Modal>

      {/* <!-- modal delete  --> */}
      <Modal
        isOpen={alert}
        className="modal-dialog modal-dialog-centered"
        role="document"
        centered
      >
        <div className="modal-content">
          <div className="modal-body modal_popup">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => handleBuildDeletePopupClose()}
            >
              <img src={modalClose} alt="modal-close" />
            </button>
            <div className="modal_data">
              <div className="row">
                <div className="col-md-12 text-center mt-5">
                  <img src={deleteAlert} alt="red-info" />
                </div>
                <div className="col-md-12 text-center mb20 mt15">
                  <h1 className="fw-600 dark-text ">
                    Are you sure you want to delete?
                  </h1>
                </div>
                <div className="col-md-12 modal-btn">
                  <button
                    className="btn secondary-btn mr-2"
                    data-dismiss="modal"
                    onClick={() => handleBuildDeletePopupClose()}
                    disabled={isHandleModal === true}
                  >
                    {" "}
                    cancel
                  </button>
                  {isHandleModal === true ? (
                    <button className="btn delete-border-btn ml-3">
                      Deleting...
                    </button>
                  ) : (
                    <button
                      className="btn delete-border-btn ml-3"
                      onClick={() => handleBuildDeleteByBuildId()}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="wrapper-heading-area filter-area">
        <h1 className="folderTitleToBuildArea">
          {isValid === true
            ? appFolderState
              ? isAfterOnDragAppTitle
              : StoreAppFolderTitle
            : messageFolderName
            ? isAfterOnDragTitle
            : StoreFolderTitle}
        </h1>
        <div className="page-filter">
          <div className="form-group mb0">
            <img src={searchIcon} className="search-icon" alt="search" />
            <input
              type="text"
              className="form-control search-input"
              placeholder="Search By Build Name"
              value={search}
              onChange={handleSearch}
            />
          </div>
          <Button
            onClick={() => CreateNewVolume()}
            className="btn primary-btn new-build"
          >
            {(addButtonTitle = isValid ? "Create New Volume" : "Create New")}
          </Button>
        </div>
      </div>
      <div className="main-table  mb20">
        <div className="table-responsive">
          <InfiniteScroll
            dataLength={data.length}
            next={
              (totalRowsByFolder > 0 && totalRowsCount > 0 && appFolderId) ||
              isValid === true
                ? fetchMoreDataAB
                : fetchMoreDataMB
            }
            hasMore={
              totalRowsCount === data.length ||
              totalRowsByFolder === data.length ||
              hasMore === false
                ? false
                : true
            }
            loader={
              data.length >= 10 ? (
                <h4 className="loading">
                  <i className="fa fa-spinner fa-spin datatable-spinner"></i>
                  Data Loading Please Wait...
                </h4>
              ) : null
            }
          >
            <table className="table table-borderless message-builder-tablev align-middle">
              <thead>
                <tr>
                  <th
                    scope="col"
                    onClick={() => handleNameSort("volume_title")}
                    className="firstchild"
                  >
                    <span className="buildSort setArrow">Name</span>
                    <i
                      className={
                        nameSort == "ASC" && isNameSorted == true
                          ? "fa-solid fa-sort-up actionSort"
                          : "fa-solid fa-sort-up"
                      }
                    ></i>
                    <i
                      className={
                        nameSort == "DESC" && isNameSorted == true
                          ? "fa-solid fa-sort-down actionSort"
                          : "fa-solid fa-sort-down"
                      }
                    ></i>
                  </th>
                  {isValid === true ? <th scope="col">Code</th> : ""}
                  <th
                    scope="col"
                    onClick={() => handleModifiedDateSort("updated_datetime")}
                  >
                    <span className="buildSort setArrow">Modified</span>
                    <i
                      className={
                        UpdatedDateSort == "ASC" && isModifiedSorted == true
                          ? "fa-solid fa-sort-up actionSort"
                          : "fa-solid fa-sort-up"
                      }
                    ></i>
                    <i
                      className={
                        UpdatedDateSort == "DESC" && isModifiedSorted == true
                          ? "fa-solid fa-sort-down actionSort"
                          : "fa-solid fa-sort-down"
                      }
                    ></i>
                  </th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody className="message-builder-library">
                {data.length > 0 ? (
                  data.map((build, index) => (
                    <Draggable
                      key={index}
                      index={
                        isValid == true
                          ? build.volume_id
                          : build.message_build_list_id
                      }
                      draggableId={
                        "" +
                        (isValid == true
                          ? build.volume_id
                          : build.message_build_list_id)
                      }
                    >
                      {(provided, snapshot) => (
                        <tr className="draggable folder-build-row">
                          <td className="customImage fa-alt-arrow  ui-draggable-handle">
                            <div
                              className="handleBuild"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <img
                                src={
                                  !!build.media_url
                                    ? build.media_url.replace(
                                        imageUrl.S3BUILDER_URL,
                                        imageUrl.BUILDER_DISPLAY_URL
                                      )
                                    : DefaultImage
                                }
                                className={`app-library-p-img buildImage ${
                                  snapshot.isDragging ? "imageDrag" : ""
                                }`}
                                alt="buildImage"
                                draggable="false"
                              />
                              <Link
                                className="message-builder-table-title"
                                to={
                                  (isValid == true
                                    ? "app-builder/edit/"
                                    : "builder/edit/") +
                                  (isValid == true
                                    ? build.volume_id
                                    : build.message_build_list_id)
                                }
                              >
                                <span
                                  key={index}
                                  className="builder_title_listing mb-0"
                                >
                                  {isValid == true
                                    ? build.volume_title
                                    : build.build_title}{" "}
                                  {isValid == true ? "" : ":"}{" "}
                                  {build.build_sub_title === ""
                                    ? "SUBTITLE"
                                    : build.build_sub_title}
                                </span>
                              </Link>
                            </div>
                          </td>
                          {isValid === true ? <td>{build.code}</td> : ""}
                          <td className="text-uppercase">
                            {moment(build.updated_datetime).format(
                              "MM/DD/YYYY h:mm:ss a"
                            )}
                          </td>
                          <td>
                            <div className="dropdown">
                              <a
                                className=" "
                                role="button"
                                id="dropdownMenuLink"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <center>
                                  <img
                                    src={buildActionIcon}
                                    className="m-0"
                                    onClick={() =>
                                      handleFolderAction(build, index)
                                    }
                                    id="DropDownBuildClose"
                                  />
                                </center>
                              </a>
                              <div
                                className={
                                  (isBuildActionId ===
                                    build.message_build_list_id &&
                                    buildAction) ||
                                  (buildVolumeAction &&
                                    isBuildActionId === build.volume_id)
                                    ? `dropdown-menu show ${
                                        lastBuildDropDown
                                          ? `${
                                              isValid == true
                                                ? "appbuildDropDown"
                                                : "buildDropDown"
                                            }`
                                          : "tableDropdown"
                                      }`
                                    : "dropdown-menu"
                                }
                                aria-labelledby="dropdownMenuLink"
                              >
                                {isValid != true ? (
                                  <a>
                                    <span
                                      className="dropdown-item"
                                      onClick={() => handleShareLinkMB(build)}
                                    >
                                      Share
                                    </span>
                                  </a>
                                ) : null}
                                {isValid != true ? (
                                  <Link
                                    to={
                                      isValid == true
                                        ? "app-builder/view/" + build.volume_id
                                        : "builder/view/" +
                                          build.message_build_list_id
                                    }
                                  >
                                    <span className="dropdown-item">
                                      Teaching View
                                    </span>
                                  </Link>
                                ) : null}
                                {duplicateLoading ? (
                                  <a>
                                    <span className="dropdown-item">
                                      {" "}
                                      Duplicate{" "}
                                    </span>
                                  </a>
                                ) : (
                                  <a>
                                    <span
                                      className="dropdown-item"
                                      onClick={() => handleListDuplicate(build)}
                                    >
                                      Duplicate
                                    </span>
                                  </a>
                                )}
                                <a>
                                  <span
                                    className="dropdown-item"
                                    onClick={() => handleListRemove(build)}
                                  >
                                    Delete
                                  </span>
                                </a>
                                <a
                                  data-toggle="modal"
                                  data-target="#EditImage"
                                  accept="image/*"
                                  multiple={false}
                                  onClick={() => {
                                    setCopied(build);
                                    if (isValid === true) {
                                      setBuildVolumeAction(!buildVolumeAction);
                                    } else {
                                      setBuildAction(!buildAction);
                                    }
                                  }}
                                >
                                  <span className="dropdown-item">
                                    Edit Image
                                  </span>
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))
                ) : data.length <= 0 && isBuildLoader === false ? (
                  <tr>
                    <td colSpan={10}>
                      {loadPlaceHolder.map((i) => (
                        <MBLoading key={i} />
                      ))}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan={10}>
                      <h6 className="no-data-found">No Data Found</h6>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      </div>
      <UploadMedia
        isOpen={copied}
        onClose={() => {
          setCopied(null);
        }}
        row={copied}
        onSave={handlePopupSubmit}
        folderId={folderId}
        appFolderId={appFolderId}
      />
    </div>
  );
};
export default CustomTable;
