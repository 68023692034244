// Auth
export const ACCESS_TOKEN_URL = "/ssoAuth";
export const GET_PROFILE_INFO = "/getUserProfileDetail";

//MB Listing
export const MBListing = "/listMessageBuild";

//MB Duplicate
export const MBCreateDuplicate = "/createDuplicateMessageBuild";

//MB Delete
export const deleteMB = "/deleteMessageBuild";

//MB share link
export const shareLinkMB = "/getShareLink";

//MB Build image upload
export const uploadMediaMB = "/updateImageBuild";

//MB folder listing
export const folderListingMB = "/listBuildFolder";

//MB folder add/edit
export const addUpdateFolderMB = "/addUpdateFolder";

//MB folder add/edit
export const deleteFolderMB = "/deleteBuildFolder";

//Get build by folder id
export const getFolderBuildById = "/getFolderMessage";

//Get library floder by key
export const getLibraryFolderByKey = "/getSystemMessageBuildVolumes";

//Get library build
export const getLibraryBuildListing = "/getSystemMessageBuildsSeriesWeekWise";

//get library volume build
export const getAppLibraryBuildListing = "/getSystemAppBuildVolumes";

//Get MB building blocks
export const getBuildingBlocks = "/listBuildElements";

//Get MB Elements
export const getMBElements = "/listMessageBuildElementsDetails";

//Get MB Elements Duplicate
export const duplicateElement = "/createDuplicateBuildElement";

//Get MB Elements Delete
export const updateBuilderElements = "/updateBuildelementsDetails";

//Get Message Builder Teaching Elements
export const getMBTeachingElements = "/listMessageBuildElementsDetails";

//Get Message Builder Teaching Elements using code
export const getMBTeachingUsingCodeElements = "/getShareCodeBuild";

//Get Message Builder Export Elements
export const getMBExportElements = "/exportPdf";

//Get Message Builder Custom Restore Point
export const createCustomMBRestorePoint = "/createNewRestorePoint";

//Get App Builder Custom Restore Point
export const createCustomABRestorePoint = "/createNewRestoreVolume";

//Delete Message Builder Manual Custom Restore Point
export const DeleteCustomMBRestorePoint = "/deleteMessageBuild";

//AB Listing
export const AppBuilderListing = "/listVolume";

//Create MB Volume
export const createMBVolume = "/createMessageBuild";

//Create AB Volume
export const createABVolume = "/createVolume";

//View App builder Volume
export const ViewAppBuilderVolume = "/listMessageBuildElementsDetails";

//View App builder Volume
export const ViewMessageBuilderVolume = "/getSeriesElementDetailsBySeriesId";

//AB Delete
export const deleteAB = "/deleteVolume";

//AB Duplicate
export const ABCreateDuplicate = "/createDuplicateVolume";

//ABLibrary Duplicate
export const createDuplicateAdminVolume = "/createDuplicateAdminVolume";

// //AB share link
export const shareLinkAB = "/getShareLink";

//AB folder listing
export const AppBuilderFolderListing = "/listVolumeFolder";

//AB folder add/edit
export const addUpdateFolderAB = "/addUpdateFolder";

//AB folder add/edit
export const deleteFolderAB = "/deleteBuildFolder";

//Get MB save list
export const getABRestoreList = "/restoreVolume";

//Get App Builder Export Elements
export const getABExportElements = "/exportPdf";

//Get build by folder id
export const getABFolderBuildById = "/getFolderVolume";

//Get App Builder Teaching Elements
export const getABTeachingElements = "/getSeriesElementDetailsBySeriesId";

//appBuilderDetail
export const AppBuilderDetailListing = "/listSeriesBuild";

//AppBuilderDetail ADD SECTION
export const AppBuilderDetailAddSection = "/addUpdateSeriesElement";

//appBuilderDetail Series elements
export const AppBuilderDetailElements = "/getSeriesElementDetailsBySeriesId";

//Section add
export const AddSections = "/addUpdateSeriesElement";

//Sections  Duplicate
export const DuplicateSections = "/createDuplicateBuildElement";

//Sections  Delete
export const DeleteSections = "/updateBuildelementsDetails";

//Series
export const SeriesDetail = "/listSeriesBuild";

//Series Add
export const AddSeries = "/addUpdateSeriesBuildList";

//Series Delete
export const DeleteSeriesDetail = "/updateSeriesBuild";

//Series Duplicate
export const DuplicateSeriesDetail = "/createDuplicateSeries";

//Series Share link
export const ShareLinkSeriesDetail = "/listSeriesBuild";

//Get MB save list
export const getMBRestoreList = "/getAllRestoreMessages";

//Get MB save list
export const MBSaveOnChange = "/updateMessageAndElements";

//Create Restore Point
export const RestorePoint = "/getAllRestoreVolume";

//Series title and code display
export const GetSeriesVolumeId = "/getSeriesListByVolumeId";

//Drag Drop build to folder
export const dragBuildToFolder = "/moveBuildToOtherFolder";

//Series Volume title and code display
export const SeriesSave = "/createUpdateVolumeForMobile";

//Series Title
export const SeriesTitleSave = "/addUpdateSeriesBuildList";

//Building block drag drop
export const buildingBlockDragDrop = "/addMessageElementForWeb";

//section drag drop
export const seriesDragAndDrop = "/saveSeriesSortOrder";

//section and message element drag drop
export const buildElementDrag = "/saveElementsSortOrder";

//Image Drag and Drop
export const imageDropZone = "/addUpdateSeriesForMobile";

// MB VIDEO Element MEDIA URL
export const videoEmbed = "/validateVideoURL";

//Site
export const GET_SITES = "/listSite";

// Get All Menus
export const LIST_ALL_APP_MENU = "/listAllApplicationMenu";
export const GET_SYSTEM_PAGE_DETAIL_URL = "/getSystemPageDetailsByKeyword";

/**App Builder Details Import Categories Volume*/
export const ImportCategoriesVolume = "/getCategoriesVolumeBuild";

/**App Builder Details Import SERIES BY Categories Volume*/
export const ImportSeries = "/getSeriesByCategoriesVolumeBuild";

/**App Builder Details Series Import BY Categories Volume and SERIES*/
export const getImportSeries = "/addSeriesCategoryInVolume";

/**Get Tutorials Data*/
export const getAllTutorial = "/listAllTutorials";

/**Get Tutorials Data By Id*/
export const getTutorialById = "/getTutorialById";

/**Get Faq Data*/
export const getAllFaq = "/listAllFaq";

// Subscription
export const GET_SUBSCRIPTION_URL = "/listProduct"
export const GET_ACTIVE_SUBSCRIPTION_URL = "/getActiveSubscriptionsBySite"
export const GET_KEYWORD_DATA = "/getPageLinkDetails"

//drift script
export const GET_DRIFT_DATA = "/getDriftCodeBySiteId"

//logout

export const LOG_OUT_USER = "/logOutUser"


