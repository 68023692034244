import {
  FETCH_BUILDING_BLOCKS_LISTING,
  FETCH_BUILDING_BLOCKS_LISTING_SUCCESS,
  FETCH_BUILDING_BLOCKS_LISTING_ERROR,

} from "./actionTypes";

import { axiosBuilder } from "services/api";
import { getBuildingBlocks } from "constants/urls";
import { toast } from 'react-toastify';

//MB building blocks Listing
export const handleBuildingBlocksListing = (
  payload, cb
) => {
  return async (dispatch) => {
    try {
      dispatch(fetchBuildingBlockListing(true));
      const res = await axiosBuilder.post(getBuildingBlocks, payload);
      if (res.data) {
        dispatch(fetchBuildingBlockListingSuccess(res.data.data.rows))
        cb(res.data.data)
        toast.success(res.data?.message);
      }
    } catch (err) {

      dispatch(fetchBuildingBlockListingError(err.response?.data?.message || err.message))
    }
  };
};

//MB Folder Listing
export const fetchBuildingBlockListing = (data) => {
  return {
    type: FETCH_BUILDING_BLOCKS_LISTING,
    payload: data
  }
}
export const fetchBuildingBlockListingSuccess = (data) => {
  return {
    type: FETCH_BUILDING_BLOCKS_LISTING_SUCCESS,
    payload: data
  }
}
export const fetchBuildingBlockListingError = (data) => {
  return {
    type: FETCH_BUILDING_BLOCKS_LISTING_ERROR,
    payload: data
  }
}

