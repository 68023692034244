import { React, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  getActiveSubscriptionsBySite,
  handleGetLibraryBuild,
} from "store/actions";
import growNoData from "assets/images/no-data.png";
import Header from "components/common/Header/Header";
import searchIcon from "assets/images/icon/search-normal.svg";
import elementary from "assets/images/icon/elementary.svg";
import rightArrow from "assets/images/eye.png";
import leftArrow from "assets/images/duplicate.png";
import Footer from "components/common/Footer/Footer";
import { imageUrl } from "services/aws";
import onClickLoader from "../../../../assets/images/onClickLoader.gif";
import LibraryLoader from "components/common/Loader/LibraryLoader";
import unauthorizedUserImage from "assets/images/unauthorizedUserImage.svg";

const LibraryDetailCard = ({
  image,
  title,
  BuildList,
  parent_category_id,
  isMinistryType,
  isDuplicateBuild,
}) => {
  const [activeTab, setActiveTab] = useState("");
  const [isBuildId, setIsBuildId] = useState(0);
  const dispatch = useDispatch();
  const [buildCount, setBuildCount] = useState(0);

  const { totalSizeMB } = useSelector((state) => state.MessageBuilderList);
  const { user } = useSelector((state) => state.auth);

  // For selected week onPageLoad
  useEffect(() => {
    if (!!BuildList?.message_details?.length) {
      const weeks = BuildList.message_details[0].week_no;
      if (!!weeks.length) setActiveTab(weeks);
    }
  }, [BuildList]);

  useEffect(() => {
    dispatch(
      getActiveSubscriptionsBySite({ site_id: 3 }, (status) => {
        setBuildCount(status.is_system_message_count);
      })
    );
  }, [dispatch]);

  return (
    <>
      <div className="col-lg-4 col-md-6 col-sm-12">
        <div className="box">
          <>
            <img
              src={image.replace(
                imageUrl.S3ACCOUNTS_URL,
                imageUrl.ACCOUNT_DISPLAY_URL
              )}
              className="box-img"
            />
            <div className="box-body">
              <>
                <h2 className="dark-text fw-600 mb-0">{title}</h2>
                <ul className="nav nav-tabs inner-tabbing">
                  {BuildList.message_details.map((BuildTitle, ind) => {
                    return (
                      <li key={ind}>
                        {isMinistryType == 4
                          ? !!BuildTitle && (
                              <button
                                className={`inner-tabbing-btn ${
                                  activeTab === BuildTitle.week_no
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => setActiveTab(BuildTitle.week_no)}
                              >
                                {BuildTitle.week_no}
                              </button>
                            )
                          : !!BuildTitle && (
                              <button
                                className={`inner-tabbing-btn ${
                                  activeTab === BuildTitle.week_no
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => setActiveTab(BuildTitle.week_no)}
                              >
                                {BuildTitle.week_no}
                              </button>
                            )}
                      </li>
                    );
                  })}
                </ul>
                <div className="tab-content inner-tab-content">
                  {BuildList.message_details.map((BuildTitle, index) => {
                    return (
                      <div
                        key={index}
                        data-aos="zoom-in"
                        className={`tab-pane ${
                          activeTab === BuildTitle.week_no
                            ? "active show transition"
                            : ""
                        }`}
                      >
                        {!!BuildTitle &&
                          BuildTitle.week_data.map((data, i) => {
                            return (
                              <div key={i} className="main-libarbary-box">
                                <div className="img-heading">
                                  <div className="img-circle">
                                    <img src={elementary} alt="elementary" />
                                  </div>
                                  {data.build_type === 0 ? (
                                    <h3 className="dark-text mb-0 fw-500">
                                      General
                                    </h3>
                                  ) : (
                                    ""
                                  )}
                                  {data.build_type === 1 ? (
                                    <h3 className="dark-text mb-0 fw-500">
                                      Preschool
                                    </h3>
                                  ) : (
                                    ""
                                  )}
                                  {data.build_type === 2 ? (
                                    <h3 className="dark-text mb-0 fw-500">
                                      Elementary
                                    </h3>
                                  ) : (
                                    ""
                                  )}
                                  {data.build_type === 3 ? (
                                    <h3 className="dark-text mb-0 fw-500">
                                      Sermon
                                    </h3>
                                  ) : (
                                    ""
                                  )}
                                  {data.build_type === 4 ? (
                                    <h3 className="dark-text mb-0 fw-500">
                                      Hybrid
                                    </h3>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="action-button">
                                  <Link
                                    className="inner-tab-box"
                                    to={`builder/view/${data.message_build_list_id}?code=${data.public_code}&parent_category_id=${parent_category_id}&ministry_type=${isMinistryType}`}
                                    title="View"
                                  >
                                    <div className="left-arrow">
                                      <img src={rightArrow} alt="left-arrow" />
                                    </div>
                                  </Link>
                                  {!!user &&
                                  // This Condition is Remove user Role Type
                                  user.user_role === 3 &&
                                  !isDuplicateBuild &&
                                  buildCount >= 12 ? (
                                    <></>
                                  ) : (
                                    <Link
                                      className="inner-tab-box"
                                      to={`builder/duplicate-build/${data.message_build_list_id}?parent_category_id=${parent_category_id}&ministry_type=${isMinistryType}`}
                                      title="Duplicate"
                                    >
                                      {data?.message_build_list_id ===
                                      isBuildId ? (
                                        <div className="arrow">
                                          <img
                                            src={onClickLoader}
                                            alt="right-arrow"
                                          />
                                        </div>
                                      ) : (
                                        <div className="arrow">
                                          <img
                                            src={leftArrow}
                                            alt="right-arrow"
                                          />
                                        </div>
                                      )}
                                    </Link>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
                </div>
              </>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

const MessageBuilderLibrary = ({
  libraryFolderId,
  isLibrarySearchValue,
  buildLibraryLoading,
  onSelectLibraryTypeLoader,
  selectedLibraryTab,
  onSelectLibraryLoader,
  isMinistryTypeVal,
}) => {
  const { LibraryBuildListing } = useSelector(
    (state) => state.MessageBuilderFolderList
  );
  const { StoreLibraryFolderTitle, StoreLibraryFolderId } = useSelector(
    (state) => state.MessageBuilderFolderList
  );
  const [isMinistryType, setIsMinistryType] = useState(1);
  const [search, setSearch] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isButton, setIsButton] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isButtonHide, setIsButtonHide] = useState(false);
  const dispatch = useDispatch();

  useMemo(() => {
    if (!!isMinistryTypeVal) {
      setIsButtonHide(true);
    } else if (isMinistryTypeVal === undefined) {
      setIsButtonHide(false);
    } else {
      setIsButtonHide(false);
    }
  }, [StoreLibraryFolderId]);

  useEffect(() => {
    onSelectLibraryLoader(false);
    setIsButton(true);
    if (StoreLibraryFolderTitle == "Free VBS") {
      dispatch(
        handleGetLibraryBuild(
          {
            volume_id: StoreLibraryFolderId,
            ministry_type: 4,
          },
          (status) => {
            if (status) {
              onSelectLibraryLoader(true);
              setIsButton(false);
            }
          }
        )
      );
    } else {
      dispatch(
        handleGetLibraryBuild(
          {
            volume_id: StoreLibraryFolderId,
            ministry_type: isMinistryTypeVal ? isMinistryTypeVal : 1, //bhavik
          },
          (status) => {
            if (status.statusCode === 200) {
              onSelectLibraryLoader(true);
              setIsButton(false);
              setIsAuthorized(false);
            } else {
              onSelectLibraryLoader(true);
              setIsButton(false);
              setIsAuthorized(true);
            }
          }
        )
      );
    }
  }, [dispatch, StoreLibraryFolderId]);

  useEffect(() => {
    dispatch(
      getActiveSubscriptionsBySite({ site_id: 3 }, (status) => {
        // console.log(status);
        // It is remove because of this shows every one user
        // if (status.count > 0) {
        setIsDuplicate(true);
        // }
      })
    );
  }, [dispatch]);

  const handleFolderSelectMB = (folderDetail) => {
    //handle search value by ministry_type
    setIsButtonEnabled(true);
    if (folderDetail !== isMinistryType) {
      setSearch("");
    }
    setIsMinistryType(folderDetail);
    onSelectLibraryTypeLoader(false);
    dispatch(
      handleGetLibraryBuild(
        {
          volume_id: libraryFolderId,
          ministry_type: folderDetail,
        },
        (status) => {
          if (status.statusCode === 200) {
            onSelectLibraryTypeLoader(true);
            setIsButtonEnabled(false);
            setIsAuthorized(false);
          } else {
            onSelectLibraryTypeLoader(true);
            setIsButtonEnabled(false);
            setIsAuthorized(true);
          }
        }
      )
    );
  };
  useEffect(() => {
    if (isMinistryTypeVal) {
      const sortedMinistryType =   [...isMinistryTypeVal].sort((a, b) => a - b);
      setIsMinistryType(sortedMinistryType[0]);
    } else {
      if (selectedLibraryTab == true) {
        setIsMinistryType(1);
      }
    }

    //handle search value by library folder
    if (isLibrarySearchValue == true) {
      setSearch("");
    }
  }, [isLibrarySearchValue, selectedLibraryTab]);
  //search title on focus out in Message Builder Library
  const handleSearch = (e) => {
    const checkSpace = e.target.value.indexOf(" ");
    if (checkSpace === 0) {
      return false;
    } else {
      setSearch(e.target.value);
      if (StoreLibraryFolderTitle == "Free VBS") {
        dispatch(
          handleGetLibraryBuild({
            volume_id: libraryFolderId,
            ministry_type: 4,
            search: e.target.value,
          }),
          [e.target.value]
        );
      } else {
        dispatch(
          handleGetLibraryBuild({
            volume_id: libraryFolderId,
            ministry_type: isMinistryType, //bhavik
            search: e.target.value,
          }),
          [e.target.value]
        );
      }
    }
  };
  const Location = useLocation();
  const isValid = useMemo(
    () => Location.pathname.startsWith("/app-builder"),
    [Location]
  );
  const loadPlaceholder = [1, 2, 3, 4, 5, 6];
  return (
    <div className="main_content">
      <Header />
      <div className="main_card main_card_body">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/builder">
                {" "}
                <span>Home</span>
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {isValid ? "APP BUILDER LIBRARY" : "LESSON BUILDER LIBRARY"}
            </li>
          </ol>
        </nav>
        <div className="main_card_wrapper tabbing-card">
          <div className="wrapper-heading-area filter-area">
            <div className="title">
              <h1 className="wrapper-heading fw-600">
                {StoreLibraryFolderTitle}
              </h1>
            </div>
            <div className="page-filter">
              <div className="form-group mb0">
                <img src={searchIcon} alt="search" className="search-icon" />
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="search"
                  value={search}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>
          <div className="tabbing-area">
            <div className="row">
              <div className="col-md-12">
                {buildLibraryLoading && (
                  <ul className="nav nav-tabs">
                    {StoreLibraryFolderTitle == "3 Month Pack" ||
                    StoreLibraryFolderTitle == "Free Trial" ? (
                      <>
                        <li>
                          {isButtonEnabled ? (
                            <button
                              data-toggle="tab"
                              disabled
                              className={
                                isMinistryType == 1
                                  ? "tabbing-btn btn active"
                                  : "tabbing-btn btn"
                              }
                            >
                              Kids
                            </button>
                          ) : (
                            <button
                              data-toggle="tab"
                              onClick={() =>
                                isMinistryType == 1
                                  ? null
                                  : handleFolderSelectMB(1)
                              }
                              className={
                                isMinistryType == 1
                                  ? "tabbing-btn btn active"
                                  : "tabbing-btn btn"
                              }
                            >
                              Kids
                            </button>
                          )}
                        </li>
                        <li>
                          {isButtonEnabled || isButton ? (
                            <button
                              data-toggle="tab"
                              disabled
                              className={
                                isMinistryType == 2
                                  ? "tabbing-btn btn active"
                                  : "tabbing-btn btn"
                              }
                            >
                              Students
                            </button>
                          ) : (
                            <button
                              data-toggle="tab"
                              onClick={() =>
                                isMinistryType == 2
                                  ? null
                                  : handleFolderSelectMB(2)
                              }
                              className={
                                isMinistryType == 2
                                  ? "tabbing-btn btn active"
                                  : "tabbing-btn btn"
                              }
                            >
                              Students
                            </button>
                          )}
                        </li>
                      </>
                    ) : StoreLibraryFolderTitle == "Free VBS" ? null : (
                      <>
                        {/* {console.log(isMinistryTypeVal)} */}
                        <li>
                          {isButtonEnabled ? (
                            <button
                              data-toggle="tab"
                              disabled
                              className={
                                isMinistryType == 1
                                  ? "tabbing-btn btn active"
                                  : "tabbing-btn btn"
                              }
                            >
                              Kids
                            </button>
                          ) : isButtonHide && isMinistryTypeVal.includes(1) ? (
                            <button
                              data-toggle="tab"
                              onClick={() =>
                                isMinistryType == 1
                                  ? null
                                  : handleFolderSelectMB(1)
                              }
                              className={
                                isMinistryType == 1
                                  ? "tabbing-btn btn active"
                                  : "tabbing-btn btn"
                              }
                            >
                              Kids
                            </button>
                          ) : !isButtonHide && isMinistryType ? (
                            <button
                              data-toggle="tab"
                              onClick={() =>
                                isMinistryType == 1
                                  ? null
                                  : handleFolderSelectMB(1)
                              }
                              className={
                                isMinistryType == 1
                                  ? "tabbing-btn btn active"
                                  : "tabbing-btn btn"
                              }
                            >
                              Kids
                            </button>
                          ) : isButtonHide && isMinistryType != 1 ? null : (
                            <button
                              data-toggle="tab"
                              disabled
                              className={
                                isMinistryType == 1
                                  ? "tabbing-btn btn active"
                                  : "tabbing-btn btn"
                              }
                            >
                              Kids
                            </button>
                          )}
                        </li>
                        <li>
                          {isButtonEnabled || isButton ? (
                            <button
                              data-toggle="tab"
                              disabled
                              className={
                                isMinistryType == 2
                                  ? "tabbing-btn btn active"
                                  : "tabbing-btn btn"
                              }
                            >
                              Students
                            </button>
                          ) : isButtonHide && isMinistryTypeVal.includes(2) ? (
                            <button
                              data-toggle="tab"
                              onClick={() =>
                                isMinistryType == 2
                                  ? null
                                  : handleFolderSelectMB(2)
                              }
                              className={
                                isMinistryType == 2
                                  ? "tabbing-btn btn active"
                                  : "tabbing-btn btn"
                              }
                            >
                              Students
                            </button>
                          ) : !isButtonHide && isMinistryType ? (
                            <button
                              data-toggle="tab"
                              onClick={() =>
                                isMinistryType == 2
                                  ? null
                                  : handleFolderSelectMB(2)
                              }
                              className={
                                isMinistryType == 2
                                  ? "tabbing-btn btn active"
                                  : "tabbing-btn btn"
                              }
                            >
                              Students
                            </button>
                          ) : isButtonHide && isMinistryType != 2 ? null : (
                            <button
                              data-toggle="tab"
                              disabled
                              className={
                                isMinistryType == 2
                                  ? "tabbing-btn btn active"
                                  : "tabbing-btn btn"
                              }
                            >
                              Students
                            </button>
                          )}
                        </li>
                        {parseInt(libraryFolderId) !== 256 ||
                        parseInt(libraryFolderId) !== 341 ? ( // ORG V8 341
                          <li>
                            {isButtonEnabled || isButton ? (
                              <button
                                data-toggle="tab"
                                disabled
                                className={
                                  isMinistryType == 3
                                    ? "tabbing-btn btn active"
                                    : "tabbing-btn btn"
                                }
                              >
                                Groups
                              </button>
                            ) : isButtonHide &&
                              isMinistryTypeVal.includes(3) ? (
                              <button
                                data-toggle="tab"
                                onClick={() =>
                                  isMinistryType == 3
                                    ? null
                                    : handleFolderSelectMB(3)
                                }
                                className={
                                  isMinistryType == 3
                                    ? "tabbing-btn btn active"
                                    : "tabbing-btn btn"
                                }
                              >
                                Groups
                              </button>
                            ) : !isButtonHide && isMinistryType ? (
                              <button
                                data-toggle="tab"
                                onClick={() =>
                                  isMinistryType == 3
                                    ? null
                                    : handleFolderSelectMB(3)
                                }
                                className={
                                  isMinistryType == 3
                                    ? "tabbing-btn btn active"
                                    : "tabbing-btn btn"
                                }
                              >
                                Groups
                              </button>
                            ) : isButtonHide && isMinistryType != 3 ? null : (
                              <button
                                data-toggle="tab"
                                disabled
                                className={
                                  isMinistryType == 3
                                    ? "tabbing-btn btn active"
                                    : "tabbing-btn btn"
                                }
                              >
                                Groups
                              </button>
                            )}
                          </li>
                        ) : null}
                      </>
                    )}
                  </ul>
                )}
                {/* Kids */}
                <div className="tab-content">
                  <div
                    id={
                      isMinistryType === 1
                        ? "kids"
                        : isMinistryType === 2
                        ? "students"
                        : "group"
                    }
                    className="tab-pane fade in active show"
                  >
                    <div className="row">
                      {buildLibraryLoading == false ? (
                        loadPlaceholder.map((i) => <LibraryLoader key={i} />)
                      ) : isAuthorized ? (
                        <div className="mx-auto">
                          <img
                            src={unauthorizedUserImage}
                            alt="unauthorizedUserImage"
                            className="unauthorizedUserImage"
                          />
                        </div>
                      ) : LibraryBuildListing &&
                        LibraryBuildListing.count > 0 &&
                        LibraryBuildListing.rows.some(
                          (item) => !!item.message_details.length
                        ) ? (
                        LibraryBuildListing &&
                        LibraryBuildListing.rows
                          .filter((i) => !!i.message_details.length)
                          .map((BuildList, i) => {
                            return (
                              <LibraryDetailCard
                                key={i}
                                image={BuildList.category_image}
                                title={BuildList.category_title}
                                BuildList={BuildList}
                                parent_category_id={StoreLibraryFolderId}
                                isMinistryType={isMinistryType}
                                isDuplicateBuild={isDuplicate}
                              />
                            );
                          })
                      ) : (
                        <div className="col-lg-12">
                          <img src={growNoData} className="no-data-found"></img>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default MessageBuilderLibrary;
