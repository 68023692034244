import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "./Footer";
import { getSystemPageDetailData } from "store/actions";
import DOMPurify from "dompurify";
import privacyImg from "assets/images/privacy-policy.jpg";
import loaderImage from "../../../assets/images/loaderImage.gif";

const Policy = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoader, setIsLoader] = useState(false);
  const pageUrl = location.pathname;
  let keyword = pageUrl.replaceAll("/", "");
  const { systemPageDetail, loading } = useSelector(
    (state) => state.footerReducer
  );
  useEffect(() => {
    if (isAuth) {
      setIsLoader(true);
      dispatch(
        getSystemPageDetailData(
          {
            site_id: 3,
            data_for: keyword,
          },
          (status) => {
            if (status) {
              setIsLoader(false);
            }
          }
        )
      );
    }
  }, [dispatch, keyword, isAuth]);

  return isLoader ? (
    <span className="loaderImage">
      <img src={loaderImage} />
    </span>
  ) : (
    <>
      <div className="no-sidebar privacy-policy">
        <Header />
        <section
          className="privacy-policy"
          style={{ backgroundImage: `url(${privacyImg})` }}
        >
          <div className="container">
            <div className="privacy-policy-heading">
              <p>{systemPageDetail?.page_title}</p>
            </div>
          </div>
        </section>

        <section className="user-contents mb55">
          <div className="container-fluid  custom-container">
            <div className="row">
              <div className="col-md-12 my-4">
                <div className="card">
                  <div className="card-body card-para">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          systemPageDetail?.description
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Policy;
