import React, { useMemo } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import { config as basicConfig, appConfig } from "constants/ckEditor";
import { useLocation } from "react-router-dom";

const CkEditor = React.forwardRef(
  ({ onChange = () => { }, data, onBlur, config = basicConfig, disabled }, ref) => {
    const Location = useLocation();
    const isValid = useMemo(
      () => Location.pathname.startsWith("/app-builder"),
      [Location]
    );
    return (
      <div ref={ref}>
        <CKEditor
          editor={ClassicEditor}
          config={isValid ? appConfig(disabled) : config(disabled)}
          data={data}
          onChange={(event, editor) => {
            const data = editor.getData();
            onChange(data);
          }}
          onBlur={onBlur}
          disabled={disabled}
        />
      </div>
    );
  }
);

export default CkEditor;
