import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Header from "components/common/Header/Header";
import Footer from "components/common/Footer/Footer";
import { AppBuilderTeachingElements } from "modules/App/Teaching/components/AppBuilderTeachingElements";
import queryString from "query-string";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import backImage from "assets/images/icon/back.png";
import unauthorizedUserImage from 'assets/images/unauthorizedUserImage.svg'
import { handleABLibraryDuplicate } from "../AppBuilder/store/action";
import { getActiveSubscriptionsBySite } from "store/actions";

export const AppBuilderTeaching = () => {
  const [isTeachElements, setIsTeachElements] = useState();
  const [isDuplicateButton, setIsDuplicateButton] = useState(false)
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const parsed = queryString.parse(location.search);
  const { isAuth } = useSelector((state) => state.auth);
  const seriesId = parsed.seriesId;
  if (!parsed.code) {
    localStorage.setItem("seriesId", seriesId);
  }
  const { ABTeachingElements } = useSelector(
    (state) => state.AppBuilderTeaching
  );
  useEffect(() => {
    setIsTeachElements(ABTeachingElements.count)
  }, [ABTeachingElements])

  useEffect(() => {
    if (isAuth) {
      dispatch(getActiveSubscriptionsBySite({ site_id: 3 }, (status) => {
        if (status.count > 0) {
          setIsDuplicateButton(true)
        }
      }))
    }
  }, [dispatch])

  const handleDuplicate = () => {
    dispatch(
      handleABLibraryDuplicate(
        {
          volume_id: parseInt(params.id)
        },
        (id) => {
          if (id) {
            history.push("/app-builder/edit/" + id);
          }
        }
      ), [dispatch]
    );
  }

  return (
    <div className="no-sidebar">
      <div className="main_content">
        <Header />
        <div className="main_card main_card_body pb-0">
          {isAuth == true ? <>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/builder">
                    {" "}
                    <span>Home</span>
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  App Builder
                </li>
              </ol>
            </nav>
            <div className="main_card_wrapper tabbing-card">
              <div className="wrapper-heading-area filter-area">
                <div className="title">
                  <Link className="" to="/app-builder">
                    <img src={backImage} />
                    <h1 className="wrapper-heading fw-600 mb0 ml5">
                      Back To Your App Builder
                    </h1>
                  </Link>
                </div>
                <div className="new-builder-action-button">
                  {
                    isTeachElements != 0 ?
                      parsed.code ?
                        isDuplicateButton ? <button className="btn primary-btn" onClick={() => handleDuplicate()}>Duplicate</button> : null
                        : <Link
                          to={"/app-builder/edit/" + params.id}
                          className={!isTeachElements ? "d-none" : "btn primary-btn"}
                        >
                          Edit
                        </Link>
                      :
                      null
                  }
                </div>
              </div>
            </div>
          </> : null}
          {isTeachElements === 0 ?
            <div className="main_card_wrapper tabbing-card">
              <div className="unauthorized_user_image col-lg-12">
                <img src={unauthorizedUserImage} alt="unauthorizedUserImage" className="unauthorizedUserImage" />
              </div>
            </div>
            :
            <div className={isAuth == true ? "row mt20" : "row mt70"}>
              <AppBuilderTeachingElements />
            </div>
          }
        </div>
        <Footer />
      </div>
    </div>
  );
};
