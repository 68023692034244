import React, { useCallback, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import { Progress } from "reactstrap";
import {
  handleABSeriesImport,
  handleABSeriesInCategoriesVolume,
  handleSectionDragDrop,
  handleAppBuilderDetailAddSection,
  handleImageSectionDropZone,
  handleFetchSeriesElement,
  handleSaveTitleSeriesDetail,
  handleSaveHeadingTitle,
  handleFetchSeriesDetail,
  handleDescSectionDropZone,
} from "store/actions";
import { MessageElements } from "components/MessageElements/MessageElements";
import CkEditor from "components/common/CustomCkEditor/CkEditor";
import { imageUrl, s3 } from "services/aws";
import { v4 as uuidV4 } from "uuid";
import queryString from "query-string";
import MultiSelect from "multiselect-react-dropdown";
import FolderImg from "assets/images/icon/folder-2.svg";
import deleteImg from "assets/images/icon/trash.svg";
import MBLoading from "components/common/Loader/MBLoading";

const defaultValue = () => ({
  id: uuidV4(),
  content: "",
  title: "",
});

const SeriesSection = ({
  seriesId,
  seriesSectionLoading,
  isImportClick,
  importByAddedSeries,
  onChangeElementTitle,
  changeHeadingTitle,
}) => {
  const {
    AppBuilderDetailListing,
    abSectionLoading,
    CategoriesVolumeList,
    SeriesByCategoriesVolume,
  } = useSelector((state) => state.AppBuilderDetail);
  const { addSeriesImport } = useSelector((state) => state.SeriesDetail);
  const { SeriesElements, seriesLoading } = useSelector(
    (state) => state.SeriesDetail
  );
  const [progress, setProgress] = useState(0);
  const [awsSeriesId, setAwsSeriesId] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [elementLoading, setElementLoading] = useState(false);
  const [elements, setElements] = useState([]);
  const [preview, setPreview] = useState("");
  const [viewImage, setViewImage] = useState("");
  const [isImage, setIsImage] = useState("");
  const [add, setSectionAdd] = useState([{ ...defaultValue() }]);
  const [isElementLoader, setIsElementLoader] = useState(true);
  const [selectedSeriesId, setSelectedSeriesID] = useState([]);
  const [ImageFile, setImageFile] = useState("");
  const [ImageName, setImageName] = useState("");
  const [ContentChange, setContentChange] = useState("");
  const [ChangeTitle, setChangeTitle] = useState({
    elementTitle: SeriesElements.series_buildlist_title,
  });
  const [seriesTitle, setSeriesTitle] = useState(false);
  const [seriesContent, setSeriesContent] = useState(false);
  const [seriesLocalId, setSeriesLocalId] = useState(0);
  const { isAuth } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const multiSelectRef = useRef();
  const params = useParams();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  let seriesData =
    SeriesElements != ""
      ? SeriesElements.sycu_build_elements_details
      : AppBuilderDetailListing;

  /** set ABDetailElements  **/
  useEffect(() => {
    setElements(seriesData);
    setSeriesTitle(false);
    setSeriesContent(false);
  }, [seriesData]);

  /** for save ImageUrl **/
  useEffect(() => {
    setIsImage(SeriesElements.media_url);
    if (typeof seriesId !== "boolean") {
      setAwsSeriesId(seriesId);
    }
  }, [SeriesElements]);

  //Drag and drop image section
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setImageFile("");
    const params = {
      ACL: "public-read",
      Key: file.name,
      ContentType: file.type,
      Body: file,
    };
    setIsUploading(true);
    setImageName(file.name);

    s3(process.env.REACT_APP_AWS_FOLDER_NAME)
      .upload(params)
      .on("httpUploadProgress", function (evt) {
        const value = Math.round((evt.loaded / evt.total) * 100);
        setProgress(value);
        if (value === 100) setIsUploading(false);
      })
      .send(function (err, data) {
        if (err) return;

        let obj = {
          series_build_list_id: typeof seriesId != "boolean" ? seriesId : "",
          media_url: data.Location,
        };
        setPreview(data.Location);
        setViewImage(data.Location);
      });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  /** Image Upload API **/
  useEffect(() => {
    if (preview) {
      dispatch(
        handleImageSectionDropZone({
          series_build_list_id: seriesId,
          media_url: preview,
        })
      );
    }
    setPreview("");
  });

  //APP BUILDER DETAILS ADD SECTION
  const handleABDetailAddSection = (ABAddElement) => {
    setElementLoading(true);
    dispatch(
      handleAppBuilderDetailAddSection(
        {
          build_list_id: seriesId,
          content: "",
          title: "Weekly Conversation",
        },
        (data) => {
          if (data) {
            setElementLoading(false);
          }
        }
      )
    );
  };

  /** Fetch ABDeatils Elements **/
  useEffect(() => {
    if (isAuth) {
      if (parsed.seriesId) {
        dispatch(
          handleFetchSeriesElement({
            series_build_list_id: parsed.seriesId,
          })
        );
      } else {
        setIsElementLoader(true);
        if (seriesId !== true) {
          setSeriesLocalId(seriesId);
          dispatch(
            handleFetchSeriesElement(
              {
                series_build_list_id: seriesId,
              },
              (status) => {
                if (status) {
                  setIsElementLoader(false);
                }
              }
            )
          );
        }
      }
    }
  }, [dispatch, seriesId]);

  /** For Change ABElement Title **/
  useEffect(() => {
    if (!!SeriesElements.series_buildlist_title)
      setChangeTitle(SeriesElements.series_buildlist_title);
  }, [SeriesElements]);

  /** Save ABElement Title On Foucs Out **/
  const handleSaveElementTitle = () => {
    const removeExtraSpace = (s) => s.trim().split(/ +/).join(" ");
    const seriesTitle = removeExtraSpace(ChangeTitle);
    if (seriesTitle != "") {
      if (ChangeTitle === "" || ChangeTitle == 0) {
        return false;
      } else {
        changeHeadingTitle(true);
        setSeriesTitle(false);
        dispatch(
          handleSaveHeadingTitle(
            {
              series_build_list_id: seriesId,
              series_buildlist_title: seriesTitle,
              volume_id: params.id,
            },
            (status) => {
              if (status) {
                dispatch(
                  handleFetchSeriesDetail({
                    volume_id: parseInt(params.id),
                  })
                );
                changeHeadingTitle(true);
              }
            }
          )
        );
      }
    } else {
      setSeriesTitle(true);
    }
  };

  /** For Change ABElement Description **/
  useEffect(() => {
    if (!!SeriesElements?.series_buildlist_content)
      setContentChange(SeriesElements?.series_buildlist_content);
  }, [SeriesElements]);

  /** Save ABElement Description On Foucs Out **/
  const handleSaveElementDescription = () => {
    const seriesContent = ContentChange.elementContent;
    if (seriesContent != "") {
      if (ContentChange === "" || ContentChange == 0) {
        return false;
      } else {
        setSeriesContent(false);
        setContentChange("");
        if (seriesId == seriesLocalId) {
          dispatch(
            handleDescSectionDropZone(
              {
                series_build_list_id: seriesLocalId,
                series_buildlist_content: seriesContent,
              },
              (status) => {
                if (status) {
                  dispatch(
                    handleFetchSeriesElement({
                      series_build_list_id: seriesId,
                    })
                  );
                }
              }
            )
          );
        }
      }
    } else {
      setSeriesContent(true);
    }
  };

  /** Drag Drop For ABElement  **/
  const onDragEnd = (onDragEnd) => {
    if (onDragEnd.destination != null) {
      let elementId = onDragEnd.draggableId;
      let destination = onDragEnd.destination.index;
      let source = onDragEnd.source.index;

      if (onDragEnd.source.droppableId === onDragEnd.destination.droppableId) {
        const reorder = (list, x, y) => {
          const result = list;
          const [removed] = result.splice(x, 1);
          result.splice(y, 0, removed);
          return result;
        };
        const items = reorder(
          elements,
          onDragEnd.source.index,
          onDragEnd.destination.index
        );
        setElements(items);
        dispatch(
          handleSectionDragDrop({
            id: seriesId,
            build_elements_details_id: elementId,
            destination: destination,
            source: source,
            type: 1,
            sort_order_type: "single",
          })
        );
      }
    }
  };

  /** Remove Image section **/
  const handleImageRemove = () => {
    dispatch(
      handleImageSectionDropZone({
        series_build_list_id: seriesId,
      })
    );
    setPreview("");
  };

  /** Series Description CKeditor Content **/
  const handleChange = () => {
    dispatch(
      handleImageSectionDropZone({
        series_build_list_id: seriesId,
        series_buildlist_content: ContentChange.elementContent,
      })
    );
  };

  const removeImage = () => {
    dispatch(
      handleImageSectionDropZone({
        series_build_list_id: seriesId,
        media_url: preview,
      })
    );
  };

  /**App Builder Details Import Series By Categories Volume */
  const handleImportCategoriesSeries = () => {
    let selectedId = document.getElementById("onSelectedId").value;
    dispatch(
      handleABSeriesInCategoriesVolume({
        volume_id: parseInt(selectedId),
      })
    );
  };

  /**App Builder Series Import */
  let newRes = [];
  let newResSeriesId = [];
  const onSelectSeriesId = (x, y) => {
    setSelectedSeriesID((prev) => [...prev, y.value]);
  };
  const handleABDetailSeriesImport = () => {
    dispatch(
      handleABSeriesImport(
        {
          volume_id: params.id,
          series_id: selectedSeriesId,
        },
        (status) => {
          if (status) {
            document.getElementById("onSelectedId").value = 0;
            importByAddedSeries(true);
            setSelectedSeriesID([]);
            multiSelectRef.current.resetSelectedValues();
          }
        }
      )
    );
  };

  const handleSeriesTitleChange = (e) => {
    const checkSpace = e.target.value.indexOf(" ");
    if (checkSpace == 0) {
      return false;
    }
    setChangeTitle(e.target.value);
  };

  return (
    <>
      <div className="col-lg-9">
        <div className="main_card_wrapper tabbing-card">
          {/* App Builder Details Import Start */}
          <div className={isImportClick ? "show" : "d-none"}>
            <div className="app_import">
              <div className="form-group">
                <select
                  className="selectpicker form-control jqmsLoaded Categories_Volume"
                  id="onSelectedId"
                  onChange={handleImportCategoriesSeries}
                >
                  <option key={0} value="0">
                    Please Select Volume
                  </option>
                  {!!CategoriesVolumeList &&
                    CategoriesVolumeList.map((element, i) => {
                      return (
                        <option key={i} value={element.volume_id}>
                          {element.volume_title}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="form-group">
                {!!SeriesByCategoriesVolume && SeriesByCategoriesVolume ? (
                  <>
                    {!!SeriesByCategoriesVolume.gb_series_buildlists &&
                      SeriesByCategoriesVolume?.gb_series_buildlists.map(
                        (item) => {
                          newRes.push({
                            value: item.series_build_list_id,
                            label: item.series_buildlist_title,
                          });
                          newResSeriesId.push(item.series_build_list_id);
                        }
                      )}
                    <MultiSelect
                      options={newRes}
                      displayValue="label"
                      onSelect={onSelectSeriesId}
                      ref={multiSelectRef}
                      className="form-control"
                      placeholder={
                        selectedSeriesId.length === 0
                          ? "Please Select Series"
                          : null
                      }
                    />
                  </>
                ) : null}
                <div className="ms-options-wrap"></div>
              </div>
              <div className="form-group">
                {addSeriesImport ? (
                  <button
                    type="submit"
                    name="submit-custom-series"
                    className="btn primary-btn"
                  >
                    Adding...
                  </button>
                ) : (
                  <button
                    onClick={handleABDetailSeriesImport}
                    type="submit"
                    name="submit-custom-series"
                    className="btn primary-btn"
                    disabled={selectedSeriesId.length === 0}
                  >
                    Add
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* App Builder Details Import End */}

          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className="message-form"
          >
            {SeriesElements.length === 0 ||
            isElementLoader == true ||
            seriesSectionLoading ? (
              <MBLoading />
            ) : (
              <>
                <div className="">
                  <h6 id="message-heading">
                    <input
                      id="renameit"
                      type="text"
                      className="box-border"
                      onBlur={handleSaveElementTitle}
                      value={ChangeTitle}
                      onChange={(e) => handleSeriesTitleChange(e)}
                    />
                    <div
                      className={
                        seriesTitle
                          ? "vol-title-error alert alert-danger"
                          : "d-none vol-title-error alert alert-danger"
                      }
                    >
                      Please Enter Series Title
                    </div>
                  </h6>
                </div>

                <div className="box-border">
                  <div className="flipbox-left image"></div>
                  <div className="flipbox-right">
                    <div
                      id="headingTwo"
                      className=""
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="etitle">
                          <b>Series Image</b>
                        </div>

                        <div
                          className="btn-delete-image"
                          onClick={() => handleImageRemove()}
                        ></div>
                      </div>
                    </div>
                    <div
                      id="collapseTwo cust-img"
                      className="collapse show"
                      aria-labelledby="headingTwo"
                    >
                      {!isImage ? (
                        <div {...getRootProps()} className="dropzone-area">
                          <input
                            {...getInputProps()}
                            accept="image/*"
                            multiple={false}
                          />

                          {isDragActive ? (
                            <p>
                              Drop the files here ...
                              {isUploading && (
                                <div className="mt-1">
                                  {ImageName}
                                  <Progress color="primary" value={progress}>
                                    {progress}%
                                  </Progress>
                                </div>
                              )}
                              <center>
                                <img className="sectionABImg" src={preview} />
                              </center>
                            </p>
                          ) : (
                            <div>
                              <img src={FolderImg} draggable="false" />
                              <h3 className="fw-500 dark-text">
                                Drop your files here, or <span>Browse</span>
                              </h3>
                              {isUploading && (
                                <div className="mt-1">
                                  {ImageName}
                                  <Progress color="primary" value={progress}>
                                    {progress}%
                                  </Progress>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      ) : null}
                      {!preview ? (
                        <center>
                          <div className="mainCalss"></div>

                          {isImage ? (
                            <div className="seriesImage">
                              <img
                                className="sectionABImg"
                                src={isImage.replace(
                                  imageUrl.S3BUILDER_URL,
                                  imageUrl.BUILDER_DISPLAY_URL
                                )}
                                alt=""
                              />
                              <img
                                src={deleteImg}
                                className="RemoveImage1"
                                onClick={() => removeImage()}
                                title="Delete"
                                alt="delete"
                              />
                            </div>
                          ) : null}
                        </center>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="box-border">
                  <div className="flipbox-left talk"></div>
                  <div className="flipbox-right">
                    <div
                      id="headingOne"
                      className=""
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <div className="flipbox-title"></div>
                    </div>
                    <div
                      id="collapseOne"
                      className=" collapse show"
                      aria-labelledby="headingOne"
                    ></div>

                    <div className="etitle hidden_title series_description">
                      <div className="mb15">
                        <b>Series Description</b>
                      </div>
                      <CkEditor
                        data={SeriesElements.series_buildlist_content.replaceAll(
                          `\\'`,
                          `'`
                        )}
                        onBlur={handleSaveElementDescription}
                        onChange={(data) => {
                          setSeriesLocalId(seriesId);
                          setContentChange({ elementContent: data });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {
              <DragDropContext onDragEnd={onDragEnd}>
                {SeriesElements.length === 0 ||
                isElementLoader == true ||
                seriesSectionLoading ? (
                  <MBLoading />
                ) : (
                  <Droppable
                    index="rowList"
                    id="rowList"
                    droppableId="CardList"
                  >
                    {(provided, snapshot) => (
                      <div
                        className="element-info"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {elements.map((element, index) => {
                          return (
                            <Draggable
                              key={element.build_elements_details_id}
                              index={index}
                              draggableId={
                                "" + element.build_elements_details_id
                              }
                            >
                              {(provided, snapshot) => (
                                <div
                                  key={index}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <MessageElements
                                    key={index}
                                    element={element}
                                    index={index}
                                    onChangeElementTitle={onChangeElementTitle}
                                    seriesId={seriesId}
                                    seriesLocalId={seriesLocalId}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {elementLoading ? <MBLoading /> : ""}
                      </div>
                    )}
                  </Droppable>
                )}
              </DragDropContext>
            }
            {SeriesElements.length === 0 ||
            isElementLoader == true ||
            seriesSectionLoading ? (
              <MBLoading />
            ) : (
              <div className="mt-4 d-flex justify-content-center">
                {abSectionLoading ? (
                  <button
                    type="button"
                    onClick={() => handleABDetailAddSection()}
                    disabled
                    className="btn primary-btn"
                  >
                    <i className="fa fa-plus-circle mr5" aria-hidden="true"></i>
                    ADD SECTION...
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => handleABDetailAddSection()}
                    className="btn primary-btn"
                  >
                    <i className="fa fa-plus-circle mr5" aria-hidden="true"></i>
                    ADD SECTION
                  </button>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default SeriesSection;
