import React from "react";
import { Col, Collapse, Row } from "reactstrap";

function FaqAccordion({ faq, id }) {
  const [isOpen, setIsOpen] = React.useState(true);
  return (
    <>
      <Row className="card-head mt-3" onClick={() => setIsOpen(!isOpen)}>
        <Col md="11">
          <h2 className="m-0"> {faq.title}</h2>
        </Col>
        <Col md="1" className="d-flex justify-content-end">
          {isOpen ? (
            <i className="fa fa-chevron-up icons" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-chevron-down icons" aria-hidden="true"></i>
          )}
        </Col>
      </Row>
      <Collapse className="mb-3" isOpen={isOpen}>
        <div
          className="card-body"
          dangerouslySetInnerHTML={
            faq.content !== null ? { __html: faq.content } : null
          }
        ></div>
      </Collapse>
    </>
  );
}

export default FaqAccordion;
