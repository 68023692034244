import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Collapse } from "reactstrap";
import { handleMBElementDelete } from "store/actions";
import CkEditor from "components/common/CustomCkEditor/CkEditor";
import downArrow from "assets/images/icon/down-arrow.svg";

export const AppTeachingElements = ({ element, index }) => {
  const { isAuth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let elementToggle = element.build_elements_details_id;
  const [isOpenElementToggle, setIsOpenElementToggle] = useState(true);
  //Element Listing Collapse
  let isClass;
  if (element.is_collapsed == 0) {
    isClass = "";
  } else {
    isClass = "d-none";
  }
  let MBElementToggle;
  if (element.is_collapsed == 0) {
    MBElementToggle = "element-off";
  } else {
    MBElementToggle = "";
  }

  // //AB Teaching Elements Toggle up down
  // const handleToggle = (e) => {
  //   var contentElement = document.getElementById(
  //     "element_1" + e.target.getAttribute("data-build-element-id")
  //   );
  //   if (contentElement.classList.contains("element-off")) {
  //     contentElement.classList.remove("element-off");
  //   } else {
  //     contentElement.classList.add("element-off");
  //   }
  // };

  useEffect(() => {
    setIsOpenElementToggle(element.is_collapsed == 0 ? true : false);
  }, []);
  //AB Teaching Elements Toggle
  const handleAppTeachingAccordion = (e) => {
    setIsOpenElementToggle(isOpenElementToggle == true ? false : true);
    let isCollapsedElement = isOpenElementToggle;
    if(isAuth){
      dispatch(
        handleMBElementDelete({
          build_elements_details_id: elementToggle,
          is_collapsed: parseInt(isCollapsedElement == true ? 1 : 0),
          is_series: 0,
        })
      );
    }
    //AB Teaching Elements Toggle up down
    var contentElement = document.getElementById(
      "element_1" + e.target.getAttribute("data-build-element-id")
    );
    if (contentElement.classList.contains("element-off")) {
      contentElement.classList.remove("element-off");
    } else {
      contentElement.classList.add("element-off");
    }
  };
  return (
    <>
      {element.is_visible == 1 ? (
        <div className="box-border MBdetail" key={index}>
          <div className={"flipbox-left image " + element.element_name}></div>
          <div className={`flipbox-right border-left-bar ${element.element_name}`}>
            <div className="d-flex align-items-center justify-content-between">
              <input
                className="flipbox-heading"
                type="text"
                name=""
                placeholder="test"
                value={element.title}
                id=""
                onChange={(e) => { }}
                disabled
              />
              <div className="righthold">
                <button
                  type="button"
                  onClick={
                    handleAppTeachingAccordion
                  }
                  data-target={element.is_collapsed}
                  data-build-element-id={element.build_elements_details_id}
                  className="teachingToogleBtn"
                >
                  <img
                    src={downArrow}
                    // onClick={handleToggle}
                    id={"element_1" + elementToggle}
                    className={`accordionArrow ` + MBElementToggle}
                    aria-hidden="true"
                    data-build-element-id={
                      element.build_elements_details_id
                    }
                  />
                </button>
              </div>
            </div>

            <Collapse
              className={"element_collapse mt15"}
              isOpen={isOpenElementToggle}
            >
              <div
                id="collapseTwo cust-img"
                className="collapse show element_image"
                aria-labelledby="headingTwo"
              >
              </div>
              <CkEditor
                name={element.element_name}
                data={element.content}
                disabled
              />
            </Collapse>
          </div>
        </div>
      ) : null}
    </>
  );
};
