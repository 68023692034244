import { React, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleBuildingBlocksListing } from "store/actions";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { imageUrl } from "services/aws";
import BBLoading from "components/common/Loader/BBLoading";
import arrowImg from "assets/images/icon/arrows.svg";


const Sidebar = ({ elementLoader, MBElementLoading }) => {
  const { BuildingBlockListing } = useSelector((state) => state.MessageBuilderBuildingBlocks);
  const { isAuth } = useSelector((state) => state.auth);

  const [BuildngBlockLoading, setBuildngBlockLoading] = useState(true);
  const [BuildingBlockElements, setBuildingBlockElements] = useState([]);
  const [sticky, setSticky] = useState(false);
  const [isDivDrag, setIsDivDrag] = useState(false);

  const dispatch = useDispatch();
  const ref = useRef();

  // BuildingBlock Listing
  useEffect(() => {
    if (isAuth) {
      dispatch(
        handleBuildingBlocksListing({
          element_category: [1, 2],
        }, (data) => {
          if (data) {
            setBuildngBlockLoading(false);
          }
        })
      );
    }
    return () => setBuildngBlockLoading()
  }, [dispatch]);

  useEffect(
    () => setBuildingBlockElements(BuildingBlockListing),
    [BuildingBlockListing]
  );

  // OnDrag Building Block CSS
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: isDragging ? `9px 10px 29px 10px` : `0px`,
    border: isDragging ? "1px solid #ccc" : "",
    ...draggableStyle,
  });

  const loadPlaceholder = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  //Right Side width set
  useEffect(() => {
    function myFunction() {
      var sticky = ref.current?.offsetTop;
      if (window.pageYOffset > sticky) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    }
    window.onscroll = function () {
      myFunction();
    };
  }, []);

  return (
    <div className="col-lg-3">
      <div
        className={`main_card_wrapper tabbing-card sideElements ${sticky ? "sticky" : ""
          }`}
        ref={ref}
      >
        <div className="mb15">
          <h3 className="mb0 fw-600">BUILDING BLOCKS</h3>
        </div>
        <Droppable index="rowList" id="rowList" droppableId="rowsList">
          {(provided, snapshot) => (
            <div>
              <ul
                className={`side-ul ${snapshot.isUsingPlaceholder ? "drag-div" : ""}`}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {MBElementLoading || elementLoader
                  ? loadPlaceholder.map((i) => <BBLoading key={i} />)
                  : BuildingBlockListing.map(
                    (BuildingBlock, i) =>
                      BuildingBlock.element_category === 1 && (
                        <Draggable
                          key={i}
                          index={i}
                          draggableId={"" + BuildingBlock.build_elements_id}
                        >
                          {(provided, snapshot) => (
                            <li
                              key={i}
                              ref={provided.innerRef}
                            className={snapshot.isDragging ? "my_drag": ''}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <a href="#">
                                {BuildingBlock.build_elements_name && (
                                  <>
                                  <div className="drag-item-left">
                                      <img
                                        className="mr15"
                                        src={BuildingBlock.icon.replace(imageUrl.S3BUILDER_URL, imageUrl.BUILDER_DISPLAY_URL)}
                                      />
                                      <h4 className="fw-600 mb0 building1" style={{ color: `#${BuildingBlock.hex_color}` }}>
                                        {BuildingBlock.build_elements_name}
                                      </h4>
                                    </div>
                                    <div className="drag-item-right">
                                      <img src={arrowImg}/>
                                    </div>
                                  </>
                                )}
                              </a>
                            </li>
                          )}
                        </Draggable>
                      )
                  )}
                {provided.placeholder}
              </ul>
            </div>
          )}
        </Droppable>
        <br></br>
        <div className="mb10 pt-4">
          <h3 className="mb0 fw-600">TOOLS</h3>
        </div>
        <Droppable index="rowList" id="rowList" droppableId="rowsList">
          {(provided, snapshot) => (
            <div>
              <ul
                className="side-ul tool-ul"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {MBElementLoading || elementLoader
                  ? loadPlaceholder.map((i) => <BBLoading key={i} />)
                  : BuildingBlockListing.map(
                    (BuildingBlock, i) =>
                      BuildingBlock.element_category === 2 && (
                        <Draggable
                          key={i}
                          index={i}
                          draggableId={"" + BuildingBlock.build_elements_id}
                        >
                          {(provided, snapshot) => (
                            <li
                              key={i}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <a href="#">
                                {BuildingBlock.build_elements_name && (
                                  <>
                                    <img
                                      className="mr15"
                                      src={BuildingBlock.icon}
                                      alt=""
                                    />
                                    <h4 className="fw-600 mb0 buildingGray">
                                      {BuildingBlock.build_elements_name}
                                    </h4>
                                  </>
                                )}
                              </a>
                            </li>
                          )}
                        </Draggable>
                      )
                  )}
                {provided.placeholder}
              </ul>
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
};
export default Sidebar;
