import { React, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Policy from "../Footer/Policy";
import axios from "axios";
import { axiosApiAdmin } from "services/api";
import { GET_KEYWORD_DATA } from "constants/urls";
import { PageNotFound } from "../PageNotFound";
import loaderImage from "../../../assets/images/loaderImage.gif";

const RoutePage = props => {
  const keyword = props.match?.params?.id
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const qry = queryString.parse(location.search)
  const userToken = qry?.userToken
  const site_id = 3

  useEffect(() => {
    ; (async () => {
      if (data == null) {
        try {
          setLoading(true)
          let res
          if (userToken !== undefined) {
            let data = { keyword: keyword, site_id: site_id }
            res = await axios.post(
              `${process.env.REACT_APP_API_ADMIN_URL}${GET_KEYWORD_DATA}`,
              data,
              {
                headers: {
                  authorization: "Bearer " + userToken.replaceAll(" ", "+"),
                },
              }
            )
          } else {
            res = await axiosApiAdmin.post(`${GET_KEYWORD_DATA}`, {
              keyword,
              site_id: site_id,
            })
          }
          if (res.data?.data?.pageLinkData) {
            setData(res.data.data.pageLinkData)
            setLoading(false)
          }
        } catch (e) {
          setLoading(false)
        }
      }
    })()
  }, [keyword, userToken, data, site_id,loading])

    return (
    <>
      {data?.link_type === 13 ? (
        <Policy {...data} site_id={site_id} />
      ) : (
        <>
          {data === null ? (
            <span className="loaderImage">
              <img src={loaderImage} />
            </span>
          ) : (
            <PageNotFound />
          )}
        </>
      )}
    </>
  );
}
export default RoutePage
