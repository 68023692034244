import axios from "axios";
import { clear, get, set } from "./cookies";
import store from "store";
import { logout, setToken } from "store/actions";
import { LOG_OUT_USER } from "constants/urls";
import { resetReduxState } from "store/Common/actions";

const API_URL_ACCOUNT = `${process.env.REACT_APP_API_ACCOUNT_URL}/api/v1/`;
// const API_URL_ACCOUNT = `http://192.168.1.158:3000/api/v1/`;
const API_URL_BUILDER = `${process.env.REACT_APP_API_BUILDER_URL}/api/v1/`;
// const API_URL_BUILDER = `http://192.168.1.98:8080/api/v1/`;
const APT_URL_ADMIN = `${process.env.REACT_APP_ADMIN_SITE_URL}/api/v1/`;
const API_ADMIN_URL = `${process.env.REACT_APP_ADMIN_API_URL}/api/v1/`;

const axiosBuilder = axios.create({
  baseURL: API_URL_BUILDER,
});
const axiosAccount = axios.create({
  baseURL: API_URL_ACCOUNT,
});
const axiosAdmin = axios.create({
  baseURL: APT_URL_ADMIN,
});
const axiosApiAdmin = axios.create({
  baseURL: API_ADMIN_URL,
});
const non_auth_routes = [LOG_OUT_USER];

const requestMiddleware = (req) => {
  if (!non_auth_routes.includes(req.url)) {
    const token = get("token");
    if (!!token)
      req.headers.authorization = token.startsWith("Bearer ")
        ? token
        : "Bearer " + token;
  }
  return req;
};

const responseMiddleware = (response) => {
  if (response?.data?.data?.token) {
    set("token", response.data.data.token);
    store.dispatch(setToken(response.data.data.token));
  }
  return response;
};

const responseErr = (error) => {
  if (error?.response?.status === 401) {
    // store.dispatch(resetReduxState());
    clear();
    store.dispatch(logout());
    return Promise.reject(error);
  } else {
    return Promise.reject(error);
  }
};

axiosBuilder.interceptors.request.use(requestMiddleware);
axiosAccount.interceptors.request.use(requestMiddleware);
axiosAdmin.interceptors.request.use(requestMiddleware);
axiosApiAdmin.interceptors.request.use(requestMiddleware);

axiosBuilder.interceptors.response.use(responseMiddleware, responseErr);
axiosAccount.interceptors.response.use(responseMiddleware, responseErr);
axiosAdmin.interceptors.response.use(responseMiddleware, responseErr);
axiosApiAdmin.interceptors.response.use(responseMiddleware, responseErr);

export { axiosBuilder, axiosAccount, axiosAdmin, axiosApiAdmin };
