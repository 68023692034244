import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {  useLocation } from "react-router-dom";
import queryString from "query-string";
import { imageUrl } from "services/aws";
import { handleFetchABElements } from "store/actions";
import CkEditor from "components/common/CustomCkEditor/CkEditor";

export const AppBuilderPrintBuilds = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { ABTeachingElements } = useSelector(
    (state) => state.AppBuilderTeaching
  );
  const { isAuth } = useSelector((state) => state.auth);
  const parsed = queryString.parse(location.search);

  const [elementChange, setOnchangeElement] = useState(false);
  const [isPrintElement, setIsPrintElement] = useState(false)

  useEffect(() => {
    if (isAuth) {
      dispatch(
        handleFetchABElements({
          series_build_list_id: parsed.seriesId,
          is_series: 0,
        }, (status) => {
          setIsPrintElement(true)
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (ABTeachingElements && isPrintElement == true) {
      props.print();
    }
  }, [ABTeachingElements, isPrintElement]);

  useEffect(() =>{
    let driftDiv = document.getElementById("drift-frame-controller")
    if(!!driftDiv){
      driftDiv.style.display = "none"
    }
    return () =>{
      if(!!driftDiv){
        driftDiv.style.display = "block"
      }
    }
  },[])

  const savePaperYes = `@page{
    margin-top: 5mm;
    margin-bottom: 5mm;
  `;

  return (
    <div className="withoutCkeditorTooltip col-12">
      {parsed.savepaper == "no" ? <style>{props.css}</style> : <style>{savePaperYes}</style>}
      <div className="" ref={ref}>
        <div className="main_card_wrapper tabbing-card app-builder-teaching print-view">
          <div className="message-form">
            <h2 id="message-heading" className="box-border" disabled>
              {ABTeachingElements.series_buildlist_title}
            </h2>
            <h4 id="message-sub-heading" className="message-sub-heading"></h4>
            <div className="box-border">
              <div className="flipbox-left image"></div>
              <div className="flipbox-right">
                <div className="flipbox-title">
                  <div className="etitle">
                    <b>Series Image</b>
                  </div>
                </div>
                <div
                  id="collapseTwo cust-img"
                  className="collapse show"
                  aria-labelledby="headingTwo"
                >
                  <center>
                    {ABTeachingElements.media_url ? (
                      <img
                        className="seriesImg"
                        src={ABTeachingElements.media_url.replace(imageUrl.S3BUILDER_URL, imageUrl.BUILDER_DISPLAY_URL)}
                        alt="series"
                      />
                    ) : (
                      <div className="nomedia">No Media Found</div>
                    )}
                  </center>
                </div>
              </div>
            </div>
            <div className="box-border">
              <div className="flipbox-left talk"></div>
              <div className="flipbox-right">
                <div
                  id="headingOne"
                  className=""
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <div className="flipbox-title"></div>
                </div>
                <div
                  id="collapseOne"
                  className=" collapse show"
                  aria-labelledby="headingOne"
                ></div>

                <div className="etitle hidden_title ">
                  <b>Series Description</b>
                  <CkEditor
                    data={ABTeachingElements.series_buildlist_content}
                    disabled
                    onChange={(data) => {
                      setOnchangeElement({ data });
                    }}
                  />
                </div>
              </div>
            </div>
            {!!ABTeachingElements.sycu_build_elements_details &&
              ABTeachingElements.sycu_build_elements_details.map((element, i) =>
                element.is_visible == 1 ? (
                  <div className="box-border MBdetail" key={i}>
                    <div
                      className={"flipbox-left image " + element.element_name}
                    ></div>
                    <div className={`flipbox-right border-left-bar ${element.element_name}`}>
                      <div
                        id="headingTwo"
                        className=""
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        <div className="flipbox-title">
                          <input
                            className="flipbox-heading mbTeachingTitle w-100"
                            disabled
                            type="text"
                            name=""
                            placeholder=""
                            value={element.title}
                            id=""
                            onChange={(e) => { }}
                          />
                        </div>
                      </div>
                      <div
                        id="collapseTwo"
                        className={
                          element.content
                            ? "collapse show mt10"
                            : "collapse mt10"
                        }
                        aria-labelledby="headingTwo"
                      >
                        <CkEditor
                          name={element.element_name}
                          data={element.content}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                ) : null
              )}
          </div>
        </div>
      </div>
    </div>
  );
});
export default AppBuilderPrintBuilds;
