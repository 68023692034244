import {
  FETCH_MB_ELEMENTS,
  FETCH_MB_ELEMENTS_SUCCESS,
  FETCH_MB_ELEMENTS_ERROR,
  MB_DUPLICATE_ELEMENT,
  MB_DUPLICATE_ELEMENT_SUCCESS,
  MB_DUPLICATE_ELEMENT_ERROR,
  MB_DELETE_ELEMENT,
  MB_DELETE_ELEMENT_SUCCESS,
  MB_DELETE_ELEMENT_ERROR,
  FETCH_MB_RESTORE_LIST,
  FETCH_MB_RESTORE_LIST_SUCCESS,
  FETCH_MB_RESTORE_LIST_ERROR,
  MB_SAVE_CUSTOM_RESTORE,
  MB_SAVE_CUSTOM_RESTORE_SUCCESS,
  MB_SAVE_CUSTOM_RESTORE_ERROR,
  SAVE_ON_CHANGE,
  SAVE_ON_CHANGE_SUCCESS,
  SAVE_ON_CHANGE_ERROR,
  SAVE_ON_ELEMENT_CHANGE,
  SAVE_ON_ELEMENT_CHANGE_SUCCESS,
  SAVE_ON_ELEMENT_CHANGE_ERROR,
  DRAG_BUILDING_BLOCK,
  DRAG_BUILDING_BLOCK_SUCCESS,
  DRAG_BUILDING_BLOCK_ERROR,
  MESSAGE_ELEMENT_DRAG_DROP,
  MESSAGE_ELEMENT_DRAG_DROP_SUCCESS,
  MESSAGE_ELEMENT_DRAG_DROP_ERROR,
  SET_MB_ELEMENTS,
  MB_DELETE_CUSTOM_RESTORE,
  MB_DELETE_CUSTOM_RESTORE_SUCCESS,
  MB_DELETE_CUSTOM_RESTORE_ERROR,
  MESSAGE_VIDEO_ELEMENT,
  MESSAGE_VIDEO_ELEMENT_SUCCESS,
  MESSAGE_VIDEO_ELEMENT_ERROR,
  HANDLE_MB_INDEX_SUCCESS,
  HANDLE_ELEMENT_INDEX_SUCCESS,
  SET_VISIBLE_UPDATE,
  SET_MESSAGE_BUILD_LENGTH,
  SET_MESSAGE_RESET_BUILD_DATA
} from "./actionTypes";

const MBElementsInitialState = {
  MBElements: [],
  MBRestoreList: [],
  saveLoading: false,
  duplicateLoading: false,
  deleteLoading: false,
  loading: false,
  MBBuildLength: 0
}

const MBElementReducer = (state = MBElementsInitialState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_MESSAGE_BUILD_LENGTH:
      return {
        ...state,
        MBBuildLength: action.payload
      }
    /** MB list */
    case FETCH_MB_ELEMENTS:
      return {
        ...state,
        loading: true,
      }
    case FETCH_MB_ELEMENTS_SUCCESS:
      return {
        ...state,
        MBElements: action.payload,
        loading: false
      }
    case FETCH_MB_ELEMENTS_ERROR:
      return {
        ...state,
        loading: false,
      }


    //MB ELEMENT DUPLICATE
    case MB_DUPLICATE_ELEMENT:
      return {
        ...state,
        duplicateLoading: true,
      }
    case MB_DUPLICATE_ELEMENT_SUCCESS:
      return {
        ...state,
        duplicateLoading: false,
      }
    case MB_DUPLICATE_ELEMENT_ERROR:
      return {
        ...state,
        duplicateLoading: true,
      }

    //MB delete element
    case MB_DELETE_ELEMENT:
      return {
        ...state,
        deleteLoading: true,
      }
    case MB_DELETE_ELEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case MB_DELETE_ELEMENT_ERROR:
      return {
        ...state,
        deleteLoading: true,
      }

    case FETCH_MB_RESTORE_LIST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_MB_RESTORE_LIST_SUCCESS:
      return {
        ...state,
        MBRestoreList: action.payload,
        loading: false,
      }
    case FETCH_MB_RESTORE_LIST_ERROR:
      return {
        ...state,
        loading: false,
      }


    //MB save on change
    case SAVE_ON_CHANGE:
      return {
        ...state,
        saveLoading: true,
      }
    case SAVE_ON_CHANGE_SUCCESS:
      return {
        ...state,
        MBElements: action.payload,
        saveLoading: false,
      }
    case SAVE_ON_CHANGE_ERROR:
      return {
        ...state,
        saveLoading: true,
      };

    case SET_MESSAGE_RESET_BUILD_DATA:
    return {
      ...state,
      MBElements: [],
    };

    //MB save on ELEMENT change
    case SAVE_ON_ELEMENT_CHANGE:
      return {
        ...state,
        saveLoading: true,
      }
    case SAVE_ON_ELEMENT_CHANGE_SUCCESS:
      return {
        ...state,
        saveLoading: false,
      }
    case SAVE_ON_ELEMENT_CHANGE_ERROR:
      return {
        ...state,
        saveLoading: false,
      }
    //building block drag drop
    case DRAG_BUILDING_BLOCK:
      return {
        ...state,
        loading: true,
      }
    case DRAG_BUILDING_BLOCK_SUCCESS:
      return {
        ...state,
        loading: true,
      }
    case DRAG_BUILDING_BLOCK_ERROR:
      return {
        ...state,
        loading: true,
      }

    //Message Builder save Custom Restore on change
    case MB_SAVE_CUSTOM_RESTORE:
      return {
        ...state,
        loading: true,
      }
    case MB_SAVE_CUSTOM_RESTORE_SUCCESS:
      return {
        ...state,
        loading: true,
      }
    case MB_SAVE_CUSTOM_RESTORE_ERROR:
      return {
        ...state,
        loading: true,
      }

    //message element drag drop
    case MESSAGE_ELEMENT_DRAG_DROP:
      return {
        ...state,
        loading: true,
      }
    case MESSAGE_ELEMENT_DRAG_DROP_SUCCESS:
      return {
        ...state,
        loading: true,
      }
    case MESSAGE_ELEMENT_DRAG_DROP_ERROR:
      return {
        ...state,
        loading: true,
      }

    //Message Builder delete Manual Restore Point
    case MB_DELETE_CUSTOM_RESTORE:
      return {
        ...state,
        loading: true,
      }
    case MB_DELETE_CUSTOM_RESTORE_SUCCESS:
      return {
        ...state,
        loading: true,
      }
    case MB_DELETE_CUSTOM_RESTORE_ERROR:
      return {
        ...state,
        loading: true,
      }
    //deleting element from redux
    case SET_MB_ELEMENTS:
      return {
        ...state,
        MBElements: {
          ...state.MBElements,
          ...(payload.elementId && payload.isDeleteSeries == 0 ? {
            sycu_build_elements_details: state.MBElements.sycu_build_elements_details.filter(item => item.build_elements_details_id !== payload.elementId)
          } : {})
        }
      }

    /** Message Builder Video Element */
    case MESSAGE_VIDEO_ELEMENT:
      return {
        ...state,
        loading: true,
      }
    case MESSAGE_VIDEO_ELEMENT_SUCCESS:
      return {
        ...state,
        loading: true,
      }
    case MESSAGE_VIDEO_ELEMENT_ERROR:
      return {
        ...state,
        loading: true,
      }

    case SET_VISIBLE_UPDATE:
      return {
        ...state,
        MBElements: {
          ...state.MBElements,
          ...state.MBElements.sycu_build_elements_details.filter(item => item.build_elements_details_id === payload.id ? item.is_visible = payload.is_visable : item)
        }
      }

    /**  Message Builder Placehpolder loading **/

    case HANDLE_MB_INDEX_SUCCESS:
      return {
        ...state,
        MBElements: {
          ...state.MBElements,
          ...({ sycu_build_elements_details: [...state.MBElements.sycu_build_elements_details, payload] })
        },
      }

    case HANDLE_ELEMENT_INDEX_SUCCESS: {
      const updatedArray = [...state.MBElements.sycu_build_elements_details]
      const swapPositions = (array, x, y) => {
        var tmp = array[x];
        array[x] = array[y];
        array[y] = tmp;
        return array
      }

      return {
        ...state,
        MBElements: {
          ...state.MBElements,
          sycu_build_elements_details: swapPositions(updatedArray, payload.destination.index, payload.source.index)
        }
      }
    }

    default:
      return state
  }
}

export default MBElementReducer;
