/** Message Builder list */
export const FETCH_MB_CUSTOM_BUILD_LIST = "FETCH_MB_CUSTOM_BUILD_LIST";
export const FETCH_MB_CUSTOM_BUILD_LIST_SUCCESS =
  "FETCH_MB_CUSTOM_BUILD_LIST_SUCCESS";
export const FETCH_MB_CUSTOM_BUILD_LIST_ERROR =
  "FETCH_MB_CUSTOM_BUILD_LIST_ERROR";

//Set MbCustom List PageNo
export const SET_PAGE_MB_CUSTOM_BUILD_LIST = "SET_PAGE_MB_CUSTOM_BUILD_LIST";

/** Message Builder DUPLICATE */
export const MB_DUPLICATE = "MB_DUPLICATE";
export const MB_DUPLICATE_SUCCESS = "MB_DUPLICATE_SUCCESS";
export const MB_DUPLICATE_ERROR = "MB_DUPLICATE_ERROR";

/** Message Builder DELETE */
export const MB_DELETE = "MB_DELETE";
export const MB_DELETE_SUCCESS = "MB_DELETE_SUCCESS";
export const MB_DELETE_ERROR = "MB_DELETE_ERROR";

/** Message Builder SHARE LINK */
export const MB_SHARE_LINK = "MB_SHARE_LINK";
export const MB_SHARE_LINK_SUCCESS = "MB_SHARE_LINK_SUCCESS";
export const MB_SHARE_LINK_ERROR = "MB_SHARE_LINK_ERROR";

/** Message Builder Upload Build Image **/
export const MB_UPLOAD_BUILD_IMAGE = "MB_UPLOAD_BUILD_IMAGE";
export const MB_UPLOAD_BUILD_IMAGE_SUCCESS = "MB_UPLOAD_BUILD_IMAGE_SUCCESS";
export const MB_UPLOAD_BUILD_IMAGE_ERROR = "MB_UPLOAD_BUILD_IMAGE_ERROR";

/** To update redux when Message is deleted */
export const SET_MB_LISTING = "SET_MB_LISTING";

/***********Library Listing */
export const SET_MB_LIBRARY = "SET_MB_LIBRARY";

export const RESET_MB_CUSTOM_BUILD_LIST = "RESET_MB_CUSTOM_BUILD_LIST";

export const SET_PAGE_MB_SORT = "SET_PAGE_MB_SORT";

export const SHARE_DASHBOARD_ACCESS = "SHARE_DASHBOARD_ACCESS";
