import {
  FETCH_BUILDING_BLOCKS_LISTING,
  FETCH_BUILDING_BLOCKS_LISTING_SUCCESS,
  FETCH_BUILDING_BLOCKS_LISTING_ERROR,
} from "./actionTypes";

const MBInitialState = {
  BuildingBlockListing: []
}

const MBBuildingBlocks = (state = MBInitialState, action) => {
  const { type, payload } = action
  switch (type) {
    /** MB building blocks list */
    case FETCH_BUILDING_BLOCKS_LISTING:
      return {
        ...state,
        loading: true,
      }
    case FETCH_BUILDING_BLOCKS_LISTING_SUCCESS:
      return {
        ...state,
        BuildingBlockListing: action.payload,
      }
    case FETCH_BUILDING_BLOCKS_LISTING_ERROR:
      return {
        ...state,
        loading: true,
      }
    default:
      return state
  }
}
export default MBBuildingBlocks
