import {
  GET_MB_FOLDER_LIST,
  GET_MB_FOLDER_LIST_SUCCESS,
  GET_MB_FOLDER_LIST_ERROR,
  MB_FOLDER_ADD_EDIT,
  MB_FOLDER_ADD_EDIT_SUCCESS,
  MB_FOLDER_ADD_EDIT_ERROR,
  MB_FOLDER_DELETE,
  MB_FOLDER_DELETE_SUCCESS,
  MB_FOLDER_DELETE_ERROR,
  GET_MB_FOLDER_BUILD_LIST,
  GET_MB_FOLDER_BUILD_LIST_SUCCESS,
  GET_MB_FOLDER_BUILD_LIST_ERROR,
  DRAG_BUILD_TO_FOLDER,
  DRAG_BUILD_TO_FOLDER_SUCCESS,
  DRAG_BUILD_TO_FOLDER_ERROR,
  GET_LIBRARY_FOLDER_BY_TYPE,
  GET_LIBRARY_FOLDER_BY_TYPE_SUCCESS,
  GET_LIBRARY_FOLDER_BY_TYPE_ERROR,
  SET_MB_LISTING_BY_FOLDER_ID,
  GET_LIBRARY_BUILD,
  GET_LIBRARY_BUILD_SUCCESS,
  GET_LIBRARY_BUILD_ERROR,
  SET_MB_LIBRARY,
  SET_MB_FOLDER_ID,
  SET_MB_FOLDER_TITLE,
  SET_MB_LIBRARY_FOLDER_ID,
  SET_MB_LIBRARY_FOLDER_TITLE,
  SET_PAGE_MB_FOLDER_BUILD_LIST,
  RESET_MB_FOLDER_BUILD_LIST
} from "./actionTypes";
import { axiosBuilder } from "services/api";
import { folderListingMB, addUpdateFolderMB, deleteFolderMB, getFolderBuildById, getLibraryFolderByKey, dragBuildToFolder, getLibraryBuildListing } from "constants/urls";
import { toast } from "react-toastify";
import { isEmptyArray } from "formik";

//MB Folder Listing
export const handleMBFolderListing = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(getMBFolderList(true));
      const res = await axiosBuilder.post(folderListingMB, payload);
      if (res.data.data) {
        dispatch(getMBFolderListSuccess(res.data.data.rows));
        toast.success(res.data?.message);
      }
    } catch (err) {
      dispatch(
        getMBFolderListError(err.response?.data?.message || err.message)
      );
    }
  };
};

//MB folder add/edit
export const handleAddEditMBFolder = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch(MBFolderAddEdit(true));
      const res = await axiosBuilder.post(addUpdateFolderMB, payload);

      if (res.data) {
        dispatch(MBFolderAddEditSuccess(res.data.data.data));
        cb(res.data)
        toast.success(res.data?.message);
      }
    } catch (err) {
      dispatch(
        MBFolderAddEditError(err.response?.data?.message || err.message)
      );
    }
  };
};

//MB folder Delete
export const handleDeleteMBFolder = (payload, cb = () => { }) => {
  return async (dispatch) => {
    try {
      dispatch(MBFolderDelete(true));
      const res = await axiosBuilder.post(deleteFolderMB, payload);
      if (res.data.data) {
        dispatch(MBFolderDeleteSuccess(res.data.data.data));
        dispatch(handleMBFolderListing());
        cb(res.data.data)
        toast.success(res.data?.message);
      }
    } catch (err) {
      dispatch(MBFolderDeleteError(err.response?.data?.message || err.message));
    }
  };
};

//Get build by folder id
export const handleGetFolderBuildById = (payload, cb = () => { }) => {
  return async (dispatch) => {
    try {
      dispatch(getMBFolderBuildList(true));
      const res = await axiosBuilder.post(getFolderBuildById, payload);
      if (res.data) {
        if (payload.page_no == 1 && payload.search !== "") {
          dispatch(resetMBFolderBuildList({}))
        }
        dispatch(getMBFolderBuildListSuccess(
          {
            data: !isEmptyArray(res.data.data.rows)
              ? res.data.data.rows[0].message_buildlists
              : res.data.data.rows, count: res.data.data.count
          }
        ))
      }
      cb(res.data.data.rows)
    } catch (err) {
      dispatch(getMBFolderBuildListError(err.response?.data?.message || err.message));
    }
  };
};

//Get libarary folder using type
export const handleGetLibraryFolderByType = (payload) => {
  return async (dispatch) => {
    try {
      const res = await axiosBuilder.get(getLibraryFolderByKey, payload);
      if (res.data) {
        dispatch(getLibraryFolderByTypeSuccess(res.data.data.rows));
      }
    } catch (err) {
      dispatch(
        getLibraryFolderByTypeError(err.response?.data?.message || err.message)
      );
    }
  };
};

//Get libarary build list
export const handleGetLibraryBuild = (payload, cb = () => { }) => {
  return async (dispatch) => {
    try {
      dispatch(getLibraryBuild())
      const res = await axiosBuilder.post(getLibraryBuildListing, payload);
      if (res.data.data) {
        dispatch(getLibraryBuildSuccess(res.data.data));
        cb(res.data)
      }
    } catch (err) {
      dispatch(
        getLibraryBuildError(err.response?.data?.message || err.message)
      );
      cb(err.response?.data)
    }
  };
};

//drag drop build to folder
export const handleDragDropOfBuild = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch(dragBuildToFOlder(true));
      const res = await axiosBuilder.post(dragBuildToFolder, payload);
      if (res.data.data) {
        dispatch(dragBuildToFOlderSuccess(res.data.data));
        cb(res.data.data)
        // if (payload.type === 1) {
        //   dispatch(handleMBFolderListing());
        // }
        toast.success(res.data?.message);
      }
    } catch (err) {
      dispatch(
        dragBuildToFOlderError(err.response?.data?.message || err.message)
      );
    }
  };
};

// update MB PageNo
export const setPageMBFolderBuildList = (data) => {
  return {
    type: SET_PAGE_MB_FOLDER_BUILD_LIST,
    payload: data,
  }
}

// reset MbFolder Build List
export const resetMBFolderBuildList = () => {
  return {
    type: RESET_MB_FOLDER_BUILD_LIST,
    payload: {}
  }
}

//MB Folder Listing
export const getMBFolderList = (data) => {
  return {
    type: GET_MB_FOLDER_LIST,
    payload: data,
  };
};
export const getMBFolderListSuccess = (data) => {
  return {
    type: GET_MB_FOLDER_LIST_SUCCESS,
    payload: data,
  };
};
export const getMBFolderListError = (data) => {
  return {
    type: GET_MB_FOLDER_LIST_ERROR,
    payload: data,
  };
};

//MB Folder aDD/EDIT
export const MBFolderAddEdit = (data) => {
  return {
    type: MB_FOLDER_ADD_EDIT,
    payload: data,
  };
};
export const MBFolderAddEditSuccess = (data) => {
  return {
    type: MB_FOLDER_ADD_EDIT_SUCCESS,
    payload: data,
  };
};
export const MBFolderAddEditError = (data) => {
  return {
    type: MB_FOLDER_ADD_EDIT_ERROR,
    payload: data,
  };
};

//MB Folder Delete
export const MBFolderDelete = (data) => {
  return {
    type: MB_FOLDER_DELETE,
    payload: data,
  };
};
export const MBFolderDeleteSuccess = (data) => {
  return {
    type: MB_FOLDER_DELETE_SUCCESS,
    payload: data,
  };
};
export const MBFolderDeleteError = (data) => {
  return {
    type: MB_FOLDER_DELETE_ERROR,
    payload: data,
  };
};

//Get MB FolderBuild using folder id
export const getMBFolderBuildList = (data) => {
  return {
    type: GET_MB_FOLDER_BUILD_LIST,
    payload: data,
  };
};

export const getMBFolderBuildListSuccess = (data) => {
  return {
    type: GET_MB_FOLDER_BUILD_LIST_SUCCESS,
    payload: data,
  };
};

export const getMBFolderBuildListError = (data) => {
  return {
    type: GET_MB_FOLDER_BUILD_LIST_ERROR,
    payload: data,
  };
};

//Get Library Folder using Type
export const getLibraryFolderByType = (data) => {
  return {
    type: GET_LIBRARY_FOLDER_BY_TYPE,
    payload: data,
  };
};

export const getLibraryFolderByTypeSuccess = (data) => {
  return {
    type: GET_LIBRARY_FOLDER_BY_TYPE_SUCCESS,
    payload: data,
  };
};

export const getLibraryFolderByTypeError = (data) => {
  return {
    type: GET_LIBRARY_FOLDER_BY_TYPE_ERROR,
    payload: data,
  };
};

//Get Library build
export const getLibraryBuild = () => {
  return {
    type: GET_LIBRARY_BUILD,
    loading: true
  };
};

export const getLibraryBuildSuccess = (data) => {
  return {
    type: GET_LIBRARY_BUILD_SUCCESS,
    loading: false,
    payload: data,
  };
};

export const getLibraryBuildError = (data) => {
  return {
    type: GET_LIBRARY_BUILD_ERROR,
    payload: data,
  };
};

//Drag drop build to folder
export const dragBuildToFOlder = (data) => {
  return {
    type: DRAG_BUILD_TO_FOLDER,
    payload: data,
  };
};

export const dragBuildToFOlderSuccess = (data) => {
  return {
    type: DRAG_BUILD_TO_FOLDER_SUCCESS,
    payload: data,
  };
};

export const dragBuildToFOlderError = (data) => {
  return {
    type: DRAG_BUILD_TO_FOLDER_ERROR,
    payload: data,
  };
};
/** To update redux when Message Build is deleted */
export const setMBListingByFolderId = (data) => {
  return {
    type: SET_MB_LISTING_BY_FOLDER_ID,
    payload: data,
  };
};

export const setMBLibrary = (data) => {
  return {
    type: SET_MB_LIBRARY,
    payload: data,
  };
};

/*Store Folder ID */
export const setMBFolderId = (data) => {
  return {
    type: SET_MB_FOLDER_ID,
    payload: data,
  };
};

/*Store Folder TITLE */
export const setMBFolderTitle = (data) => {
  return {
    type: SET_MB_FOLDER_TITLE,
    payload: data,
  };
};

/*Store Library Folder ID */
export const setMBLibraryFolderId = (data) => {
  return {
    type: SET_MB_LIBRARY_FOLDER_ID,
    payload: data,
  };
};

/*Store Library Folder TITLE */
export const setMBLibraryFolderTitle = (data) => {
  return {
    type: SET_MB_LIBRARY_FOLDER_TITLE,
    payload: data,
  };
};

