/** Message Builder list */
export const FETCH_MB_ELEMENTS = "FETCH_MB_ELEMENTS"
export const FETCH_MB_ELEMENTS_SUCCESS = "FETCH_MB_ELEMENTS_SUCCESS"
export const FETCH_MB_ELEMENTS_ERROR = "FETCH_MB_ELEMENTS_ERROR"

/** Message Builder DUPLICATE */
export const MB_DUPLICATE_ELEMENT = "MB_DUPLICATE_ELEMENT"
export const MB_DUPLICATE_ELEMENT_SUCCESS = "MB_DUPLICATE_ELEMENT_SUCCESS"
export const MB_DUPLICATE_ELEMENT_ERROR = "MB_DUPLICATE_ELEMENT_ERROR"

/** Message Builder DELETE */
export const MB_DELETE_ELEMENT = "MB_DELETE_ELEMENT"
export const MB_DELETE_ELEMENT_SUCCESS = "MB_DELETE_ELEMENT_SUCCESS"
export const MB_DELETE_ELEMENT_ERROR = "MB_DELETE_ELEMENT_ERROR"

/** Message Builder SHARE LINK */
export const MB_SHARE_LINK = "MB_SHARE_LINK"
export const MB_SHARE_LINK_SUCCESS = "MB_SHARE_LINK_SUCCESS"
export const MB_SHARE_LINK_ERROR = "MB_SHARE_LINK_ERROR"

/** Message Builder RESTOREList */
export const FETCH_MB_RESTORE_LIST = "FETCH_MB_RESTORE_LIST"
export const FETCH_MB_RESTORE_LIST_SUCCESS = "FETCH_MB_RESTORE_LIST_SUCCESS"
export const FETCH_MB_RESTORE_LIST_ERROR = "FETCH_MB_RESTORE_LIST_ERROR"


/** Message Builder save on change */
export const SAVE_ON_CHANGE = "SAVE_ON_CHANGE"
export const SAVE_ON_CHANGE_SUCCESS = "SAVE_ON_CHANGE_SUCCESS"
export const SAVE_ON_CHANGE_ERROR = "SAVE_ON_CHANGE_ERROR"

/** Message Builder save on Element change */
export const SAVE_ON_ELEMENT_CHANGE = "SAVE_ON_ELEMENT_CHANGE"
export const SAVE_ON_ELEMENT_CHANGE_SUCCESS = "SAVE_ON_ELEMENT_CHANGE_SUCCESS"
export const SAVE_ON_ELEMENT_CHANGE_ERROR = "SAVE_ON_ELEMENT_CHANGE_ERROR"

/** On Building Block Drag */
export const DRAG_BUILDING_BLOCK = "DRAG_BUILDING_BLOCK"
export const DRAG_BUILDING_BLOCK_SUCCESS = "DRAG_BUILDING_BLOCK_SUCCESS"
export const DRAG_BUILDING_BLOCK_ERROR = "DRAG_BUILDING_BLOCK_ERROR"

/** On Message element Drag */
export const MESSAGE_ELEMENT_DRAG_DROP = "MESSAGE_ELEMENT_DRAG_DROP"
export const MESSAGE_ELEMENT_DRAG_DROP_SUCCESS = "MESSAGE_ELEMENT_DRAG_DROP_SUCCESS"
export const MESSAGE_ELEMENT_DRAG_DROP_ERROR = "MESSAGE_ELEMENT_DRAG_DROP_ERROR"

/** Message Builder save Custom Restore on change */
export const MB_SAVE_CUSTOM_RESTORE = "MB_SAVE_CUSTOM_RESTORE"
export const MB_SAVE_CUSTOM_RESTORE_SUCCESS = "MB_SAVE_CUSTOM_RESTORE_SUCCESS"
export const MB_SAVE_CUSTOM_RESTORE_ERROR = "MB_SAVE_CUSTOM_RESTORE_ERROR"

export const SET_MB_ELEMENTS = "SET_MB_ELEMENTS";
export const SET_MESSAGE_RESET_BUILD_DATA = "SET_MESSAGE_RESET_BUILD_DATA";

/** Message Builder delete Manual Restore Point  */
export const MB_DELETE_CUSTOM_RESTORE = "MB_DELETE_CUSTOM_RESTORE"
export const MB_DELETE_CUSTOM_RESTORE_SUCCESS = "MB_DELETE_CUSTOM_RESTORE_SUCCESS"
export const MB_DELETE_CUSTOM_RESTORE_ERROR = "MB_DELETE_CUSTOM_RESTORE_ERROR"

/** Message Builder Video Element */
export const MESSAGE_VIDEO_ELEMENT = "MESSAGE_VIDEO_ELEMENT"
export const MESSAGE_VIDEO_ELEMENT_SUCCESS = "MESSAGE_VIDEO_ELEMENT_SUCCESS"
export const MESSAGE_VIDEO_ELEMENT_ERROR = "MESSAGE_VIDEO_ELEMENT_ERROR"

// Handle Drag and Drop Index

export const HANDLE_MB_INDEX_SUCCESS = "HANDLE_MB_INDEX_SUCCESS"

// MBElements Sorting By Index

export const HANDLE_ELEMENT_INDEX_SUCCESS = "HANDLE_ELEMENT_INDEX_SUCCESS"
export const SET_VISIBLE_UPDATE = "SET_VISIBLE_UPDATE"

export const SET_MESSAGE_BUILD_LENGTH = "SET_MESSAGE_BUILD_LENGTH"
