import {
  FETCH_MB_EXPORT_ELEMENTS,
  FETCH_MB_EXPORT_ELEMENTS_SUCCESS,
  FETCH_MB_EXPORT_ELEMENTS_ERROR,
  SET_MB_EXPORT_LOADING,
} from "./actionTypes";
import axios from "axios";
import { axiosBuilder } from "services/api";
import { getMBExportElements } from "constants/urls";
import fileDownload from 'js-file-download'

//Message Builder Export Elements
export const handleFetchMBExportElements = (
  payload,
) => {
  return async (dispatch) => {
    try {
      dispatch(fetchMBElements(true));
      dispatch(setLoading(true));
      const res = await axiosBuilder.post(getMBExportElements, payload);
      if (res.data.data) {
        const response = await axios({
          url: res.data.data.url,
          method: "GET",
          responseType: "blob",
        });
        fileDownload(response.data, "Message_Builder_"+(new Date()/1000)+".pdf")
        dispatch(fetchMBElementsSuccess(res.data.data))
      }
    } catch (err) {
      dispatch(fetchMBElementsError(err.response?.data?.message || err.message))
      dispatch(setLoading(false));
    }
  };
};

//Message Builder Export Elements Listing
export const fetchMBElements = (data) => {
  return {
    type: FETCH_MB_EXPORT_ELEMENTS,
    payload: data
  }
}
export const fetchMBElementsSuccess = (data) => {
  return {
    type: FETCH_MB_EXPORT_ELEMENTS_SUCCESS,
    payload: data
  }
}

export const fetchMBElementsError = (data) => {
  return {
    type: FETCH_MB_EXPORT_ELEMENTS_ERROR,
    payload: data
  }
}

export const setLoading = (data) => ({
  type: FETCH_MB_EXPORT_ELEMENTS,
  payload: data,  
});

export const setMBExportLoading = (data) => ({
  type: SET_MB_EXPORT_LOADING,
  payload: data, 
})
