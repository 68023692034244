import {
  FETCH_MB_TEACHING_ELEMENTS,
  FETCH_MB_TEACHING_ELEMENTS_SUCCESS,
  FETCH_MB_TEACHING_ELEMENTS_ERROR,
  FETCH_MB_TEACHING_ELEMENTS_BY_CODE,
  FETCH_MB_TEACHING_ELEMENTS_BY_CODE_SUCCESS,
  FETCH_MB_TEACHING_ELEMENTS_BY_CODE_ERROR
} from "./actionTypes";

import { axiosBuilder } from "services/api";
import {
  getMBTeachingElements,
  getMBTeachingUsingCodeElements,
} from "constants/urls";
import { toast } from "react-toastify";

//Message Builder Teaching Elements
export const handleFetchMBTeachingElements = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch(fetchMBElements(true));
      const res = await axiosBuilder.post(getMBTeachingElements, payload);
      if (res.data.data) {
        dispatch(fetchMBElementsSuccess(res.data.data));
        cb(res.data.data);
        toast.success(res.data?.message);
      }
    } catch (err) {
      dispatch(
        fetchMBElementsError(err.response?.data?.message || err.message)
      );
    }
  };
};

//Message Builder Get data using code
export const handleFetchMBCodeElements = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch(fetchMBTechingElementsByCode(true));
      const res = await axiosBuilder.post(
        getMBTeachingUsingCodeElements,
        payload
      );
      if (res.data.data) {
        dispatch(fetchMBTechingElementsByCodeSuccess(res.data.data));
        cb(res.data.data);
        toast.success(res.data?.message);
      }
    } catch (err) {
      dispatch(
        fetchMBTechingElementsByCodeError(err.response?.data?.message || err.message)
      );
    }
  };
};

//Message Builder Teaching Elements Listing
export const fetchMBElements = (data) => {
  return {
    type: FETCH_MB_TEACHING_ELEMENTS,
    payload: data,
  };
};
export const fetchMBElementsSuccess = (data) => {
  return {
    type: FETCH_MB_TEACHING_ELEMENTS_SUCCESS,
    payload: data,
  };
};
export const fetchMBElementsError = (data) => {
  return {
    type: FETCH_MB_TEACHING_ELEMENTS_ERROR,
    payload: data,
  };
};

//Message Builder Teaching Elements Listing By Code
export const fetchMBTechingElementsByCode = (data) => {
  return {
    type: FETCH_MB_TEACHING_ELEMENTS_BY_CODE,
    payload: data
  }
}

export const fetchMBTechingElementsByCodeSuccess = (data) => {
  return {
    type: FETCH_MB_TEACHING_ELEMENTS_BY_CODE_SUCCESS,
    payload: data
  }
}

export const fetchMBTechingElementsByCodeError = (data) => {
  return {
    type: FETCH_MB_TEACHING_ELEMENTS_BY_CODE_ERROR,
    payload: data
  }
}

