/** Message Builder folder list */
export const GET_MB_FOLDER_LIST = "GET_MB_FOLDER_LIST"
export const GET_MB_FOLDER_LIST_SUCCESS = "GET_MB_FOLDER_LIST_SUCCESS"
export const GET_MB_FOLDER_LIST_ERROR = "GET_MB_FOLDER_LIST_ERROR"

/** Message Builder folder add/edit */
export const MB_FOLDER_ADD_EDIT = "MB_FOLDER_ADD_EDIT"
export const MB_FOLDER_ADD_EDIT_SUCCESS = "MB_FOLDER_ADD_EDIT_SUCCESS"
export const MB_FOLDER_ADD_EDIT_ERROR = "MB_FOLDER_ADD_EDIT_ERROR"

/** Message Builder folder delete*/
export const MB_FOLDER_DELETE = "MB_FOLDER_DELETE"
export const MB_FOLDER_DELETE_SUCCESS = "MB_FOLDER_DELETE_SUCCESS"
export const MB_FOLDER_DELETE_ERROR = "MB_FOLDER_DELETE_ERROR"

/** Get MB USING FOLDER ID*/
export const GET_MB_FOLDER_BUILD_LIST = "GET_MB_FOLDER_BUILD_LIST"
export const GET_MB_FOLDER_BUILD_LIST_SUCCESS = "GET_MB_FOLDER_BUILD_LIST_SUCCESS"
export const GET_MB_FOLDER_BUILD_LIST_ERROR = "GET_MB_FOLDER_BUILD_LIST_ERROR"

/** Get Library Folder using type*/
export const GET_LIBRARY_FOLDER_BY_TYPE = "GET_LIBRARY_FOLDER_BY_TYPE"
export const GET_LIBRARY_FOLDER_BY_TYPE_SUCCESS = "GET_LIBRARY_FOLDER_BY_TYPE_SUCCESS"
export const GET_LIBRARY_FOLDER_BY_TYPE_ERROR = "GET_LIBRARY_FOLDER_BY_TYPE_ERROR"

/** Drag MB to folder */
export const DRAG_BUILD_TO_FOLDER = "DRAG_BUILD_TO_FOLDER"
export const DRAG_BUILD_TO_FOLDER_SUCCESS = "DRAG_BUILD_TO_FOLDER_SUCCESS"
export const DRAG_BUILD_TO_FOLDER_ERROR = "DRAG_BUILD_TO_FOLDER_ERROR"

/** To update redux when Message is deleted */
export const SET_MB_LISTING_BY_FOLDER_ID = "SET_MB_LISTING_BY_FOLDER_ID"

/** Get Library Build */
export const GET_LIBRARY_BUILD = "GET_LIBRARY_BUILD"
export const GET_LIBRARY_BUILD_SUCCESS = "GET_LIBRARY_BUILD_SUCCESS"
export const GET_LIBRARY_BUILD_ERROR = "GET_LIBRARY_BUILD_ERROR"

/***********Library Listing */
export const SET_MB_LIBRARY = "SET_MB_LIBRARY"

/*Store Folder ID */
export const SET_MB_FOLDER_ID = "SET_MB_FOLDER_ID"

/*Store Folder TITLE */
export const SET_MB_FOLDER_TITLE = "SET_MB_FOLDER_TITLE"


/*Store Library Folder ID */
export const SET_MB_LIBRARY_FOLDER_ID = "SET_MB_LIBRARY_FOLDER_ID"

/*Store Library Folder TITLE */
export const SET_MB_LIBRARY_FOLDER_TITLE = "SET_MB_LIBRARY_FOLDER_TITLE"

/* store pageNo for Mbfolder */
export const SET_PAGE_MB_FOLDER_BUILD_LIST = "SET_PAGE_MB_FOLDER_BUILD_LIST"

/* Reset Mbfolder build list */
export const RESET_MB_FOLDER_BUILD_LIST = "RESET_MB_FOLDER_BUILD_LIST"
