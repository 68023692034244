import {
  FETCH_AB_CUSTOM_BUILD_LIST,
  FETCH_AB_CUSTOM_BUILD_LIST_SUCCESS,
  FETCH_AB_CUSTOM_BUILD_LIST_ERROR,
  SET_PAGE_AB_CUSTOM_BUILD_LIST,
  AB_DUPLICATE,
  AB_DUPLICATE_SUCCESS,
  AB_DUPLICATE_ERROR,
  AB_SHARE_LINK,
  AB_SHARE_LINK_SUCCESS,
  AB_SHARE_LINK_ERROR,
  AB_DELETE,
  AB_DELETE_SUCCESS,
  AB_DELETE_ERROR,
  AB_CREATE_VOLUME,
  AB_CREATE_VOLUME_SUCCESS,
  AB_CREATE_VOLUME_ERROR,
  SET_AB_VOLUME,
  GET_LIBRARY_BUILD,
  GET_LIBRARY_BUILD_SUCCESS,
  GET_LIBRARY_BUILD_ERROR,
  SET_AB_LIBRARY,
  RESET_AB_CUSTOM_BUILD_LIST
} from "./actionTypes"
import { axiosBuilder } from "services/api";
import { toast } from 'react-toastify';
import { AppBuilderListing, deleteAB, ABCreateDuplicate, shareLinkAB, createABVolume, getAppLibraryBuildListing, createDuplicateAdminVolume } from "constants/urls";

//AB Listing
export const handleFetchABListing = (payload, cb = () => { }) => {
  return async (dispatch) => {
    try {
      dispatch(fetchABCustomBuildList(true));
      const res = await axiosBuilder.post(AppBuilderListing, payload);
      if (res.data.data.rows) {
        if (payload.page_no == 1 && payload.search !== "") {
          dispatch(resetABCustomBuildList({}))
        }
        dispatch(fetchABCustomBuildListSuccess({ data: res.data.data.rows, count: res.data.data.count }))
        cb(res.data.data.rows)
        toast.success(res.data?.message);
      }
    } catch (err) {
      dispatch(fetchABCustomBuildListError(err.response?.data?.message || err.message))
    }
  };
};
//Create volume
export const CreateVolumeAB = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(ABCreateVolume(true))
      const res = await axiosBuilder.post(createABVolume, payload)
      if (res.data) {
        dispatch(ABCreateVolumeSuccess(res.data.data.data))

      }
    } catch (err) {

      dispatch(ABCreateVolumeError(err.response?.data?.message || err.message))
    }
  }
}
//AB Delete
export const handleABDelete = (
  payload, cb = () => { }
) => {
  return async (dispatch) => {
    try {
      dispatch(ABDelete(true))
      const res = await axiosBuilder.post(deleteAB, payload);
      if (res.data.data) {
        dispatch(ABDeleteSuccess(res.data.data))
        cb(res.data.data)
        toast.success(res.data.data?.message);
      }
    } catch (err) {
      dispatch(ABDeleteError(err.response?.data?.message || err.message))
    }
  };
};
//AB Duplicate
export const handleABDuplicate = (
  payload, cb
) => {
  return async (dispatch) => {
    try {
      dispatch(ABDuplicate(true));
      const res = await axiosBuilder.post(ABCreateDuplicate, payload);
      if (res.data) {
        dispatch(ABDuplicateSuccess({ data: res.data.data }))
        cb(res.data.data.volume_id)
        toast.success(res.data?.message);
      }
    } catch (err) {

      dispatch(ABDuplicateError(err.response?.data?.message || err.message))
    }
  };
};

//AB Library Duplicate
export const handleABLibraryDuplicate = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch(ABDuplicate(true));
      const res = await axiosBuilder.post(createDuplicateAdminVolume, payload);
      if (res.data.data) {
        dispatch(ABDuplicateSuccess(res.data.data))
        cb(res.data.data.volume_id)
        toast.success(res.data?.message);
      }
    } catch (err) {
      dispatch(ABDuplicateError(err.response?.data?.message || err.message))
    }
  }
}


//Get App libarary build list
export const handleGetAppLibraryBuild = (
  payload
) => {
  return async (dispatch) => {
    try {
      dispatch(getLibraryBuild(true))
      const res = await axiosBuilder.post(getAppLibraryBuildListing, payload)
      if (res.data.data) {
        dispatch(getLibraryBuildSuccess(res.data.data))
      }
    } catch (err) {
      dispatch(getLibraryBuildError(err.response?.data?.message || err.message))
    }
  };
};

//AB Share link
export const handleABShareLink = (
  payload,
) => {
  return async (dispatch) => {
    try {
      dispatch(ABShareLink(true));
      const res = await axiosBuilder.post(shareLinkAB, payload);
      if (res.data.data) {
        dispatch(ABShareLinkSuccess(res.data.data))
        toast.success(res.data?.message);
      }
    } catch (err) {

      dispatch(ABShareLinkError(err.response?.data?.message || err.message))
    }
  };
};

// Reset Ab Custom Build List //
export const resetABCustomBuildList = () => {
  return {
    type: RESET_AB_CUSTOM_BUILD_LIST,
    payload: {},
  };
};

//AB Listing
export const fetchABCustomBuildList = (data) => {
  return {
    type: FETCH_AB_CUSTOM_BUILD_LIST,
    payload: data
  }
}
export const fetchABCustomBuildListSuccess = (data) => {
  return {
    type: FETCH_AB_CUSTOM_BUILD_LIST_SUCCESS,
    payload: data
  }
}
export const fetchABCustomBuildListError = (data) => {
  return {
    type: FETCH_AB_CUSTOM_BUILD_LIST_ERROR,
    payload: data
  }
}

//AB Create Volume
export const ABCreateVolume = (data) => {
  return {
    type: AB_CREATE_VOLUME,
    payload: data,
  }
}
export const ABCreateVolumeSuccess = (data) => {
  return {
    type: AB_CREATE_VOLUME_SUCCESS,
    payload: data,
  }
}
export const ABCreateVolumeError = (data) => {
  return {
    type: AB_CREATE_VOLUME_ERROR,
    payload: data,
  }
}

//AB Delete
export const ABDelete = (data) => {
  return {
    type: AB_DELETE,
    payload: data
  }
}
export const ABDeleteSuccess = (data) => {
  return {
    type: AB_DELETE_SUCCESS,
    payload: data
  }
}
export const ABDeleteError = (data) => {
  return {
    type: AB_DELETE_ERROR,
    payload: data
  }
}

//AB duplicate
export const ABDuplicate = (data) => {
  return {
    type: AB_DUPLICATE,
    payload: data
  }
}
export const ABDuplicateSuccess = (data) => {
  return {
    type: AB_DUPLICATE_SUCCESS,
    payload: data
  }
}
export const ABDuplicateError = (data) => {
  return {
    type: AB_DUPLICATE_ERROR,
    payload: data
  }
}

// Set PageNo For AB Custom Build List
export const setPageABCustomBuildList = data => ({
  type: SET_PAGE_AB_CUSTOM_BUILD_LIST,
  payload: data,
})


//AB Share Link
export const ABShareLink = (data) => {
  return {
    type: AB_SHARE_LINK,
    payload: data
  }
}
export const ABShareLinkSuccess = (data) => {
  return {
    type: AB_SHARE_LINK_SUCCESS,
    payload: data
  }
}
export const ABShareLinkError = (data) => {
  return {
    type: AB_SHARE_LINK_ERROR,
    payload: data
  }
}
/** To update redux when App Volume is deleted from User Builds*/
export const setABVolume = (data) => {
  return {
    type: SET_AB_VOLUME,
    payload: data
  }
}

export const setABLibrary = (data) => {
  return {
    type: SET_AB_LIBRARY,
    payload: data
  }
}

//Get Library build
export const getLibraryBuild = () => {
  return {
    type: GET_LIBRARY_BUILD,
    loading: true
  }
}

export const getLibraryBuildSuccess = (data) => {
  return {
    type: GET_LIBRARY_BUILD_SUCCESS,
    loading: false,
    payload: data
  }
}

export const getLibraryBuildError = (data) => {
  return {
    type: GET_LIBRARY_BUILD_ERROR,
    payload: data
  }
}
