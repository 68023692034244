import React, { useEffect, useState, useRef, useCallback } from "react";
import { v4 as uuidV4 } from "uuid";
import { handleSeriesDetailDuplicate, setPageABCustomBuildList, setPageABFolderBuildList } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useLocation } from "react-router-dom";
import { Modal } from "reactstrap";
import { toast } from "react-toastify";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import { handleSeriesDetailDelete, handleABShareLink, handleFetchSeriesDetail, handleFetchSeriesElement, handleAddSeries, handleSaveSeriesDetail, handleSeriesDragDrop } from "store/actions";
import { setABSeries } from "../../store/appSeries/actions";
import addimage from "assets/images/icon/add-card.svg";
import dotImage from "assets/images/icon/white-dot.svg";
import modalClose from "assets/images/icon/modal-close.svg";
import deleteAlert from "assets/images/icon/red-info.svg";
import threeDots from 'assets/images/icon/3-black-dot.svg';
import queryString from "query-string";
import BBLoading from "components/common/Loader/BBLoading";

//for add button
const defaultValue = () => ({
  id: uuidV4(),
  description: "",
  title: "",
});
//for colors of series
const colors = {
  1: "bg-red",
  2: "bg-blue",
  3: "bg-green",
  0: "bg-yellow",
};
// for border of series
const border = {
  1: "bg-red-border",
  2: "bg-blue-border",
  3: "bg-green-border",
  0: "bg-yellow-border",
};
// for title of series
const title = {
  1: "bg-red-title",
  2: "bg-blue-title",
  3: "bg-green-title",
  0: "bg-yellow-title",
};

export default function Series({ selectedSeriesId, seriesId, seriesSectionLoading, isChangeHeadingTitle, isMakeCopy, handleSeriesDisable, isSeriesDisable }) {
  const { shareABLink } = useSelector((state) => state.AppBuilderListing);
  const { SeriesDetail, loading, addSeriesImport, addDuplicateSeriesLoading } = useSelector((state) => state.SeriesDetail);
  const { StoreAppFolderId } = useSelector((state) => state.AppBuilderFolderListing);
  const [add, setSeriesAdd] = useState([{ ...defaultValue() }]);
  const [share, setshare] = useState(false);
  const [disabledTasks, setDispabledTasks] = useState([]);
  const [isActive, setActive] = useState(0);
  const [seriesID, setSeriesID] = useState();
  const [elementChange, setOnchangeElement] = useState("");
  const [isHide, setIsHide] = useState({ id: "", isvisible: true });
  const [dropDown, setDropDown] = useState(false);
  const [seriesActionId, setSeriesActionId] = useState(0);
  const [isParsedSeriesId, setIsParsedSeriesId] = useState("");
  const [selectedSeries, setSelectedSeries] = useState(seriesId);
  const [onDragSeries, setOnDragSeries] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [alert, setAlert] = useState(false);
  const [isVisibility, setIsVisibility] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [volumeTitle, setVolumeTitle] = useState(false);
  const [isAddSeries, setIsAddSeries] = useState(false);
  const [isDelete, setIsDelete] = useState(false)
  const [isVolumeBuildTitle, setIsVolumeBuildTitle] = useState(false);
  const [seriesVisibility, setSeriesVisibility] = useState(false)

  const dispatch = useDispatch();
  const params = useParams();
  const ref = useRef();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const parsedSeriesId = parsed.seriesId;
  const parsedIntSeriesId = parseInt(parsedSeriesId);
  const { isAuth } = useSelector((state) => state.auth);

  useEffect(() => {
    setIsParsedSeriesId(parsedIntSeriesId);
    setOnDragSeries(SeriesDetail.gb_series_buildlists);
  }, [parsedIntSeriesId, SeriesDetail.gb_series_buildlists]);

  //For Series Listing
  useEffect(() => {
    if (isAuth) {
      dispatch(
        handleFetchSeriesDetail({
          volume_id: parseInt(params.id),
        })
      );
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    function myFunction() {
      var sticky = ref.current?.offsetTop;
      if (window.pageYOffset > sticky) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    }
    window.onscroll = function () {
      myFunction();
    };
  }, []);

  //For Series Volume Title
  useEffect(() => {
    if (isChangeHeadingTitle === false && isAddSeries === false && !isVolumeBuildTitle || isMakeCopy && isAddSeries === false) {
      if (SeriesDetail.volume_title) {
        setOnchangeElement(SeriesDetail.volume_title);
      }
      if (
        SeriesDetail?.gb_series_buildlists &&
        SeriesDetail.gb_series_buildlists.length
      ) {
        let id = SeriesDetail.gb_series_buildlists[0].series_build_list_id;
        let handleSeriesId = parseInt(
          localStorage.getItem("handleSeriesIdWhenDuplicate")
        );
        let id1 = localStorage.getItem("seriesId");
        let newId = JSON.parse(id1);
        if (newId) {
          //Go back to Detail from Teaching view series selected
          localStorage.removeItem("handleSeriesIdWhenDuplicate");
          setSelectedSeries(false);
          setActive(newId);
          selectedSeriesId(newId);
          localStorage.removeItem("seriesId");
        } else if (selectedSeries || isMakeCopy) {
          //Series Selected and Make A Copy
          if (isVisibility == false || isMakeCopy && isChangeHeadingTitle !== true) {
            selectedSeriesId(id);
            if (isMakeCopy && isSeriesDisable === false) {
              //After Make A Copy Default Series Selected
              setActive(id);
            }
          }
          if (parsed.seriesId) {
            // If SERIES id in URL
            setActive(parsedIntSeriesId);
          } else {
            if (handleSeriesId) {
              //handle SeriesId When Duplicate
              if (isActive === handleSeriesId) {
                setActive(handleSeriesId);
              } else {
                localStorage.removeItem("handleSeriesIdWhenDuplicate");
                setActive(id);
              }
            } else {
              if (isVisibility == false) {
                setActive(id);
              }
            }
          }
        } else {
          selectedSeriesId(seriesId);
        }
      }
    } else {
      //After Add series Make a copy  series listing First series selected
      if (isMakeCopy && isAddSeries === true && SeriesDetail?.gb_series_buildlists &&
        SeriesDetail.gb_series_buildlists.length) {
        let id2 = SeriesDetail.gb_series_buildlists[0].series_build_list_id;
        setActive(id2);
      }
    }
  }, [loading]);

  //for add series
  const addSeries = () => {
    setIsAddSeries(true)
    dispatch(
      handleAddSeries({
        volume_id: params.id,
      })
    );
  };

  //series delete
  const handleSeriesRemove = (series_build_list_id) => {
    setSeriesAdd((prev) =>
      prev.filter(
        (series) => series.series_build_list_id !== series_build_list_id
      )
    );
    setSelectedRows([series_build_list_id]);
    setAlert(true);
  };

  //for series Click
  const SeriesClick = (series_build_list_id) => {
    setSeriesAdd((prev) =>
      prev.filter(
        (series) => series.series_build_list_id !== series_build_list_id
      )
    );
    setActive(series_build_list_id);
    setSeriesID(series_build_list_id);
    selectedSeriesId(series_build_list_id);
  };

  //Duplicate Series
  const duplicateSeries = (series_build_list_id) => {
    localStorage.setItem("handleSeriesIdWhenDuplicate", isActive);
    setDropDown(false);
    dispatch(
      handleSeriesDetailDuplicate({
        series_build_list_id: series_build_list_id,
      })
    );
  };

  //for visibility of series
  const handleSeriesVisibility = (id) => {
    setIsVisibility(true)
    handleSeriesDisable(true)
    if (id.is_visible === 1) {
      setSeriesVisibility(true)
      setDispabledTasks((prevState) =>
        prevState.filter((series) => series !== id)
      );
      dispatch(
        handleSeriesDetailDelete({
          series_build_list_id: id.series_build_list_id,
          is_visible: 0,
          volume_id: params.id,
        }, (status) => {
          if (status) {
            dispatch(
              handleFetchSeriesDetail({
                volume_id: params.id,
              }, (status) => {
                if (status) {
                  setSeriesVisibility(false)
                }
              })
            );
          }
        })
      );
    } else {
      setIsHide({ id: id.series_build_list_id, isvisible: true });
      setSeriesVisibility(true)
      dispatch(
        handleSeriesDetailDelete({
          series_build_list_id: id.series_build_list_id,
          is_visible: 1,
          volume_id: params.id,
        }, (status) => {
          if (status) {
            dispatch(
              handleFetchSeriesDetail({
                volume_id: params.id,
              }, (status) => {
                if (status) {
                  setSeriesVisibility(false)
                }
              })
            );
          }
        })
      );
    }
  };

  //For sharing
  const onShare = (shareLinkId) => {
    setshare(true);
    dispatch(
      handleABShareLink({
        build_share_code_id: shareLinkId,
      })
    );
  };

  // Share Series link copy
  const handleSubmitShareLink = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(e.target[0].value);
    toast.success("Link Copied SuccessFully");
    setshare(false);
  };
  // close shareLink Modal
  const handleCloseShareLinkAB = () => {
    setshare(false);
  };

  //Save volume title on focus out
  const handleSaveElementTitle = (e) => {
    if (StoreAppFolderId !== 0) {
      dispatch(setPageABFolderBuildList(1))
    } else {
      dispatch(setPageABCustomBuildList(1))
    }
    const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');
    const volumeTitle = removeExtraSpace(elementChange);
    if (volumeTitle !== "") {
      setVolumeTitle(false);
      setIsVolumeBuildTitle(true)
      dispatch(
        handleSaveSeriesDetail(
          {
            volume_id: params.id,
            volume_title: volumeTitle,
          },
          (data) => {
            setOnchangeElement(data.volume_title);
          }
        )
      );
    } else {
      setVolumeTitle(true);
    }
  };

  //save series sort order
  const onDragEnd = (onDragEnd, result) => {
    if (onDragEnd.destination != null) {
      let seriesId = onDragEnd.draggableId;
      let destination = onDragEnd.destination.index;
      let source = onDragEnd.source.index;
      if (onDragEnd.source.droppableId === onDragEnd.destination.droppableId) {
        const reorder = (list, x, y) => {
          const result = list;
          const [removed] = result.splice(x, 1);
          result.splice(y, 0, removed);
          return result;
        };
        const items = reorder(
          onDragSeries,
          onDragEnd.source.index,
          onDragEnd.destination.index
        );
        setOnDragSeries(items);
        dispatch(
          handleSeriesDragDrop({
            series_build_list_id: seriesId,
            destination: destination,
            volume_id: params.id,
            sort_order_type: "single",
            source: source,
          })
        );
      }
    };
  }

  const loadPlaceholder = [1, 2, 3, 4, 5];
  //Right Side width set
  useEffect(() => {
    function myFunction() {
      var sticky = ref.current?.clientWidth;
      if (window.pageYOffset > sticky) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    }
    window.onscroll = function () {
      myFunction();
    };
  }, []);

  /** open series Dropdown  **/
  const showDropDownMenu = (seriesId) => {
    setSeriesActionId(seriesId);
    setDropDown(!dropDown);
  };

  /** Delete Sereis Modal **/
  const handleDeleteModalConfirm = () => {
    //Update state when SERIES is delete..so SERIES is deleted from state
    let elementId = [...selectedRows][0];
    //app series
    setIsDelete(true)
    dispatch(setABSeries({ elementId }));
    dispatch(
      handleSeriesDetailDelete({
        series_build_list_id: [...selectedRows][0],
        volume_id: params.id,
        is_deleted: 1,
      }, (status) => {
        if (status) {
          setIsDelete(false)
          setAlert(false);
        }
      })
    );
    setSelectedRows([]);
    if (isActive === elementId) {
      if (
        SeriesDetail?.gb_series_buildlists &&
        SeriesDetail.gb_series_buildlists.length
      ) {
        let id
        if (elementId === SeriesDetail.gb_series_buildlists[0].series_build_list_id) {
          id = SeriesDetail.gb_series_buildlists[1].series_build_list_id;
          selectedSeriesId(id)
        }
        else {
          id = SeriesDetail.gb_series_buildlists[0].series_build_list_id;
        }
        setActive(id);
        dispatch(
          handleFetchSeriesElement({
            series_build_list_id: id,
          })
        );
      }
    }
  };

  /** close Delete Modal **/
  const hanldeDeleteModalClose = () => {
    setAlert(false);
    setSelectedRows([]);
  };

  /** close Dropdown onClick anywhere start **/
  const closeDropDown = useCallback((e) => {
    let click = e.target.closest("#DropDownClose");
    if (!click) {
      setDropDown(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("click", closeDropDown);
    return () => window.removeEventListener("click", closeDropDown);
  }, [closeDropDown]);

  /** close Dropdown onClick anywhere End **/

  return (
    <div className="col-lg-3">
      <div className={`main_card_wrapper tabbing-card p-0 ${sticky ? "sticky" : ""
        }`}
        ref={ref}>
        <div className="app-right-nav">
          <div className="p30">
            <h2 className="app-right-heading">
              <input
                type="text"
                className="text-volume-title"
                onBlur={handleSaveElementTitle}
                value={elementChange}
                onChange={(e) => {
                  setOnchangeElement(e.target.value);
                }}
              />
              <div className={volumeTitle ? "vol-title-error alert alert-danger" : "d-none vol-title-error alert alert-danger"}>Please Enter Volume Title</div>
            </h2>
            <h4
              className={
                SeriesDetail.length === 0 ? "add-text app-volume-code dashed-box" : "add-text app-volume-code"
              }
            >
              {SeriesDetail.volume_code}
            </h4>
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable index="rowList" id="rowList" droppableId="rowsList">
              {(provided, snapshot) => (
                <div>
                  <ul
                    className="new-volume-ul"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {/* share series Modal */}
                    <Modal isOpen={share} className="fileUpload shareLink" centered>
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">Public Link</h5>
                        <button
                          type="button"
                          className="close"
                          onClick={() => handleCloseShareLinkAB()}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <form
                        className="needs-validation p-4"
                        onSubmit={handleSubmitShareLink}
                      >
                        <div className="row d-flex justify-content-start align-items-center clipboard-row">
                          <div className="col-11">
                            <input
                              className="inputBox form-control"
                              type="text"
                              name="shareLinkUrl"
                              value={shareABLink}
                              disabled
                              onChange={(e) => { }}
                            ></input>
                          </div>
                          <div className="col-1">
                            <div className="d-flex justify-content-start">
                              <button
                                className="copy_btn btn-primary"
                                type="submit"
                              >
                                <i
                                  title="Copied"
                                  className="message-builder-table-action-icon fa fa-clone"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </Modal>

                    {/* Delete Series Modal */}
                    <Modal isOpen={alert} className="modal-dialog modal-dialog-centered" role="document" centered>
                      <div className="modal-content">
                        <div className="modal-body modal_popup">
                          {
                            isDelete ?
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close" disabled>
                                <img src={modalClose} alt="modal-close" />
                              </button> :
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => hanldeDeleteModalClose()}>
                                <img src={modalClose} alt="modal-close" />
                              </button>
                          }

                          <div className="modal_data editImageModalData">
                            <div className="row">
                              <div className="col-md-12 text-center mt-5">
                                <img src={deleteAlert} alt="red-info" />
                              </div>
                              <div className="col-md-12 text-center mb20 mt15">
                                <h1 className="fw-600 dark-text ">Are you sure you want to delete?</h1>
                              </div>
                              <div className="col-md-12 modal-btn">
                                {
                                  isDelete ? <button className="btn secondary-btn mr-2" data-dismiss="modal" disabled> cancel</button> :
                                    <button className="btn secondary-btn mr-2" data-dismiss="modal" onClick={() => hanldeDeleteModalClose()}> cancel</button>
                                }

                                {isDelete ? <button className="btn delete-border-btn ml-3" disabled>
                                  Deleting...
                                </button> :
                                  <button className="btn delete-border-btn ml-3" onClick={() => handleDeleteModalConfirm()}>
                                    Delete
                                  </button>
                                }

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>

                    {/* Series Listing */}
                    {SeriesDetail.length === 0 ||
                      seriesSectionLoading ||
                      addSeriesImport
                      ? loadPlaceholder.map((i) => <BBLoading key={i} />)
                      : !!onDragSeries &&
                      onDragSeries.map((series, i) => {
                        return (
                          <Draggable
                            key={series.series_build_list_id}
                            index={i}
                            draggableId={"" + series.series_build_list_id}
                          >
                            {(provided, snapshot) => (
                              <li
                                id={series.series_buildlist_title}
                                className={`p15-30 ${colors[(i + 1) % 4]} ${isActive === series.series_build_list_id ? `selected` : "0"} ${isHide.id == series.series_build_list_id &&
                                  isHide.isvisible === false
                                  ? "SeriesDisable"
                                  : series.is_visible === 1
                                    ? ""
                                    : "SeriesDisable"
                                  } `}
                                key={i}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="d-flex align-items-center justify-content-between" onClick={() => { SeriesClick(series.series_build_list_id) }}>
                                  <h3
                                    className={isActive === series.series_build_list_id ? "mb0 fw-600 text-white" : "mb0 fw-600"}>
                                    {series.series_buildlist_title}
                                  </h3>
                                </div>
                                <div className={isActive === series.series_build_list_id ? "add-text d-flex align-items-center justify-content-between mb-1" : `${border[(i + 1) % 4]} d-flex align-items-center justify-content-between mb-1 ${isHide.id == series.series_build_list_id &&
                                  isHide.isvisible === false
                                  ? "SeriesDisable"
                                  : series.is_visible === 1
                                    ? ""
                                    : "SeriesDisable"
                                  }`}>
                                  <span className={isActive === series.series_build_list_id ? "text-white" : `${title[(i + 1) % 4]} ${isHide.id == series.series_build_list_id &&
                                    isHide.isvisible === false
                                    ? "SeriesDisable"
                                    : series.is_visible === 1
                                      ? ""
                                      : "SeriesDisable"
                                    }`}>{series.code}</span>
                                  <div className={isActive === series.series_build_list_id ? "action" : "toggle-action"}>
                                    <label className="switch-style mb0" htmlFor="toggle" title="Toggle">
                                      <input
                                        type="checkbox"
                                        id={series.series_build_list_id}
                                        onClick={() =>
                                          handleSeriesVisibility(series)
                                        }
                                        defaultChecked={series.is_visible}
                                        disabled={seriesVisibility == true ? true : false}
                                      />
                                      <label
                                        className="mb0"
                                        htmlFor={series.series_build_list_id}
                                      ></label>
                                    </label>
                                    <div className="dropdown ml-2">
                                      <Link
                                        to="#"
                                        role="button"
                                        id="dropdownMenuLink"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={() => showDropDownMenu(series.series_build_list_id)}
                                      >
                                        {isActive === series.series_build_list_id ? <img src={dotImage} alt="dot" id="DropDownClose" /> : <img src={threeDots} alt="dot" id="DropDownClose" className="p5" />}
                                      </Link>

                                      <div
                                        className={dropDown && seriesActionId === series.series_build_list_id ? "dropdown-menu deatils-dropdown show" : "dropdown-menu"}
                                        aria-labelledby="dropdownMenuLink"
                                      >
                                        <Link
                                          to="#"
                                          className="dropdown-item"
                                          onClick={() =>
                                            onShare(series.code_id)
                                          }
                                        >
                                          Share
                                        </Link>
                                        <Link
                                          to="#"
                                          id="DropDownClose"
                                          className="dropdown-item"
                                          onClick={() =>
                                            duplicateSeries(
                                              series.series_build_list_id
                                            )
                                          }
                                        >
                                          Duplicate
                                        </Link>
                                        {SeriesDetail.gb_series_buildlists
                                          .length != 1 ? (
                                          <Link
                                            to="#"
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleSeriesRemove(
                                                series.series_build_list_id
                                              )
                                            }
                                            title=""
                                          >
                                            Delete
                                          </Link>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )}
                          </Draggable>
                        );
                      })}
                  </ul>
                  {SeriesDetail.length === 0 ? ""
                    : addDuplicateSeriesLoading != 0 ?
                      <button
                        className="btn-add-series bg-transparent border-0 float-right"
                      >
                        <span className="add-series float-right">
                          <img
                            src={addimage}
                            className="mr-1 add-image"
                            alt="add"
                          />
                          {addDuplicateSeriesLoading == 1 ? "ADDING SERIES ..." : (addDuplicateSeriesLoading == 2 ? "DUPLICATING SERIES ..." : null)}
                        </span>
                      </button>
                      :
                      <button
                        onClick={() => addSeries(params.id)}
                        className="add-series bg-transparent border-0 float-right"
                      >
                        <span>
                          <img
                            src={addimage}
                            className="mr-1 add-image"
                            alt="add"
                          />
                          ADD SERIES
                        </span>
                      </button>
                  }
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </div>
  );
}
