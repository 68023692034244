import {
  FETCH_AppBuilderDetail_LISTING,
  FETCH_AppBuilderDetail_LISTING_SUCCESS,
  FETCH_AppBuilderDetail_LISTING_ERROR,
  AB_DETAIL_ADD_SECTION,
  AB_DETAIL_ADD_SECTION_SUCCESS,
  AB_DETAIL_ADD_SECTION_ERROR,
  FETCH_RESTORE_POINT_LISTING,
  FETCH_RESTORE_POINT_LISTING_SUCCESS,
  FETCH_RESTORE_POINT_LISTING_ERROR,
  DUPLICATE_SECTIONS,
  DUPLICATE_SECTIONS_SUCCESS,
  DUPLICATE_SECTIONS_ERROR,
  DELETE_SECTIONS,
  DELETE_SECTIONS_SUCCESS,
  DELETE_SECTIONS_ERROR,
  SECTION_DRAG_DROP,
  SECTION_DRAG_DROP_SUCCESS,
  SECTION_DRAG_DROP_ERROR,
  IMAGE_SECTIONS,
  IMAGE_SECTIONS_SUCCESS,
  IMAGE_SECTIONS_ERROR,
  AB_SAVE_CUSTOM_RESTORE,
  AB_SAVE_CUSTOM_RESTORE_SUCCESS,
  AB_SAVE_CUSTOM_RESTORE_ERROR,
  FETCH_AB_RESTORE_LIST,
  FETCH_AB_RESTORE_LIST_SUCCESS,
  FETCH_AB_RESTORE_LIST_ERROR,
  FETCH_AB_CATEGORIES_VOLUME_LIST,
  FETCH_AB_CATEGORIES_VOLUME_LIST_SUCCESS,
  FETCH_AB_CATEGORIES_VOLUME_LIST_ERROR,
  FETCH_AB_SERIES_BY_CATEGORIES_VOLUME,
  FETCH_AB_SERIES_BY_CATEGORIES_VOLUME_SUCCESS,
  FETCH_AB_SERIES_BY_CATEGORIES_VOLUME_ERROR,
} from "./actionTypes";

const AppBuilderDetailInitialState = {
  AppBuilderDetailListing: [],
  RestorePoint: [],
  SeriesDetail: [],
  ABRestoreList: [],
  CategoriesVolumeList: [],
  SeriesByCategoriesVolume: [],
  SeriesImport: [],
  loading: false,
  abSectionLoading: false,
  saveSeriesDescLoading: false,
  search: "",
  sizePerPage: 10,
  page: 1,
  sortField: "message_build_list_id",
  sortOrder: "DESC",
  totalSize: 0,
}

const AppBuilderDetail = (state = AppBuilderDetailInitialState, action) => {
  const { type, payload } = action
  switch (type) {
    /** AppBuilderDetail list */
    case FETCH_AppBuilderDetail_LISTING:
      return {
        ...state,
        loading: true,
      }
    case FETCH_AppBuilderDetail_LISTING_SUCCESS:
      return {
        ...state,
        AppBuilderDetailListing: action.payload,
        loading: false,
        totalSize: payload.count,
      }
    case FETCH_AppBuilderDetail_LISTING_ERROR:
      return {
        ...state,
        loading: false,
      }

    //AB DETAILS ADD SECTION
    case AB_DETAIL_ADD_SECTION:
      return {
        ...state,
        abSectionLoading: true
      }
    case AB_DETAIL_ADD_SECTION_SUCCESS:
      return {
        ...state,
        abSectionLoading: false
      }
    case AB_DETAIL_ADD_SECTION_ERROR:
      return {
        ...state,
        loading: false,
        abSectionLoading: false,
      }
    /** DUPLICATE Sections */
    case DUPLICATE_SECTIONS:
      return {
        ...state,
        loading: true,
      };
    case DUPLICATE_SECTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        totalSize: payload.count,
      };
    case DUPLICATE_SECTIONS_ERROR:
      return {
        ...state,
        loading: false,
      };

    /** DELETE Sections */
    case DELETE_SECTIONS:
      return {
        ...state,
        loading: true,
      };
    case DELETE_SECTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        totalSize: payload.count,
      };
    case DELETE_SECTIONS_ERROR:
      return {
        ...state,
        loading: false,
      };

    /** Restore Point list */
    case FETCH_RESTORE_POINT_LISTING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_RESTORE_POINT_LISTING_SUCCESS:
      return {
        ...state,
        RestorePoint: action.payload,
        loading: false,
        totalSize: payload.count,
      };
    case FETCH_RESTORE_POINT_LISTING_ERROR:
      return {
        ...state,
        loading: false,
      };
    /** Section drag drop */
    case SECTION_DRAG_DROP:
      return {
        ...state,
        loading: true,
      }
    case SECTION_DRAG_DROP_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case SECTION_DRAG_DROP_ERROR:
      return {
        ...state,
        loading: false,
      }

    /** Sections Image DropZone Element  */
    case IMAGE_SECTIONS:
      return {
        ...state,
        loading: true,
        saveSeriesDescLoading: true,
      }
    case IMAGE_SECTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        saveSeriesDescLoading: false,
      }
    case IMAGE_SECTIONS_ERROR:
      return {
        ...state,
        loading: false,
        saveSeriesDescLoading: false,
      }

    //APP Builder save Custom Restore on change
    case AB_SAVE_CUSTOM_RESTORE:
      return {
        ...state,
        loading: true,
      }
    case AB_SAVE_CUSTOM_RESTORE_SUCCESS:
      return {
        ...state,
        loading: true,
      }
    case AB_SAVE_CUSTOM_RESTORE_ERROR:
      return {
        ...state,
        loading: true,
      }


    //AB Elements restore Listing
    case FETCH_AB_RESTORE_LIST:

      return {
        ...state,
        loading: true,
      }
    case FETCH_AB_RESTORE_LIST_SUCCESS:

      return {
        ...state,
        ABRestoreList: action.payload,
        loading: false,
      }
    case FETCH_AB_RESTORE_LIST_ERROR:
      return {
        ...state,
        loading: false,
      }

    /**App Builder Details Import Categories Volume*/
    case FETCH_AB_CATEGORIES_VOLUME_LIST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_AB_CATEGORIES_VOLUME_LIST_SUCCESS:
      return {
        ...state,
        CategoriesVolumeList: action.payload,
        loading: false,
      }
    case FETCH_AB_CATEGORIES_VOLUME_LIST_ERROR:
      return {
        ...state,
        loading: false,
      }


    /**App Builder Details Import Series By Categories Volume*/
    case FETCH_AB_SERIES_BY_CATEGORIES_VOLUME:
      return {
        ...state,
        loading: true,
      }
    case FETCH_AB_SERIES_BY_CATEGORIES_VOLUME_SUCCESS:
      return {
        ...state,
        SeriesByCategoriesVolume: action.payload,
        loading: false,
      }
    case FETCH_AB_SERIES_BY_CATEGORIES_VOLUME_ERROR:
      return {
        ...state,
        loading: false,
      }
    default:
      return state


  }
}
export default AppBuilderDetail
