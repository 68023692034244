import {
  FETCH_MB_ELEMENTS,
  FETCH_MB_ELEMENTS_SUCCESS,
  FETCH_MB_ELEMENTS_ERROR,
  MB_DUPLICATE_ELEMENT,
  MB_DUPLICATE_ELEMENT_SUCCESS,
  MB_DUPLICATE_ELEMENT_ERROR,
  MB_DELETE_ELEMENT,
  MB_DELETE_ELEMENT_SUCCESS,
  MB_DELETE_ELEMENT_ERROR,
  FETCH_MB_RESTORE_LIST,
  FETCH_MB_RESTORE_LIST_SUCCESS,
  FETCH_MB_RESTORE_LIST_ERROR,
  MB_SAVE_CUSTOM_RESTORE,
  MB_SAVE_CUSTOM_RESTORE_SUCCESS,
  MB_SAVE_CUSTOM_RESTORE_ERROR,
  SAVE_ON_CHANGE,
  SAVE_ON_CHANGE_SUCCESS,
  SAVE_ON_CHANGE_ERROR,
  SAVE_ON_ELEMENT_CHANGE,
  SAVE_ON_ELEMENT_CHANGE_SUCCESS,
  SAVE_ON_ELEMENT_CHANGE_ERROR,
  DRAG_BUILDING_BLOCK,
  DRAG_BUILDING_BLOCK_SUCCESS,
  DRAG_BUILDING_BLOCK_ERROR,
  MESSAGE_ELEMENT_DRAG_DROP,
  MESSAGE_ELEMENT_DRAG_DROP_SUCCESS,
  MESSAGE_ELEMENT_DRAG_DROP_ERROR,
  SET_MB_ELEMENTS,
  MB_DELETE_CUSTOM_RESTORE,
  MB_DELETE_CUSTOM_RESTORE_SUCCESS,
  MB_DELETE_CUSTOM_RESTORE_ERROR,
  MESSAGE_VIDEO_ELEMENT,
  MESSAGE_VIDEO_ELEMENT_SUCCESS,
  MESSAGE_VIDEO_ELEMENT_ERROR,
  HANDLE_MB_INDEX_SUCCESS,
  HANDLE_ELEMENT_INDEX_SUCCESS,
  SET_VISIBLE_UPDATE,
  SET_MESSAGE_BUILD_LENGTH,
  SET_MESSAGE_RESET_BUILD_DATA,
} from "./actionTypes";

import { axiosBuilder } from "services/api";
import {
  getMBElements,
  duplicateElement,
  updateBuilderElements,
  getMBRestoreList,
  MBSaveOnChange,
  buildingBlockDragDrop,
  buildElementDrag,
  createCustomMBRestorePoint,
  DeleteCustomMBRestorePoint,
  videoEmbed,
} from "constants/urls";
import { toast } from "react-toastify";
import { handleFetchSeriesElement } from "../../store/appSeries/actions";

//MB Elements
export const handleFetchMBElements = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch(fetchMBElements(true));
      const res = await axiosBuilder.post(getMBElements, payload);
      if (res.data) {
        dispatch(fetchMBElementsSuccess(res.data.data));
        cb(res.data.data);
      }
    } catch (err) {
      dispatch(
        fetchMBElementsError(err.response?.data?.message || err.message)
      );
    }
  };
};

//MB Elements duplicate
export const handleMBElementDuplicate = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(MBDuplicateElement(true));
      const res = await axiosBuilder.post(duplicateElement, payload);
      if (res.data.data) {
        dispatch(MBDuplicateElementSuccess(res.data.data));
        if (res.data.data.is_series == 0) {
          dispatch(
            handleFetchMBElements({
              id: res.data.data.build_list_id,
            })
          );
        } else {
          dispatch(
            handleFetchSeriesElement({
              series_build_list_id: res.data.data.build_list_id,
            })
          );
        }

        toast.success(res.data?.message);
      }
    } catch (err) {
      dispatch(
        MBDuplicateElementError(err.response?.data?.message || err.message)
      );
    }
  };
};

//MB Elements Delete
export const handleMBElementDelete = (payload, cb = () => { }) => {
  return async (dispatch) => {
    try {
      dispatch(MBDeleteElement(true));
      const res = await axiosBuilder.post(updateBuilderElements, payload);
      if (res.data) {
        dispatch(MBDeleteElementSuccess(res.data.data));
        cb(res.data.data)
        // if (payload.is_series == 0) {
        //   dispatch(
        //     handleFetchMBElements({
        //       id: res.data.data.id,
        //     })
        //   );
        // } else {
        //   dispatch(
        //     handleFetchSeriesElement({
        //       series_build_list_id: res.data.data.id,
        //     })
        //   );
        // }
        toast.success(res.data?.message);
      }
    } catch (err) {
      dispatch(
        MBDeleteElementError(err.response?.data?.message || err.message)
      );
    }
  };
};

//MB restore listing
export const handleMBRestoreList = (payload, cb = () => { }) => {
  return async (dispatch) => {
    try {
      dispatch(fetchMBRestoreList(true));
      const res = await axiosBuilder.post(getMBRestoreList, payload);
      if (res.data.data) {
        dispatch(fetchMBRestoreListSuccess(res.data.data));
        cb(res.data.data);
      }
    } catch (err) {
      dispatch(
        fetchMBRestoreListError(err.response?.data?.message || err.message)
      );
    }
  };
};

//MB save on change
export const handleSaveOnChangeMB = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch(saveOnChangeMB(true));
      const res = await axiosBuilder.post(MBSaveOnChange, payload);
      if (res.data) {
        dispatch(saveOnChangeMBSuccess(res.data.data));
        cb(res.data.data)
      }
    } catch (err) {
      dispatch(saveOnChangeMBError(err.response?.data?.message || err.message));
    }
  };
};

//MB save on ELEMENT change
export const handleSaveOnElementChangeMB = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch(saveOnElementChangeMB(true));
      const res = await axiosBuilder.post(MBSaveOnChange, payload);
      if (res.data) {
        dispatch(saveOnElementChangeMBSuccess(res.data.data.data));
        cb(res.data);
      }
    } catch (err) {
      dispatch(
        saveOnElementChangeMBError(err.response?.data?.message || err.message)
      );
    }
  };
};

//MB save custom restore
export const handleSaveMBCustomRestore = (payload, cb) => {
  return async (dispatch) => {
    try {
      const res = await axiosBuilder.post(createCustomMBRestorePoint, payload);
      if (res.data.data) {
        dispatch(saveMBCustomRestoreSuccess(res.data.data));
        cb(res.data.data)
      }
    } catch (err) {
      dispatch(
        saveMBCustomRestoreError(err.response?.data?.message || err.message)
      );
    }
  };
};

// MB Delete Manual Restore Point

export const handleDeleteMBCustomRestore = (payload, cb) => {
  return async (dispatch) => {
    try {
      const res = await axiosBuilder.post(DeleteCustomMBRestorePoint, payload);
      if (res.data.data) {
        dispatch(DeleteMBCustomRestoreSuccess(res.data.data));
        cb(res.data.data)
        dispatch(handleMBRestoreList);
      }
    } catch (err) {
      dispatch(
        DeleteMBCustomRestoreError(err.response?.data?.message || err.message)
      );
    }
  };
};

//building block drag drop
export const handleBuildingBlockDrag = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(buildingBlockDrag(true));
      const res = await axiosBuilder.post(buildingBlockDragDrop, payload);
      if (res.data.data) {
        dispatch(buildingBlockDragSuccess(res.data.data));
        dispatch(
          handleFetchMBElements({
            id: payload.message_build_list_id,
            is_series: 0,
          })
        );
      }
    } catch (err) {
      dispatch(
        buildingBlockDragError(err.response?.data?.message || err.message)
      );
    }
  };
};

//Message Element drag drop
export const handleMessageElementDragDrop = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(messageElementDragDrop(true));
      const res = await axiosBuilder.post(buildElementDrag, payload);
      if (res.data.data) {
        dispatch(messageElementDragDropSuccess(res.data.data));
        dispatch(handleFetchMBElements({ id: payload.id, is_series: 0 }));
      }
    } catch (err) {
      dispatch(
        messageElementDragDropError(err.response?.data?.message || err.message)
      );
    }
  };
};

/** Message Builder Video Element */
export const handleMessageVideoElement = (payload, successData) => {
  return async (dispatch) => {
    try {
      dispatch(messageVideoElement(true));
      const res = await axiosBuilder.post(videoEmbed, payload);
      if (res.data.data) {
        successData(res.data.data.flag);
        dispatch(messageVideoElementSuccess(res.data.data));
      }
    } catch (err) {
      dispatch(
        messageVideoElementError(err.response?.data?.message || err.message)
      );
    }
  };
};

//MB Elements Listing
export const fetchMBElements = (data) => {
  return {
    type: FETCH_MB_ELEMENTS,
    payload: data,
  };
};

export const setMBElements = (data) => {
  return {
    type: SET_MB_ELEMENTS,
    payload: data,
  };
};

export const fetchMBElementsSuccess = (data) => {
  return {
    type: FETCH_MB_ELEMENTS_SUCCESS,
    payload: data,
  };
};
export const fetchMBElementsError = (data) => {
  return {
    type: FETCH_MB_ELEMENTS_ERROR,
    payload: data,
  };
};

//MB Element duplicate
export const MBDuplicateElement = (data) => {
  return {
    type: MB_DUPLICATE_ELEMENT,
    payload: data,
  };
};
export const MBDuplicateElementSuccess = (data) => {
  return {
    type: MB_DUPLICATE_ELEMENT_SUCCESS,
    payload: data,
  };
};
export const MBDuplicateElementError = (data) => {
  return {
    type: MB_DUPLICATE_ELEMENT_ERROR,
    payload: data,
  };
};

//MB Delete
export const MBDeleteElement = (data) => {
  return {
    type: MB_DELETE_ELEMENT,
    payload: data,
  };
};
export const MBDeleteElementSuccess = (data) => {
  return {
    type: MB_DELETE_ELEMENT_SUCCESS,
    payload: data,
  };
};
export const MBDeleteElementError = (data) => {
  return {
    type: MB_DELETE_ELEMENT_ERROR,
    payload: data,
  };
};

//MB Elements restore Listing
export const fetchMBRestoreList = (data) => {
  return {
    type: FETCH_MB_RESTORE_LIST,
    payload: data,
  };
};
export const fetchMBRestoreListSuccess = (data) => {
  return {
    type: FETCH_MB_RESTORE_LIST_SUCCESS,
    payload: data,
  };
};
export const fetchMBRestoreListError = (data) => {
  return {
    type: FETCH_MB_RESTORE_LIST_ERROR,
    payload: data,
  };
};

//Message builder Save on change
export const saveOnChangeMB = (data) => {
  return {
    type: SAVE_ON_CHANGE,
    payload: data,
  };
};
export const saveOnChangeMBSuccess = (data) => {
  return {
    type: SAVE_ON_CHANGE_SUCCESS,
    payload: data,
  };
};
export const saveOnChangeMBError = (data) => {
  return {
    type: SAVE_ON_CHANGE_ERROR,
    payload: data,
  };
};

//Message builder Save on ELEMENT change
export const saveOnElementChangeMB = (data) => {
  return {
    type: SAVE_ON_ELEMENT_CHANGE,
    payload: data,
  };
};
export const saveOnElementChangeMBSuccess = (data) => {
  return {
    type: SAVE_ON_ELEMENT_CHANGE_SUCCESS,
    payload: data,
  };
};
export const saveOnElementChangeMBError = (data) => {
  return {
    type: SAVE_ON_ELEMENT_CHANGE_ERROR,
    payload: data,
  };
};

//MB Elements save custom restore point
export const saveMBCustomRestore = (data) => {
  return {
    type: MB_SAVE_CUSTOM_RESTORE,
    payload: data,
  };
};
export const saveMBCustomRestoreSuccess = (data) => {
  return {
    type: MB_SAVE_CUSTOM_RESTORE_SUCCESS,
    payload: data,
  };
};
export const saveMBCustomRestoreError = (data) => {
  return {
    type: MB_SAVE_CUSTOM_RESTORE_ERROR,
    payload: data,
  };
};

//MB Delete Manual restore point
export const DeleteMBCustomRestore = (data) => {
  return {
    type: MB_DELETE_CUSTOM_RESTORE,
    payload: data,
  };
};
export const DeleteMBCustomRestoreSuccess = (data) => {
  return {
    type: MB_DELETE_CUSTOM_RESTORE_SUCCESS,
    payload: data,
  };
};
export const DeleteMBCustomRestoreError = (data) => {
  return {
    type: MB_DELETE_CUSTOM_RESTORE_ERROR,
    payload: data,
  };
};

//On Building Block Drag Drop
export const buildingBlockDrag = (data) => {
  return {
    type: DRAG_BUILDING_BLOCK,
    payload: data,
    loading: true,
  };
};
export const buildingBlockDragSuccess = (data) => {
  return {
    type: DRAG_BUILDING_BLOCK_SUCCESS,
    payload: data,
    loading: false,
  };
};
export const buildingBlockDragError = (data) => {
  return {
    type: DRAG_BUILDING_BLOCK_ERROR,
    payload: data,
    loading: false,
  };
};

//On Message Element Drag Drop
export const messageElementDragDrop = (data) => {
  return {
    type: MESSAGE_ELEMENT_DRAG_DROP,
    payload: data,
  };
};
export const messageElementDragDropSuccess = (data) => {
  return {
    type: MESSAGE_ELEMENT_DRAG_DROP_SUCCESS,
    payload: data,
  };
};
export const messageElementDragDropError = (data) => {
  return {
    type: MESSAGE_ELEMENT_DRAG_DROP_ERROR,
    payload: data,
  };
};

/** Message Builder Video Element */
export const messageVideoElement = (data) => {
  return {
    type: MESSAGE_VIDEO_ELEMENT,
    payload: data,
  };
};
export const messageVideoElementSuccess = (data) => {
  return {
    type: MESSAGE_VIDEO_ELEMENT_SUCCESS,
    payload: data,
  };
};
export const messageVideoElementError = (data) => {
  return {
    type: MESSAGE_VIDEO_ELEMENT_ERROR,
    payload: data,
  };
};

//On/Off Slider Message Elements
export const setVisableUpdate = (data) => {
  return {
    type: SET_VISIBLE_UPDATE,
    payload: data,
  };
};

export const handleMBIndexSuccess = (data) => {
  return {
    type: HANDLE_MB_INDEX_SUCCESS,
    payload: data,
  };
};

export const handleElementIndexSuccess = (data) => {
  return {
    type: HANDLE_ELEMENT_INDEX_SUCCESS,
    payload: data,
  };
};

export const handleMBBuildLength = (data) => {
  return{
    type:SET_MESSAGE_BUILD_LENGTH,
    payload: data
  }
}

export const handleMBResetBuildData = data => {
  return {
    type: SET_MESSAGE_RESET_BUILD_DATA,
    payload: data,
  };
};
