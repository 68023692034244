import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import {
  handleFetchMBCodeElements,
  handleFetchMBTeachingElements,
} from "../store/action";
import MBLoading from "components/common/Loader/MBLoading";
import { MessageTeachingElement } from "./MessageTeachingElement";
import growNoData from "assets/images/no-data.png";
import queryString from "query-string";

export const MessageBuilderTeachingElements = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [elementLoader, setElementLoader] = useState(false);
  const { MBTeachingElements } = useSelector(
    (state) => state.MessageBuilderTeaching
  );
  const { isAuth } = useSelector((state) => state.auth);
  const location = useLocation();

  let newStr, newQry;
  let character;

  if (location.hash !== "") {
    newQry = location.search + location.hash;
  } else {
    newQry = location.search;
  }

  let qry = queryString.parse(newQry);
  function containsAny(str, characters) {
    return characters.some((char) => {
      let includes = str.includes(char);
      if (includes) {
        character = char;
      }
      return includes;
    });
  }

  useEffect(() => {
    // if (containsAny(newQry, ['&'])) {
    //   let index1 = newQry.indexOf(character);
    //   newStr = newQry.substr(index1);
    //   qry.code = qry.code + newStr;
    //   console.log('newStr', newStr);
    //   console.log('qry.code', qry.code);

    //   if (containsAny(qry.code, ['&parent_category_id='])) {
    //     let index1 = newQry.indexOf(character);
    //     newStr = qry.code.substr(0,index1);
    //     qry.code = qry.code;
    //     console.log('newStr1', newStr);
    //     console.log('qry.code1', qry.code);
    //   }
    // }
    // else {
    //   console.log("inn");
    //   if (containsAny(newQry, ['&'])) {
    //     let index1 = newQry.indexOf(character);
    //     newStr = newQry.substr(index1);
    //     qry.code = qry.code + newStr;
    //     console.log('newStr2', newStr);
    //     console.log('qry.code2', qry.code);
    //   }
    // }

    if (containsAny(newQry, ["&"])) {
      let index1 = newQry.indexOf("?code=");
      let index2 = newQry.indexOf("&parent_category_id");

      if (index1 !== -1 && index2 !== -1 && index1 < index2) {
        newStr = newQry.substring(index1 + 6, index2);
        qry.code = newStr;
      } else {
        let index1 = newQry.indexOf(character);
        newStr = newQry.substr(index1);
        qry.code = qry.code + newStr;
      }
    }

    setElementLoader(false);
    if (qry.code !== undefined) {
      if (!isAuth) {
        dispatch(
          handleFetchMBCodeElements(
            {
              code_id: qry.code,
            },
            (status) => {
              if (status) {
                setElementLoader(true);
              }
            }
          )
        );
      } else {
        dispatch(
          handleFetchMBCodeElements(
            {
              code_id: qry.code,
            },
            (status) => {
              if (status) {
                setElementLoader(true);
              }
            }
          )
        );
      }
    } else {
      if (isAuth) {
        dispatch(
          handleFetchMBTeachingElements(
            {
              id: params.id,
              is_series: 0,
            },
            (status) => {
              if (status) {
                setElementLoader(true);
              }
            }
          )
        );
      }
    }
    return () => {
      setElementLoader(false);
    };
  }, [dispatch]);

  return elementLoader == false ? (
    <div className="col-lg-12">
      <MBLoading />
    </div>
  ) : Object.keys(MBTeachingElements).length !== 0 ? (
    <div className="col-lg-12">
      <div className="main_card_wrapper tabbing-card">
        <form action="#" method="post">
          {elementLoader == false ? (
            <MBLoading />
          ) : (
            <>
              <h2 id="message-heading" className="box-border">
                {MBTeachingElements.build_title}
              </h2>
              <input
                id="renameit"
                type="text"
                className="box-border-subtitle"
                name="message-heading"
                value={
                  MBTeachingElements.build_sub_title == ""
                    ? "New Message"
                    : MBTeachingElements.build_sub_title
                }
                placeholder="NEW MESSAge"
                onChange={(e) => {}}
                disabled
              />
            </>
          )}
          {elementLoader == false ? (
            <MBLoading />
          ) : (
            <>
              {!!MBTeachingElements?.sycu_build_elements_details &&
                MBTeachingElements.sycu_build_elements_details.map(
                  (element, index) => {
                    return (
                      <div key={index}>
                        {element.build_elements_name == "BIG IDEA / BIBLE" &&
                        element.is_visible == 1 ? (
                          <div className="tools">
                            <div className="tools-area">
                              <div className="tools-left">
                                <div className="bib-left">
                                  <input
                                    onChange={(e) => {}}
                                    type="text"
                                    className="etitle"
                                    value={element.title}
                                    disabled
                                  />
                                  <TextareaAutosize
                                    rows={5}
                                    className="subtitle"
                                    value={element.content}
                                    onChange={(e) => {}}
                                    disabled
                                  />
                                </div>
                                <div className="bib-right">
                                  <input
                                    type="text"
                                    className="etitle"
                                    value={element.extra_title}
                                    onChange={(e) => {}}
                                    disabled
                                  />
                                  <TextareaAutosize
                                    rows={5}
                                    className="subtitle"
                                    value={element.extra_content}
                                    onChange={(e) => {}}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                            {!!element?.extra_title_1 ||
                            !!element?.extra_content_1 ? (
                              <div
                                className="tools-area"
                                style={{ marginTop: "2%" }}
                              >
                                <div className="tools-left">
                                  <div className="aboutWeek">
                                    <input
                                      onChange={(e) => {}}
                                      type="text"
                                      className="etitle"
                                      value={element?.extra_title_1}
                                      disabled
                                    />
                                    <TextareaAutosize
                                      rows={5}
                                      className="subtitle"
                                      value={element?.extra_content_1}
                                      onChange={(e) => {}}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : null}

                        {/* CHANGE */}
                        {element.build_elements_name == "ABOUT THIS WEEK" &&
                        element.is_visible == 1 ? (
                          <div className="tools">
                            <div className="tools-area">
                              <div className="tools-left">
                                <div className="aboutWeek">
                                  <input
                                    onChange={(e) => {}}
                                    type="text"
                                    className="etitle"
                                    value={element.title}
                                    disabled
                                  />
                                  <TextareaAutosize
                                    rows={5}
                                    className="subtitle"
                                    value={element.content}
                                    onChange={(e) => {}}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}

                        {element.build_elements_name == "Section" &&
                        element.is_visible == 1 ? (
                          <div className="lisection esection">
                            <div
                              className="boxheader_section"
                              id="drop-element-65696"
                            >
                              <input
                                type="hidden"
                                className="current-element"
                                value="SECTION"
                                onChange={(e) => {}}
                              />
                              <input
                                type="hidden"
                                className="lilimain"
                                value="8260077"
                                onChange={(e) => {}}
                              />
                              <input
                                type="text"
                                className="esection"
                                name="field[8260077][elementtitle]"
                                value={element.title}
                                onChange={(e) => {}}
                                disabled
                              />
                              <input
                                type="hidden"
                                className="etype"
                                name="field[8260077][elementtype]"
                                value="section"
                                onChange={(e) => {}}
                              />
                            </div>
                          </div>
                        ) : null}

                        {element.build_elements_name == "Header" &&
                        element.is_visible == 1 ? (
                          <div className="boxheader" id="drop-element-93624">
                            <input
                              type="hidden"
                              className="current-element"
                              value="HEADER"
                              onChange={(e) => {}}
                            />
                            <input
                              type="hidden"
                              className="lilimain"
                              value="8260078"
                              onChange={(e) => {}}
                            />
                            <input
                              type="text"
                              className="eheader"
                              name="field[8260078][elementtitle]"
                              value={element.title}
                              onChange={(e) => {}}
                              disabled
                            />
                            <input
                              type="hidden"
                              name="field[8260078][elementtype]"
                              className="etype"
                              value="header"
                              onChange={(e) => {}}
                            />
                          </div>
                        ) : null}

                        {element.build_elements_name == "Big Idea (Legacy)" &&
                        element.is_visible == 1 ? (
                          <div className="tools-legacy">
                            <div className="big-legacy">
                              <input
                                type="text"
                                className="etitle"
                                value={element.title}
                                disabled
                              />
                              <input
                                type="text"
                                className="subtitle"
                                value={element.content}
                                disabled
                              />
                            </div>
                          </div>
                        ) : null}

                        <MessageTeachingElement
                          key={index}
                          element={element}
                          index={index}
                        />
                      </div>
                    );
                  }
                )}
            </>
          )}
        </form>
      </div>
    </div>
  ) : (
    <div className="col-lg-12 no-data-col">
      <img src={growNoData} className="no-data-found"></img>
    </div>
  );
};
