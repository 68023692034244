import {
  FETCH_MB_CUSTOM_BUILD_LIST,
  FETCH_MB_CUSTOM_BUILD_LIST_SUCCESS,
  FETCH_MB_CUSTOM_BUILD_LIST_ERROR,
  SET_PAGE_MB_CUSTOM_BUILD_LIST,
  MB_DUPLICATE,
  MB_DUPLICATE_SUCCESS,
  MB_DUPLICATE_ERROR,
  MB_SHARE_LINK,
  MB_SHARE_LINK_SUCCESS,
  MB_SHARE_LINK_ERROR,
  MB_DELETE,
  MB_DELETE_SUCCESS,
  MB_DELETE_ERROR,
  MB_UPLOAD_BUILD_IMAGE,
  MB_UPLOAD_BUILD_IMAGE_SUCCESS,
  MB_UPLOAD_BUILD_IMAGE_ERROR,
  SET_MB_LISTING,
  SET_MB_LIBRARY,
  RESET_MB_CUSTOM_BUILD_LIST,
  SHARE_DASHBOARD_ACCESS,
} from "./actionTypes";
import { axiosBuilder } from "services/api";
import {
  MBListing,
  MBCreateDuplicate,
  deleteMB,
  shareLinkMB,
  uploadMediaMB,
} from "constants/urls";
import { toast } from "react-toastify";

//MB Listing
export const handleFetchMBListing = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch(fetchMBCustomBuildList(true));
      const res = await axiosBuilder.post(MBListing, payload);
      if (res.data) {
        if (payload.page_no == 1 && payload.search !== "") {
          dispatch(resetMBCustomBuildList({}));
        }
        dispatch(
          setShareDashboard(res.data.data?.sharedDashboardAccess || false)
        );
        dispatch(
          fetchMBCustomBuildListSuccess({
            data: res.data.data.rows,
            count: res.data.data.count,
          })
        );
        cb(res.data.data.rows);
      }
    } catch (err) {
      dispatch(
        fetchMBCustomBuildListError(err.response?.data?.message || err.message)
      );
    }
  };
};
//MB Duplicate
export const handleMBDuplicate = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch(MBDuplicate(true));
      const res = await axiosBuilder.post(MBCreateDuplicate, payload);
      if (res.data.data) {
        dispatch(MBDuplicateSuccess({ data: res.data.data }));
        cb(res.data.data);
      }
    } catch (err) {
      dispatch(MBDuplicateError(err.response?.data?.message || err.message));
      cb(err.response);
    }
  };
};

//MB Share link
export const handleMBShareLink = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(MBShareLink(true));
      const res = await axiosBuilder.post(shareLinkMB, payload);
      if (res.data.data) {
        dispatch(MBShareLinkSuccess(res.data.data));
        toast.success(res.data?.message);
      }
    } catch (err) {
      dispatch(MBShareLinkError(err.response?.data?.message || err.message));
    }
  };
};
//MB Delete
export const handleMBDelete = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch(MBDelete(true));
      const res = await axiosBuilder.post(deleteMB, payload);

      if (res.data) {
        dispatch(MBDeleteSuccess(res.data.data.data));
        cb(res.data);
      }
    } catch (err) {
      dispatch(MBDeleteError(err.response?.data?.message || err.message));
    }
  };
};

//MB Build Upload Image
export const handleMBUploadBuildImage = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch(MBUploadBuildImage(true));
      const res = await axiosBuilder.post(uploadMediaMB, payload);
      if (res.data.data) {
        dispatch(MBUploadBuildImageSuccess(res.data.data));
        cb(res.data.data);
      }
    } catch (err) {
      dispatch(
        MBUploadBuildImageError(err.response?.data?.message || err.message)
      );
    }
  };
};

//MB Listing
export const resetMBCustomBuildList = () => {
  return {
    type: RESET_MB_CUSTOM_BUILD_LIST,
    payload: {},
  };
};

export const fetchMBCustomBuildList = (data) => {
  return {
    type: FETCH_MB_CUSTOM_BUILD_LIST,
    payload: data,
  };
};

export const fetchMBCustomBuildListSuccess = (data) => {
  return {
    type: FETCH_MB_CUSTOM_BUILD_LIST_SUCCESS,
    payload: data,
  };
};

export const fetchMBCustomBuildListError = (data) => {
  return {
    type: FETCH_MB_CUSTOM_BUILD_LIST_ERROR,
    payload: data,
  };
};

//Set MbCustom List PageNo
export const setPageMBCustomBuildList = (data) => {
  return {
    type: SET_PAGE_MB_CUSTOM_BUILD_LIST,
    payload: data,
  };
};
//MB duplicate
export const MBDuplicate = (data) => {
  return {
    type: MB_DUPLICATE,
    payload: data,
  };
};
export const MBDuplicateSuccess = (data) => {
  return {
    type: MB_DUPLICATE_SUCCESS,
    payload: data,
  };
};
export const MBDuplicateError = (data) => {
  return {
    type: MB_DUPLICATE_ERROR,
    payload: data,
  };
};

//MB Share Link
export const MBShareLink = (data) => {
  return {
    type: MB_SHARE_LINK,
    payload: data,
  };
};
export const MBShareLinkSuccess = (data) => {
  return {
    type: MB_SHARE_LINK_SUCCESS,
    payload: data,
  };
};
export const MBShareLinkError = (data) => {
  return {
    type: MB_SHARE_LINK_ERROR,
    payload: data,
  };
};

// setMbLibrary
export const setMBLibrary = (data) => {
  return {
    type: SET_MB_LIBRARY,
    payload: data,
  };
};

//MB Delete
export const MBDelete = (data) => {
  return {
    type: MB_DELETE,
    payload: data,
  };
};
export const MBDeleteSuccess = (data) => {
  return {
    type: MB_DELETE_SUCCESS,
    payload: data,
  };
};
export const MBDeleteError = (data) => {
  return {
    type: MB_DELETE_ERROR,
    payload: data,
  };
};

//MB Build Upload Image
export const MBUploadBuildImage = (data) => {
  return {
    type: MB_UPLOAD_BUILD_IMAGE,
    payload: data,
  };
};
export const MBUploadBuildImageSuccess = (data) => {
  return {
    type: MB_UPLOAD_BUILD_IMAGE_SUCCESS,
    payload: data,
  };
};
export const MBUploadBuildImageError = (data) => {
  return {
    type: MB_UPLOAD_BUILD_IMAGE_ERROR,
    payload: data,
  };
};
/** To update redux when Message Build is deleted from User Builds*/
export const setMBListing = (data) => {
  return {
    type: SET_MB_LISTING,
    payload: data,
  };
};

export const setShareDashboard = (data) => ({
  type: SHARE_DASHBOARD_ACCESS,
  payload: data,
});
