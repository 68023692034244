import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Droppable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import { Modal } from "reactstrap";
import { handleABFolderListing, handleAddEditABFolder, handleDeleteABFolder, handleGetLibraryFolderByType, handleGetAppLibraryBuild, setABFolderId, setABFolderTitle, setABLibraryFolderId, setABLibraryFolderTitle, setMBFolderId, setMBFolderTitle, setPageABFolderBuildList, getActiveSubscriptionsBySite } from "store/actions";
import { setPageABCustomBuildList } from "../AppBuilder/store/action";
import growLogo from "assets/images/logo.png";
import addIcon from "assets/images/icon/plus.svg";
import dotActionIcon from "assets/images/icon/build-dot.svg";
import libraryFolderIcon from "assets/images/icon/volume.svg";
import folderIcon from "assets/images/icon/file.svg";
import modalClose from "assets/images/icon/modal-close.svg";
import deleteAlert from "assets/images/icon/red-info.svg";
export default function AppSidebar({ _isActive, onSelectAppFolder, onSelectAppFolderTitle, onSelectAppLibraryFolder, afterOnDragAppTitle, onSelectFolderSearch, isOnDrag, handleDuplicateBuild }) {
  //UseSelector
  const { AppBuilderFolderListing, loading, LibraryListing, StoreAppFolderId, StoreAppLibraryFolderId, ABFolderSave } = useSelector((state) => state.AppBuilderFolderListing);
  // For Toggle Menu
  const { toggleMenu, isAuth } = useSelector((state) => state.auth);
  //UseStates
  const [isActive, setActive] = useState(0);
  const [alert, setAlert] = useState(false);
  const [getFolderName, setGetFolderName] = useState("");
  const [folderSelected, setFolderSelected] = useState(false);
  const [buttonEnable, setButtonEnable] = useState(false);
  const [addFolderPopup, setAddFolderPopup] = useState(false);
  const [isActionButton, setIsActionButton] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [folderAction, setFolderAction] = useState(false);
  const [isFolderActionId, setIsFolderActionId] = useState(0);
  const [isFolderRename, setIsFolderRename] = useState(false);
  const [isFolderMatch, setIsFolderMatch] = useState(false);
  const [folderAlert, setFolderAlert] = useState(false);
  const [isRenameFolderId, setIsRenameFolderId] = useState(0)
  const [isFolderScroll, setIsFolderScroll] = useState(1)
  const [isHandleModal, setIsHandleModal] = useState(false)
  const [folderInfo, setFolderInfo] = useState({ folderName: "", folderId: 0 });
  const dispatch = useDispatch();
  // Listing Folders
  useEffect(() => {
    if (_isActive !== 0 && !StoreAppLibraryFolderId) {
      //Folder Select When BUILD Drag To Folder
      setActive(JSON.parse(_isActive));
      onSelectAppFolder(JSON.parse(_isActive));
    } else {
      if (StoreAppLibraryFolderId == 0) {
        //Folder Select When Folder Click
        setActive(StoreAppFolderId);
        onSelectAppFolder(StoreAppFolderId);
      } else {
        //Library Folder Select When Library Folder Click
        setActive(StoreAppLibraryFolderId);
        onSelectAppLibraryFolder(StoreAppLibraryFolderId);
        // Listing Library Folders
        // dispatch(handleGetAppLibraryBuild({
        //   volume_id: StoreAppLibraryFolderId,
        // }));
      }
    }
    if (isAuth && !isOnDrag && !folderSelected) {
      // Listing Folders
      dispatch(handleABFolderListing({}));
    }
    //Store Message Folder ID
    dispatch(setMBFolderId(0));
    //Store Message Folder TITLE
    dispatch(setMBFolderTitle("User Builds"));
  }, [dispatch, _isActive, StoreAppLibraryFolderId]);
  //Get Library folder using type
  useEffect(() => {
    if (isAuth) {
      dispatch(handleGetLibraryFolderByType({}));
    }
  }, [dispatch]);
  //Add Folder show
  const handleFolderAddAB = () => {
    setButtonEnable(false)
    setAddFolderPopup(true);
    setFolderSelected(false);
    setFolderInfo({ folderName: "", folderId: 0, });
  };
  //Edit Folder show
  const handleFolderEditAB = (folder) => {
    setButtonEnable(false)
    setFolderAction(!folderAction);
    setAddFolderPopup(true);
    setIsFolderRename(true);
    setIsRenameFolderId(folder.build_folder_id)
  };
  //Close Add Folder popup
  const handleAddFolderPopupClose = () => {
    setAlert(false);
    setAddFolderPopup(false);
    setIsFolderRename(false);
    setIsFolderMatch(false)
    setButtonEnable(false);
    setIsHandleModal(false)
  };
  //Get FOLDER Name
  const handleGetFolderNameAB = (e) => {
    //Add and Edit FOLDER Name Space Validation at first position
    const checkSpace = e.target.value.indexOf(" ");
    if (checkSpace === 0) {
      return false;
    }
    //Add and Edit FOLDER Name input value Validation
    if (e.target.value == "" || checkSpace === 0) {
      setButtonEnable(false);
    } else {
      setButtonEnable(true);
    }
    setFolderInfo((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    setAddFolderPopup(true);
    setGetFolderName(e.target.value);
  };
  //Save Folder when Add and Edit FOLDER Name
  const handleSaveFolderAB = () => {
    //Folder ID
    if (folderInfo.folderId) {
      const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');
      const folderTitle = removeExtraSpace(folderInfo.folderName);
      setIsHandleModal(true)
      dispatch(
        handleAddEditABFolder({
          build_folder_title: folderTitle,
          parent_id: 0,
          folder_type: 2,
          is_system: 1,
          build_folder_id: folderInfo.folderId,
        }, (status) => {
          setIsHandleModal(false)
          setIsFolderMatch(status.message)
          if (status.message !== "Folder already exist") {
            setAddFolderPopup(false);
            setIsFolderRename(false);
            dispatch(handleABFolderListing());
            //handle folder Title when Rename
            if (isRenameFolderId == StoreAppFolderId && StoreAppFolderId !== 0) {
              dispatch(setABFolderTitle(folderTitle));
            }
          }
        })
      );
    } else {
      if (!ABFolderSave) {
        const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');
        const folderTitle = removeExtraSpace(folderInfo.folderName);
        setIsHandleModal(true)
        dispatch(
          handleAddEditABFolder({
            build_folder_title: folderTitle,
            parent_id: 0,
            folder_type: 2,
            is_system: 1,
          }, (status) => {
            setIsHandleModal(false)
            setIsFolderMatch(status.message)
            if (status.message !== "Folder already exist") {
              setAddFolderPopup(false);
              setIsFolderRename(false);
              dispatch(handleABFolderListing());
              //handle folder Title when Rename
              if (isRenameFolderId == StoreAppFolderId && StoreAppFolderId !== 0) {
                dispatch(setABFolderTitle(folderInfo.folderName));
              }
            }
          })
        );
      }
    }
    if (folderInfo.folderName === "") {
      setSelectedRows([]);
      setFolderAlert(true);
    }
  };
  //Folder Select
  const handleFolderSelectAB = (folderDetail, isActionButtonStatus = false) => {
    window.scrollTo(0, 0)
    //handle App Build Search Value by Folder
    onSelectFolderSearch(true);
    setInterval(() => {
      onSelectFolderSearch(false);
    }, 2000);
    //Select App Folder Title
    if (folderDetail.build_folder_id == 0) {
      onSelectAppFolderTitle("User Builds");
      afterOnDragAppTitle("User Builds")
    } else {
      onSelectAppFolderTitle(folderDetail.build_folder_title);
      afterOnDragAppTitle(folderDetail.build_folder_title)
    }
    setIsActionButton(isActionButtonStatus);
    setFolderSelected(folderDetail);
    setFolderInfo({
      folderName: folderDetail.build_folder_title,
      folderId: folderDetail.build_folder_id
    });
    setActive(folderDetail.build_folder_id);
    //Store Folder ID
    dispatch(setABFolderId(folderDetail.build_folder_id));
    //Store Folder TITLE
    if (folderDetail.build_folder_id == 0) {
      dispatch(setPageABFolderBuildList(isFolderScroll))
      dispatch(setPageABCustomBuildList(isFolderScroll))
      dispatch(setABFolderTitle("User Builds"));
    } else {
      dispatch(setPageABCustomBuildList(isFolderScroll))
      dispatch(setPageABFolderBuildList(isFolderScroll))
      dispatch(setABFolderTitle(folderDetail.build_folder_title));
    }
    //Store Library Folder ID
    dispatch(setABLibraryFolderId(""));
    //Store Library Folder TITLE
    dispatch(setABLibraryFolderTitle(""));
    onSelectAppFolder(folderDetail.build_folder_id, folderDetail.title);
    // if (folderDetail.build_folder_id === 0) {
    //   dispatch(handleABFolderListing({}));
    // }
  };
  //handle Delete Modal for Folder
  const handleFolderDeleteAB = (deleteAB) => {
    setFolderAction(!folderAction);
    setSelectedRows([deleteAB]);
    setAlert(true);
  };
  //Delete Folder By Folder ID
  const handleFolderDeleteByFolderId = () => {
    //AB folder Delete
    setIsHandleModal(true)
    dispatch(handleDeleteABFolder({ build_folder_id: [...selectedRows][0].build_folder_id }, (status) => {
      if (status) {
        setIsHandleModal(false)
        setAlert(false);
        setSelectedRows([]);
      }
    }));
    if (isActive === [...selectedRows][0].build_folder_id) {
      //Default First Folder selected when AB folder Delete
      dispatch(setABFolderId(0))
      setActive(0);
      onSelectAppFolderTitle(dispatch(setABFolderTitle("User Builds")));
      onSelectAppFolder(0);
    }
    // setAlert(false);
    // setSelectedRows([]);
  };
  const getListStyle = (isDraggingOver) => {
    return {
      background: isDraggingOver ? "#B0CE47" : "",
      borderBottomLeftRadius: "25px",
      borderTopLeftRadius: "25px",
    };
  };
  //Library Folder Select
  const handleFolderLibrarySelectMB = (folderDetails) => {
    //Disable Folder Delete and Edit Button By Library Folder ID
    if (folderDetails.category_id) {
      setIsActionButton(true);
    }
    onSelectAppLibraryFolder(folderDetails);
    setActive(folderDetails.category_id);
    //Store Library Folder ID
    dispatch(setABLibraryFolderId(folderDetails.category_id));
    //Store Library Folder TITLE
    dispatch(setABLibraryFolderTitle(folderDetails.category_title));
    //Get App Library build list
    // dispatch(handleGetAppLibraryBuild({ volume_id: folderDetails.category_id }));
    // dispatch(getActiveSubscriptionsBySite({ site_id: 3 }, (status) => {
    //   if (status.count > 0) {
    //     handleDuplicateBuild(true)
    //   }
    // }))
  };
  //Open Action By Folder ID
  const handleFolderAction = (folderId) => {
    setFolderAction(!folderAction);
    setIsFolderActionId(folderId.build_folder_id);
    setFolderInfo({
      folderName: folderId.build_folder_title,
      folderId: folderId.build_folder_id,
    });
  };
  //DropDown(Action) Close By Folder ID
  const closeDropDown = (e) => {
    let click = e.target.closest("#DropDownClose");
    if (!click) {
      setFolderAction(false);
    }
  };
  useEffect(() => {
    window.addEventListener("click", closeDropDown);
    return () => window.removeEventListener("click", closeDropDown);
  }, [closeDropDown]);
  return (
    <div className="side_nav">
      <div className="logo_header">
        <Link to="/">
          <img src={growLogo} alt="" className="big-logo" />
          <img src={growLogo} alt="" className="mini-logo" />
        </Link>
      </div>
      <div className="menu-header">
        <h5 className="mb0 fw-600 siderbar-heading-text">FOLDERS</h5>
        <img onClick={() => handleFolderAddAB(folderSelected, true)} src={addIcon} alt="add"
          className={!toggleMenu ? "d-none" : "text-success folder-toolbar-btn fas fa-plus  align-self-center add-plus"}
          data-toggle="modal" data-target="#addFolder" />
      </div>
      {/* Add and Edit FOLDER Name Modal */}
      <Modal isOpen={addFolderPopup} className="modal-dialog modal-dialog-centered" role="document" centered >
        <div className="modal-content">
          <div className="modal-body modal_popup">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
              onClick={() => handleAddFolderPopupClose()}>
              <img src={modalClose} alt="modal-close" />
            </button>
            <div className="modal_title">
              <h3>{isFolderRename ? "Rename Folder" : "Add Folder"}</h3>
            </div>
            <div className="modal_data editImageModalData">
              <div className="row">
                <div className="col-md-12 form-group">
                  <label className="form-label">Folder name</label>
                  <input className="form-control" placeholder="Enter folder name" type="text" name="folderName"
                    autoFocus value={folderInfo.folderName} onChange={handleGetFolderNameAB} /><br />
                  {isFolderMatch === "Folder already exist" ? <div className="form-label alert alert-danger folderExist">Folder already exist</div> : null}
                </div>
                <div className="col-md-12 modal-btn">
                  <button className="btn secondary-btn mr-2" onClick={() => handleAddFolderPopupClose()} disabled={isHandleModal === true}>cancel</button>
                  {isHandleModal === true ? <button className="btn primary-btn">Saving...</button> : <button className="btn primary-btn" onClick={() => handleSaveFolderAB()} disabled={!buttonEnable}>Save</button>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* <!-- modal delete  --> */}
      <Modal isOpen={alert} className="modal-dialog modal-dialog-centered" role="document" centered >
        <div className="modal-content">
          <div className="modal-body modal_popup">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => handleAddFolderPopupClose()}>
              <img src={modalClose} alt="modal-close" />
            </button>
            <div className="modal_data editImageModalData">
              <div className="row">
                <div className="col-md-12 text-center mt-5">
                  <img src={deleteAlert} alt="red-info" />
                </div>
                <div className="col-md-12 text-center mb20 mt15">
                  <h1 className="fw-600 dark-text ">Are you sure you want to delete?</h1>
                </div>
                <div className="col-md-12 modal-btn">
                  <button className="btn secondary-btn mr-2" data-dismiss="modal" onClick={() => handleAddFolderPopupClose()} disabled={isHandleModal === true}>
                    cancel
                  </button>
                  {isHandleModal === true ? <button className="btn delete-border-btn ml-3">
                    Deleting...
                  </button> : <button className="btn delete-border-btn ml-3" onClick={() => handleFolderDeleteByFolderId()} >
                    Delete
                  </button>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ul>
        <li className={`${isActive === 0 ? "active" : "0"}`} onClick={() => handleFolderSelectAB({ build_folder_id: 0 }, true)}>
          <div className="folder-info">
            <img src={folderIcon} alt="folder" title="Folder" />
            <span data-bs-toggle="collapse" data-bs-target="#collapseFolder" aria-expanded="true" aria-controls="collapseFolder">
              User Builds
            </span>
          </div>
        </li>
        {AppBuilderFolderListing.map((folder, i) => (
          <Droppable key={i} droppableId={"" + folder.build_folder_id}>
            {(provided, snapshot) => (<li key={i} className={`${isActive === folder.build_folder_id ? "active" : "0"}`} style={getListStyle(
              snapshot.isDraggingOver,
              folder.build_folder_id
            )}>
              <div onClick={() => handleFolderSelectAB(folder)} className={toggleMenu ? "folder-info" : "folder-mini"}
                key={folder.build_folder_id} ref={provided.innerRef}
                {...provided.droppableProps}>
                <img src={folderIcon} alt="folder" title="Folder" />
                <span data-bs-toggle="collapse" data-bs-target="#collapseFolder1"
                  aria-expanded="false" aria-controls="collapseFolder" >
                  {folder.build_folder_title}
                </span>
              </div>
              <div className="dropdown">
                <div className="" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-expanded="false">
                  <img src={dotActionIcon} alt="dot" className="m-0" onClick={() => handleFolderAction(folder)} id="DropDownClose" />
                </div>
                <div className={isFolderActionId === folder.build_folder_id && folderAction ? "dropdown-menu show folderDropDown" : "dropdown-menu"} aria-labelledby="dropdownMenuLink" >
                  <div className="dropdown-item" data-toggle="modal" data-target="#renameFolder1" onClick={() => handleFolderEditAB(folder)}>
                    Rename
                  </div>
                  <div className="dropdown-item" data-toggle="modal" data-target="#deleteModal"
                    onClick={() => handleFolderDeleteAB(folder)}
                    disabled={isActionButton} >
                    Delete
                  </div>
                </div>
              </div>
            </li>
            )}
          </Droppable>
        ))}
      </ul>
      <div className="menu-header">
        <h5 className="mb0 fw-600 siderbar-heading-text">LIBRARY</h5>
      </div>
      <ul className="library app-builder-library">
        {LibraryListing && LibraryListing.map((libraryFolder, i) => (
          libraryFolder.category_title == "Free VBS" ? "" :
            <li
              key={i}
              onClick={() => isActive === libraryFolder.category_id ? null : handleFolderLibrarySelectMB(libraryFolder)}
              className={`${isActive === libraryFolder.category_id ? "active" : "0"}`} >
              <div className={toggleMenu ? "folder-info" : "folder-mini"} key={i}>
                <img src={libraryFolderIcon} title="Library" />
                <span>{libraryFolder.category_title}</span>
              </div>
            </li>
        ))}
      </ul>
    </div>
  );
}