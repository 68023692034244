import {
  FETCH_APP_SERIES_LISTING,
  FETCH_APP_SERIES_LISTING_SUCCESS,
  FETCH_APP_SERIES_LISTING_ERROR,
  SERIES_DETAIL_DUPLICATE,
  SERIES_DETAIL_DUPLICATE_SUCCESS,
  SERIES_DETAIL_DUPLICATE_ERROR,
  SERIES_DETAIL_SHARE_LINK,
  SERIES_DETAIL_SHARE_LINK_SUCCESS,
  SERIES_DETAIL_SHARE_LINK_ERROR,
  SERIES_DETAIL_DELETE,
  SERIES_DETAIL_DELETE_SUCCESS,
  SERIES_DETAIL_DELETE_ERROR,
  FETCH_SERIES_ELEMENT_LISTING,
  FETCH_SERIES_ELEMENT_LISTING_SUCCESS,
  FETCH_SERIES_ELEMENT_LISTING_ERROR,
  ADD_SERIES,
  ADD_SERIES_SUCCESS,
  ADD_SERIES_ERROR,
  SAVE_APP_VOLUME_DATA,
  SAVE_APP_VOLUME_DATA_SUCCESS,
  SAVE_APP_VOLUME_DATA_ERROR,
  SERIES_DRAG_DROP,
  SERIES_DRAG_DROP_SUCCESS,
  SERIES_DRAG_DROP_ERROR,
  SAVE_APP_SERIES_TITLE,
  SAVE_APP_SERIES_TITLE_SUCCESS,
  SAVE_APP_SERIES_TITLE_ERROR,
  SET_AB_SECTION,
  SET_AB_SERIES,
  SET_VISIBLE_APP_UPDATE,
  FETCH_AB_SERIES_IMPORT,
  FETCH_AB_SERIES_IMPORT_SUCCESS,
  FETCH_AB_SERIES_IMPORT_ERROR,
  AB_VOLUME_LENGTH,
  AB_RESET_SERIES_DATA,
} from "./actionTypes";

import { axiosBuilder } from "services/api";
import {
  DuplicateSeriesDetail,
  DeleteSeriesDetail,
  ShareLinkSeriesDetail,
  AppBuilderDetailElements,
  SeriesDetail,
  AddSeries,
  SeriesSave,
  seriesDragAndDrop,
  SeriesTitleSave,
  getImportSeries
} from "constants/urls";
import { toast } from "react-toastify";

//SeriesDetail Listing

export const handleFetchSeriesDetail = (payload, cb = () => { }) => {
  return async (dispatch) => {
    try {
      dispatch(fetchSeriesDetail(true));

      const res = await axiosBuilder.post(SeriesDetail, payload);

      if (res.data.data) {
        dispatch(fetchSeriesDetailSuccess(res.data.data));

        cb(res.data.data.gb_series_buildlists);
      }
    } catch (err) {
      dispatch(
        fetchSeriesDetailError(err.response?.data?.message || err.message)
      );
    }
  };
};

//VOLUME Title Save
export const handleSaveSeriesDetail = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch(SaveSeriesDetail(true));
      const res = await axiosBuilder.post(SeriesSave, payload);
      if (res.data) {
        dispatch(SaveSeriesDetailSuccess(res.data.data.data));
        cb(res.data.data);
      }
    } catch (err) {
      dispatch(
        SaveSeriesDetailError(err.response?.data?.message || err.message)
      );
    }
  };
};

//Series Title Save
export const handleSaveTitleSeriesDetail = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(SaveTitleSeriesDetail(true));
      const res = await axiosBuilder.post(SeriesTitleSave, payload);
      if (res.data.data) {
        dispatch(SaveTitleSeriesDetailSuccess(res.data.data));
        dispatch(
          handleFetchSeriesDetail({
            volume_id: payload.volume_id,
          })
        );
      }
    } catch (err) {
      dispatch(
        SaveTitleSeriesDetailError(err.response?.data?.message || err.message)
      );
    }
  };
};

//drag drop series
export const handleSeriesDragDrop = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(seriesDragDrop(true));
      const res = await axiosBuilder.post(seriesDragAndDrop, payload);
      if (res.data.data.data) {
        dispatch(seriesDragDropSuccess(res.data.data.data));
      }
    } catch (err) {
      dispatch(seriesDragDropError(err.response?.data?.message || err.message));
    }
  };
};

//Series Title Save
export const SaveSeriesDetail = (data) => {
  return {
    type: SAVE_APP_VOLUME_DATA,
    payload: data,
  };
};
export const SaveSeriesDetailSuccess = (data) => {
  return {
    type: SAVE_APP_VOLUME_DATA_SUCCESS,
    payload: data,
  };
};
export const SaveSeriesDetailError = (data) => {
  return {
    type: SAVE_APP_VOLUME_DATA_ERROR,
    payload: data,
  };
};

//Series Title Save
export const SaveTitleSeriesDetail = (data) => {
  return {
    type: SAVE_APP_SERIES_TITLE,
    payload: data,
  };
};
export const SaveTitleSeriesDetailSuccess = (data) => {
  return {
    type: SAVE_APP_SERIES_TITLE_SUCCESS,
    payload: data,
  };
};
export const SaveTitleSeriesDetailError = (data) => {
  return {
    type: SAVE_APP_SERIES_TITLE_ERROR,
    payload: data,
  };
};

//SeriesDetail Duplicate
export const handleSeriesDetailDuplicate = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(SeriesDetailDuplicate(true));
      const res = await axiosBuilder.post(DuplicateSeriesDetail, payload);
      if (res.data) {
        dispatch(SeriesDetailDuplicateSuccess(res.data.data));
        dispatch(
          handleFetchSeriesDetail({
            volume_id: res.data.data.volume_id,
          })
        );
        toast.success(res.data?.message);
      }
    } catch (err) {
      dispatch(
        SeriesDetailDuplicateError(err.response?.data?.message || err.message)
      );
    }
  };
};

//SeriesDetail Share link
export const handleSeriesDetailShareLink = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(SeriesDetailShareLink(true));
      const res = await axiosBuilder.post(ShareLinkSeriesDetail, payload);

      if (res.data.data.data) {
        dispatch(SeriesDetailShareLinkSuccess(res.data.data.data));
      }
    } catch (err) {
      dispatch(
        SeriesDetailShareLinkError(err.response?.data?.message || err.message)
      );
    }
  };
};

//Series detail Delete
export const handleSeriesDetailDelete = (payload, cb = () => { }) => {
  return async (dispatch) => {
    try {
      dispatch(SeriesDetailDelete(true));
      const res = await axiosBuilder.post(DeleteSeriesDetail, payload);
      if (res.data.statusCode) {
        dispatch(SeriesDetailDeleteSuccess(res.data));
        cb(res.data.data)
        toast.success(res.data?.message);
      }
    } catch (err) {
      dispatch(
        SeriesDetailDeleteError(err.response?.data?.message || err.message)
      );
    }
  };
};

/**App Builder Series Import*/
export const handleABSeriesImport = (
  payload, cb
) => {
  return async (dispatch) => {
    try {
      dispatch(fetchABSeriesImport(true));
      const res = await axiosBuilder.post(getImportSeries, payload);
      if (res.data.data) {
        dispatch(fetchABSeriesImportSuccess(res.data.data))
        cb(res.data.data)
        dispatch(handleFetchSeriesDetail({
          volume_id: payload.volume_id,
        })
        );
      }
    } catch (err) {
      dispatch(fetchABSeriesImportError(err.response?.data?.message || err.message))
    }
  };
};

//SeriesDetail Listing
export const fetchSeriesDetail = (data) => {
  return {
    type: FETCH_APP_SERIES_LISTING,
    payload: data,
  };
};
export const fetchSeriesDetailSuccess = (data) => {
  return {
    type: FETCH_APP_SERIES_LISTING_SUCCESS,
    payload: data,
  };
};
export const fetchSeriesDetailError = (data) => {
  return {
    type: FETCH_APP_SERIES_LISTING_ERROR,
    payload: data,
  };
};

//SeriesDetail Delete
export const SeriesDetailDelete = (data) => {
  return {
    type: SERIES_DETAIL_DELETE,
    payload: data,
  };
};
export const SeriesDetailDeleteSuccess = (data) => {
  return {
    type: SERIES_DETAIL_DELETE_SUCCESS,
    payload: data,
  };
};
export const SeriesDetailDeleteError = (data) => {
  return {
    type: SERIES_DETAIL_DELETE_ERROR,
    payload: data,
  };
};

//SeriesDetail duplicate
export const SeriesDetailDuplicate = (data) => {
  return {
    type: SERIES_DETAIL_DUPLICATE,
    payload: data,
  };
};
export const SeriesDetailDuplicateSuccess = (data) => {
  return {
    type: SERIES_DETAIL_DUPLICATE_SUCCESS,
    payload: data,
  };
};
export const SeriesDetailDuplicateError = (data) => {
  return {
    type: SERIES_DETAIL_DUPLICATE_ERROR,
    payload: data,
  };
};

//SeriesDetail Share Link
export const SeriesDetailShareLink = (data) => {
  return {
    type: SERIES_DETAIL_SHARE_LINK,
    payload: data,
  };
};
export const SeriesDetailShareLinkSuccess = (data) => {
  return {
    type: SERIES_DETAIL_SHARE_LINK_SUCCESS,
    payload: data,
  };
};
export const SeriesDetailShareLinkError = (data) => {
  return {
    type: SERIES_DETAIL_SHARE_LINK_ERROR,
    payload: data,
  };
};

//SeriesElement Listing
export const handleFetchSeriesElement = (payload, cb = () => { }) => {
  return async (dispatch) => {
    try {
      dispatch(fetchSeriesElement(true));
      const res = await axiosBuilder.post(AppBuilderDetailElements, payload);
      if (res.data) {
        dispatch(fetchSeriesElementSuccess(res.data.data));
        cb(res.data.data.series_build_list_id);
      }
    } catch (err) {
      dispatch(
        fetchSeriesElementError(err.response?.data?.message || err.message)
      );
    }
  };
};

//SeriesElement Listing
export const fetchSeriesElement = (data) => {
  return {
    type: FETCH_SERIES_ELEMENT_LISTING,
    payload: data,
  };
};
export const fetchSeriesElementSuccess = (data) => {
  return {
    type: FETCH_SERIES_ELEMENT_LISTING_SUCCESS,
    payload: data,
  };
};
export const fetchSeriesElementError = (data) => {
  return {
    type: FETCH_SERIES_ELEMENT_LISTING_ERROR,
    payload: data,
  };
};

//Series Add
export const handleAddSeries = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(addSeries(true));
      const res = await axiosBuilder.post(AddSeries, payload);
      if (res.data) {
        dispatch(addSeriesSuccess(res.data.data));
        dispatch(
          handleFetchSeriesDetail({
            volume_id: payload.volume_id,
          })
        );
      }
    } catch (err) {
      dispatch(addSeriesError(err.response?.data?.message || err.message));
    }
  };
};

//Series Add
export const addSeries = (data) => {
  return {
    type: ADD_SERIES,
    payload: data,
  };
};
export const addSeriesSuccess = (data) => {
  return {
    type: ADD_SERIES_SUCCESS,
    payload: data,
  };
};
export const addSeriesError = (data) => {
  return {
    type: ADD_SERIES_ERROR,
    payload: data,
  };
};

//series Drag drop
export const seriesDragDrop = (data) => {
  return {
    type: SERIES_DRAG_DROP,
    payload: data,
  };
};
export const seriesDragDropSuccess = (data) => {
  return {
    type: SERIES_DRAG_DROP_SUCCESS,
    payload: data,
  };
};
export const seriesDragDropError = (data) => {
  return {
    type: SERIES_DRAG_DROP_ERROR,
    payload: data,
  };
};
export const setABSection = (data) => {
  return {
    type: SET_AB_SECTION,
    payload: data,
  };
};
export const setABSeries = (data) => {
  return {
    type: SET_AB_SERIES,
    payload: data,
  };
};

//On/Off Slider App Elements
export const setVisibleAppUpdate = (data) => {
  return {
    type: SET_VISIBLE_APP_UPDATE,
    payload: data,
  };
};


/**App Builder Series Import*/
export const fetchABSeriesImport = (data) => {
  return {
    type: FETCH_AB_SERIES_IMPORT,
    payload: data
  }
}
export const fetchABSeriesImportSuccess = (data) => {
  return {
    type: FETCH_AB_SERIES_IMPORT_SUCCESS,
    payload: data
  }
}
export const fetchABSeriesImportError = (data) => {
  return {
    type: FETCH_AB_SERIES_IMPORT_ERROR,
    payload: data
  }
}

export const handleVolumeLength = (data) => {
  return {
    type: AB_VOLUME_LENGTH,
    payload: data
  }
}

export const handleResetVolumeData = data => {
  return {
    type: AB_RESET_SERIES_DATA,
    payload: data,
  };
};
