import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import AppBuilderPrintBuilds from "modules/App/Print/components/AppBuilderPrintBuilds";
import Header from "components/common/Header/Header";
import Footer from "components/common/Footer/Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import backImage from "assets/images/icon/back.png";
import { useSelector } from "react-redux";
import unauthorizedUserImage from "assets/images/unauthorizedUserImage.svg";

export const AppBuilderPrint = () => {
  const [isPrintElements, setIsPrintElements] = useState();
  const params = useParams();
  const componentRef = useRef();
  const { ABTeachingElements } = useSelector(
    (state) => state.AppBuilderTeaching
  );
  const print = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `App_Builder_${new Date() / 1000}.pdf`,
  });

  useEffect(() => {
    setIsPrintElements(ABTeachingElements.count);
  }, [ABTeachingElements]);

  const printCss = `@media print {
    .print-tools-box.tools-left .bib-left {
      width: auto !important;
    }
    .print-tools-box.tools-left .bib-right {
      width: auto !important;
    }
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
    .page-break {
      margin-bottom: 20rem;
      display: block;
      page-break-before: auto;
    }
  }

  @page {
    size: auto;
    margin: 15mm 10mm;
  }
  .MBdetail{ 
    page-break-inside: avoid;
  }
  `;

  return (
    <div className="no-sidebar">
      <div className="main_content">
        <Header />
        <div className="main_card main_card_body">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/builder">
                  {" "}
                  <span>Home</span>
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                App Builder
              </li>
            </ol>
          </nav>
          <div className="main_card_wrapper tabbing-card">
            <div className="wrapper-heading-area filter-area">
              <Link className="title" to="/app-builder">
                <div>
                  {" "}
                  <img src={backImage} />
                </div>
                <h1 className="ml15 wrapper-heading fw-600 mb0">
                  Back To Your App Builder
                </h1>
              </Link>
              {isPrintElements != 0 ? (
                <div className="new-builder-action-button">
                  <Link
                    to={"/app-builder/edit/" + params.id}
                    className={!isPrintElements ? "d-none" : "btn primary-btn"}
                  >
                    Edit
                  </Link>
                </div>
              ) : null}
            </div>
          </div>
          <div className="main-container">
            {isPrintElements === 0 ? (
              <div className="main_card_wrapper tabbing-card">
                <div className="unauthorized_user_image col-lg-12">
                  <img
                    src={unauthorizedUserImage}
                    alt="unauthorizedUserImage"
                    className="unauthorizedUserImage"
                  />
                </div>
              </div>
            ) : (
              <div className="container-fluid custom-cont-padd">
                <div className="row sub-container-area">
                  <AppBuilderPrintBuilds
                    ref={componentRef}
                    css={printCss}
                    print={print}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
