import {
  FETCH_APP_SERIES_LISTING,
  FETCH_APP_SERIES_LISTING_SUCCESS,
  FETCH_APP_SERIES_LISTING_ERROR,
  SERIES_DETAIL_DUPLICATE,
  SERIES_DETAIL_DUPLICATE_SUCCESS,
  SERIES_DETAIL_DUPLICATE_ERROR,
  SERIES_DETAIL_SHARE_LINK,
  SERIES_DETAIL_SHARE_LINK_SUCCESS,
  SERIES_DETAIL_SHARE_LINK_ERROR,
  SERIES_DETAIL_DELETE,
  SERIES_DETAIL_DELETE_SUCCESS,
  SERIES_DETAIL_DELETE_ERROR,
  FETCH_SERIES_ELEMENT_LISTING,
  FETCH_SERIES_ELEMENT_LISTING_SUCCESS,
  FETCH_SERIES_ELEMENT_LISTING_ERROR,
  ADD_SERIES,
  ADD_SERIES_SUCCESS,
  ADD_SERIES_ERROR,
  SAVE_APP_VOLUME_DATA,
  SAVE_APP_VOLUME_DATA_SUCCESS,
  SAVE_APP_VOLUME_DATA_ERROR,
  SERIES_DRAG_DROP,
  SERIES_DRAG_DROP_SUCCESS,
  SERIES_DRAG_DROP_ERROR,
  SAVE_APP_SERIES_TITLE,
  SAVE_APP_SERIES_TITLE_SUCCESS,
  SAVE_APP_SERIES_TITLE_ERROR,
  SET_AB_SECTION,
  SET_AB_SERIES,
  SET_VISIBLE_APP_UPDATE,
  FETCH_AB_SERIES_IMPORT,
  FETCH_AB_SERIES_IMPORT_SUCCESS,
  FETCH_AB_SERIES_IMPORT_ERROR,
  AB_VOLUME_LENGTH,
  AB_RESET_SERIES_DATA,
} from "./actionTypes";

const SeriesDetailInitialState = {
  SeriesSave: [],
  SeriesDetail: [],
  SeriesElements: [],
  AddSeries: null,
  loading: false,
  seriesChange: false,
  elementDuplicate: false,
  seriesSelectionLoading: false,
  addSeriesLoading: false,
  addSeriesImport: false,
  addDuplicateSeriesLoading: 0,
  search: "",
  sizePerPage: 10,
  page: 1,
  sortField: "message_build_list_id",
  sortOrder: "DESC",
  totalSize: 0,
  ABVolumeLength: 0
};

const SeriesDetailReducer = (state = SeriesDetailInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case AB_VOLUME_LENGTH:
      return {
        ...state,
        ABVolumeLength: action.payload
      }
    case AB_RESET_SERIES_DATA:
      return {
        ...state,
        SeriesElements: [],
        SeriesDetail:[],
      };
    /** SeriesDetail list */
    case FETCH_APP_SERIES_LISTING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_APP_SERIES_LISTING_SUCCESS:
      return {
        ...state,
        SeriesDetail: action.payload,
        loading: false,
        totalSize: payload.count,
      };
    case FETCH_APP_SERIES_LISTING_ERROR:
      return {
        ...state,
        loading: false,
      };

    //Series Save
    case SAVE_APP_VOLUME_DATA:
      return {
        ...state,
        seriesChange: true,
        loading: true,
      };
    case SAVE_APP_VOLUME_DATA_SUCCESS:
      return {
        SeriesSave: action.payload,
        ...state,
        seriesChange: false,
        loading: true,
      };
    case SAVE_APP_VOLUME_DATA_ERROR:
      return {
        ...state,
        loading: true,
      };

    //Series Title Save
    case SAVE_APP_SERIES_TITLE:
      return {
        ...state,
        seriesChange: true,
        abSectionLoading: true,
        loading: true,
      };
    case SAVE_APP_SERIES_TITLE_SUCCESS:
      return {
        SeriesSave: action.payload,
        ...state,
        seriesChange: false,
        abSectionLoading: false,
        loading: true,
      };
    case SAVE_APP_SERIES_TITLE_ERROR:
      return {
        ...state,
        loading: true,
      };

    //SeriesDetail DUPLICATE
    case SERIES_DETAIL_DUPLICATE:
      return {
        ...state,
        elementDuplicate: true,
        addDuplicateSeriesLoading: 2,
        loading: true,
      };
    case SERIES_DETAIL_DUPLICATE_SUCCESS:
      return {
        ...state,
        elementDuplicate: false,
        addDuplicateSeriesLoading: 0,
        loading: true,
      };
    case SERIES_DETAIL_DUPLICATE_ERROR:
      return {
        ...state,
        addDuplicateSeriesLoading: 0,
        loading: true,
      };

    //SeriesDetail share link
    case SERIES_DETAIL_SHARE_LINK:
      return {
        ...state,
        loading: true,
      };
    case SERIES_DETAIL_SHARE_LINK_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case SERIES_DETAIL_SHARE_LINK_ERROR:
      return {
        ...state,
        loading: true,
      };

    //SeriesDetail delete
    case SERIES_DETAIL_DELETE:
      return {
        ...state,
      };
    case SERIES_DETAIL_DELETE_SUCCESS:
      return {
        ...state,
      };
    case SERIES_DETAIL_DELETE_ERROR:
      return {
        ...state,
      };

    /** SeriesElement list */
    case FETCH_SERIES_ELEMENT_LISTING:
      return {
        ...state,
        seriesSelectionLoading: true,
      };
    case FETCH_SERIES_ELEMENT_LISTING_SUCCESS:
      return {
        ...state,
        SeriesElements: action.payload,
        seriesSelectionLoading: false,
        totalSize: payload.count,
      };
    case FETCH_SERIES_ELEMENT_LISTING_ERROR:
      return {
        ...state,
        seriesSelectionLoading: false,
      };

    /** Add Series */
    case ADD_SERIES:
      return {
        ...state,
        addSeriesLoading: true,
        addDuplicateSeriesLoading: 1,
      };
    case ADD_SERIES_SUCCESS:
      return {
        ...state,
        AddSeries: action.payload,
        addSeriesLoading: false,
        addDuplicateSeriesLoading: 0,
        totalSize: payload.count,
      };
    case ADD_SERIES_ERROR:
      return {
        ...state,
        addDuplicateSeriesLoading: 0,
        loading: false,
      };

    /** series drag drop */
    case SERIES_DRAG_DROP:
      return {
        ...state,
        loading: false,
      };
    case SERIES_DRAG_DROP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SERIES_DRAG_DROP_ERROR:
      return {
        ...state,
        loading: false,
      };
    //deleting element from redux
    case SET_AB_SECTION:
      return {
        ...state,
        //deleting element from redux
        SeriesElements: {
          ...state.SeriesElements,
          ...(payload.elementId && payload.isDeleteSeries == 1
            ? {
              sycu_build_elements_details:
                state.SeriesElements.sycu_build_elements_details.filter(
                  (item) =>
                    item.build_elements_details_id !== payload.elementId
                ),
            }
            : {}),
        },
      };

    //deleting SERIES from redux
    case SET_AB_SERIES:
      return {
        ...state,
        SeriesDetail: {
          ...state.SeriesDetail,
          ...(payload.elementId
            ? {
              gb_series_buildlists:
                state.SeriesDetail.gb_series_buildlists.filter(
                  (item) => item.series_build_list_id !== payload.elementId
                ),
            }
            : {}),
        },
      };

    //On/Off Slider App Elements
    case SET_VISIBLE_APP_UPDATE:
      return {
        ...state,
        SeriesElements: {
          ...state.SeriesElements,
          ...state.SeriesElements.sycu_build_elements_details.filter((item) =>
            item.build_elements_details_id === payload.id
              ? (item.is_visible = payload.sts)
              : item
          ),
        },
      };

    /**App Builder Series Import*/
    case FETCH_AB_SERIES_IMPORT:
      return {
        ...state,
        loading: true,
        addSeriesImport: true,
      };
    case FETCH_AB_SERIES_IMPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        addSeriesImport: false,
      };
    case FETCH_AB_SERIES_IMPORT_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default SeriesDetailReducer;
