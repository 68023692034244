/** App Builder list */
export const FETCH_AB_CUSTOM_BUILD_LIST = "FETCH_AB_CUSTOM_BUILD_LIST"
export const FETCH_AB_CUSTOM_BUILD_LIST_SUCCESS = "FETCH_AB_CUSTOM_BUILD_LIST_SUCCESS"
export const FETCH_AB_CUSTOM_BUILD_LIST_ERROR = "FETCH_AB_CUSTOM_BUILD_LIST_ERROR"

/** App Builder DELETE */
export const AB_DELETE = "AB_DELETE"
export const AB_DELETE_SUCCESS = "AB_DELETE_SUCCESS"
export const AB_DELETE_ERROR = "AB_DELETE_ERROR"

/** App Builder DUPLICATE */
export const AB_DUPLICATE = "AB_DUPLICATE"
export const AB_DUPLICATE_SUCCESS = "AB_DUPLICATE_SUCCESS"
export const AB_DUPLICATE_ERROR = "AB_DUPLICATE_ERROR"

//Set PageNo Of ABCustom Build List
export const SET_PAGE_AB_CUSTOM_BUILD_LIST = "SET_PAGE_AB_CUSTOM_BUILD_LIST"

/** App Builder SHARE LINK */
export const AB_SHARE_LINK = "AB_SHARE_LINK"
export const AB_SHARE_LINK_SUCCESS = "AB_SHARE_LINK_SUCCESS"
export const AB_SHARE_LINK_ERROR = "AB_SHARE_LINK_ERROR"

/** App Builder CREATE VOLUME */
export const AB_CREATE_VOLUME = "AB_CREATE_VOLUME"
export const AB_CREATE_VOLUME_SUCCESS = "AB_CREATE_VOLUME_SUCCESS"
export const AB_CREATE_VOLUME_ERROR = "AB_CREATE_VOLUME_ERROR"

/** To update redux when Volume is deleted */
export const SET_AB_VOLUME = "SET_AB_VOLUME"

/** To update redux when Volume is deleted */
export const SET_AB_LIBRARY = "SET_AB_LIBRARY"

/** Get Library Build */
export const GET_LIBRARY_BUILD = "GET_LIBRARY_BUILD"
export const GET_LIBRARY_BUILD_SUCCESS = "GET_LIBRARY_BUILD_SUCCESS"
export const GET_LIBRARY_BUILD_ERROR = "GET_LIBRARY_BUILD_ERROR"

/*Reset ABCustom Build List */
export const RESET_AB_CUSTOM_BUILD_LIST = "RESET_AB_CUSTOM_BUILD_LIST"
