import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { handleGetAllFaq } from "store/actions";
import Header from "components/common/Header/Header";
import Footer from "components/common/Footer/Footer";
import FaqLoader from "../Loader/MBLoading";
import FaqAccordion from "./FaqAccordion";
import growNoData from "assets/images/no-data.png";
import backImage from "assets/images/icon/back.png";

function Faq() {
  const [isLoader, setIsLoader] = useState(false);
  const { isAuth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (isAuth) {
      setIsLoader(false)
      dispatch(
        handleGetAllFaq({
          site_id: 3,
        }, (status) => {
          if (status) {
            setIsLoader(true)
          }
        })
      );
    }
  }, []);

  const { faqData } = useSelector((state) => state.auth);

  return (
    <div className="no-sidebar tutorialDetails">
      <Header />
      <div className="main_card main_card_body pb-0">
        <div className="main_card_wrapper tabbing-card main_card_wrapper_1">
          <div className="wrapper-heading-area filter-area">
            <div className="title">
              <Link className="" to="/builder">
                {" "}
                <img src={backImage} className="mr-2" />
                <h1 className="wrapper-heading fw-600 mb0">
                  {" "}
                  Frequently Asked Questions
                </h1>
              </Link>
            </div>
            <div className="page-filter">
            </div>
          </div>
        </div>
        {faqData.length > 0 ? <div className="main_card_wrapper tabbing-card">
          <Card style={{ borderRadius: "15px" }} className="faqCollapse">
            <CardBody>
              {faqData.map((faq, i) => {
                return <FaqAccordion faq={faq} id={i} key={i} />;
              })}
            </CardBody>
          </Card>
        </div> : (faqData.length <= 0 && isLoader ? <div className="row">
          <div className="col-lg-12">
            <img
              src={growNoData}
              className="no-data-found d-flex"
            ></img>
          </div>
        </div> : (isLoader == false ? <FaqLoader /> : null))}
      </div>
      <Footer />
    </div>
  );
}

export default Faq;
