/** App Builder folder list */
export const FETCH_AB_FOLDER_LISTING = "FETCH_AB_FOLDER_LISTING"
export const FETCH_AB_FOLDER_LISTING_SUCCESS = "FETCH_AB_FOLDER_LISTING_SUCCESS"
export const FETCH_AB_FOLDER_LISTING_ERROR = "FETCH_AB_FOLDER_LISTING_ERROR"

/** App Builder folder add/edit */
export const AB_FOLDER_ADD_EDIT = "AB_FOLDER_ADD_EDIT"
export const AB_FOLDER_ADD_EDIT_SUCCESS = "AB_FOLDER_ADD_EDIT_SUCCESS"
export const AB_FOLDER_ADD_EDIT_ERROR = "AB_FOLDER_ADD_EDIT_ERROR"

/** Message Builder folder delete*/
export const AB_FOLDER_DELETE = "AB_FOLDER_DELETE"
export const AB_FOLDER_DELETE_SUCCESS = "AB_FOLDER_DELETE_SUCCESS"
export const AB_FOLDER_DELETE_ERROR = "AB_FOLDER_DELETE_ERROR"

/** Get AB USING FOLDER ID*/
export const FETCH_AB_FOLDER_BUILD_LIST = "FETCH_AB_FOLDER_BUILD_LIST"
export const FETCH_AB_FOLDER_BUILD_LIST_SUCCESS = "FETCH_AB_FOLDER_BUILD_LIST_SUCCESS"
export const FETCH_AB_FOLDER_BUILD_LIST_ERROR = "FETCH_AB_FOLDER_BUILD_LIST_ERROR"

/** Get Library Folder using type*/
export const GET_LIBRARY_FOLDER_BY_TYPE = "GET_LIBRARY_FOLDER_BY_TYPE"
export const GET_LIBRARY_FOLDER_BY_TYPE_SUCCESS = "GET_LIBRARY_FOLDER_BY_TYPE_SUCCESS"
export const GET_LIBRARY_FOLDER_BY_TYPE_ERROR = "GET_LIBRARY_FOLDER_BY_TYPE_ERROR"

/** Get Library Folder using type*/
export const GET_LIBRARY_BUILD = "GET_LIBRARY_BUILD"
export const GET_LIBRARY_BUILD_SUCCESS = "GET_LIBRARY_BUILD_SUCCESS"
export const GET_LIBRARY_BUILD_ERROR = "GET_LIBRARY_BUILD_ERROR"

export const SET_AB_LISTING_BY_FOLDER_ID = "SET_AB_LISTING_BY_FOLDER_ID"

/*Store Folder ID */
export const SET_AB_FOLDER_ID = "SET_AB_FOLDER_ID"

/*Store Folder TITLE */
export const SET_AB_FOLDER_TITLE = "SET_AB_FOLDER_TITLE"

/*Store Library Folder ID */
export const SET_AB_LIBRARY_FOLDER_ID = "SET_AB_LIBRARY_FOLDER_ID"
/*Store Library Folder TITLE */
export const SET_AB_LIBRARY_FOLDER_TITLE = "SET_AB_LIBRARY_FOLDER_TITLE"

/* Reset AB Folder Build List */
export const RESET_AB_FOLDER_BUILD_LIST = "RESET_AB_FOLDER_BUILD_LIST"

/*Set PageNo Of AB Folder Build List  */
export const SET_PAGE_AB_FOLDER_BUILD_LIST = "SET_PAGE_AB_FOLDER_BUILD_LIST"
