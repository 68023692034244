import backImage from "assets/images/icon/back.png";
import unauthorizedUserImage from "assets/images/unauthorizedUserImage.svg";
import Footer from "components/common/Footer/Footer";
import Header from "components/common/Header/Header";
import { MessageBuilderTeachingElements } from "modules/Message/Teaching/components/MessageBuilderTeachingElement";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { getActiveSubscriptionsBySite, handleMBDuplicate } from "store/actions";

export const MessageBuilderTeaching = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { MBTeachingElements } = useSelector(
    (state) => state.MessageBuilderTeaching
  );
  const { StoreLibraryFolderId } = useSelector(
    (state) => state.MessageBuilderFolderList
  );

  const [isMessageList, setIsMessageList] = useState();
  const [isDuplicateButton, setIsDuplicateButton] = useState(false);
  const [isDuplicateLibraryBuild, setIsDuplicateLibraryBuild] = useState(false);

  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const qry = queryString.parse(location.search);

  // Set MBTeachingElements
  useEffect(() => {
    setIsMessageList(MBTeachingElements.count);
  }, [MBTeachingElements]);

  useEffect(() => {
    if (isAuth) {
      dispatch(
        getActiveSubscriptionsBySite({ site_id: 3 }, (status) => {
          if (status.count > 0) {
            setIsDuplicateButton(true);
          }
        })
      );
    }
  }, [dispatch]);

  const handleDuplicateLibraryBuild = () => {
    setIsDuplicateLibraryBuild(true);
    dispatch(
      handleMBDuplicate(
        {
          message_build_list_id: parseInt(params.id),
          category_id: parseInt(StoreLibraryFolderId),
          ministry_type: parseInt(qry.ministry_type),
        },
        (status) => {
          if (status.message_build_list_id) {
            setIsDuplicateLibraryBuild(false);
            history.push(`/builder/edit/${status.message_build_list_id}`);
          }
        }
      )
    );
  };

  return (
    <>
      <div className="no-sidebar MBDetails">
        <Header />
        <div className="main_card pb-0">
          {isAuth == true ? (
            <>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/builder">
                      <span>Home</span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Lesson Builder
                  </li>
                </ol>
              </nav>
              <div className="main_card_wrapper tabbing-card">
                <div className="wrapper-heading-area filter-area">
                  <div className="title">
                    <Link to="/">
                      <img src={backImage} alt="" className="mr-2" />
                      <h1 className="wrapper-heading fw-600 mb0">
                        Back To Your Lesson Builder
                      </h1>
                    </Link>
                  </div>
                  {isMessageList !== 0 ? (
                    <div className="page-filter">
                      {qry.code ? (
                        isDuplicateButton ||
                        MBTeachingElements?.sharedDashboardAccess ? (
                          <Link
                            className="btn primary-btn"
                            // to={`/builder/duplicate-build/${params.id}?parent_category_id=${`${parseInt(StoreLibraryFolderId)}`}&ministry_type=${parseInt(qry.ministry_type)}`}
                            onClick={() => handleDuplicateLibraryBuild()}
                            to="#"
                          >
                            Duplicate
                          </Link>
                        ) : null
                      ) : (
                        <Link
                          to={"/builder/edit/" + params.id}
                          className={
                            !isMessageList ? "d-none" : "btn primary-btn"
                          }
                        >
                          Edit
                        </Link>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          ) : null}
        </div>
        {isMessageList === 0 ? (
          <div className="main_card_wrapper unauthorize-build-data">
            <div className="unauthorized_user_image col-lg-12">
              <div className="main_card_wrapper tabbing-card">
                <img
                  src={unauthorizedUserImage}
                  alt="unauthorizedUserImage"
                  className="unauthorizedUserImage"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="main_card main_card_body pt-0">
            <div className={isAuth == true ? "row" : "row mt50"}>
              <MessageBuilderTeachingElements />
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};
