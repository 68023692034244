import {
  FETCH_AB_TEACHING_ELEMENTS,
  FETCH_AB_TEACHING_ELEMENTS_SUCCESS,
  FETCH_AB_TEACHING_ELEMENTS_ERROR,
} from "./actionTypes";

const ABElementsInitialState = {
  ABTeachingElements: [],
}

const ABTeachingElementReducer = (state = ABElementsInitialState, action) => {
  const { type, payload } = action
  switch (type) {
    /** App Builder Teaching list */
    case FETCH_AB_TEACHING_ELEMENTS:
      return {
        ...state,
        loading: true,
      }
    case FETCH_AB_TEACHING_ELEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        ABTeachingElements: action.payload,
        totalSize: payload.length,
      }
    case FETCH_AB_TEACHING_ELEMENTS_ERROR:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
export default ABTeachingElementReducer
