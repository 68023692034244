import React from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";

const WarningAlert = ({
  children,
  title = "Are you sure?",
  confirmButtonText = "Ok",
  onConfirm,
  onCancel,
}) => {
  return (
    <SweetAlert
      title={title}
      warning
      showCancel
      confirmButtonText={confirmButtonText}
      confirmBtnBsStyle="success"
      cancelBtnBsStyle="danger"
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      {children}
    </SweetAlert>
  )
}

WarningAlert.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  confirmButtonText: PropTypes.string,
  onConfirm: PropTypes.any,
  onCancel: PropTypes.any,
}

export default WarningAlert
