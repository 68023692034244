import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext } from "react-beautiful-dnd";
import { handleDragDropOfBuild } from "store/actions";
import MessageBuilder from "./components/BuildersList";
import Sidebar from "modules/Message/Folders";
import MessageBuilderLibrary from "../Builder/BuilderLibrary/index";
import { setPageMBFolderBuildList } from "../Folders/store/actions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const MessageBuilderListing = () => {
  const dispatch = useDispatch();
  const [folderId, setFolderId] = useState(0);
  const [defaultUserFolder, setDefaultUserFolder] = useState(0);
  const [libraryFolderId, setLibraryFolderId] = useState(true);
  const [isLibrary, setIsLibrary] = useState(false);
  const [isFolder, setIsFolder] = useState(true);
  const [folderTitle, setFolderTitle] = useState("User Builds");
  const [folderTitleState, setFolderTitleState] = useState("");
  const [messageFolderName, setMessageFolderName] = useState(false);
  const [isSearchValue, setIsSearchValue] = useState(false);
  const [isLibrarySearchValue, setIsLibrarySearchValue] = useState(false);
  const [buildLibraryLoading, setBuildLibraryLoading] = useState(false);
  const [selectedLibraryTab, setSelectedLibraryTab] = useState(false);
  const [isFolderScroll, setIsFolderScroll] = useState(1);
  const [isCategoryId, setIsCategoryId] = useState(0);
  const [isMinistryType, setIsMinistryType] = useState(null);
  const [isAfterOnDragTitle, setIsAfterOnDragTitle] = useState("User Builds");
  const [isOnDrag, setIsOnDrag] = useState(false);
  const [isDuplicateBuild, setIsDuplicateBuild] = useState(false);
  const [isButton, setIsButton] = useState(false);
  const { LibraryListing } = useSelector(
    (state) => state.MessageBuilderFolderList
  );
  const onDragEnd = (dragDropDetails) => {
    if (dragDropDetails.destination !== null) {
      let messageId = dragDropDetails.draggableId;
      let folderId = dragDropDetails.destination.droppableId;
      if (folderId != "rowsList") {
        setIsOnDrag(true);
        dispatch(
          handleDragDropOfBuild(
            {
              folder_id: folderId,
              id: messageId,
              type: 1,
            },
            (data) => {
              setFolderId(parseInt(folderId));
              dispatch(setPageMBFolderBuildList(isFolderScroll));
              setMessageFolderName(true);
              setIsAfterOnDragTitle(data.build_folder_title);
              setIsOnDrag(false);
            }
          )
        );
      }
    }
  };

  //Select Message Folder ID
  const onSelectFolder = (onSelectFolder) => {
    setFolderId(onSelectFolder);
    setFolderTitle(onSelectFolderTitle);
    setIsFolder(true);
    setIsLibrary(false);
  };
  //Select Message Folder Title
  const onSelectFolderTitle = (onSelectFolderTitle) => {
    setFolderTitle(onSelectFolderTitle);
  };

  // Select Library Folder
  const onSelectLibraryFolder = (onSelectLibraryFolder) => {
    setLibraryFolderId(onSelectLibraryFolder);
    setIsFolder(false);
    setIsLibrary(true);
  };

  const setDefaultFolder = (setDefaultFolder) => {
    setDefaultUserFolder(setDefaultFolder);
  };
  //Select Message Search Value by Folder
  const onSelectFolderSearch = (onSelectFolderSearch) => {
    setIsSearchValue(onSelectFolderSearch);
  };
  //Select Message  Search Value by Library Folder
  const onSelectLibraryFolderSearch = (onSelectLibraryFolderSearch) => {
    setIsLibrarySearchValue(onSelectLibraryFolderSearch);
  };

  //Select Message Library Folder Loader
  const onSelectLibraryLoader = (onSelectLibraryLoader) => {
    setBuildLibraryLoading(onSelectLibraryLoader);
  };
  //Search LibraryLoader
  const onSelectLibraryTypeLoader = (onSelectLibraryTypeLoader) => {
    setBuildLibraryLoading(onSelectLibraryTypeLoader);
  };
  // For Toggle Menu
  const { toggleMenu } = useSelector((state) => state.auth);

  const onSelectedLibraryTab = (onSelectedLibraryTab) => {
    setSelectedLibraryTab(onSelectedLibraryTab);
  };
  const afterOnDragTitle = (afterOnDragTitle) => {
    setIsAfterOnDragTitle(afterOnDragTitle);
  };

  const handleCategoryId = (handleCategoryId) => {
    setIsCategoryId(handleCategoryId);
  };

  const handleMinistryType = (handleMinistryType) => {
    setIsMinistryType(handleMinistryType);
  };

  const handleDuplicateBuild = (handleDuplicateBuild) => {
    setIsDuplicateBuild(handleDuplicateBuild);
  };

  const handleButton = (handleButton) => {
    setIsButton(handleButton);
  };

  return (
    <div className={!toggleMenu ? "mini-menu" : ""}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Sidebar
          onSelectFolder={onSelectFolder}
          onSelectFolderTitle={onSelectFolderTitle}
          _isActive={folderId}
          onSelectLibraryFolder={onSelectLibraryFolder}
          defaultUserFolder={defaultUserFolder}
          onSelectFolderSearch={onSelectFolderSearch}
          isSearchValue={isSearchValue}
          onSelectLibraryFolderSearch={onSelectLibraryFolderSearch}
          onSelectLibraryLoader={onSelectLibraryLoader}
          onSelectedLibraryTab={onSelectedLibraryTab}
          afterOnDragTitle={afterOnDragTitle}
          handleCategoryId={handleCategoryId}
          handleMinistryType={handleMinistryType}
          handleDuplicateBuild={handleDuplicateBuild}
          handleButton={handleButton}
        />
        {isFolder ? (
          <MessageBuilder
            folderId={folderId}
            folderTitle={folderTitle}
            folderTitleState={folderTitleState}
            messageFolderName={messageFolderName}
            isSearchValue={isSearchValue}
            isAfterOnDragTitle={isAfterOnDragTitle}
          />
        ) : (
          <MessageBuilderLibrary
            isCategoryId={isCategoryId}
            isMinistryTypeVal={
              isMinistryType || LibraryListing[0]?.ministry_type
            }
            libraryFolderId={libraryFolderId}
            setDefaultFolder={setDefaultFolder}
            buildLibraryLoading={buildLibraryLoading}
            isLibrarySearchValue={isLibrarySearchValue}
            onSelectLibraryTypeLoader={onSelectLibraryTypeLoader}
            selectedLibraryTab={selectedLibraryTab}
            isDuplicateBuild={isDuplicateBuild}
            isButton={isButton}
            onSelectLibraryLoader={onSelectLibraryLoader}
          />
        )}
      </DragDropContext>
    </div>
  );
};

export default MessageBuilderListing;
