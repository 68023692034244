const BBLoading = () => {
    return (
            <div className="ph-col-12">
                <div className="ph-row">
                    <div className="ph-col-12 big"></div>
                    <div className="ph-col-12 empty"></div>
                </div>
            </div>
    )
}

export default BBLoading;
