import {
  FETCH_AB_FOLDER_LISTING,
  FETCH_AB_FOLDER_LISTING_SUCCESS,
  FETCH_AB_FOLDER_LISTING_ERROR,
  AB_FOLDER_ADD_EDIT,
  AB_FOLDER_ADD_EDIT_SUCCESS,
  AB_FOLDER_ADD_EDIT_ERROR,
  AB_FOLDER_DELETE,
  AB_FOLDER_DELETE_SUCCESS,
  AB_FOLDER_DELETE_ERROR,
  FETCH_AB_FOLDER_BUILD_LIST,
  FETCH_AB_FOLDER_BUILD_LIST_SUCCESS,
  FETCH_AB_FOLDER_BUILD_LIST_ERROR,
  GET_LIBRARY_FOLDER_BY_TYPE,
  GET_LIBRARY_FOLDER_BY_TYPE_SUCCESS,
  GET_LIBRARY_FOLDER_BY_TYPE_ERROR,
  SET_AB_LISTING_BY_FOLDER_ID,
  SET_AB_FOLDER_ID,
  SET_AB_FOLDER_TITLE,
  SET_AB_LIBRARY_FOLDER_ID,
  SET_AB_LIBRARY_FOLDER_TITLE,
  RESET_AB_FOLDER_BUILD_LIST,
  SET_PAGE_AB_FOLDER_BUILD_LIST
} from "./actionTypes";
import { AppBuilderFolderListing, addUpdateFolderAB, deleteFolderAB, getLibraryFolderByKey, getABFolderBuildById } from "constants/urls";
import { axiosBuilder } from "services/api";
import { toast } from 'react-toastify';

//AB Folder Listing
export const handleABFolderListing = (
  payload,
) => {
  return async (dispatch) => {
    try {
      dispatch(fetchABFolderListing(true));
      const res = await axiosBuilder.post(AppBuilderFolderListing, payload);
      if (res.data.data.rows) {
        dispatch(fetchABFolderListingSuccess(res.data.data.rows))
        toast.success(res.data?.message);
      }
    } catch (err) {
      dispatch(fetchABFOlderListingError(err.response?.data?.message || err.message))
    }
  };
};


//AB folder Add/edit
export const handleAddEditABFolder = (
  payload, cb
) => {
  return async (dispatch) => {
    try {
      dispatch(ABFolderAddEdit(true));
      const res = await axiosBuilder.post(addUpdateFolderAB, payload);
      if (res.data.data) {
        dispatch(ABFolderAddEditSuccess(res.data.data))
        cb(res.data)
        toast.success(res.data?.message);
      }
    } catch (err) {

      dispatch(ABFolderAddEditError(err.response?.data?.message || err.message))
    }
  };
};

//AB folder Delete
export const handleDeleteABFolder = (
  payload, cb = () => { }
) => {
  return async (dispatch) => {
    try {
      dispatch(ABFolderDelete(true));
      const res = await axiosBuilder.post(deleteFolderAB, payload);
      if (res.data.data) {
        dispatch(ABFolderDeleteSuccess(res.data.data.data))
        dispatch(handleABFolderListing())
        cb(res.data.data)
        toast.success(res.data?.message);
      }
    } catch (err) {

      dispatch(ABFolderDeleteError(err.response?.data?.message || err.message))
    }
  };
};

//Get AB using Folder id
export const handleGetABFolderBuildById = (
  payload, cb = () => { }
) => {
  return async (dispatch) => {
    try {
      dispatch(fetchABFolderBuildList(true));
      const res = await axiosBuilder.post(getABFolderBuildById, payload);
      if (res.data.data) {
        if (payload.page_no == 1 && payload.search !== "") {
          dispatch(resetABFolderBuildList({}))
        }
        dispatch(fetchABFolderBuildListSuccess({ data: res.data.data.volume_models, count: res.data.data.count }))
        cb(res.data.data)
        toast.success(res.data?.message);
      }
    } catch (err) {
      dispatch(fetchABFolderBuildListError(err.response?.data?.message || err.message))
    }
  };
};

//Get libarary folder using type
export const handleGetLibraryFolderByType = (
  payload,
) => {
  return async (dispatch) => {
    try {
      const res = await axiosBuilder.get(getLibraryFolderByKey, payload);
      if (res.data) {
        dispatch(getLibraryFolderByTypeSuccess(res.data.data.rows))
      }
    } catch (err) {
      dispatch(getLibraryFolderByTypeError(err.response?.data?.message || err.message))
    }
  };
};

// Reset AB Folder Build List //
export const resetABFolderBuildList = () => {
  return {
    type: RESET_AB_FOLDER_BUILD_LIST,
    payload: {}
  }
}

// Set PageNo Of AB Folder Build List //
export const setPageABFolderBuildList = (data) => {
  return {
    type: SET_PAGE_AB_FOLDER_BUILD_LIST,
    payload: data
  }
}

//AB Folder Listing
export const fetchABFolderListing = (data) => {
  return {
    type: FETCH_AB_FOLDER_LISTING,
    payload: data
  }
}
export const fetchABFolderListingSuccess = (data) => {
  return {
    type: FETCH_AB_FOLDER_LISTING_SUCCESS,
    payload: data
  }
}
export const fetchABFOlderListingError = (data) => {
  return {
    type: FETCH_AB_FOLDER_LISTING_ERROR,
    payload: data
  }
}


//AB Folder Add/Edit
export const ABFolderAddEdit = (data) => {
  return {
    type: AB_FOLDER_ADD_EDIT,
    payload: data
  }
}
export const ABFolderAddEditSuccess = (data) => {
  return {
    type: AB_FOLDER_ADD_EDIT_SUCCESS,
    payload: data
  }
}
export const ABFolderAddEditError = (data) => {
  return {
    type: AB_FOLDER_ADD_EDIT_ERROR,
    payload: data
  }
}
//AB Folder Delete
export const ABFolderDelete = (data) => {
  return {
    type: AB_FOLDER_DELETE,
    payload: data
  }
}
export const ABFolderDeleteSuccess = (data) => {
  return {
    type: AB_FOLDER_DELETE_SUCCESS,
    payload: data
  }
}
export const ABFolderDeleteError = (data) => {
  return {
    type: AB_FOLDER_DELETE_ERROR,
    payload: data
  }
}

//Get AB using folder id
export const fetchABFolderBuildList = (data) => {
  return {
    type: FETCH_AB_FOLDER_BUILD_LIST,
    payload: data
  }
}
export const fetchABFolderBuildListSuccess = (data) => {
  return {
    type: FETCH_AB_FOLDER_BUILD_LIST_SUCCESS,
    payload: data
  }
}
export const fetchABFolderBuildListError = (data) => {
  return {
    type: FETCH_AB_FOLDER_BUILD_LIST_ERROR,
    payload: data
  }
}
/** To update redux when App Volume is deleted */
export const setABListingByFolderId = (data) => {
  return {
    type: SET_AB_LISTING_BY_FOLDER_ID,
    payload: data
  }
}

//Get Library Folder using Type
export const getLibraryFolderByType = (data) => {
  return {
    type: GET_LIBRARY_FOLDER_BY_TYPE,
    payload: data
  }
}

export const getLibraryFolderByTypeSuccess = (data) => {
  return {
    type: GET_LIBRARY_FOLDER_BY_TYPE_SUCCESS,
    payload: data
  }
}

export const getLibraryFolderByTypeError = (data) => {
  return {
    type: GET_LIBRARY_FOLDER_BY_TYPE_ERROR,
    payload: data
  }
}

/*Store Folder ID */
export const setABFolderId = (data) => {
  return {
    type: SET_AB_FOLDER_ID,
    payload: data,
  };
};

/*Store Folder TITLE */
export const setABFolderTitle = (data) => {
  return {
    type: SET_AB_FOLDER_TITLE,
    payload: data,
  };
};

/*Store Library Folder ID */
export const setABLibraryFolderId = (data) => {
  return {
    type: SET_AB_LIBRARY_FOLDER_ID,
    payload: data,
  };
};

/*Store Library Folder TITLE */
export const setABLibraryFolderTitle = (data) => {
  return {
    type: SET_AB_LIBRARY_FOLDER_TITLE,
    payload: data,
  };
};
