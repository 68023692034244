import { axiosAccount } from "services/api"
import {
  SET_LOADING,
  GET_SUBSCRIPTION_PRODUCT_LIST,
  GET_ACTIVE_SUBSCRIPTION_LIST,
  SET_PAGE,
  SET_PAGE_RECORD,
  SET_TOTAL_RECORDS,
} from "./actionTypes"
import {
  GET_SUBSCRIPTION_URL,
  GET_ACTIVE_SUBSCRIPTION_URL,
} from "constants/urls"
import { toast } from "react-toastify"

// get Subscription product list
export const getSubscriptionProductList = data => {
  return async dispatch => {
    try {
      dispatch(setLoading(true))
      const res = await axiosAccount.post(GET_SUBSCRIPTION_URL, data)
      if (res?.data?.data) {
        dispatch(getSubscriptionList(res.data.data.product_data))
      }
      dispatch(setLoading(false))
    } catch (err) {
      toast.error(err.response?.data?.message || err.message)
      dispatch(setLoading(false))
    }
  }
}

// get Subscription product list
export const getActiveSubscriptionsBySite = ({ site_id }, cb = () => { }) => {
  return async dispatch => {
    try {
      dispatch(setLoading(true))
      const res = await axiosAccount.get(
        `${GET_ACTIVE_SUBSCRIPTION_URL}/${site_id}`
      )
      if (res?.data?.data) {
        dispatch(getActiveSubscriptionList(res.data.data.rows))
        cb(res.data.data)
      }
      dispatch(setLoading(false))
    } catch (err) {
      toast.error(err.response?.data?.message || err.message)
      dispatch(setLoading(false))
    }
  }
}

export const setLoading = data => ({
  type: SET_LOADING,
  payload: data,
})

export const getSubscriptionList = data => {
  return {
    type: GET_SUBSCRIPTION_PRODUCT_LIST,
    payload: data,
  }
}

export const getActiveSubscriptionList = data => {
  return {
    type: GET_ACTIVE_SUBSCRIPTION_LIST,
    payload: data,
  }
}

export const setPage = data => ({
  type: SET_PAGE,
  payload: data,
})

export const setPageRecord = data => ({
  type: SET_PAGE_RECORD,
  payload: data,
})

export const setTotalRecords = data => ({
  type: SET_TOTAL_RECORDS,
  payload: data,
})
