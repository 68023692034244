import { LiveChatWidget } from "@livechat/widget-react";
import Layout from "components/common/Layout/Layout";
import queryString from "query-string";
import { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, useHistory, useLocation } from "react-router-dom";
import { get, remove, set } from "services/cookies";
import {
  fatchAccessToken,
  fetchProfile,
  fetchSites,
  getAllFooterMenuList,
  getAllMenuList,
  getProfileSidebarMenuList,
  setIsAuth,
  setToken,
} from "store/actions";
import PrivateRouteMiddleware from "./routes/PrivateRouteMiddleware";
import protectedRoutes from "./routes/privateRoutes";
import PublicRouteMiddleware from "./routes/PublicRouteMiddleware";
import publicRoutes from "./routes/publicRoutes";

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const qry = queryString.parse(location.search);
  const pageUrl = location.pathname;

  const tempToken = qry?.token;
  const { isAuth } = useSelector((state) => state.auth);

  useEffect(() => {
    window.uetq = window.uetq || [];
    window.uetq.push("event", "page_view", { page_path: window.location.href });
  }, [pageUrl]);

  useLayoutEffect(() => {
    const token = get("token");
    if (!isAuth && !!token) {
      dispatch(setToken(token));
      dispatch(fetchProfile());
      dispatch(setIsAuth(true));
      dispatch(fetchSites());
    }
    if (isAuth) {
      dispatch(getAllMenuList({ site_id: 3, menu_type: 1 }, [getAllMenuList]));
      dispatch(
        getProfileSidebarMenuList({
          menu_type: 4,
          site_id: 1,
        })
      );
      dispatch(getAllFooterMenuList({ menu_type: 2, site_id: 3 }));
    }
  }, [dispatch, isAuth]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const token = get("token");
    if (
      isAuth === false &&
      !token &&
      tempToken === undefined &&
      qry.code === undefined
    ) {
      window.location.replace(
        `${process.env.REACT_APP_REDIRECT_URL}login?app_id=3&redirect=${
          process.env.REACT_APP_SITE_URL
        }${location.pathname + location.search}`
      );
    }
  }, [
    isAuth,
    tempToken,
    dispatch,
    qry.code,
    location.pathname,
    location.search,
  ]);

  useEffect(() => {
    if (tempToken) {
      dispatch(
        fatchAccessToken({ temp_token: tempToken, site_id: 3 }, () => {
          history.replace("/");
          set("site_id", 3);
        })
      );
    }
  }, [tempToken, dispatch, history]);

  const onClose = () => {
    const mainToken = get("mainToken");
    set("token", mainToken);
    remove("mainToken");
    remove("userId");
    remove("site");
    window.location.replace(process.env.REACT_APP_ADMIN_SITE_URL);
  };

  return (
    <>
      {isAuth ? (
        <Layout>
          {!!get("mainToken") && window.location === window.parent.location && (
            <div className="bg-top-bar d-flex align-items-center justify-content-end p-3">
              <button
                className="btn bg-white text-top-bar d-flex align-items-center rounded-pill px-3 frame-close-btn shadow-lg "
                onClick={onClose}
              >
                <i className="fas fa-arrow-left mr-2" />{" "}
                <span>Back To Dashboard</span>
              </button>
            </div>
          )}
          <Switch>
            {protectedRoutes.map((item, i) => (
              <PrivateRouteMiddleware key={i} {...item} />
            ))}
            {publicRoutes.map((item, i) => (
              <PublicRouteMiddleware key={i} {...item} />
            ))}
          </Switch>
        </Layout>
      ) : (
        <Layout>
          <Switch>
            {protectedRoutes.map((item, i) => (
              <PrivateRouteMiddleware key={i} {...item} />
            ))}
            {publicRoutes.map((item, i) => (
              <PublicRouteMiddleware key={i} {...item} />
            ))}
          </Switch>
        </Layout>
      )}
      <LiveChatWidget license="8932034" />

      <script>
        {`        
      _linkedin_partner_id = "1573140";
      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
      window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    
      (function(l) {
      if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
      window.lintrk.q=[]}
      var s = document.getElementsByTagName("script")[0];
      var b = document.createElement("script");
      b.type = "text/javascript";b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      s.parentNode.insertBefore(b, s);})(window.lintrk);
    `}
      </script>

      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=1573140&fmt=gif"
        />
      </noscript>
    </>
  );
};

export default App;
