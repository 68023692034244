import React, { useMemo, useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams, useLocation } from "react-router-dom";
import { Modal, Progress, Collapse } from "reactstrap";
import TextareaAutosize from "react-textarea-autosize";
import { useDropzone } from "react-dropzone";
import { imageUrl, s3 } from "services/aws";
import {
  setVisableUpdate,
  handleMBElementDuplicate,
  handleMBElementDelete,
  setMBElements,
  handleMessageVideoElement,
  handleElementTitleChange,
  handleFetchMBElements,
  handleSaveOnElementChangeMB,
} from "store/actions";
import { handleFetchSeriesElement, setVisibleAppUpdate } from "store/actions";
import CkEditor from "components/common/CustomCkEditor/CkEditor";
import folder from "assets/images/icon/folder-2.svg";
import copy from "assets/images/icon/copy.svg";
import trash from "assets/images/icon/trash.svg";
import downArrow from "assets/images/icon/down-arrow.svg";
import copyWhite from "assets/images/icon/copy_white.svg";
import trashWhite from "assets/images/icon/trash_white.svg";
import deleteImg from "assets/images/icon/trash.svg";
import deleteAlertIcon from "assets/images/icon/red-info.svg";
import modalClose from "assets/images/icon/modal-close.svg";

export const MessageElements = ({
  element,
  index,
  onChangeElementTitle,
  seriesLocalId,
  seriesId,
}) => {
  let isClass;
  let MBElementToggle;
  const [onOffVisible, setOnOffVisible] = useState([]);
  const dispatch = useDispatch();
  const params = useParams();
  const messageId = params.id;
  // for open alert when delete
  const [alert, setAlert] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [ImageName, setImageName] = useState("");
  const [preview, setPreview] = useState(element.media_url);
  const [isMediaUrl, setIsMediaUrl] = useState("");
  const [successData, setSuccessData] = useState(true);
  const [isVideoUrl, setIsVideoUrl] = useState("");
  const [inValidUrl, setInValidUrl] = useState("");
  const [toolSectionElement, setToolSectionElement] = useState(element.title);
  const [toolHeaderElement, setToolHeaderElement] = useState(element.title);
  const [toolBigIdeaLegacyTitle, setToolBigIdeaLegacyTitle] = useState(
    element.title
  );
  const [toolBigIdeaLegacyContent, setToolBigIdeaLegacyContent] = useState(
    element.content
  );
  const [toolBigIdeaTitle, setToolBigIdeaTitle] = useState(element.title);
  const [toolBigIdeaContent, setToolBigIdeaContent] = useState(element.content);
  const [toolBigIdeaExtraTitle, setToolBigIdeaExtraTitle] = useState(
    element.extra_title
  );
  const [toolBigIdeaExtraContent, setToolBigIdeaExtraContent] = useState(
    element.extra_content
  );
  const [toolBigIdeaExtraTitle1, setToolBigIdeaExtraTitle1] = useState(
    element?.extra_title_1
  );
  const [toolBigIdeaExtraContent1, setToolBigIdeaExtraContent1] = useState(
    element?.extra_content_1
  );
  const [isOpenElementToggle, setIsOpenElementToggle] = useState(true);
  const [elementSwitchId, setElementSwitchId] = useState(0);
  const [isBuildVisible, setIsBuildVisible] = useState(element.is_visible);
  const [isElementTitle, setIsElementTitle] = useState(false);
  const [isDeleteElement, setIsDeleteElement] = useState(false);

  if (element.is_collapsed === 0) {
    isClass = "";
    MBElementToggle = "element-off";
  } else {
    isClass = "d-none";
    MBElementToggle = "";
  }

  //Builder Details and Teaching Title
  const Location = useLocation();
  const isValid = useMemo(
    () => Location.pathname.startsWith("/app-builder/edit"),
    [Location]
  );
  let elementTitle;
  let elementToggle;
  let buildVisible;
  let isDeleteSeries;

  if (isValid === true) {
    elementTitle = element.element_name;
    elementToggle = element.build_elements_details_id;
    buildVisible = element.is_visible;
    isDeleteSeries = 1;
  } else {
    elementTitle = element.build_elements_name;
    elementToggle = element.build_elements_details_id;
    buildVisible = element.is_visible;
    isDeleteSeries = 0;
  }

  const getYoutubeVideoId = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };

  //handle video element while videoUrl is not embed
  useEffect(() => {
    if (element.media_url) {
      let video_url = element.media_url;
      if (
        ["www.youtube.com", "youtu.be", "youtube.com"].some((item) =>
          video_url.includes(item)
        )
      ) {
        const video_main_url = getYoutubeVideoId(video_url);
        video_url = `https://www.youtube.com/embed/${video_main_url}`;
      } else if (video_url.includes("vimeo.com")) {
        const vimeo_url = video_url.split("/");
        if (video_url.includes("?h")) {
          video_url = video_url;
        } else if (vimeo_url[3] != "") {
          video_url = `https://player.vimeo.com/video/${vimeo_url[3]}?h=${vimeo_url[4]}`;
        } else {
          const code = video_url.split("/").pop();
          video_url = `https://vimeo.com/video/${code}`;
        }
      } else if (video_url.includes("dailymotion.com")) {
        const dailymotion_url = video_url.split("/");
        if (dailymotion_url[3] != "") {
          video_url = `https://dailymotion.com/${dailymotion_url[3]}/${dailymotion_url[4]}`;
        } else {
          const code = video_url.split("/").pop();
          video_url = `https://dailymotion.com/video/${code}`;
        }
      } else if (video_url.includes("ted.com")) {
        const ted_url = video_url.split("/");
        if (ted_url[3] != "") {
          video_url = `https://ted.com/${ted_url[3]}/${ted_url[4]}`;
        } else {
          const code = video_url.split("/").pop();
          video_url = `https://ted.com/talks/${code}`;
        }
      }
      setIsVideoUrl(video_url);
    }
  }, []);

  useEffect(() => {
    setOnOffVisible((prev) => [
      ...prev,
      {
        id: elementToggle,
        sts: isBuildVisible,
      },
    ]);
  }, [isBuildVisible, elementToggle]);

  useEffect(() => {
    setIsOpenElementToggle(element.is_collapsed === 0 ? true : false);
  }, [element.is_collapsed]);

  //MB Details Elements Toggle
  const handleMessageDetailAccordion = (e) => {
    setIsOpenElementToggle(isOpenElementToggle === true ? false : true);
    let isCollapsedElement = isOpenElementToggle;

    dispatch(
      handleMBElementDelete({
        build_elements_details_id: elementToggle,
        is_collapsed: parseInt(isCollapsedElement === true ? 1 : 0),
        is_series: 0,
      })
    );

    //MB Details Elements Toggle up down
    var contentElement = document.getElementById(
      "element_1" + e.target.getAttribute("data-build-element-id")
    );
    if (contentElement.classList.contains("element-off")) {
      contentElement.classList.remove("element-off");
    } else {
      contentElement.classList.add("element-off");
    }
  };

  //Duplicate MB Details Elements
  const handleDuplicateElementMB = (duplicateElementMB) => {
    dispatch(
      handleMBElementDuplicate({
        build_elements_details_id: duplicateElementMB.build_elements_details_id,
        is_series: 0,
      })
    );
  };

  //Delete MB Details Elements
  const handleDeleteMBElements = (deleteAB, index) => {
    setSelectedRows([deleteAB], index);
    setAlert(true);
  };
  const [elementChange, setOnchangeElement] = useState({
    elementTitle: element.title,
    elementContent: element.content,
  });

  //Save ELEMENT on focus out
  const handleSaveElement = () => {
    let elementId = element.build_elements_details_id;
    if (isValid === true) {
      if (elementChange.elementTitle !== "") {
        setIsElementTitle(false);
        onChangeElementTitle(true);
        if (seriesId == seriesLocalId) {
          dispatch(
            handleElementTitleChange(
              {
                build_elements_details_id: elementId,
                title: elementChange.elementTitle,
                content: elementChange.elementContent,
              },
              (status) => {
                if (status) {
                  onChangeElementTitle(false);
                  dispatch(
                    handleFetchSeriesElement({
                      series_build_list_id: status.series_id,
                    })
                  );
                }
              }
            )
          );
        }
      } else {
        setIsElementTitle(true);
      }
    } else {
      if (elementChange.elementTitle !== "") {
        setIsElementTitle(false);
        dispatch(
          handleSaveOnElementChangeMB(
            {
              type: 1,
              message_build_list_id: messageId,
              element_details: [
                {
                  build_elements_details_id: elementId,
                  title: elementChange.elementTitle,
                  content: elementChange.elementContent,
                },
              ],
            },
            (status) => {
              if (status) {
                dispatch(
                  handleFetchMBElements({ id: messageId, is_series: 0 })
                );
              }
            }
          )
        );
      } else {
        setIsElementTitle(true);
      }
    }
  };

  //MB Details Elements ON and OFF
  const handleMessageDetailOnOff = (elementToggle, sts) => {
    setElementSwitchId(elementToggle);
    if (isBuildVisible === 1) {
      if (isValid === true) {
        dispatch(setVisibleAppUpdate({ id: elementToggle, is_visable: sts }));
      } else {
        dispatch(setVisableUpdate({ id: elementToggle, is_visable: sts }));
      }
      setOnOffVisible((prevState) =>
        prevState.filter((item) => item !== elementToggle)
      );
      dispatch(
        handleMBElementDelete({
          build_elements_details_id: elementToggle,
          is_visible: 0,
          is_series: isDeleteSeries,
        })
      );
      setIsBuildVisible(0);
    } else {
      if (isValid === true) {
        dispatch(setVisibleAppUpdate({ id: elementToggle, is_visable: sts }));
      } else {
        dispatch(setVisableUpdate({ id: elementToggle, is_visable: sts }));
      }
      setOnOffVisible((prevState) => [...prevState, elementToggle]);
      dispatch(
        handleMBElementDelete({
          build_elements_details_id: elementToggle,
          is_visible: 1,
          is_series: isDeleteSeries,
        })
      );
      setIsBuildVisible(1);
    }
  };

  function FCKeditor_OnComplete(editorInstance) {
    editorInstance.Events.AttachEvent("OnBlur", FCKeditor_OnBlur);
    editorInstance.Events.AttachEvent("OnFocus", FCKeditor_OnFocus);
  }
  function FCKeditor_OnBlur(editorInstance) {
    editorInstance.ToolbarSet.Collapse();
  }
  function FCKeditor_OnFocus(editorInstance) {
    editorInstance.ToolbarSet.Expand();
  }

  const handleChange = (id, key, value) => {
    let parser = new DOMParser();
    const doc = parser.parseFromString(value, "text/html");
    let p = doc.getElementsByTagName("p");
    let img = doc.getElementsByTagName("figure");
    let image_resize = doc.getElementsByClassName("image_resized");
    if (img.length >= 1 && p.length <= 0 && image_resize.length <= 0) {
      handleSaveElement();
    }
    dispatch(setMBElements({ id, key, value, isArray: true }));
  };

  //DRAG AND DROP IMAGE
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const params = {
        ACL: "public-read",
        Key: file.name,
        ContentType: file.type,
        Body: file,
      };
      setIsUploading(true);
      setImageName(file.name);
      s3(process.env.REACT_APP_AWS_FOLDER_NAME)
        .upload(params)
        .on("httpUploadProgress", function (evt) {
          const value = Math.round((evt.loaded / evt.total) * 100);
          setProgress(value);
          if (value === 100) {
            setIsUploading(false);
          }
        })
        .send(function (err, data) {
          // const fileName = data.Location.replace(".webp", ".jpg");

          // const webFileUrl = data.Location.slice(
          //   0,
          //   data.Location.lastIndexOf("/")
          // );

          // const fileExtension = data.Location.substring(
          //   data.Location.lastIndexOf("/") + 1
          // );
          // console.log("webFileName :>> ", fileExtension);

          const webFileUrl = data.Location.substring(
            0,
            data.Location.lastIndexOf("/") + 1
          );

          const webFileName = data.Location.substring(
            data.Location.lastIndexOf("/") + 1
          );

          const ext = webFileName.substring(0, webFileName.lastIndexOf("."));

          const webFullName = webFileName.includes(".webp")
            ? webFileUrl + ext + ".jpg"
            : webFileUrl + webFileName;

          if (err) {
            return;
          }
          dispatch(
            handleSaveOnElementChangeMB(
              {
                message_build_list_id: messageId,
                type: 1,
                element_details: [
                  {
                    build_elements_details_id:
                      element.build_elements_details_id,
                    // media_url: data.Location,
                    media_url: webFullName,
                  },
                ],
              },
              (status) => {
                if (status) {
                  dispatch(handleFetchMBElements({ id: messageId }));
                }
              }
            )
          );
          setPreview(data.Location);
        });
    },
    [dispatch, messageId, element.build_elements_details_id]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  /** Message Builder Video Element */
  const handleMessageDetailsVideoElement = () => {
    let video_url = isMediaUrl;
    if (
      ["www.youtube.com", "youtu.be", "youtube.com"].some((item) =>
        video_url.includes(item)
      )
    ) {
      const video_main_url = getYoutubeVideoId(video_url);
      video_url = `https://www.youtube.com/embed/${video_main_url}`;
    } else if (video_url.includes("vimeo.com")) {
      const vimeo_url = video_url.split("/");
      if (video_url.includes("?h")) {
        video_url = video_url;
      } else if (vimeo_url[3] != "") {
        let result = `https://player.vimeo.com/video/${vimeo_url[3]}?h=${vimeo_url[4]}`;
        if (result.includes("?share")) {
          video_url = result.replace("?share=copy", "");
        } else {
          video_url = `https://player.vimeo.com/video/${vimeo_url[3]}?h=${vimeo_url[4]}`;
        }
      } else {
        const code = video_url.split("/").pop();
        video_url = `https://vimeo.com/video/${code}`;
      }
    } else if (video_url.includes("dailymotion.com")) {
      const dailymotion_url = video_url.split("/");
      if (dailymotion_url[3] != "") {
        video_url = `https://dailymotion.com/${dailymotion_url[3]}/${dailymotion_url[4]}`;
      } else {
        const code = video_url.split("/").pop();
        video_url = `https://dailymotion.com/video/${code}`;
      }
    } else if (video_url.includes("ted.com")) {
      const ted_url = video_url.split("/");
      if (ted_url[3] != "") {
        video_url = `https://ted.com/${ted_url[3]}/${ted_url[4]}`;
      } else {
        const code = video_url.split("/").pop();
        video_url = `https://ted.com/talks/${code}`;
      }
    } else {
      setIsVideoUrl(true);
    }
    setIsVideoUrl(video_url);
    dispatch(
      handleMessageVideoElement({ url: video_url }, (status) => {
        setSuccessData(status);
        if (status === true) {
          dispatch(
            handleSaveOnElementChangeMB({
              message_build_list_id: messageId,
              type: 1,
              element_details: [
                {
                  build_elements_details_id: element.build_elements_details_id,
                  media_url: video_url,
                },
              ],
            })
          );
        }
      })
    );
  };
  //Remove Element Video
  const handleElementVideoRemove = () => {
    dispatch(
      handleSaveOnElementChangeMB({
        message_build_list_id: messageId,
        type: 1,
        element_details: [
          {
            build_elements_details_id: element.build_elements_details_id,
            media_url: "",
          },
        ],
      })
    );
    setIsVideoUrl("");
    setIsMediaUrl("");
    setSuccessData(0);
  };

  //Remove Image section
  const handleImageRemove = () => {
    dispatch(
      handleSaveOnElementChangeMB({
        message_build_list_id: messageId,
        type: 1,
        element_details: [
          {
            build_elements_details_id: element.build_elements_details_id,
            media_url: "",
          },
        ],
      })
    );
    setPreview("");
  };

  //Tool Section Element Save
  const handleSectionElement = () => {
    if (toolSectionElement !== "") {
      dispatch(
        handleSaveOnElementChangeMB(
          {
            type: 1,
            message_build_list_id: messageId,
            element_details: [
              {
                build_elements_details_id: element.build_elements_details_id,
                title: toolSectionElement,
              },
            ],
          },
          (status) => {
            if (status) {
              dispatch(handleFetchMBElements({ id: messageId, is_series: 0 }));
            }
          }
        )
      );
    }
  };

  //Tool Header Element Save
  const handleHeaderElement = () => {
    if (toolHeaderElement !== "") {
      dispatch(
        handleSaveOnElementChangeMB(
          {
            type: 1,
            message_build_list_id: messageId,
            element_details: [
              {
                build_elements_details_id: element.build_elements_details_id,
                title: toolHeaderElement,
              },
            ],
          },
          (status) => {
            if (status) {
              dispatch(handleFetchMBElements({ id: messageId, is_series: 0 }));
            }
          }
        )
      );
    }
  };
  //Tool Big Idea (Legacy) Element Save
  const handleBigIdeaLegacyElement = () => {
    if (toolBigIdeaLegacyTitle !== "") {
      dispatch(
        handleSaveOnElementChangeMB(
          {
            type: 1,
            message_build_list_id: messageId,
            element_details: [
              {
                build_elements_details_id: element.build_elements_details_id,
                title: toolBigIdeaLegacyTitle,
                content: toolBigIdeaLegacyContent,
              },
            ],
          },
          (status) => {
            if (status) {
              dispatch(handleFetchMBElements({ id: messageId, is_series: 0 }));
            }
          }
        )
      );
    }
  };

  //Tool Big Idea Element Save
  const handleBigIdeaElement = () => {
    if (toolBigIdeaTitle !== "") {
      dispatch(
        handleSaveOnElementChangeMB(
          {
            type: 1,
            message_build_list_id: messageId,
            element_details: [
              {
                build_elements_details_id: element.build_elements_details_id,
                title: toolBigIdeaTitle,
                content: toolBigIdeaContent,
                extra_title: toolBigIdeaExtraTitle,
                extra_content: toolBigIdeaExtraContent,
                extra_title_1: toolBigIdeaExtraTitle1,
                extra_content_1: toolBigIdeaExtraContent1,
              },
            ],
          },
          (status) => {
            if (status) {
              dispatch(handleFetchMBElements({ id: messageId, is_series: 0 }));
            }
          }
        )
      );
    }
  };

  const handleDeleteConfirm = () => {
    setIsDeleteElement(true);
    // Delete for tools
    dispatch(
      handleMBElementDelete(
        {
          build_elements_details_id: [...selectedRows][0]
            .build_elements_details_id,
          is_delete: "1",
          is_collapsed: "",
          is_series: isDeleteSeries,
        },
        (status) => {
          if (status) {
            if (isDeleteSeries == 0) {
              dispatch(
                handleFetchMBElements({
                  id: status.id,
                })
              );
              setIsDeleteElement(false);
              setAlert(false);
              setSelectedRows([]);
            } else {
              dispatch(
                handleFetchSeriesElement({
                  series_build_list_id: status.id,
                })
              );
              setIsDeleteElement(false);
              setAlert(false);
              setSelectedRows([]);
            }
          }
        }
      )
    );
  };

  const handleDeleteClose = () => {
    setAlert(false);
    setSelectedRows([]);
  };
  return (
    <>
      {/* Section Tools Start*/}
      {element.build_elements_name == "Section" ? (
        <div
          className={`lisection esection ${
            onOffVisible.includes(elementToggle)
              ? ""
              : isBuildVisible === 1
              ? ""
              : "disableOff"
          }`}
        >
          <div className="boxheader_section toolSwitch">
            <div className="righthold">
              <label className="switch-style mb0 mr-1" title="Toggle">
                <input
                  type="checkbox"
                  id={elementToggle}
                  onClick={() =>
                    handleMessageDetailOnOff(elementToggle, !element.is_visible)
                  }
                  defaultChecked={element.is_visible}
                />
                <label className="mb0 mt5" htmlFor={elementToggle}></label>
              </label>
              <div
                className="btn-duplicate-section"
                clicky=""
                title="Duplicate"
              >
                <Link to="#" onClick={() => handleDuplicateElementMB(element)}>
                  <img src={copyWhite} alt="copy" className="ml-1" />
                </Link>
              </div>
              <div className="btn-duplicate-section" clicky="">
                <Link
                  to="#"
                  onClick={() => handleDeleteMBElements(element)}
                  title="Delete"
                >
                  <img src={trashWhite} alt="trash" className="ml-2" />
                </Link>
              </div>
            </div>
            <input
              type="hidden"
              className="current-element"
              onChange={(e) => {}}
            />
            <input
              type="hidden"
              className="lilimain"
              value="8260077"
              onChange={(e) => {}}
            />
            <input
              type="text"
              className="esection"
              name="field[8260077][elementtitle]"
              value={toolSectionElement}
              onBlur={handleSectionElement}
              onChange={(e) => {
                setToolSectionElement(e.target.value);
              }}
            />
            <input
              type="hidden"
              className="etype"
              name="field[8260077][elementtype]"
              value="section"
              onChange={(e) => {}}
            />
          </div>
        </div>
      ) : null}

      {/* Section Tools End*/}

      {/* Header Tools Start*/}
      {element.build_elements_name == "Header" ? (
        <div
          className={`d-flex boxheader ${
            onOffVisible.includes(elementToggle)
              ? ""
              : isBuildVisible === 1
              ? ""
              : "disableOff"
          }`}
          id="drop-element-93624"
        >
          <input
            type="hidden"
            className="current-element"
            value="HEADER"
            onChange={(e) => {}}
          />
          <input
            type="hidden"
            className="lilimain"
            value="8260078"
            onChange={(e) => {}}
          />
          <input
            type="text"
            className="eheader"
            name="field[8260078][elementtitle]"
            value={toolHeaderElement}
            onBlur={handleHeaderElement}
            onChange={(e) => {
              setToolHeaderElement(e.target.value);
            }}
          />
          <input
            type="hidden"
            name="field[8260078][elementtype]"
            className="etype"
            value="header"
            onChange={(e) => {}}
          />
          <div className="righthold header_option toolSwitch d-flex justify-content-center align-items-center">
            <label className="switch-style mb0 mr-1 ml-2" title="Toggle">
              <input
                type="checkbox"
                id={elementToggle}
                onClick={() =>
                  handleMessageDetailOnOff(elementToggle, !element.is_visible)
                }
                defaultChecked={element.is_visible}
              />
              <label className="mb0" htmlFor={elementToggle}></label>
            </label>
            <div className="btn-duplicate-section" clicky="">
              <Link
                to="#"
                onClick={() => handleDuplicateElementMB(element)}
                title="Duplicate"
              >
                <img src={copy} alt="copy" className="ml-1" />
              </Link>
            </div>
            <div className="trashit clicky">
              <Link
                to="#"
                onClick={() => handleDeleteMBElements(element)}
                title="Delete"
              >
                <img src={trash} alt="trash" className="ml-2" />
              </Link>
            </div>
          </div>
        </div>
      ) : null}

      {/* Header Tools End*/}

      {/* Big Idea (Legacy) Start*/}
      {element.build_elements_name == "Big Idea (Legacy)" ? (
        <div
          className={`tools-legacy ${
            onOffVisible.includes(elementToggle)
              ? ""
              : isBuildVisible === 1
              ? ""
              : "disableOff"
          }`}
        >
          <div className="righthold toolSwitch d-flex justify-content-center align-items-center">
            <label className="switch-style mb0 " title="Toggle">
              <input
                type="checkbox"
                id={elementToggle}
                onClick={() =>
                  handleMessageDetailOnOff(elementToggle, !element.is_visible)
                }
                defaultChecked={element.is_visible}
              />
              <label className="mb0" htmlFor={elementToggle}></label>
            </label>
            <Link
              to="#"
              onClick={() => handleDuplicateElementMB(element)}
              title="Duplicate"
            >
              <img src={copyWhite} alt="copy" className="ml-2" />
            </Link>
            <Link
              to="#"
              onClick={() => handleDeleteMBElements(element)}
              title="Delete"
            >
              <img src={trashWhite} alt="trash" className="ml-2" />
            </Link>
          </div>
          <div className="big-legacy">
            <input
              type="text"
              className="etitle"
              value={toolBigIdeaLegacyTitle}
              onBlur={handleBigIdeaLegacyElement}
              onChange={(e) => {
                setToolBigIdeaLegacyTitle(e.target.value);
              }}
            />
            <input
              type="text"
              className="subtitle"
              value={toolBigIdeaLegacyContent}
              onBlur={handleBigIdeaLegacyElement}
              onChange={(e) => {
                setToolBigIdeaLegacyContent(e.target.value);
              }}
            />
          </div>
        </div>
      ) : null}

      {/* Big Idea (Legacy) End*/}

      {/* BIG IDEA / BIBLE Start*/}
      {element.build_elements_name == "BIG IDEA / BIBLE" ? (
        <div
          className={`tools ${
            onOffVisible.includes(elementToggle)
              ? ""
              : isBuildVisible === 1
              ? ""
              : "disableOff"
          }`}
        >
          <div className="tools-area toolSwitch">
            <div className="righthold">
              <label className="switch-style mb0" title="Toggle">
                <input
                  type="checkbox"
                  id={elementToggle}
                  onClick={() =>
                    handleMessageDetailOnOff(elementToggle, !element.is_visible)
                  }
                  defaultChecked={element.is_visible}
                />
                <label className="mb0" htmlFor={elementToggle}></label>
              </label>
              <Link
                to="#"
                onClick={() => handleDuplicateElementMB(element)}
                title="Duplicate"
              >
                <img src={copy} alt="copy" className="ml-2" />
              </Link>
              <Link
                to="#"
                onClick={() => handleDeleteMBElements(element)}
                title="Delete"
              >
                <img src={trash} alt="trash" className="ml-1" />
              </Link>
            </div>
            <div className="tools-left">
              <div className="bib-left">
                <input
                  type="text"
                  className="etitle"
                  value={toolBigIdeaTitle}
                  onBlur={handleBigIdeaElement}
                  onChange={(e) => {
                    setToolBigIdeaTitle(e.target.value);
                  }}
                />
                <TextareaAutosize
                  defaultValue={toolBigIdeaContent}
                  rows={5}
                  onBlur={handleBigIdeaElement}
                  onChange={(e) => {
                    setToolBigIdeaContent(e.target.value);
                  }}
                  className="subtitle"
                />
              </div>
              <div className="bib-right">
                <input
                  type="text"
                  className="etitle"
                  value={toolBigIdeaExtraTitle}
                  onBlur={handleBigIdeaElement}
                  onChange={(e) => {
                    setToolBigIdeaExtraTitle(e.target.value);
                  }}
                />
                <TextareaAutosize
                  defaultValue={toolBigIdeaExtraContent}
                  onBlur={handleBigIdeaElement}
                  onChange={(e) => {
                    setToolBigIdeaExtraContent(e.target.value);
                  }}
                  rows={5}
                  className="subtitle"
                />
              </div>
            </div>
            {!!toolBigIdeaExtraTitle1 || !!toolBigIdeaExtraContent1 ? (
              <div
                className="tools-area toolSwitch commonDisableFunction"
                style={{ marginTop: "2%" }}
              >
                {/* <div className="righthold ">
                <label className="switch-style  mb0" title="Toggle">
                  <input
                    type="checkbox"
                    id={elementToggle}
                    onClick={() =>
                      handleMessageDetailOnOff(
                        elementToggle,
                        !element.is_visible
                      )
                    }
                    defaultChecked={element.is_visible}
                  />
                  <label className="mb0" htmlFor={elementToggle}></label>
                </label>
                <Link
                  to="#"
                  onClick={() => handleDuplicateElementMB(element)}
                  title="Duplicate"
                >
                  <img src={copy} alt="copy" className="ml-2" />
                </Link>
                <Link
                  to="#"
                  onClick={() => handleDeleteMBElements(element)}
                  title="Delete"
                >
                  <img src={trash} alt="trash" className="ml-1" />
                </Link>
              </div> */}

                <div className="tools-left commonDisableLeftFunction">
                  <div className="aboutWeek">
                    <input
                      type="text"
                      className="etitle"
                      value={toolBigIdeaExtraTitle1}
                      onBlur={handleBigIdeaElement}
                      onChange={(e) => {
                        setToolBigIdeaExtraTitle1(e.target.value);
                      }}
                    />
                    <TextareaAutosize
                      defaultValue={toolBigIdeaExtraContent1}
                      rows={5}
                      onBlur={handleBigIdeaElement}
                      onChange={(e) => {
                        setToolBigIdeaExtraContent1(e.target.value);
                      }}
                      className="subtitle"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : null}
      {/* BIG IDEA / BIBLE End*/}

      {/* ABOUT THIS WEEK */}

      {element.build_elements_name == "ABOUT THIS WEEK" ? (
        <div
          className={`tools ${
            onOffVisible.includes(elementToggle)
              ? ""
              : isBuildVisible === 1
              ? ""
              : "disableOff"
          }`}
        >
          <div className="tools-area toolSwitch commonDisableFunction">
            <div className="righthold ">
              <label className="switch-style  mb0" title="Toggle">
                <input
                  type="checkbox"
                  id={elementToggle}
                  onClick={() =>
                    handleMessageDetailOnOff(elementToggle, !element.is_visible)
                  }
                  defaultChecked={element.is_visible}
                />
                <label className="mb0" htmlFor={elementToggle}></label>
              </label>
              <Link
                to="#"
                onClick={() => handleDuplicateElementMB(element)}
                title="Duplicate"
              >
                <img src={copy} alt="copy" className="ml-2" />
              </Link>
              <Link
                to="#"
                onClick={() => handleDeleteMBElements(element)}
                title="Delete"
              >
                <img src={trash} alt="trash" className="ml-1" />
              </Link>
            </div>
            <div className="tools-left commonDisableLeftFunction">
              <div className="aboutWeek">
                <input
                  type="text"
                  className="etitle"
                  value={toolBigIdeaTitle}
                  onBlur={handleBigIdeaElement}
                  onChange={(e) => {
                    setToolBigIdeaTitle(e.target.value);
                  }}
                />
                <TextareaAutosize
                  defaultValue={toolBigIdeaContent}
                  rows={5}
                  onBlur={handleBigIdeaElement}
                  onChange={(e) => {
                    setToolBigIdeaContent(e.target.value);
                  }}
                  className="subtitle"
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* Delete Modal  */}
      <Modal
        isOpen={alert}
        className="modal-dialog modal-dialog-centered"
        role="document"
        centered
      >
        <div className="modal-content">
          <div className="modal-body modal_popup">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => handleDeleteClose()}
              disabled={isDeleteElement === true}
            >
              <img src={modalClose} alt="modal-close" />
            </button>
            <div className="modal_data editImageModalData">
              <div className="row">
                <div className="col-md-12 text-center mt-5">
                  <img src={deleteAlertIcon} alt="red-info" />
                </div>
                <div className="col-md-12 text-center mb20 mt15">
                  <h1 className="fw-600 dark-text ">
                    Are you sure you want to delete?
                  </h1>
                </div>
                <div className="col-md-12 modal-btn">
                  <button
                    className="btn secondary-btn mr-2"
                    data-dismiss="modal"
                    onClick={() => handleDeleteClose()}
                    disabled={isDeleteElement === true}
                  >
                    {" "}
                    cancel
                  </button>
                  {isDeleteElement === true ? (
                    <button className="btn delete-border-btn ml-3">
                      Deleting...
                    </button>
                  ) : (
                    <button
                      className="btn delete-border-btn ml-3"
                      onClick={() => handleDeleteConfirm()}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div
        className={`flipbox MBdetail
    ${
      onOffVisible.includes(elementToggle)
        ? ""
        : isBuildVisible === 1
        ? ""
        : "disableOff"
    }
      `}
        key={index}
      >
        <div className={`flipbox-left ${elementTitle}`}></div>
        {element.build_elements_name != "BIG IDEA / BIBLE" &&
        element.build_elements_name != "Section" &&
        element.build_elements_name != "Header" &&
        element.build_elements_name != "Big Idea (Legacy)" &&
        element.build_elements_name != "ABOUT THIS WEEK" ? (
          <div
            className={`box-border d-flex align-items-center border-left-bar ${elementTitle}`}
          >
            <div className="box-inner w-100">
              <div className="flipbox-right">
                <div className="editor-section">
                  <input
                    className="box-border-title"
                    type="text"
                    name="elementTitle"
                    value={elementChange.elementTitle}
                    onBlur={handleSaveElement}
                    onChange={(e) => {
                      setOnchangeElement({
                        elementTitle: e.target.value,
                      });
                    }}
                    placeholder=""
                    id=""
                  />

                  <div className="d-flex align-items-center ml-2">
                    <label className="switch-style mb0" title="Toggle">
                      <input
                        type="checkbox"
                        id={elementToggle}
                        onClick={() =>
                          handleMessageDetailOnOff(
                            elementToggle,
                            !element.is_visible
                          )
                        }
                        defaultChecked={element.is_visible}
                      />
                      <label className="mb0" htmlFor={elementToggle}></label>
                    </label>
                    <Link
                      to="#"
                      onClick={() => handleDuplicateElementMB(element)}
                      className="ml-3"
                      title="Duplicate"
                    >
                      {" "}
                      <img src={copy} alt="copy" />
                    </Link>
                    <Link
                      to="#"
                      className="ml-3"
                      onClick={() => handleDeleteMBElements(element, index)}
                      title="Delete"
                    >
                      {" "}
                      <img src={trash} alt="trash" />
                    </Link>
                    <div className="righthold">
                      <button
                        type="button"
                        onClick={handleMessageDetailAccordion}
                        data-target={element.is_collapsed}
                        data-build-element-id={
                          element.build_elements_details_id
                        }
                        className="teachingToogleBtn"
                        title="Collapse"
                      >
                        {" "}
                        <img
                          src={downArrow}
                          // onClick={handleToggle}
                          id={"element_1" + elementToggle}
                          className={`fa fa-caret-down ` + MBElementToggle}
                          aria-hidden="true"
                          data-build-element-id={
                            element.build_elements_details_id
                          }
                          alt="downArrow"
                        />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="mt-3">
                  <div
                    className={
                      isElementTitle === true
                        ? "vol-title-error alert alert-danger"
                        : "d-none vol-title-error alert alert-danger"
                    }
                  >
                    Please Enter Element Title
                  </div>
                </div>

                {/* Delete Details Elements Modal */}

                <Modal
                  isOpen={alert}
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                  centered
                >
                  <div className="modal-content">
                    <div className="modal-body modal_popup">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => handleDeleteClose()}
                        disabled={isDeleteElement === true}
                      >
                        <img src={modalClose} alt="modal-close" />
                      </button>
                      <div className="modal_data editImageModalData">
                        <div className="row">
                          <div className="col-md-12 text-center mt-5">
                            <img src={deleteAlertIcon} alt="red-info" />
                          </div>
                          <div className="col-md-12 text-center mb20 mt15">
                            <h1 className="fw-600 dark-text ">
                              Are you sure you want to delete?
                            </h1>
                          </div>
                          <div className="col-md-12 modal-btn">
                            <button
                              className="btn secondary-btn mr-2"
                              data-dismiss="modal"
                              onClick={() => handleDeleteClose()}
                              disabled={isDeleteElement === true}
                            >
                              {" "}
                              cancel
                            </button>
                            {isDeleteElement === true ? (
                              <button className="btn delete-border-btn ml-3">
                                Deleting...
                              </button>
                            ) : (
                              <button
                                className="btn delete-border-btn ml-3"
                                onClick={() => handleDeleteConfirm()}
                              >
                                Delete
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>

                <Collapse
                  className={"element_collapse"}
                  isOpen={isOpenElementToggle}
                >
                  {elementTitle == "Image" ? (
                    <div
                      id="collapseTwo cust-img"
                      className="collapse show element_image"
                      aria-labelledby="headingTwo"
                    >
                      {preview == "" ? (
                        <div {...getRootProps()}>
                          <input
                            {...getInputProps()}
                            accept="image/*"
                            multiple={false}
                          />
                          {
                            <div className="dropzone-area">
                              <img src={folder} alt="folder" />
                              <h3 className="fw-500 dark-text">
                                Drop your files here, or <span>Browse</span>
                              </h3>
                              <h4 className="mb-0 gray-text fw-300">
                                Supports, docs, pdf, txt
                              </h4>
                              {isUploading && (
                                <div className="mt-1">
                                  {ImageName}
                                  <Progress color="primary" value={progress}>
                                    {progress}%
                                  </Progress>
                                </div>
                              )}
                            </div>
                          }
                        </div>
                      ) : (
                        <div className="ImageDelete">
                          <img
                            src={preview.replace(
                              imageUrl.S3BUILDER_URL,
                              imageUrl.BUILDER_DISPLAY_URL
                            )}
                            className="sectionABImg"
                            alt="preview"
                          />
                          <img
                            src={deleteImg}
                            className="RemoveImage"
                            title="Delete"
                            onClick={() => handleImageRemove()}
                            alt="delete"
                          />
                        </div>
                      )}
                    </div>
                  ) : null}
                  {elementTitle == "Video" ? (
                    isVideoUrl === "" || successData == false ? (
                      <div>
                        {(successData === false && isVideoUrl) ||
                        isVideoUrl == true ? (
                          <div className="viderror">
                            Video link invalid! Please try again.
                          </div>
                        ) : null}
                        <div className="flipbox-title">
                          <input
                            className="box-border-video_url"
                            type="text"
                            placeholder="Enter Video URL Here"
                            value={isMediaUrl}
                            id="videoUrl"
                            onChange={(e) => {
                              setIsMediaUrl(e.target.value);
                              setInValidUrl(e.target.value);
                            }}
                          />
                          <br />
                        </div>
                        {inValidUrl === undefined ? (
                          <span className="embedclick clicky">Embed Video</span>
                        ) : (
                          <span
                            className="embedclick clicky"
                            onClick={handleMessageDetailsVideoElement}
                          >
                            Embed Video
                          </span>
                        )}
                      </div>
                    ) : successData == true ? (
                      <center>
                        <iframe
                          src={isVideoUrl}
                          frameBorder="0"
                          className="iframe iframeVideo"
                          title="Uploaded Video"
                          allowFullScreen
                        ></iframe>
                        <img
                          src={deleteImg}
                          alt="delete"
                          className="MBDeleteVideo"
                          title="Delete"
                          onClick={() => handleElementVideoRemove()}
                        />
                      </center>
                    ) : null
                  ) : null}

                  <div className="editor mt-3">
                    <CkEditor
                      name="elementContent"
                      data={element.content}
                      onBlur={handleSaveElement}
                      onChange={(data) => {
                        setOnchangeElement({
                          elementContent: data,
                        });
                        handleChange(
                          element.build_elements_details_id,
                          "content",
                          data
                        );
                      }}
                    />
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
