const LibraryLoader = () => {
    return (
        <>
            <div className="col-md-6 col-lg-4 col-xxl-3">
                <div className="card volume-5 mb-5 card-content">
                    <div className="ph-item loader">
                        <div className="ph-col-12">
                            <div className="ph-picture"></div>
                        </div>
                        <div className="ph-col-12">
                            <div className="ph-row">
                                <div className="ph-col-10 big empty"></div>
                                <div className="ph-col-10 big"></div>
                                <div className="ph-col-10 big"></div>
                                <div className="ph-col-10 big"></div>
                                <div className="ph-col-10 big"></div>
                                <div className="ph-col-10 big"></div>
                                <div className="ph-col-10 big empty"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LibraryLoader