import {
  FETCH_AB_FOLDER_LISTING,
  FETCH_AB_FOLDER_LISTING_SUCCESS,
  FETCH_AB_FOLDER_LISTING_ERROR,
  AB_FOLDER_ADD_EDIT,
  AB_FOLDER_ADD_EDIT_SUCCESS,
  AB_FOLDER_ADD_EDIT_ERROR,
  AB_FOLDER_DELETE,
  AB_FOLDER_DELETE_SUCCESS,
  AB_FOLDER_DELETE_ERROR,
  GET_LIBRARY_FOLDER_BY_TYPE,
  GET_LIBRARY_FOLDER_BY_TYPE_SUCCESS,
  GET_LIBRARY_FOLDER_BY_TYPE_ERROR,
  FETCH_AB_FOLDER_BUILD_LIST,
  FETCH_AB_FOLDER_BUILD_LIST_SUCCESS,
  FETCH_AB_FOLDER_BUILD_LIST_ERROR,
  GET_LIBRARY_BUILD,
  GET_LIBRARY_BUILD_SUCCESS,
  GET_LIBRARY_BUILD_ERROR,
  SET_AB_LISTING_BY_FOLDER_ID,
  SET_AB_FOLDER_ID,
  SET_AB_FOLDER_TITLE,
  SET_AB_LIBRARY_FOLDER_ID,
  SET_AB_LIBRARY_FOLDER_TITLE,
  RESET_AB_FOLDER_BUILD_LIST,
  SET_PAGE_AB_FOLDER_BUILD_LIST
} from "./actionTypes";

const ABInitialState = {
  AppBuilderFolderListing: [],
  ABFolderBuildList: [],
  LibraryAppBuildListing: [],
  StoreAppFolderId: 0,
  StoreAppFolderTitle: "User Builds",
  StoreAppLibraryFolderId: "",
  StoreAppLibraryFolderTitle: "",
  ABFolderSave: false,
  loading: false,
  search: "",
  sizePerPageABFolder: 10,
  pageNoABFolder: 1,
  sortField: "volume_id",
  sortOrder: "DESC",
  totalSizeABFolder: 0,
}

const ABReducer = (state = ABInitialState, action) => {
  const { type, payload } = action
  switch (type) {
    /*Reset ABFolder Build List */
    case RESET_AB_FOLDER_BUILD_LIST:
      return {
        ...state,
        ABFolderBuildList: []
      }
      /*Set pageNo of AB Folder Build List */
    case SET_PAGE_AB_FOLDER_BUILD_LIST:
      return {
        ...state,
        pageNoABFolder: payload
      }
    /** AB folder list */
    case FETCH_AB_FOLDER_LISTING:
      return {
        ...state,
        loading: true,
      }
    case FETCH_AB_FOLDER_LISTING_SUCCESS:
      return {
        ...state,
        AppBuilderFolderListing: action.payload,
        loading: false,
        totalSizeABFolder: payload.count,
      }
    case FETCH_AB_FOLDER_LISTING_ERROR:
      return {
        ...state,
        loading: true,
      }
    /** AB folder ADD/EDIT */
    case AB_FOLDER_ADD_EDIT:
      return {
        ...state,
        loading: false,
        ABFolderSave: true,
      }
    case AB_FOLDER_ADD_EDIT_SUCCESS:
      return {
        ...state,
        loading: true,
        ABFolderSave: false,
      }
    case AB_FOLDER_ADD_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        ABFolderSave: false,
      }

    /** AB folder DELETE */
    case AB_FOLDER_DELETE:
      return {
        ...state,
        loading: true,
      }
    case AB_FOLDER_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case AB_FOLDER_DELETE_ERROR:
      return {
        ...state,
        loading: true,
      }
    /** Get library folder using type */
    case GET_LIBRARY_FOLDER_BY_TYPE:
      return {
        ...state,
        loading: false,
      }
    case GET_LIBRARY_FOLDER_BY_TYPE_SUCCESS:
      return {
        ...state,
        LibraryListing: action.payload,
        loading: false,
      }
    case GET_LIBRARY_FOLDER_BY_TYPE_ERROR:
      return {
        ...state,
        loading: true,
      }
    /** Get library build */
    case GET_LIBRARY_BUILD:
      return {
        ...state,
        loading: false,
      }
    case GET_LIBRARY_BUILD_SUCCESS:
      return {
        ...state,
        LibraryAppBuildListing: action.payload,
        loading: false,
      }
    case GET_LIBRARY_BUILD_ERROR:
      return {
        ...state,
        loading: true,
      }
    /** Get builder by folder id */
    case FETCH_AB_FOLDER_BUILD_LIST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_AB_FOLDER_BUILD_LIST_SUCCESS:
      return {
        ...state,
        ABFolderBuildList: [...state.ABFolderBuildList, ...payload.data],
        StoreAppLibraryFolderId: "",
        loading: false,
        totalSizeABFolder: payload.count
      }
    case FETCH_AB_FOLDER_BUILD_LIST_ERROR:
      return {
        ...state,
        loading: true,
      }

    //deleting AB VOLUME from redux
    case SET_AB_LISTING_BY_FOLDER_ID:
      return {
        ...state,
        ABFolderBuildList: [
          ...state.ABFolderBuildList.filter(item => item.volume_id !== payload.elementId.volume_id)
        ]
      }

    /*Store Folder ID */
    case SET_AB_FOLDER_ID:
      return {
        ...state,
        StoreAppFolderId: action.payload,
        loading: true,
      }
    /*Store Folder TITLE */
    case SET_AB_FOLDER_TITLE:
      return {
        ...state,
        StoreAppFolderTitle: action.payload,
        loading: true,
      }

    /*Store Library Folder ID */
    case SET_AB_LIBRARY_FOLDER_ID:
      return {
        ...state,
        StoreAppLibraryFolderId: action.payload,
        StoreAppFolderId: 0,
        loading: false,
      }

    /*Store Library Folder TITLE */
    case SET_AB_LIBRARY_FOLDER_TITLE:
      return {
        ...state,
        StoreAppLibraryFolderTitle: action.payload,
        loading: true,
      }
    default:
      return state
  }
}
export default ABReducer
