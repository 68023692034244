import {
  FETCH_MB_TEACHING_ELEMENTS,
  FETCH_MB_TEACHING_ELEMENTS_SUCCESS,
  FETCH_MB_TEACHING_ELEMENTS_ERROR,
  FETCH_MB_TEACHING_ELEMENTS_BY_CODE,
  FETCH_MB_TEACHING_ELEMENTS_BY_CODE_SUCCESS,
  FETCH_MB_TEACHING_ELEMENTS_BY_CODE_ERROR,
} from "./actionTypes";

const MBElementsInitialState = {
  MBTeachingElements: []
}

const MBTeachingElementReducer = (state = MBElementsInitialState, action) => {
  const { type, payload } = action
  switch (type) {
    /** App Builder Teaching list */
    case FETCH_MB_TEACHING_ELEMENTS:
      return {
        ...state,
        loading: true,
      }
    case FETCH_MB_TEACHING_ELEMENTS_SUCCESS:

      return {
        ...state,
        loading: false,
        MBTeachingElements: action.payload,
        totalSize: payload.length,
      }
    case FETCH_MB_TEACHING_ELEMENTS_ERROR:
      return {
        ...state,
        loading: false,
      }

      /** App Builder Teaching list By Code **/
    case FETCH_MB_TEACHING_ELEMENTS_BY_CODE:
      return {
        ...state,
        loading: true,
      }

    case FETCH_MB_TEACHING_ELEMENTS_BY_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        MBTeachingElements: action.payload,
        totalSize: payload.length,
      }

    case FETCH_MB_TEACHING_ELEMENTS_BY_CODE_ERROR:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}
export default MBTeachingElementReducer
