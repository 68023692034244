import {
  LOGOUT,
  SET_AUTH_DATA,
  SET_TOKEN,
  SET_TOKEN_LOADING,
  SET_TOKEN_ERROR,
  SET_PROFILE,
  SET_IS_AUTH,
  SET_MENU,
  GET_TUTORIAL_DATA,
  GET_TUTORIAL_DATA_SUCCESS,
  GET_TUTORIAL_DATA_ERROR,
  GET_TUTORIAL_DATA_BY_ID,
  GET_TUTORIAL_DATA_BY_ID_SUCCESS,
  GET_TUTORIAL_DATA_BY_ID_ERROR,
  GET_FAQ_DATA,
  GET_FAQ_DATA_SUCCESS,
  GET_FAQ_DATA_ERROR,
  SET_PROFILE_SIDEBAR_LOADING,
  GET_PROFILE_SIDEBAR_MENU
} from "./actionTypes";
import { ACCESS_TOKEN_URL, GET_PROFILE_INFO, getAllTutorial, getTutorialById, getAllFaq, LIST_ALL_APP_MENU, LOG_OUT_USER } from "constants/urls";
import { axiosAccount, axiosApiAdmin } from "services/api";
import { fetchSites } from "store/actions";
import { clear, get } from "services/cookies";

export const fetchProfile = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await axiosAccount.get(GET_PROFILE_INFO);
      if (res.data?.data?.user) {
        dispatch(setProfileData(res.data.data.user));
      }
      dispatch(setLoading(false));
    } catch (err) {
      dispatch(setError(err.response?.data?.message || err.message));
      dispatch(setLoading(false));
    }
  };
};

export const setProfileData = (data) => ({
  type: SET_PROFILE,
  payload: data,
});

/** Profile Menu list */
export const getProfileSidebarMenuList = (data) => async (dispatch) => {
  try {
    dispatch(setProfileSidebarLoading(true));
    const res = await axiosAccount.post(LIST_ALL_APP_MENU, data);
    if (res.status) {
      if (res.data?.data) {
        dispatch(setProfileSidebarMenuList(res.data.data.application_menu));
      }
      dispatch(setProfileSidebarLoading(false));
    }
  } catch (err) {
    dispatch(setProfileSidebarLoading(false));
  }
};

export const logOutUser = () => {
  return async dispatch => {
    try {
      dispatch(setLoading(true))
      const res = await axiosAccount.post(LOG_OUT_USER, {
        token: get("token")
      })
      if (res.status === 200) {
        clear()
        dispatch(logout())
      }
      dispatch(setLoading(false))
    } catch (err) {
      dispatch(setError(err.response?.data?.message || err.message))
      dispatch(setLoading(false))
    }
  }
}


export const setProfileSidebarMenuList = (data) => ({
  type: GET_PROFILE_SIDEBAR_MENU,
  payload: data,
});

export const setProfileSidebarLoading = (data) => ({
  type: SET_PROFILE_SIDEBAR_LOADING,
  payload: data,
});

export const fatchAccessToken = (data, cb) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await axiosAccount.post(ACCESS_TOKEN_URL, data);
      if (res.data?.data) {
        dispatch(setToken(res.data.data.token));
        dispatch(fetchProfile());
        dispatch(fetchSites());
        dispatch(setIsAuth(true));
        cb();
      }
      dispatch(setLoading(false));
    } catch (err) {
      dispatch(setError(err.response?.data?.message || err.message));
      dispatch(setLoading(false));
    }
  };
};

/*******************Get All Tutorials data***********************/
export const handleGetAllTutorial = (payload, cb = () => { }) => {
  return async (dispatch) => {
    try {
      const res = await axiosApiAdmin.get(`${getAllTutorial}/${payload}`);
      if (res.data.data) {
        dispatch(getAllTutorialSuccess(res.data.data.rows));
        cb(res.data.data.rows)
      }
    } catch (err) {
      dispatch(
        getAllTutorialError(err.response?.data?.message || err.message)
      );
    }
  };
};

/*******************Get Tutorial data by id***********************/
export const handleGetTutorialById = (payload) => {
  return async (dispatch) => {
    try {
      const res = await axiosApiAdmin.get(`${getTutorialById}/${payload}`);
      if (res.data.data) {
        dispatch(getTutorialDataByIdSuccess(res.data.data.data));
      }
    } catch (err) {
      dispatch(
        getTutorialDataByIdError(err.response?.data?.message || err.message)
      );
    }
  };
};

/*******************Get All FAQ data***********************/
export const handleGetAllFaq = (payload, cb = () => { }) => {
  return async (dispatch) => {
    try {
      const res = await axiosApiAdmin.post(getAllFaq, payload);
      if (res.data.data) {
        dispatch(getAllFaqSuccess(res.data.data.rows));
        cb(res.data.data.rows)
      }
    } catch (err) {
      dispatch(
        getAllFaqError(err.response?.data?.message || err.message)
      );
    }
  };
};

export const setAuthData = (data) => ({
  type: SET_AUTH_DATA,
  payload: data,
});

export const setToken = (data) => ({
  type: SET_TOKEN,
  payload: data,
});

export const logout = () => ({
  type: LOGOUT,
});

export const setLoading = (data) => ({
  type: SET_TOKEN_LOADING,
  payload: data,
});

export const setError = (message) => ({
  type: SET_TOKEN_ERROR,
  payload: message,
});

export const setIsAuth = (data) => ({
  type: SET_IS_AUTH,
  payload: data,
});

export const setToggleMenu = (data) => ({
  type: SET_MENU,
  payload: data,
});

export const getAllTutorialData = (data) => {
  return {
    type: GET_TUTORIAL_DATA,
    payload: data,
  };
};
export const getAllTutorialSuccess = (data) => {
  return {
    type: GET_TUTORIAL_DATA_SUCCESS,
    payload: data,
  };
};
export const getAllTutorialError = (data) => {
  return {
    type: GET_TUTORIAL_DATA_ERROR,
    payload: data,
  };
};

export const getTutorialDataByIdData = (data) => {
  return {
    type: GET_TUTORIAL_DATA_BY_ID,
    payload: data,
  };
};
export const getTutorialDataByIdSuccess = (data) => {
  return {
    type: GET_TUTORIAL_DATA_BY_ID_SUCCESS,
    payload: data,
  };
};
export const getTutorialDataByIdError = (data) => {
  return {
    type: GET_TUTORIAL_DATA_BY_ID_ERROR,
    payload: data,
  };
};

export const getAllFaqData = (data) => {
  return {
    type: GET_FAQ_DATA,
    payload: data,
  };
};
export const getAllFaqSuccess = (data) => {
  return {
    type: GET_FAQ_DATA_SUCCESS,
    payload: data,
  };
};
export const getAllFaqError = (data) => {
  return {
    type: GET_FAQ_DATA_ERROR,
    payload: data,
  };
};
