import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logOutUser, setToggleMenu } from "store/actions";
import profileImg from "assets/images/user-img-1.png";
import growCurriculum from "assets/images/grow-curriculum.jpeg";
import toogleIcon from "assets/images/icon/menu.svg";
import headerLogo from "assets/images/logo.png";
import useOutsideClick from "constants/useOutsideClick";
import { get } from "../../../services/cookies";

const Header = ({ isPageNotFound }) => {
  const [sidebarMenu, setSidebarMenu] = useState("");
  const [overlay01, setOverlay01] = useState("");
  const [showDropDown, setShowDropDown] = useState(false);
  const ref = useRef();

  const { isAuth, user, toggleMenu, profileMenuList } = useSelector(
    (state) => state.auth
  );
  const { appMenuList } = useSelector((state) => state.site);
  const Location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    const body = document.querySelector("body");
    if (sidebarMenu == "show") {
      body.classList.add("overflow-hidden");
    } else {
      body.classList.remove("overflow-hidden");
    }
  });

  const handleLogout = () => {
    dispatch(logOutUser());
  };

  const clickOutSide = (e) => {
    let click = e.target.closest("#dropdownMenuLink");
    if (!click) {
      setShowDropDown(false);
    }
  };

  const handleToggle = () => {
    dispatch(setToggleMenu(toggleMenu));
  };

  useEffect(() => {
    window.addEventListener("click", clickOutSide);
    return () => window.removeEventListener("click", clickOutSide);
  }, [clickOutSide]);

  const openSidebarMenu = () => {
    setSidebarMenu("show");
    setOverlay01("is-open");
  };

  const closeSidebarMenu = useCallback(() => {
    setSidebarMenu("");
    setOverlay01("");
  }, []);

  useOutsideClick(ref, closeSidebarMenu);

  const handleReturnUrl = () => {
    window.location.replace(process.env.REACT_APP_REDIRECT_URL + "login");
  };

  return (
    <div className="top_nav">
      {id != undefined || isPageNotFound ? (
        <Link to="/">
          <img src={headerLogo} alt="logo" className="big-logo" />
        </Link>
      ) : Location.pathname === "/subscription" ||
        Location.pathname === "/faq" ||
        Location.pathname === "/tutorial" ||
        Location.pathname === "/privacy_policy" ? (
        <Link to="/">
          <img src={headerLogo} alt="logo" className="big-logo" />
        </Link>
      ) : (
        !isPageNotFound && (
          <div className="menu-toggle">
            <Link to="#" onClick={handleToggle}>
              <img src={toogleIcon} alt="menu" className="menu" />
            </Link>
          </div>
        )
      )}
      <div className="ml20">
        <ul className="top-menu">
          {!!appMenuList.length &&
            appMenuList?.map((MainMenu, i) => (
              <span key={MainMenu.application_menu_id}>
                {Location.pathname === MainMenu.link ? (
                  <li key={i} className="active">
                    <Link
                      to={{
                        pathname:
                          isAuth == true ? MainMenu.link : handleReturnUrl(),
                      }}
                    >
                      {MainMenu.application_menu_title}
                    </Link>
                  </li>
                ) : Location.pathname === MainMenu.link ? (
                  <li key={i} className="active">
                    <Link
                      to={{
                        pathname: MainMenu.link,
                      }}
                    >
                      {MainMenu.application_menu_title}
                    </Link>
                  </li>
                ) : (Location.pathname === "/" &&
                    MainMenu.link === "/builder") ||
                  (Location.pathname.match("^/builder") &&
                    MainMenu.link.match("^/builder")) ? (
                  <li className="active">
                    <Link
                      to={{
                        pathname: MainMenu.link,
                      }}
                    >
                      {MainMenu.application_menu_title}
                    </Link>
                  </li>
                ) : Location.pathname.match("^/app-builder") &&
                  MainMenu.link.match("^/app-builder") ? (
                  <li className="active">
                    <Link
                      to={{
                        pathname: MainMenu.link,
                      }}
                    >
                      {MainMenu.application_menu_title}
                    </Link>
                  </li>
                ) : Location.pathname.match("^/subscription") &&
                  MainMenu.link.match("^/subscription") ? (
                  <li className="active">
                    <Link
                      to={{
                        pathname: MainMenu.link,
                      }}
                    >
                      {MainMenu.application_menu_title}
                    </Link>
                  </li>
                ) : (
                  <li>
                    <Link
                      to={{
                        pathname: MainMenu.link,
                      }}
                    >
                      {MainMenu.application_menu_title}
                    </Link>
                  </li>
                )}
              </span>
            ))}
        </ul>
      </div>
      {isAuth ? (
        <>
          <div className={`overlay overlay01 ${overlay01}`}></div>
          <div
            className="sidebarIconToggle"
            id="openSidebarMenu"
            onClick={openSidebarMenu}
          >
            <img
              src={
                !!user && !!user.profile_image ? user.profile_image : profileImg
              }
              alt="user-img"
              className="profile-img-icon"
            />
            {!!user && !!user.first_name && (
              <span key={user.first_name} className="user-header-name">
                {user.first_name}
              </span>
            )}
            <i className="fa fa-chevron-down ml-2"></i>
          </div>

          <div id="sidebarMenu" className={`${sidebarMenu}`} ref={ref}>
            <div className="sidemenu-header"></div>
            <ul className="sidebarMenuInner">
              <li className="user">
                <img
                  src={
                    !!user && !!user.profile_image
                      ? user.profile_image
                      : profileImg
                  }
                  alt="user-img"
                  className="user-img"
                />
                <label className="sidebarIconToggle" onClick={closeSidebarMenu}>
                  <i className="fas fa-times"></i>
                </label>

                {!!user && !!user.display_name && (
                  <h3 key={user.display_name} className="mtb10">
                    {user.display_name}
                  </h3>
                )}
                {!!user && !!user.email && (
                  <p key={user.email} className="header-user-email">
                    {user.email}
                  </p>
                )}
                <div className="siderbar-btn">
                  <Link
                    className="btn account-btn btn-size"
                    target="_parent"
                    to={{ pathname: `${process.env.REACT_APP_REDIRECT_URL}` }}
                  >
                    Account
                  </Link>
                  <Link
                    to=""
                    className="btn logout-btn btn-size"
                    onClick={handleLogout}
                  >
                    Logout
                  </Link>
                </div>
              </li>
              <li>
                <h5 className="heading-text">Applications</h5>
                <ul className="application-submenu p-0">
                  {profileMenuList?.map((siteLink) => (
                    <Link
                      key={siteLink.application_menu_id}
                      target="_parent"
                      to={{ pathname: siteLink.link }}
                    >
                      {Location.pathname === "/app-builder" ? (
                        <li
                          className={`${
                            siteLink.link.indexOf("app-builder") != -1
                              ? "active"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              !!siteLink.icon ? siteLink.icon : growCurriculum
                            }
                            alt=""
                          />
                          {siteLink.application_menu_title}
                        </li>
                      ) : (
                        <li
                          className={`${
                            Location.pathname != "/app-builder" &&
                            siteLink.link.indexOf("app-builder") === -1 &&
                            siteLink.link.indexOf("builder") != -1
                              ? "active"
                              : ""
                          }`}
                        >
                          <img
                            src={
                              !!siteLink.icon ? siteLink.icon : growCurriculum
                            }
                            alt=""
                          />
                          {siteLink.application_menu_title}
                        </li>
                      )}
                    </Link>
                  ))}
                </ul>
              </li>
            </ul>
            {!!user && (user.user_role === 1 || user.user_role === 2) && (
              <div className="admin-btn" key={user.user_role}>
                <Link
                  className="btn-edit-profile"
                  target="_parent"
                  to={{ pathname: `${process.env.REACT_APP_ADMIN_SITE_URL}` }}
                >
                  Go To Admin Panel
                </Link>
              </div>
            )}
          </div>
        </>
      ) : (
        <Link
          className="login-btn"
          target="_parent"
          to={{ pathname: `${process.env.REACT_APP_REDIRECT_URL}` }}
        >
          Login
        </Link>
      )}
    </div>
  );
};

export default Header;
