import React, { useEffect, useState } from 'react'
import errorPageNotFound from "assets/images/errorPageNotFound.svg"
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { Link } from 'react-router-dom'

export const PageNotFound = () => {
  const [isPageNotFound, setIsPageNotFound] = useState(false)

  useEffect(() => {
    setIsPageNotFound(true)
  }, [])
  return (
    <div className="no-sidebar MBDetails">
      <Header isPageNotFound={isPageNotFound} />
      <div className="main_card pb-0">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/builder">
                <span>Home</span>
              </Link>
            </li>
          </ol>
        </nav>
        <div className="main_card_wrapper tabbing-card">
          <div className="wrapper-heading-area filter-area">
            <div className="errorPageNotFoundImage">
              <img src={errorPageNotFound} alt="unauthorizedUserImage" className="unauthorizedUserImage" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
