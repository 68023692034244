import React, { useEffect, useMemo, useState } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import Header from "components/common/Header/Header";
import Footer from "components/common/Footer/Footer";
import backImage from "assets/images/icon/back.png";
import growNoData from "assets/images/no-data.png";
import modalClose from "assets/images/icon/modal-close.svg";
import deleteAlert from "assets/images/icon/red-info.svg";
import {
  getSubscriptionProductList,
  getActiveSubscriptionsBySite,
  handleFetchMBListing,
  handleFetchABListing,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import LoaderSubscription from "components/common/Loader/MBLoading"
import { toast } from "react-toastify";
import queryString from "query-string";
import { Modal } from "reactstrap";

const Subscription = () => {
  const { subscriptionProduct, activeSubscriptionsList, loading } = useSelector(state => state.subscription);
  const { totalSizeMB } = useSelector((state) => state.MessageBuilderList);
  const { totalSizeAB } = useSelector((state) => state.AppBuilderListing);
  const { isAuth, user } = useSelector((state) => state.auth);

  const [subscriptionLimit, setSubscriptionLimit] = useState(false);
  const [alert, setAlert] = useState(false);
  const [isUserId, setIsUserId] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const parsed = queryString.parse(location.search);
  let urlValue = parsed.redirect_url;

  let userId
  useEffect(() => {
    if (isAuth) {
      dispatch(getSubscriptionProductList({ site_id: 3, category_id: 0 }))
      dispatch(getActiveSubscriptionsBySite({ site_id: 3 }, (status) => {
        if (status.count === undefined && !!user && user.user_role === 3 && (status.is_system_message_count >= 12 || status.is_user_message_count >= 1 || status.is_system_volume_count >= 12 || status.is_user_volume_count >= 1)) {
          setAlert(true)
        }
        if (urlValue || (status.count === undefined && !!user && user.user_role === 3 && (status.is_system_message_count >= 12 || status.is_user_message_count >= 1 || status.is_system_volume_count >= 12 || status.is_user_volume_count >= 1))) {
          setAlert(true)
        }
        if (status) {
          userId = status.rows.map((item) => {
            setIsUserId(item.user_id)
          })
        }
      }))
    }
  }, [dispatch,urlValue])

  const getDurationInPerFormat = duration => {
    switch (Math.floor(duration / 30)) {
      case 1:
      case 2:
        return "/ month"
      case 3:
      case 4:
      case 5:
        return "/ 4-month"
      case 6:
      case 7:
      case 8:
        return "/ 6-month"
      case 9:
      case 10:
      case 11:
      case 12:
        return "/ year"
      default:
        return ""
    }
  }

  const getDurationInPerFormatName = duration => {
    switch (Math.floor(duration / 30)) {
      case 1:
      case 2:
        return " monthly"
      case 3:
      case 4:
      case 5:
        return " 4-month"
      case 6:
      case 7:
      case 8:
        return " 6-month"
      case 9:
      case 10:
      case 11:
      case 12:
        return " yearly"
      default:
        return ""
    }
  }

  const aSIds = useMemo(
    () =>
      !!activeSubscriptionsList && activeSubscriptionsList?.length
        ? activeSubscriptionsList.map(el => {
          const products = []
          if (!!el.user_orders && el.user_orders?.length)
            el.user_orders.forEach(childEl => {
              childEl.user_order_items.forEach(i => {
                products.push(i.product_id)
              })
            })
          return {
            next_payment_date: el.next_payment_date,
            user_subscription_id: el.user_subscription_id,
            products,
          }
        })
        : [],
    [activeSubscriptionsList]
  )

  const handleSubscription = () => {
    setSubscriptionLimit(true);
  }

  const goBack = () => {
    window.location.replace(`${parsed.redirect_url}${location?.hash}`)
  }

  //Subscription Modal Close
  const handleSubscriptionPopupClose = () => {
    setAlert(false)
  }

  return (
    <>
      <div className="no-sidebar tutorials subscription">
        <Header />
        <div className="main_card main_card_body pb-0">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/builder">
                  {" "}
                  <span>Home</span>
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Subscription
              </li>
            </ol>
          </nav>
          <div className="main_card_wrapper tabbing-card main_card_wrapper_1">
            <div className="wrapper-heading-area filter-area">
              <div className="title">
                {
                  parsed.redirect_url ?
                    <Link className="" to="#" onClick={goBack}>
                      {" "}
                      <img src={backImage} className="mr-2" />
                      <h1 className="wrapper-heading fw-600 mb0">
                        {" "}
                        Go Back
                      </h1>
                    </Link>
                    :
                    <Link className="" to="/builder">
                      {" "}
                      <img src={backImage} className="mr-2" />
                      <h1 className="wrapper-heading fw-600 mb0">
                        {" "}
                        Back To Your Message
                      </h1>
                    </Link>
                }
              </div>
            </div>
          </div>

          {/* <!-- modal Subscription  --> */}
          <Modal isOpen={alert} className="modal-dialog modal-dialog-centered" role="document" centered >
            <div className="modal-content">
              <div className="modal-body modal_popup">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => handleSubscriptionPopupClose()}>
                  <img src={modalClose} alt="modal-close" />
                </button>
                <div className="modal_data editImageModalData subscriptionModal">
                  <div className="row">
                    <div className="col-md-12 text-center mt-5">
                      <img src={deleteAlert} alt="red-info" />
                    </div>
                    <div className="col-md-12 text-center mb20 mt15">
                      <h1 className="fw-600 dark-text "> THANKS! "Sorry! As a Free Trial user, you are limited to editing builds that only pertain to the 4-week series you were provided."</h1>
                    </div>
                    <div className="col-md-12 modal-btn">
                      <button className="btn primary-btn" onClick={() => handleSubscriptionPopupClose()}>Ok</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          {!!loading ? (
            <LoaderSubscription />
          ) : <div className="main_card_wrapper tabbing-card subscription-main-card">
            <div className="card">
              <div className="card-body">
                <div className="filter_nav w-100">
                  <div className="d-flex main-notification-title mb-4">
                    <div className="d-flex">
                      <h2 className="subscription_title">Subscription</h2>
                    </div>
                  </div>
                  <div className={subscriptionLimit ? "form-label alert alert-danger" : "form-label alert alert-danger d-none"}>You are already having one active subscription. Please cancel that and try again.</div>
                  {
                    subscriptionProduct.length > 0 ? "" : <img
                      src={growNoData}
                      className="no-data-found d-flex"
                    ></img>
                  }
                  {subscriptionProduct?.map((item, i) => {
                    return (
                      <React.Fragment key={i}>
                        {!!aSIds.find(el =>
                          el.products.includes(item.product_id)
                        )?.next_payment_date && (user?.user_id === isUserId &&
                          <div className="date-renewal">
                            Next Renewal :{" "}
                            {moment(
                              aSIds.find(el =>
                                el.products.includes(item.product_id)
                              )?.next_payment_date
                            ).format("MMMM DD, yyyy")}
                          </div>
                          )}
                        {user?.user_id === isUserId ?
                          <div className="card card-subscription">
                            <div className="card-body body-subscription">
                              <p className="user_id_notification">
                                <b className="mr-1 price-month">
                                  ${item?.product_price || 0}
                                </b>
                                <span className="subscription-month">
                                  {getDurationInPerFormat(
                                    item?.product_duration
                                  )}
                                </span>
                              </p>
                              <div className="main-subscription-div">
                                {/* link */}
                                {!!aSIds.find(el =>
                                  el.products.includes(item.product_id)
                                ) ? (
                                  <Link
                                    target="_parent"
                                    to={{
                                      pathname: `${process.env
                                        .REACT_APP_REDIRECT_URL
                                        }view-subscription/${aSIds.find(el =>
                                          el.products.includes(
                                            item.product_id
                                          )
                                        )?.user_subscription_id
                                        }`,
                                    }}
                                    className="subscription-link"
                                  >
                                    Your Subscription
                                  </Link>
                                ) : (
                                  activeSubscriptionsList?.filter(site => site.site_id === 3)?.length > 0 ?
                                    <div target="_parent"
                                      className="subscription-btn"
                                      onClick={handleSubscription}
                                    >
                                      <span>Try{" "}
                                        {getDurationInPerFormatName(
                                          item?.product_duration
                                        )}{" "}
                                        Subscription</span>
                                    </div>
                                    :
                                    <Link
                                      target="_parent"
                                      to={{
                                        pathname: `${process.env
                                          .REACT_APP_REDIRECT_URL
                                          }subscription-confirmation?product_id=${item.product_id
                                          }&site_id=${+item?.site_id}&redirect_url=${process.env.REACT_APP_SITE_URL
                                          }/subscription`,
                                      }}
                                      className="subscription-btn"
                                    >
                                      Try{" "}
                                      {getDurationInPerFormatName(
                                        item?.product_duration
                                      )}{" "}
                                      Subscription
                                    </Link>
                                )}
                              </div>
                            </div>
                          </div> : <div className="card-body body-subscription">
                                <p className="user_id_notification">
                                <b className="mr-1 price-month">
                                  ${item?.product_price || 0}
                                </b>
                                <span className="subscription-month">
                                  {getDurationInPerFormat(
                                    item?.product_duration
                                  )}
                                </span>
                              </p>
                              <div className="main-subscription-div">
                            
                                {(
                                  activeSubscriptionsList?.filter(site => site.site_id === 3)?.length > 0 ?
                                    <div target="_parent"
                                      className="subscription-btn"
                                      onClick={handleSubscription}
                                    >
                                      <span>Try{" "}
                                        {getDurationInPerFormatName(
                                          item?.product_duration
                                        )}{" "}
                                        Subscription</span>
                                    </div>
                                    :
                                    <Link
                                      target="_parent"
                                      to={{
                                        pathname: `${process.env
                                          .REACT_APP_REDIRECT_URL
                                          }subscription-confirmation?product_id=${item.product_id
                                          }&site_id=${+item?.site_id}&redirect_url=${process.env.REACT_APP_SITE_URL
                                          }/subscription`,
                                      }}
                                      className="subscription-btn"
                                    >
                                      Try{" "}
                                      {getDurationInPerFormatName(
                                        item?.product_duration
                                      )}{" "}
                                      Subscription
                                    </Link>
                                )}
                                </div>
                            </div>}
                      </React.Fragment>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          }
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Subscription
