import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Droppable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import { Modal } from "reactstrap";
import {
  handleMBFolderListing,
  handleAddEditMBFolder,
  handleGetLibraryFolderByType,
  handleGetLibraryBuild,
  setMBFolderId,
  setMBFolderTitle,
  setMBLibraryFolderId,
  setMBLibraryFolderTitle,
  setABFolderId,
  setABFolderTitle,
  handleDeleteMBFolder,
  setPageMBFolderBuildList,
  setPageMBCustomBuildList,
  setPageABFolder,
  setPageAB,
  getActiveSubscriptionsBySite,
} from "store/actions";
import growLogo from "assets/images/GrowLessonBuilderLogo.png";
import addIcon from "assets/images/icon/plus.svg";
import folderIcon from "assets/images/icon/file.svg";
import libraryFolderIcon from "assets/images/icon/volume.svg";
import modalClose from "assets/images/icon/modal-close.svg";
import dotActionIcon from "assets/images/icon/build-dot.svg";
import deleteAlert from "assets/images/icon/red-info.svg";

const Sidebar = ({
  _isActive,
  onSelectFolder,
  onSelectFolderTitle,
  onSelectLibraryFolder,
  onSelectFolderSearch,
  onSelectLibraryFolderSearch,
  onSelectLibraryLoader,
  onSelectedLibraryTab,
  afterOnDragTitle,
  isOnDrag,
  handleCategoryId,
  handleMinistryType,
  handleDuplicateBuild,
  handleButton,
}) => {
  const dispatch = useDispatch();
  //UseSelector
  const {
    MBFolderListing,
    LibraryListing,
    StoreFolderId,
    StoreLibraryFolderId,
    StoreFolderTitle,
    MBFolderSave,
  } = useSelector((state) => state.MessageBuilderFolderList);
  // For Toggle Menu
  const { toggleMenu, isAuth } = useSelector((state) => state.auth);

  //UseStates
  const [isActive, setActive] = useState(0);
  const [alert, setAlert] = useState(false);
  const [addFolderPopup, setAddFolderPopup] = useState(false);
  const [buttonEnable, setButtonEnable] = useState(false);
  const [folderSelected, setFolderSelected] = useState(false);
  const [getFolderName, setGetFolderName] = useState("");
  const [isLibraryFolder, setIsLibraryFolder] = useState(0);
  const [isActionButton, setIsActionButton] = useState(true);
  const [folderAlert, setFolderAlert] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [folderAction, setFolderAction] = useState(false);
  const [isFolderRename, setIsFolderRename] = useState(false);
  const [isFolderMatch, setIsFolderMatch] = useState(false);
  const [isFolderActionId, setIsFolderActionId] = useState(0);
  const [folderSelection, setFolderSelection] = useState(false);
  const [isRenameFolderId, setIsRenameFolderId] = useState(0);
  const [isFolderScroll, setIsFolderScroll] = useState(1);
  const [isHandleModal, setIsHandleModal] = useState(false);

  const [folderInfo, setFolderInfo] = useState({
    folderName: StoreFolderTitle,
    folderId: 0,
  });

  // Listing Folders
  useEffect(() => {
    if (_isActive !== 0 && !StoreLibraryFolderId) {
      //Folder Select When BUILD Drag To Folder
      setActive(JSON.parse(_isActive));
      onSelectFolder(JSON.parse(_isActive));
    } else {
      if (StoreLibraryFolderId == 0) {
        //Folder Select When Folder Click
        setActive(StoreFolderId);
        onSelectFolder(StoreFolderId);
      } else {
        //Library Folder Select When Library Folder Click
        setActive(StoreLibraryFolderId);
        onSelectLibraryFolder(StoreLibraryFolderId);
        // Listing Library Folders
        // onSelectLibraryLoader(false);
        // dispatch(handleGetLibraryBuild({
        //   volume_id: StoreLibraryFolderId,
        //   ministry_type: 1
        // }, (status) => {
        //   if (status) {
        //     onSelectLibraryLoader(true);
        //   }
        // }));
      }
    }
    // Listing Folders
    if (isAuth && !isOnDrag && !folderSelected) {
      dispatch(handleMBFolderListing({}));
    }
    // Store App Folder ID
    dispatch(setABFolderId(0));
    //Store App Folder TITLE
    dispatch(setABFolderTitle("User Builds"));
  }, [dispatch, _isActive, StoreLibraryFolderId]);

  // For folder selection
  const handleFolderSelectMB = (folderDetail, isActionButtonStatus = false) => {
    window.scrollTo(0, 0);
    if (folderAction === false) {
      //Select Message Build Search Value by Folder
      onSelectFolderSearch(true);
      setInterval(() => {
        onSelectFolderSearch(false);
      }, 2000);
      //Select App Folder Title
      if (folderDetail.build_folder_id == 0) {
        onSelectFolderTitle("User Builds");
        afterOnDragTitle("User Builds");
      } else {
        onSelectFolderTitle(folderDetail.build_folder_title);
        afterOnDragTitle(folderDetail.build_folder_title);
      }
      setIsActionButton(isActionButtonStatus);
      setFolderSelected(folderDetail);
      setFolderInfo({
        folderName: folderDetail.build_folder_title,
        folderId: folderDetail.build_folder_id,
      });
      setActive(folderDetail.build_folder_id);
      //Store Folder ID
      if (isLibraryFolder == 0) {
        dispatch(setMBFolderId(folderDetail.build_folder_id));
      }
      //Store Folder TITLE
      if (folderDetail.build_folder_id == 0) {
        dispatch(setPageMBCustomBuildList(isFolderScroll));
        dispatch(setPageMBFolderBuildList(isFolderScroll));
        dispatch(setMBFolderTitle("User Builds"));
      } else {
        dispatch(setPageMBCustomBuildList(isFolderScroll));
        dispatch(setPageMBFolderBuildList(isFolderScroll));
        dispatch(setMBFolderTitle(folderDetail.build_folder_title));
      }

      //Store Library Folder ID
      dispatch(setMBLibraryFolderId(""));
      //Store Library Folder TITLE
      dispatch(setMBLibraryFolderTitle(""));

      onSelectFolder(folderDetail.build_folder_id, folderDetail.title);
      // if (folderDetail.build_folder_id === 0)
      //   dispatch(handleMBFolderListing({}));
    }
  };

  //handle Delete Modal for Folder
  const handleFolderDeleteMB = (deleteAB) => {
    setFolderAction(!folderAction);
    setSelectedRows([deleteAB]);
    setAlert(true);
  };
  //Delete Folder By Folder ID
  const handleFolderDeleteByFolderId = () => {
    //MB folder Delete
    setIsHandleModal(true);
    dispatch(
      handleDeleteMBFolder(
        {
          build_folder_id: [...selectedRows][0].build_folder_id,
        },
        (status) => {
          if (status) {
            setIsHandleModal(false);
            setAlert(false);
            setSelectedRows([]);
          }
        }
      )
    );
    if (isActive === [...selectedRows][0].build_folder_id) {
      //Default First Folder selected when AB folder Delete
      dispatch(setMBFolderId(0));
      setActive(0);
      onSelectFolderTitle(dispatch(setMBFolderTitle("User Builds")));
      onSelectFolder(0);
    }
    // setAlert(false);
    // setSelectedRows([]);
  };

  //Add Folder show
  const handleFolderAddMB = () => {
    setButtonEnable(false);
    setAddFolderPopup(true);
    setFolderSelected(false);
    setFolderInfo({ folderName: "", folderId: 0 });
  };

  //Edit Folder show
  const handleFolderEditMB = (folder) => {
    setButtonEnable(false);
    setFolderAction(!folderAction);
    setAddFolderPopup(true);
    setIsFolderRename(true);
    setIsRenameFolderId(folder.build_folder_id);
  };

  //Close Add Folder popup
  const handleAddFolderPopupClose = () => {
    setAlert(false);
    setAddFolderPopup(false);
    setIsFolderRename(false);
    setIsFolderMatch(false);
    setButtonEnable(false);
    setIsHandleModal(false);
  };

  //Get Folder Name
  const handleGetFolderNameMB = (e) => {
    //Add and Edit FOLDER Name Space Validation at first position
    const checkSpace = e.target.value.indexOf(" ");
    if (checkSpace === 0) {
      return false;
    }
    //Add and Edit FOLDER Name input value Validation
    if (e.target.value == "" || checkSpace === 0) {
      setButtonEnable(false);
    } else {
      setButtonEnable(true);
    }
    setFolderInfo((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    if (isFolderRename) {
      setIsFolderRename(true);
    } else {
      setAddFolderPopup(true);
    }
    setGetFolderName(e.target.value);
  };

  //Save Folder when Add and Edit FOLDER Name
  const handleSaveFolderMB = () => {
    //Folder ID
    if (folderInfo.folderId) {
      const removeExtraSpace = (s) => s.trim().split(/ +/).join(" ");
      const folderTitle = removeExtraSpace(folderInfo.folderName);
      setIsHandleModal(true);
      dispatch(
        handleAddEditMBFolder(
          {
            build_folder_title: folderTitle,
            parent_id: 0,
            folder_type: 1,
            is_system: 1,
            build_folder_id: folderInfo.folderId,
          },
          (status) => {
            setIsHandleModal(false);
            setIsFolderMatch(status.message);
            if (status.message !== "Folder already exist") {
              setAddFolderPopup(false);
              setIsFolderRename(false);
              dispatch(handleMBFolderListing());
              //handle folder Title when Rename
              if (isRenameFolderId == StoreFolderId && StoreFolderId !== 0) {
                dispatch(setMBFolderTitle(folderTitle));
              }
            }
          }
        )
      );
    } else {
      if (!MBFolderSave) {
        setIsHandleModal(true);
        dispatch(
          handleAddEditMBFolder(
            {
              build_folder_title: folderInfo.folderName,
              parent_id: 0,
              folder_type: 1,
              is_system: 1,
            },
            (status) => {
              setIsHandleModal(false);
              setIsFolderMatch(status.message);
              if (status.message !== "Folder already exist") {
                setAddFolderPopup(false);
                setIsFolderRename(false);
                dispatch(handleMBFolderListing());
                //handle folder Title when Rename
                if (isRenameFolderId == StoreFolderId && StoreFolderId !== 0) {
                  dispatch(setMBFolderTitle(folderInfo.folderName));
                }
              }
            }
          )
        );
      }
    }

    if (folderInfo.folderName === "") {
      setSelectedRows([]);
      setFolderAlert(true);
    }
  };
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#B0CE47" : "",
    borderBottomLeftRadius: "25px",
    borderTopLeftRadius: "25px",
  });

  // Listing Folders
  useEffect(() => {
    if (isAuth) {
      dispatch(handleGetLibraryFolderByType({}));
    }
  }, [dispatch]);

  //Library Folder Select
  const handleFolderLibrarySelectMB = (folderDetails) => {
    handleCategoryId(folderDetails.category_id);
    handleMinistryType(folderDetails?.ministry_type);

    handleButton(true);
    onSelectedLibraryTab(true);
    //handle Message Library Build Search Value by Folder
    onSelectLibraryFolderSearch(true);
    setInterval(() => {
      onSelectLibraryFolderSearch(false);
      onSelectedLibraryTab(false);
    }, 2000);
    //Disable Folder Delete and Edit Button Library Folder ID
    if (folderDetails.category_id) {
      setIsActionButton(true);
    }
    onSelectLibraryFolder(folderDetails);
    setActive(folderDetails.category_id);
    //Store Library Folder ID
    dispatch(setMBLibraryFolderId(folderDetails.category_id));
    //Store Library Folder TITLE
    dispatch(setMBLibraryFolderTitle(folderDetails.category_title));
    setIsLibraryFolder(folderDetails.category_id);
    onSelectLibraryLoader(false);
    //Get Message Library build list
    // dispatch(handleGetLibraryBuild(
    //   {
    //     volume_id: folderDetails.category_id,
    //     ministry_type: 1,
    //   }, (status) => {
    //     if (status) {
    //       onSelectLibraryLoader(true);
    //       handleButton(false)
    //     }
    //   }));
  };

  //Open Action By Folder ID
  const handleFolderAction = (folderId) => {
    setFolderSelection(true);
    setFolderAction(!folderAction);
    setIsFolderActionId(folderId.build_folder_id);
    setFolderInfo({
      folderName: folderId.build_folder_title,
      folderId: folderId.build_folder_id,
    });
  };

  //DropDown(Action) Close By Folder ID
  const closeDropDown = (e) => {
    let click = e.target.closest("#DropDownClose");
    if (!click) {
      setFolderAction(false);
    }
  };
  useEffect(() => {
    window.addEventListener("click", closeDropDown);
    return () => window.removeEventListener("click", closeDropDown);
  }, [closeDropDown]);

  return (
    <div className="side_nav">
      <div className="logo_header">
        <Link to="/">
          <img
            src={growLogo}
            alt=""
            className="big-logo"
            style={{ height: "30px", width: "192.6px" }}
          />
          <img
            src={growLogo}
            alt=""
            className="mini-logo"
            style={{ height: "30px", width: "192.6px" }}
          />
        </Link>
      </div>
      <div className="menu-header">
        <h5 className="mb0 fw-600 siderbar-heading-text">FOLDERS</h5>
        <img
          onClick={() => handleFolderAddMB(folderSelected, true)}
          src={addIcon}
          alt="add"
          className={
            !toggleMenu
              ? "d-none"
              : "text-success folder-toolbar-btn fas fa-plus  align-self-center add-plus"
          }
        />
      </div>

      {/* <!-- modal Add Folder and Rename Folder  --> */}
      <Modal
        isOpen={addFolderPopup}
        className="modal-dialog modal-dialog-centered"
        role="document"
        centered
      >
        <div className="modal-content">
          <div className="modal-body modal_popup">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => handleAddFolderPopupClose()}
            >
              <img src={modalClose} alt="modal-close" />
            </button>
            <div className="modal_title">
              <h3>{isFolderRename ? "Rename Folder" : "Add Folder"}</h3>
            </div>
            <div className="modal_data editImageModalData">
              <div className="row">
                <div className="col-md-12 form-group">
                  <label className="form-label">Folder name</label>
                  <input
                    className="form-control "
                    placeholder="Enter folder name"
                    type="text"
                    name="folderName"
                    autoFocus
                    value={folderInfo.folderName}
                    onChange={handleGetFolderNameMB}
                  />
                  <br />
                  {isFolderMatch === "Folder already exist" ? (
                    <div className="form-label alert alert-danger folderExist">
                      Folder already exist
                    </div>
                  ) : null}
                </div>
                <div className="col-md-12 modal-btn">
                  <button
                    className="btn secondary-btn mr-2"
                    onClick={() => handleAddFolderPopupClose()}
                    disabled={isHandleModal === true}
                  >
                    cancel
                  </button>
                  {isHandleModal === true ? (
                    <button className="btn primary-btn">Saving...</button>
                  ) : (
                    <button
                      className="btn primary-btn"
                      onClick={() => handleSaveFolderMB()}
                      disabled={!buttonEnable}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* <!-- modal delete  --> */}
      <Modal
        isOpen={alert}
        className="modal-dialog modal-dialog-centered"
        role="document"
        centered
      >
        <div className="modal-content">
          <div className="modal-body modal_popup">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => handleAddFolderPopupClose()}
            >
              <img src={modalClose} alt="modal-close" />
            </button>
            <div className="modal_data editImageModalData">
              <div className="row">
                <div className="col-md-12 text-center mt-5">
                  <img src={deleteAlert} alt="red-info" />
                </div>
                <div className="col-md-12 text-center mb20 mt15">
                  <h1 className="fw-600 dark-text ">
                    Are you sure you want to delete?
                  </h1>
                </div>
                <div className="col-md-12 modal-btn">
                  <button
                    className="btn secondary-btn mr-2"
                    data-dismiss="modal"
                    onClick={() => handleAddFolderPopupClose()}
                    disabled={isHandleModal === true}
                  >
                    cancel
                  </button>
                  {isHandleModal === true ? (
                    <button className="btn delete-border-btn ml-3">
                      Deleting...
                    </button>
                  ) : (
                    <button
                      className="btn delete-border-btn ml-3"
                      onClick={() => handleFolderDeleteByFolderId()}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <ul>
        <li
          className={`${isActive === 0 ? "active" : "0"}`}
          onClick={() => handleFolderSelectMB({ build_folder_id: 0 }, true)}
        >
          <div className="folder-info">
            <img src={folderIcon} alt="folder" title="Folder" />
            <span
              data-bs-toggle="collapse"
              data-bs-target="#collapseFolder"
              aria-expanded="true"
              aria-controls="collapseFolder"
            >
              User Builds
            </span>
          </div>
        </li>

        {MBFolderListing &&
          MBFolderListing.map((folder, i) => (
            <Droppable key={i} droppableId={"" + folder.build_folder_id}>
              {(provided, snapshot) => (
                <li
                  key={i}
                  className={`${
                    isActive === folder.build_folder_id ? "active" : "0"
                  }`}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  <div
                    onClick={() => handleFolderSelectMB(folder)}
                    className={toggleMenu ? "folder-info" : "folder-mini"}
                    id={folder.build_folder_id}
                    key={i}
                    ref={provided.innerRef}
                  >
                    <img src={folderIcon} alt="" title="Folder" />
                    <span
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFolder1"
                      aria-expanded="false"
                      aria-controls="collapseFolder"
                    >
                      {folder.build_folder_title}
                    </span>
                  </div>

                  <div className="dropdown">
                    <div
                      className=""
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={dotActionIcon}
                        alt="dot"
                        className="m-0"
                        onClick={() => handleFolderAction(folder)}
                        id="DropDownClose"
                      />
                    </div>
                    <div
                      className={
                        isFolderActionId === folder.build_folder_id &&
                        folderAction
                          ? "dropdown-menu show folderDropDown"
                          : "dropdown-menu"
                      }
                      aria-labelledby="dropdownMenuLink"
                    >
                      <div
                        className="dropdown-item"
                        data-toggle="modal"
                        data-target="#renameFolder1"
                        onClick={() => handleFolderEditMB(folder)}
                      >
                        Rename
                      </div>
                      <div
                        className="dropdown-item"
                        data-toggle="modal"
                        data-target="#deleteModal"
                        onClick={() => handleFolderDeleteMB(folder)}
                        disabled={isActionButton}
                      >
                        Delete
                      </div>
                    </div>
                  </div>
                </li>
              )}
            </Droppable>
          ))}
      </ul>
      <div className="menu-header">
        <h5 className="mb0 fw-600 siderbar-heading-text">LIBRARY</h5>
      </div>
      <ul>
        {LibraryListing &&
          LibraryListing.map((libraryFolder, i) => (
            <li
              key={i}
              onClick={() =>
                isActive === libraryFolder.category_id
                  ? null
                  : handleFolderLibrarySelectMB(libraryFolder)
              }
              className={`${
                isActive === libraryFolder.category_id ? "active" : "0"
              }`}
            >
              <div
                className={toggleMenu ? "folder-info" : "folder-mini"}
                key={i}
              >
                <img src={libraryFolderIcon} title="Library" />
                <span>{libraryFolder.category_title}</span>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};
export default Sidebar;
