//App
import AppBuilderListing from "modules/App/AppBuilder";
import Appbuilderdetail from "modules/App/AppBuilderDetails";
import { AppBuilderTeaching } from "modules/App/Teaching";
import { AppBuilderPrint } from "modules/App/Print";

//Message
import MessageBuilderList from "modules/Message/Builder";
import MessageBuilderDetail from "modules/Message/Details";
import { MessageBuilderTeaching } from "modules/Message/Teaching";
import { MessageBuilderPrint } from "modules/Message/Print";
import LibraryDuplicate from "modules/Message/Builder/BuilderLibrary/LibraryDuplicate";
import Tutorials from "components/common/Tutorial/Tutorial";
import TutorialDetail from "components/common/Tutorial/TutorialDetail";
import FAQ from "components/common/FAQ/Faq";
import Subscription from "components/common/Subscription/Subscription";
import RoutesPage from "components/common/RoutesPage/RoutesPage";
import { PageNotFound } from "components/common/PageNotFound";


const protectedRoutes = [
  { path: "/subscription", exact: true, component: Subscription },
  { path: "/builder/edit/:id", exact: true, component: MessageBuilderDetail },
  { path: "/builder/view/:id", exact: true, component: MessageBuilderTeaching },
  { path: "/builder/print/:id", exact: true, component: MessageBuilderPrint },
  { path: "/builder/duplicate-build/:id", exact: true, component: LibraryDuplicate },
  { path: "/app-builder/edit/:id", exact: true, component: Appbuilderdetail },
  { path: "/app-builder/view/:id", exact: true, component: AppBuilderTeaching },
  { path: "/app-builder", exact: true, component: AppBuilderListing },
  { path: "/app-builder/print/:id", exact: true, component: AppBuilderPrint },
  { path: "/tutorial", exact: true, component: Tutorials },
  { path: "/tutorialDetail/:id", exact: true, component: TutorialDetail },
  { path: "/faq", exact: true, component: FAQ },
  { path: "/builder", exact: true, component: MessageBuilderList },
  { path: "/detail", exact: true, checkCondition: false },
  { path: "/:id", exact: true, component: RoutesPage },
  { path: "/", exact: true, component: MessageBuilderList },
  { path: "*", exact: true, component: PageNotFound }

];

export default protectedRoutes;
