import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { Modal } from "reactstrap";
import SeriesSection from "modules/App/AppBuilderDetails/components/SeriesSection";
import Series from "components/AppSeries/Series";
import Header from "components/common/Header/Header";
import Footer from "components/common/Footer/Footer";
import { handleABCategoriesVolumeList, handleSaveABCustomRestore, handleFetchRestorePoint, handleFetchABExportElements, handleABRestoreList, handleFetchSeriesDetail, handleSaveHeadingTitle, getActiveSubscriptionsBySite } from "store/actions";
import { handleABDuplicate, handleABDelete, handleFetchABListing } from "../AppBuilder/store/action";
import moment from "moment";
import queryString from "query-string";
import dropdownImg from "assets/images/icon/dropimg.svg";
import backImage from "assets/images/icon/back.png";
import modalClose from "assets/images/icon/modal-close.svg";
import deleteAlertIcon from "assets/images/icon/red-info.svg";
import unauthorizedUserImage from 'assets/images/unauthorizedUserImage.svg';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import html2pdf from "html2pdf.js";
import ExportAppBuilder from "./components/ExportAppBuilder";

const Appbuilderdetail = ({ history }) => {
  const { RestorePoint } = useSelector((state) => state.RestorePoint);
  const { SeriesDetail } = useSelector((state) => state.SeriesDetail);
  const { abExpportLoading } = useSelector((state) => state.AppBuilderExport);
  const { saveSeriesDescLoading, abSectionLoading } = useSelector((state) => state.AppBuilderDetail);
  const { saveLoading, duplicateLoading } = useSelector((state) => state.MessageBuilderDetail);
  const {totalSizeAB } = useSelector((state) => state.AppBuilderListing);
  const { user, isAuth } = useSelector((state => state.auth));

  const [savePaper, setSavePaper] = useState("yes");
  const [restore, setRestore] = useState(false);
  const [saveBuildName, setSaveBuildName] = useState("");
  const [bulidLimit, setBuildLimit] = useState(false);
  const [buildRestore, setBuildRestore] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [abBuildId, setABBuildId] = useState("");
  const [deleteRestore, setDeleteRestore] = useState(false);
  const [seriesSectionLoading, setSeriesSectionLoading] = useState(false);
  const [isImportClick, setIsImportClick] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [alert, setAlert] = useState(false);
  const [seriesId, setSeriesId] = useState(true);
  const [buttonEnable, setButtonEnable] = useState(false)
  const [isContentChange, setIsContentChange] = useState(false);
  const [isChangeHeadingTitle, setIsChangeHeadingTitle] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [seriesData, setSeriesData] = useState()
  const [isMakeCopy, setIsMakeCopy] = useState(false)
  const [isSeriesDisable, setIsSeriesDisable] = useState(false)
  const [isBuildElementSave, setIsBuildElementSave] = useState(false)
  const [isOnSave, setIsOnSave] = useState(false);
  const [scrollToTop, setScrollToTop] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false);
  const componentRef = useRef();

  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    setSeriesData(SeriesDetail.count)
  }, [SeriesDetail]);

  useEffect(() => {
    if (isAuth) {
      dispatch(getActiveSubscriptionsBySite({ site_id: 3 }, (status) => {
        if (status.count > 0) {
          setIsSubscription(true)
        }
      }))
    }
  }, [dispatch])

  //For AB Details Return Url
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  let decoded;
  if (parsed.media_url) {
    decoded = window.atob(parsed.returnUrl);
  }
  const handleSaveList = () => {
    setShowDropdown(!showDropdown);
    if (!showDropdown) {
      dispatch(
        handleFetchRestorePoint({
          volume_id: parseInt(params.id),
        })
      );
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
        setScrollToTop(true)
      }
      else if (scrolled <= 300) {
        setScrollToTop(false)
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  /** onClick close dropdown start **/
  const closeDropDown = (e) => {
    let click = e.target.closest("#navbarDropdown");
    if (!click) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", closeDropDown);
    return () => window.removeEventListener("click", closeDropDown);
  }, [closeDropDown]);

  /** onClick close dropdown end **/

  /* Close Restore point Div*/
  const closeRestorePointDiv = () => {
    setShowDropdown(false);
  };

  /* for save selected seriesId */
  const selectedSeriesId = (selectedSeriesId) => {
    setSeriesId(selectedSeriesId);
  };

  /** for openPrintModal **/
  const handlePrintModal = (id) => {
    setSelectedRows([id]);
    setAlert(true);
  };

  /** for closePrintModal **/
  const handleCloseAlert = () => {
    setAlert(false);
  };

  /** for select print option **/
  const handleChange = (event) => {
    setSavePaper(event.target.value);
  };

  /** for print ABdetails **/
  const handleSubmitPrint = (e) => {
    localStorage.setItem("seriesId", seriesId)
    e.preventDefault();
    history.push(
      `/app-builder/print/${params.id}?seriesId=${parseInt(localStorage.getItem("seriesId"))}&seriesprint=yes&savepaper=${savePaper}`
    );
  };

  /** for Export ABDetails **/
  const handleExportClick = () => {
    // ******** Old Export through API ********
    // dispatch(
    //   handleFetchABExportElements({
    //     id: parseInt(seriesId),
    //     type: 1,
    //   })
    // );
    // ******** Old Export through API ********


    // ******** New Export ********
    const element = componentRef?.current;
    const opt = {
      margin: [0.5, 0.5, 0.5, 0.5],
      filename: `App_Builder_${new Date() / 1000}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        scale: 4, // Increase scale to improve quality
        useCORS: true,
        logging: true, // Enable logging for debugging
        dpi: 300, // Set DPI to improve quality
        letterRendering: true, // Better text rendering
      },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };
    html2pdf().from(element).set(opt).save();
    // ******** New Export ********
  };

  // App Builder Details Import
  const handleImportClick = () => {
    setIsImportClick(!isImportClick)
    dispatch(handleABCategoriesVolumeList({
      id: 0
    })
    );
  };

  /** for open restore point update or copy modal **/
  const handleBuildRestoreClick = (volume_id) => {
    setABBuildId(volume_id);
    setBuildRestore(true);
  };

  /** for close restore point update or copy modal **/
  const handleBuildCloseRestore = () => {
    setBuildRestore(false);
  };

  /** for delete Manual Restore Point **/
  const ManualRestorePointDelete = (message_build_list_id) => {
    setSelectedRows([message_build_list_id]);
    setDeleteRestore(true);
  };

  let temp = [];
  let temp2 = [];

  /** For open Create Restore point Modal **/
  const handleRestoreClick = () => {
    dispatch(
      handleFetchRestorePoint(
        {
          volume_id: parseInt(params.id),
        },
        (data) => {
          if (data.flag == false) {
            setBuildLimit(true);
          } else {
            setRestore(true);
          }
        }
      )
    );
    setShowDropdown(false);
  };

  /** For close Create Restore point Modal **/
  const handleCloseRestore = () => {
    setRestore(false);
    setSaveBuildName("")
    setButtonEnable(false)
  };

  /**for Add Manual Restore Point **/
  const handleSubmitRestore = (e) => {
    setIsSubmit(true)
    e.preventDefault();
    dispatch(
      handleSaveABCustomRestore({
        volume_id: parseInt(params.id),
        restore_point_title: saveBuildName,
        is_auto_saved: 0,
      }, (status) => {
        if (status) {
          setIsSubmit(false)
          setSaveBuildName("")
          setRestore(false);
        }
      })
    );
    setButtonEnable(false);
  };

  /**for Change Manual Restore Point Value **/
  const handleValueChange = (event) => {
    const checkSpace = event.target.value.indexOf(" ");
    if (checkSpace === 0) {
      return false
    }

    if (event.target.value === "" || checkSpace === 0) {
      setButtonEnable(false)
    } else {
      setButtonEnable(true)
    }
    setSaveBuildName(event.target.value);
  };

  /** For Update Current Restore Point **/
  const handleUpdateCurrent = (e) => {
    setSeriesSectionLoading(true);
    setIsUpdate(true)
    dispatch(
      handleABRestoreList(
        {
          series_build_list_id: seriesId,
          volume_id: e.abBuildId,
        },
        (status) => {
          if (status) {
            setSeriesSectionLoading(false);
            setIsUpdate(false)
            setBuildRestore(false);
            dispatch(
              handleFetchSeriesDetail({
                volume_id: parseInt(params.id),
              })
            );
          }
        }
      )
    );
  };

  /** For open Manaul Restore Point Limit Modal **/
  const handleBuildLimit = () => {
    setBuildLimit(false);
  };
  
  /** For Make Copy Current Restore Point **/
  const handleMakeCopy = (e) => {
    if (!!user && user.user_role === 3) {
      if (isSubscription) {
        setSeriesSectionLoading(true);
        setIsMakeCopy(true)
        dispatch(
          handleABDuplicate(
            {
              volume_id: e.abBuildId,
            },
            (id) => {
              if (id) {
                history.replace("/app-builder/edit/" + id);
                setIsSeriesDisable(false)
                dispatch(
                  handleFetchSeriesDetail({ volume_id: id }, (status) => {
                    if (!!status && status) {
                      setSeriesSectionLoading(false);
                      setIsMakeCopy(false)
                      dispatch(handleFetchABListing({
                        page_no: 1,
                        page_record: 10,
                        sortField: "created_datetime",
                        sortOrder: "DESC",
                      }));
                    }
                  })
                );
              }
            }
          )
        );
        setBuildRestore(false);
      } else if (totalSizeAB >= 12) {
        history.push("/subscription")
      } else {
        setSeriesSectionLoading(true);
        setIsMakeCopy(true)
        dispatch(
          handleABDuplicate(
            {
              volume_id: e.abBuildId,
            },
            (id) => {
              if (id) {
                history.replace("/app-builder/edit/" + id);
                setIsSeriesDisable(false)
                dispatch(
                  handleFetchSeriesDetail({ volume_id: id }, (status) => {
                    if (!!status && status) {
                      setSeriesSectionLoading(false);
                      setIsMakeCopy(false)
                      dispatch(handleFetchABListing({
                        page_no: 1,
                        page_record: 10,
                        sortField: "created_datetime",
                        sortOrder: "DESC",
                      }));
                    }
                  })
                );
              }
            }
          )
        );
        setBuildRestore(false);
      }
    } else {
      setSeriesSectionLoading(true);
      setIsMakeCopy(true)
      dispatch(
        handleABDuplicate(
          {
            volume_id: e.abBuildId,
          },
          (id) => {
            if (id) {
              history.replace("/app-builder/edit/" + id);
              setIsSeriesDisable(false)
              dispatch(
                handleFetchSeriesDetail({ volume_id: id }, (status) => {
                  if (!!status && status) {
                    setSeriesSectionLoading(false);
                    setIsMakeCopy(false)
                    dispatch(handleFetchABListing({
                      page_no: 1,
                      page_record: 10,
                      sortField: "created_datetime",
                      sortOrder: "DESC",
                    }));
                  }
                })
              );
            }
          }
        )
      );
      setBuildRestore(false);
    }

  };

  /* Every 10 min restors point create */
  const MINUTE_MS = 600000;
  // every 10 min. send a call for auto save
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(
        handleSaveABCustomRestore({
          volume_id: parseInt(params.id),
          restore_point_title: "Restore",
          is_auto_saved: 1,
        })
      );
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  //App Builder Detail Redirect To Return URL(Admin)
  const handleReturnUrl = () => {
    window.location.replace(decoded + "?tab=3");
  };


  /** For Open Delete Modal **/
  const handleDeleteConfirm = () => {
    setIsDelete(true)
    dispatch(
      handleABDelete({
        volume_id: [...selectedRows][0],
      }, (status) => {
        if (status) {
          setIsDelete(false)
          setDeleteRestore(false);
        }
      })
    );
    setSelectedRows([]);
  };

  /** For Close Delete Modal **/
  const handleDeleteClose = () => {
    setDeleteRestore(false);
    setSelectedRows([]);
    setIsDelete(false)
  };

  const importByAddedSeries = (importByAddedSeries) => {
    setIsImportClick(!importByAddedSeries);
  };

  const onChangeElementTitle = (onChangeElementTitle) => {
    setIsContentChange(onChangeElementTitle)
  }
  const changeHeadingTitle = (changeHeadingTitle) => {
    setIsChangeHeadingTitle(changeHeadingTitle)
  }

  const handleSeriesDisable = (handleSeriesDisable) => {
    setIsSeriesDisable(handleSeriesDisable)
  }

  //On Click Save App Builder Details Elements Data
  const handleSaveAppElements = () => {
    setIsOnSave(true)
    setInterval(() => {
      setIsOnSave(false);
    }, 2000);
  }

  return (
    <div className="no-sidebar ABDetails">
      <div className="main_content">
        <Header />
        <div className="main_card pb0">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/builder">
                  {" "}
                  <span>Home</span>
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                App Builder
              </li>
            </ol>
          </nav>
          <div className="main_card_wrapper tabbing-card">
            <div className="wrapper-heading-area filter-area">
              <div className="title">
                {parsed.returnUrl ? (
                  <Link className="" onClick={handleReturnUrl}>
                    {" "}
                    <img src={backImage} className="mr-2" alt="" />{" "}
                    <h1 className="wrapper-heading fw-600 mb0">
                      Back To Admin
                    </h1>
                  </Link>
                ) : (
                  <Link className="" to="/app-builder">
                    {" "}
                    <img src={backImage} className="mr-2" alt="" />
                    <h1 className="wrapper-heading fw-600 mb0">
                      Back To Your Library
                    </h1>
                  </Link>
                )}
              </div>
              {seriesData == 0 ? null
                :
                <div className={!seriesData ? "d-none" : "page-filter"}>
                  <span className="savingLoader">
                    {saveLoading || abSectionLoading || isContentChange ? "Saving..." : ""}
                  </span>
                  <span className="savingLoader">
                    {saveSeriesDescLoading || isOnSave === true ? "Saving..." : ""}
                  </span>
                  <span className="savingLoader">
                    {duplicateLoading ? "Duplicating..." : ""}
                  </span>
                  <Link
                    className={abExpportLoading ? "btn primary-border-btn disabled" : "btn primary-border-btn"}
                    to={
                      "/app-builder/view/" + params.id + "?seriesId=" + seriesId
                    }
                  >
                    Teach
                  </Link>
                  {
                    abExpportLoading ?
                      <button
                        disabled
                        type="submit"
                        className="btn primary-border-btn"
                      >
                        Print
                      </button> :
                      <button
                        onClick={() => handlePrintModal()}
                        type="submit"
                        className="btn primary-border-btn"
                      >
                        Print
                      </button>
                  }
                  {
                    abExpportLoading ?
                      <button
                        disabled
                        type="submit"
                        className="btn primary-border-btn"
                      >
                        Import
                      </button> :
                      <button
                        onClick={handleImportClick}
                        type="submit"
                        className="btn primary-border-btn"
                      >
                        Import
                      </button>
                  }
                  {abExpportLoading ? (
                    <button className="btn primary-border-btn" disabled>
                      Exporting...
                    </button>
                  ) : (
                    <button
                      onClick={() => handleExportClick()}
                      type="submit"
                      className="btn primary-border-btn"
                    >
                      Export
                    </button>
                  )}

                  {abExpportLoading ? <button
                    type="submit"
                    className="btn primary-border-btn"
                  >
                    Save
                  </button> : <button
                    onClick={() => handleSaveAppElements()}
                    type="submit"
                    className="btn primary-border-btn"
                    disabled={isOnSave === true}
                  >
                    Save
                  </button>}


                  {
                    abExpportLoading ?
                      <button
                        disabled
                        type="submit"
                        className="btn primary-border-btn"
                        id="restore-point"
                      >
                        Create Restore Point
                      </button> :
                      <button
                        onClick={() => handleRestoreClick()}
                        type="submit"
                        className="btn primary-border-btn"
                        id="restore-point"
                      >
                        Create Restore Point
                      </button>
                  }

                  <Link
                    className={abExpportLoading ? "dropdown restore-dropdown detailApp-Dropdown disabled" : "dropdown restore-dropdown detailApp-Dropdown"}
                    onClick={() => handleSaveList()}
                    to="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      src={dropdownImg}
                      id="dropdownMenuTable"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      alt=""
                    />

                    <div
                      className={`dropdown-menu dropdown_collpase dropdown-table dropdown-menu-right  ${showDropdown ? "show" : ""
                        }`}
                      aria-labelledby="navbarDropdown"
                    >
                      <div className="list-area">
                        <h3 className="strip">Automatic Restore Points</h3>
                        {!!RestorePoint.rows &&
                          RestorePoint.rows.map((saveList, i) => {
                            if (
                              saveList.is_auto_saved === 1 ||
                              saveList.is_auto_saved === null
                            ) {
                              temp.push(saveList);
                            } else {
                              temp2.push(saveList);
                            }
                          })}
                        {temp.length > 0 ? (
                          temp &&
                          temp.map((saveList, i) => {
                            if (
                              saveList.is_auto_saved === 1 ||
                              saveList.is_auto_saved === null
                            ) {
                              return (
                                <div
                                  className="dropdown-item"
                                  key={i}
                                  onClick={() =>
                                    handleBuildRestoreClick(saveList.volume_id)
                                  }
                                >
                                  <span>Restore<n /></span>
                                  <span className="point-time ml-3">
                                    {moment(saveList.updated_datetime).format(
                                      "MM/DD/YYYY h:mm:ss A"
                                    )}
                                    { }
                                  </span>
                                </div>
                              );
                            }
                          })
                        ) : (
                          <h3>No data found</h3>
                        )}
                        <h3 className="strip">Manual Restore Points</h3>
                        {temp2.length > 0 ? (
                          temp2 &&
                          temp2.map((saveList, i) => {
                            if (saveList.is_auto_saved === 0) {
                              return (
                                <div
                                  className="d-flex align-items-center dropdown-item-content"
                                  key={i}
                                >
                                  <div
                                    className="dropdown-item"
                                    key={i}
                                    onClick={() =>
                                      handleBuildRestoreClick(saveList.volume_id)
                                    }
                                  >
                                    <span>{saveList.restore_point_title}</span>
                                    <span className="ml-3">
                                      {moment(saveList.updated_datetime).format(
                                        "MM/DD/YYYY h:mm:ss A"
                                      )}
                                      { }
                                    </span>
                                  </div>
                                  <div className="deleteButton mr-3">
                                    <i
                                      onClick={() =>
                                        ManualRestorePointDelete(
                                          saveList.volume_id
                                        )
                                      }
                                      className="fa fa-trash delete_restore_point"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                              );
                            }
                          })
                        ) : (
                          <h3>No data found</h3>
                        )}
                      </div>
                    </div>
                  </Link>
                </div>
              }

            </div>
          </div>
        </div>
        {
          seriesData === 0 ?
            <div className="main_card main_card_body pt0">
              <div className="unauthorized_user_image">
                <div className="main_card_wrapper">
                  <img src={unauthorizedUserImage} alt="unauthorizedUserImage" className="unauthorizedUserImage" />
                </div>
              </div>
            </div> :
            <div className="main_card main_card_body pt0">
              <div className="row" onClick={() => closeRestorePointDiv()}>
                {seriesId && (
                  <SeriesSection
                    importByAddedSeries={importByAddedSeries}
                    seriesId={seriesId}
                    seriesSectionLoading={seriesSectionLoading}
                    isImportClick={isImportClick}
                    onChangeElementTitle={onChangeElementTitle}
                    changeHeadingTitle={changeHeadingTitle}
                  />
                )}
                <Series
                  isChangeHeadingTitle={isChangeHeadingTitle}
                  selectedSeriesId={selectedSeriesId}
                  seriesId={seriesId}
                  isMakeCopy={isMakeCopy}
                  seriesSectionLoading={seriesSectionLoading}
                  handleSeriesDisable={handleSeriesDisable}
                  isSeriesDisable={isSeriesDisable}
                />
              </div>
            </div>
        }

        <Footer />
        {/* Print Modal */}
        <Modal isOpen={alert} className="fileUpload sharableLink" centered>
          <div className="modal-body modal_popup">
            <button
              type="button"
              className="close"
              onClick={() => handleCloseAlert()}
            >
              <img src={modalClose} alt="modal-close" />
            </button>
            <div className="modal_title">
              <h3>Print Option</h3>
            </div>
            <div className="modal_data">
              <form onSubmit={handleSubmitPrint}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="print-box mb-3">
                      <div className="heading">
                        <h4 className="dark-text">Spaced Out</h4>
                        <h4 className="gray-text">Don’t cut off sections</h4>
                      </div>
                      <label className="radio-style">
                        <input
                          type="radio"
                          checked={savePaper === "no"}
                          name="swal2-radio"
                          value="no"
                          onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="print-box">
                      <div className="heading">
                        <h4 className="dark-text">Tight formatting</h4>
                        <h4 className="gray-text">Save paper</h4>
                      </div>
                      <label className="radio-style">
                        <input
                          type="radio"
                          checked={savePaper === "yes"}
                          name="swal2-radio"
                          value="yes"
                          onChange={handleChange}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 modal-btn">
                    <button className="btn primary-btn w-100" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal>

        {/* Delete Modal */}
        <Modal
          isOpen={deleteRestore}
          className="modal-dialog modal-dialog-centered"
          role="document"
          centered
        >
          <div className="modal-content">
            <div className="modal-body modal_popup">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => handleDeleteClose()}
              >
                <img src={modalClose} alt="modal-close" />
              </button>
              <div className="modal_data editImageModalData">
                <div className="row">
                  <div className="col-md-12 text-center mt-5">
                    <img src={deleteAlertIcon} alt="red-info" />
                  </div>
                  <div className="col-md-12 text-center mb20 mt15">
                    <h1 className="fw-600 dark-text ">
                      Are you sure you want to delete?
                    </h1>
                  </div>
                  <div className="col-md-12 modal-btn">
                    {
                      isDelete ?
                        <button
                          className="btn secondary-btn mr-2"
                          data-dismiss="modal"
                          disabled
                        >
                          {" "}
                          cancel
                        </button> :
                        <button
                          className="btn secondary-btn mr-2"
                          data-dismiss="modal"
                          onClick={() => handleDeleteClose()}
                        >
                          {" "}
                          cancel
                        </button>
                    }

                    {
                      isDelete ?
                        <button
                          className="btn delete-border-btn ml-3"
                          disabled
                        >
                          Deleting...
                        </button> :
                        <button
                          className="btn delete-border-btn ml-3"
                          onClick={() => handleDeleteConfirm()}
                        >
                          Delete
                        </button>
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* Create Restore Point Modal */}
        <Modal isOpen={restore} className="fileUpload sharableLink" centered>
          <div className="modal-body modal_popup">
            {
              isSubmit ?
                <button
                  type="button"
                  className="close"
                  disabled
                >
                  <img src={modalClose} alt="modal-close" />
                </button> :
                <button
                  type="button"
                  className="close"
                  onClick={() => handleCloseRestore()}
                >
                  <img src={modalClose} alt="modal-close" />
                </button>
            }

            <div className="modal_title">
              <h3>Name Current Version</h3>
            </div>
            <div className="modal_data">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <input
                      type="text"
                      name="build-name"
                      className="form-control"
                      value={saveBuildName}
                      onChange={handleValueChange}
                    />
                  </div>
                  <div className="col-md-12 modal-btn">
                    {
                      isSubmit ?
                        <button
                          className="btn primary-btn"
                          type="submit"
                          disabled
                        >
                          Submiting...
                        </button> :
                        <button
                          className="btn primary-btn"
                          type="submit"
                          disabled={!buttonEnable}
                          onClick={(e) => handleSubmitRestore(e)}
                        >
                          Submit
                        </button>
                    }

                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal>

        {/* Update And Make Copy Modal */}
        <Modal
          isOpen={buildRestore}
          className="fileUpload sharableLink restore-modal"
          centered
        >
          <div className="modal-body modal_popup">
            <button
              type="button"
              className="close"
              onClick={() => handleBuildCloseRestore()}
            >
              <img src={modalClose} alt="modal-close" />
            </button>
            <div className="modal_title">
              <h5>How do you want to restore this version?</h5>
            </div>
            <div className="modal_data">
              <div className="row mt-4">
                <div className="col-md-6">
                  {
                    isUpdate ?
                      <button
                        className="btn primary-btn"
                        type="submit"
                        disabled
                      >
                        Updating Current...
                      </button> :
                      <button
                        className="btn primary-btn"
                        type="submit"
                        onClick={() => handleUpdateCurrent({ abBuildId })}
                      >
                        Update Current
                      </button>
                  }

                </div>
                <div className="col-md-6">
                  {
                    isUpdate ?
                      <button
                        className="btn primary-btn"
                        type="submit"
                        disabled
                      >
                        Make a copy
                      </button> :
                      <button
                        className="btn primary-btn"
                        type="submit"
                        onClick={() => handleMakeCopy({ abBuildId })}
                      >
                        Make a copy
                      </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {/* ScrollToTop Button */}
        {scrollToTop && <div onClick={scrollTop} className="scrollToTop"><i className="fa fa-arrow-up"></i></div>}

        {/* Manual Restore Point Build Limit Modal */}
        <Modal isOpen={bulidLimit} className="fileUpload restoreLimit" centered>
          <div className="modal-content">
            <div className="modal-body modal_popup">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => handleBuildLimit()}
              >
                <img src={modalClose} alt="modal-close" />
              </button>
              <div className="modal_data editImageModalData">
                <div className="row">
                  <div className="col-md-12 text-center mt-5">
                    <img src={deleteAlertIcon} alt="red-info" />
                  </div>
                  <div className="col-md-12 text-center mb20 mt15">
                    <span className="fw-600 dark-text ">
                      You can only save 5 restore points. Please delete any if
                      you want to create a new one.
                    </span>
                  </div>
                  <div className="col-md-12 modal-btn">
                    <button
                      className="btn primary-btn mr-2"
                      onClick={() => handleBuildLimit()}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      {/* Export Builder Component */}
      <ExportAppBuilder isHidden={true} ref={componentRef} />
    </div>
  );
};

export default Appbuilderdetail;
