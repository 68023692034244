import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import { handleFetchMBTeachingElements } from "store/actions";
import CkEditor from "components/common/CustomCkEditor/CkEditor";
import queryString from "query-string";
import { imageUrl } from "services/aws";

export const MessageBuilderPrintBuilds = React.forwardRef((props, ref) => {
  const { MBTeachingElements } = useSelector(
    (state) => state.MessageBuilderTeaching
  );
  const { isAuth } = useSelector((state) => state.auth);
  const [isMediaUrl, setIsMediaUrl] = useState();
  const [successData, setSuccessData] = useState(true);
  const [isVideoUrl, setIsVideoUrl] = useState();
  const [inValidUrl, setInValidUrl] = useState(false);
  const [isPrintElement, setIsPrintElement] = useState(false);

  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  let elementTitle;
  useEffect(() => {
    if (isAuth) {
      dispatch(
        handleFetchMBTeachingElements(
          {
            id: params.id,
            is_series: 0,
          },
          (status) => {
            if (status) {
              setIsPrintElement(true);
            }
          }
        )
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      MBTeachingElements.sycu_build_elements_details &&
      isPrintElement == true
    ) {
      props.print();
    }
  }, [MBTeachingElements, isPrintElement]);

  useEffect(() => {
    let driftDiv = document.getElementById("drift-frame-controller");
    if (!!driftDiv) {
      driftDiv.style.display = "none";
    }
    return () => {
      if (!!driftDiv) {
        driftDiv.style.display = "block";
      }
    };
  }, []);

  const savePaperYes = `@page{
    margin-top: 5mm;
    margin-bottom: 5mm;
  `;

  return (
    <div className="withoutCkeditorTooltip col-12">
      {parsed.savepaper == "no" ? (
        <style>{props.css}</style>
      ) : (
        <style>{savePaperYes}</style>
      )}
      <div className="" ref={ref}>
        <div className="main_card_wrapper tabbing-card app-builder-teaching print-view">
          <div className="message-form">
            <h2 id="message-heading" className="box-border">
              {MBTeachingElements.build_title}
            </h2>
            <input
              id="renameit"
              type="text"
              className="box-border-subtitle"
              name="message-heading"
              disabled
              value={MBTeachingElements.build_sub_title}
              placeholder="NEW MESSAge"
              onChange={(e) => {}}
            />

            {!!MBTeachingElements?.sycu_build_elements_details &&
              MBTeachingElements.sycu_build_elements_details.map(
                (element, i) => (
                  <div key={i}>
                    {element.build_elements_name == "BIG IDEA / BIBLE" &&
                    element.is_visible == 1 ? (
                      <div className="tools">
                        <div className="tools-area">
                          <table className="w-100 p-0">
                            <tbody>
                              <tr className="tools-left print-tools-box">
                                <td className="bib-left">
                                  <div className="my_wrapper">
                                    <center>
                                      <input
                                        type="text"
                                        className="etitle"
                                        value={element.title}
                                        onChange={(e) => {}}
                                        disabled
                                      />
                                      <span>
                                        <pre className="subtitle" disabled>
                                          {element.content}
                                        </pre>
                                      </span>
                                    </center>
                                  </div>
                                </td>
                                <td className="bib-center"></td>
                                <td className="bib-right">
                                  <div className="my_wrapper">
                                    <center>
                                      <input
                                        type="text"
                                        className="etitle"
                                        value={element.extra_title}
                                        onChange={(e) => {}}
                                        disabled
                                      />
                                      <span>
                                        <pre className="subtitle" disabled>
                                          {element.extra_content}
                                        </pre>
                                      </span>
                                    </center>
                                  </div>
                                </td>
                              </tr>
                              {element?.extra_title_1 ||
                              element?.extra_content_1 ? (
                                <tr
                                  className="tools-left print-tools-box"
                                  style={{ marginTop: "2%" }}
                                >
                                  <td className="bib-left1 w-100">
                                    <div className="my_wrapper">
                                      <center>
                                        <input
                                          type="text"
                                          className="etitle"
                                          value={element.extra_title_1}
                                          onChange={(e) => {}}
                                          disabled
                                        />
                                        <span>
                                          <pre className="subtitle" disabled>
                                            {element.extra_content_1}
                                          </pre>
                                        </span>
                                      </center>
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : null}

                    {element.build_elements_name == "Section" &&
                    element.is_visible == 1 ? (
                      <div className="lisection esection">
                        <div
                          className="boxheader_section"
                          id="drop-element-65696"
                        >
                          <input
                            type="hidden"
                            className="current-element"
                            value="SECTION"
                            onChange={(e) => {}}
                          />
                          <input
                            type="hidden"
                            className="lilimain"
                            value="8260077"
                            onChange={(e) => {}}
                          />
                          <input
                            type="text"
                            className="esection"
                            name="field[8260077][elementtitle]"
                            value={element.title}
                            onChange={(e) => {}}
                            disabled
                          />
                          <input
                            type="hidden"
                            className="etype"
                            name="field[8260077][elementtype]"
                            value="section"
                            onChange={(e) => {}}
                          />
                        </div>
                      </div>
                    ) : null}

                    {element.build_elements_name == "Header" &&
                    element.is_visible == 1 ? (
                      <div className="boxheader" id="drop-element-93624">
                        <input
                          type="hidden"
                          className="current-element"
                          value="HEADER"
                          onChange={(e) => {}}
                        />
                        <input
                          type="hidden"
                          className="lilimain"
                          value="8260078"
                          onChange={(e) => {}}
                        />
                        <input
                          type="text"
                          className="eheader"
                          name="field[8260078][elementtitle]"
                          value={element.title}
                          onChange={(e) => {}}
                          disabled
                        />
                        <input
                          type="hidden"
                          name="field[8260078][elementtype]"
                          className="etype"
                          value="header"
                          onChange={(e) => {}}
                        />
                      </div>
                    ) : null}

                    {element.build_elements_name == "Big Idea (Legacy)" &&
                    element.is_visible == 1 ? (
                      <div className="tools-legacy">
                        <div className="big-legacy">
                          <input
                            type="text"
                            className="etitle"
                            value={element.title}
                            disabled
                          />
                          <input
                            type="text"
                            className="subtitle"
                            value={element.content}
                            disabled
                          />
                        </div>
                      </div>
                    ) : null}

                    {(element.build_elements_name == "Talk" ||
                      element.build_elements_name == "Scripture" ||
                      element.build_elements_name == "Story" ||
                      element.build_elements_name == "Image" ||
                      element.build_elements_name == "Video" ||
                      element.build_elements_name == "Question" ||
                      element.build_elements_name == "Object Lesson" ||
                      element.build_elements_name == "Activity" ||
                      element.build_elements_name == "Discussion" ||
                      element.build_elements_name == "Prayer" ||
                      element.build_elements_name == "Reflection" ||
                      element.build_elements_name == "Response" ||
                      element.build_elements_name == "Music" ||
                      element.build_elements_name == "Poll" ||
                      element.build_elements_name == "Add Your Own") &&
                    element.is_visible == 1 ? (
                      <div
                        className={`flipbox MBdetail  ${elementTitle}`}
                        key={i}
                      >
                        <div
                          className={
                            "flipbox-left " + element.build_elements_name
                          }
                        ></div>
                        <div
                          className={`flipbox-right box-border border-left-bar ${element.build_elements_name}`}
                        >
                          <div
                            id="headingTwo"
                            className=""
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="true"
                            aria-controls="collapseTwo"
                          >
                            <div className="flipbox-title">
                              <input
                                className="box-border-title w-100"
                                disabled
                                type="text"
                                name=""
                                placeholder=""
                                value={element.title}
                                id=""
                                onChange={(e) => {}}
                              />
                            </div>
                          </div>
                          <div
                            id="collapseTwo"
                            className={
                              element.content || element.media_url
                                ? "collapse show mt15"
                                : "collapse mt15"
                            }
                            aria-labelledby="headingTwo"
                          >
                            {element.build_elements_name == "Image" ? (
                              <div
                                id="collapseTwo cust-img"
                                className="collapse show element_image"
                                aria-labelledby="headingTwo"
                              >
                                <center>
                                  {element.media_url ? (
                                    <img
                                      className="seriesImg"
                                      src={element.media_url.replace(
                                        imageUrl.S3BUILDER_URL,
                                        imageUrl.BUILDER_DISPLAY_URL
                                      )}
                                    />
                                  ) : (
                                    <div className="nomedia">
                                      No Media Found
                                    </div>
                                  )}
                                </center>
                              </div>
                            ) : null}
                            {element.build_elements_name == "Video" ? (
                              (isVideoUrl === undefined &&
                                !element.media_url) ||
                              successData == false ? (
                                <div>
                                  {successData != true &&
                                    inValidUrl == true &&
                                    isMediaUrl == undefined && (
                                      <div className="viderror">
                                        <b>
                                          Video link invalid! Please try again.
                                        </b>
                                      </div>
                                    )}
                                </div>
                              ) : successData == true ? (
                                <center>
                                  {" "}
                                  <iframe
                                    src={
                                      isMediaUrl
                                        ? isMediaUrl
                                        : element.media_url
                                    }
                                    frameBorder="0"
                                    className="iframe mt-3 mb-2"
                                    allowFullScreen
                                  ></iframe>{" "}
                                </center>
                              ) : null
                            ) : null}

                            <CkEditor
                              name={element.element_name}
                              data={element.content}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                )
              )}
          </div>
        </div>
      </div>
    </div>
  );
});
export default MessageBuilderPrintBuilds;
